var publicRegistrationConstants = {
  GET_FORM: 'REGISTRATION_GET_FORM',
  GET_FORM_SUCCESS: 'REGISTRATION_GET_FORM_SUCCESS',
  GET_FORM_FAILURE: 'REGISTRATION_GET_FORM_FAILURE',
  GET_FORM_PREVIEW: 'REGISTRATION_GET_FORM_PREVIEW',
  GET_FORM_PREVIEW_SUCCESS: 'REGISTRATION_GET_FORM_PREVIEW_SUCCESS',
  GET_FORM_PREVIEW_FAILURE: 'REGISTRATION_GET_FORM_PREVIEW_FAILURE',
  REGISTER: 'REGISTRATION_REGISTER',
  REGISTER_SUCCESS: 'REGISTRATION_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTRATION_REGISTER_FAILURE',
  CREATE_PAYMENT_INTENT: 'REGISTRATION_CREATE_PAYMENT_INTENT',
  CREATE_PAYMENT_INTENT_SUCCESS: 'REGISTRATION_CREATE_PAYMENT_INTENT_SUCCESS',
  CREATE_PAYMENT_INTENT_FAILURE: 'REGISTRATION_CREATE_PAYMENT_INTENT_FAILURE',
  DECLINE_REQUEST: 'DECLINE_REQUEST',
  DECLINE_SUCCESS: 'DECLINE_SUCCESS',
  DECLINE_FAILURE: 'DECLINE_FAILURE',
  CANCEL_CHECKOUT: 'REGISTRATION_CANCEL_CHECKOUT'
};
export default publicRegistrationConstants;