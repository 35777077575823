import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { handleResponse, requestHeader } from '../helpers/request-helpers';
var logService = {
  logInformation: logInformation,
  logDebug: logDebug,
  logError: logError
};
function logInformation(text) {
  return log(text, 'info');
}
function logDebug(text) {
  return log(text, 'debug');
}
function logError(errorObj) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      text: errorObj.stack
    })
  };
  return fetch("".concat(config.apiUrl, "/log/error"), requestOptions).then(handleResponse);
}
function log(text, type) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      text: text
    })
  };
  return fetch("".concat(config.apiUrl, "/log/").concat(type), requestOptions).then(handleResponse);
}
export default logService;