import { messageConstants } from '../constants/actions';
export default function message() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case messageConstants.SUCCESS:
      return {
        type: 'success',
        text: action.message,
        key: action.key
      };
    case messageConstants.ERROR:
      return {
        type: 'error',
        text: action.response.message,
        errorCodes: action.response.errorCodes,
        key: action.key
      };
    case messageConstants.CLEAR:
      return {};
    default:
      return state;
  }
}