var eventFeatureType = {
  EVENT_DETAILS: 0,
  BRANDING: 100,
  REGISTRATION: 200,
  REGISTRATION_FORM: 210,
  REGISTRATION_LANDING_PAGE: 220,
  REGISTRATION_PUBLISH: 230,
  TEMPLATES: 300,
  EMAIL_SENDER: 400,
  PARTICIPANTS: 500,
  VOUCHERS: 510,
  INFO_PAGES: 600,
  POLLS: 700,
  VIDEOS: 750,
  DOCUMENTS: 800,
  SPEAKERS: 900,
  AGENDA: 1000,
  LOBBY_PAGE: 1100,
  PLATFORM_SETTINGS: 1300,
  PROMOTED_MESSAGES: 1400,
  TIMELINE: 1500,
  SPONSORS: 1600,
  SUPPORT: 9000,
  CHAT: 9100,
  RTMP: 9200
};
export default eventFeatureType;