import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import dropdownType from './dropdown-type-constants';
import en from '../../translations/countries/en.json';
import no from '../../translations/countries/no.json';
var countries = function countries(language) {
  var translations = language === 'nb-NO' ? no : en;
  return Object.keys(translations).map(function (key) {
    return {
      id: key,
      options: {
        label: translations[key],
        value: key,
        isHidden: false
      }
    };
  });
};
var getDropdownListByType = function getDropdownListByType(type, language) {
  switch (type) {
    case dropdownType.COUNTRIES:
      return countries(language);
    default:
      return undefined;
  }
};
var getCountryNameByCode = function getCountryNameByCode(code, language) {
  var translations = language === 'nb-NO' ? no : en;
  return translations[code === null || code === void 0 ? void 0 : code.toLowerCase()];
};
export { countries, getDropdownListByType, getCountryNameByCode };