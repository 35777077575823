var config = {
  baseApiUrl: process.env.API_BASE_URL,
  apiUrl: process.env.API_BASE_URL + process.env.API_VERISON_URL,
  liveEventUrl: process.env.LIVE_EVENT_URL,
  oldSystemLiveEventUrl: process.env.OLD_SYSTEM_LIVE_EVENT_URL,
  googleApiKey: 'AIzaSyBnF2n1nPZ-VoUhV4Sr157Yl-rIHuDlz90',
  stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
  env: process.env.NODE_ENV,
  signupEnabled: process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local',
  addonsEnabled: process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local'
};
export default config;