var _namedPollTypes;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var pollType = {
  MULTIPLE_ANSWERS: 0,
  OPEN_TEXT: 1,
  QUIZ: 2,
  TREASURE_HUNT: 3,
  CODED_POLLS: 4,
  CODED_QUIZZES: 5,
  RATING: 10
};
var namedPollTypes = (_namedPollTypes = {}, _defineProperty(_namedPollTypes, pollType.MULTIPLE_ANSWERS, 'poll'), _defineProperty(_namedPollTypes, pollType.OPEN_TEXT, 'poll'), _defineProperty(_namedPollTypes, pollType.RATING, 'poll'), _defineProperty(_namedPollTypes, pollType.QUIZ, 'quiz'), _defineProperty(_namedPollTypes, pollType.TREASURE_HUNT, 'treasure_hunt'), _defineProperty(_namedPollTypes, pollType.CODED_POLLS, 'coded_polls'), _defineProperty(_namedPollTypes, pollType.CODED_QUIZZES, 'coded_quizzes'), _namedPollTypes);
var pollSubPageTypes = {
  POLLS: 'polls',
  SINGLE_POLLS: 'singlePolls',
  TEMPLATES: 'templates',
  QUIZ: 'quiz'
};
var quizTypeOptions = [{
  value: pollType.QUIZ,
  label: 'Quiz'
}, {
  value: pollType.TREASURE_HUNT,
  label: 'Treasure hunt'
}];
var singlePollTypeOptions = [{
  value: pollType.MULTIPLE_ANSWERS,
  label: 'Multiple answers'
}, {
  value: pollType.OPEN_TEXT,
  label: 'Open-text Poll'
}, {
  value: pollType.RATING,
  label: 'Rating'
}];
export { pollType, namedPollTypes, pollSubPageTypes, quizTypeOptions, singlePollTypeOptions };