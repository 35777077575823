var _workspaceFeatureType;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import eventFeatureType from './event-features-constants';
var workspaceFeatureState = {
  ACTIVE: 0,
  AVAILABLE: 1
};
var workspaceFeatureType = (_workspaceFeatureType = {}, _defineProperty(_workspaceFeatureType, eventFeatureType.SUPPORT, {
  limitProp: 'support',
  featureProp: 'isSupportEnabled'
}), _defineProperty(_workspaceFeatureType, eventFeatureType.CHAT, {
  limitProp: 'chat',
  featureProp: 'isChatEnabled'
}), _defineProperty(_workspaceFeatureType, eventFeatureType.RTMP, {
  limitProp: 'rtmp',
  featureProp: 'isRTMPStreamEnabled'
}), _defineProperty(_workspaceFeatureType, eventFeatureType.AGENDA, {
  limitProp: 'agenda',
  featureProp: 'isAgendaEnabled'
}), _defineProperty(_workspaceFeatureType, eventFeatureType.SPEAKERS, {
  limitProp: 'speakers',
  featureProp: 'isSpeakersEnabled'
}), _defineProperty(_workspaceFeatureType, eventFeatureType.LOBBY_PAGE, {
  limitProp: 'lobby',
  featureProp: 'isLobbyPageEnabled'
}), _defineProperty(_workspaceFeatureType, eventFeatureType.SPONSORS, {
  limitProp: 'sponsors',
  featureProp: 'isSponsorsEnabled'
}), _workspaceFeatureType);
export { workspaceFeatureState, workspaceFeatureType };