import "core-js/modules/es.string.link.js";
import shortid from 'shortid';
import { appIntl } from '../../../IntlGlobalProvider';
import { settingType } from '../../../constants/types';
import { badgeElementType } from '../../../constants/types/badge-elements-constants';
function getLinkSetting(widget, required) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'link',
    type: settingType.LINK,
    placeholder: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Link_placeholder',
      defaultMessage: 'e.g. https://www.google.no'
    }),
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Link',
      defaultMessage: 'Link'
    }),
    value: widget.link,
    required: required
  };
}
function getFileUploadSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'fileUrl',
    type: settingType.FILE,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.File',
      defaultMessage: 'File'
    }),
    value: widget.link,
    required: true
  };
}
function getSettingsByType(element) {
  var intl = appIntl();
  switch (element.type) {
    case badgeElementType.IMAGE:
      return [getFileUploadSetting(element), getLinkSetting(element, false)];
    default:
      return [];
  }
}
export default getSettingsByType;