import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import agendaConstants from '../constants/actions/agenda-constants';
export default function agendaReducer() {
  var _action$result$agenda, _action$result, _action$result$agenda2, _action$result2;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case agendaConstants.GET_AGENDA_BY_EVENT_ID_REQUEST:
      return {
        success: false,
        loading: true,
        error: undefined
      };
    case agendaConstants.GET_AGENDA_BY_EVENT_ID_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        success: true,
        loading: false,
        error: undefined
      });
    case agendaConstants.GET_AGENDA_BY_EVENT_ID_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case agendaConstants.ADD_TRACK_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        trackSuccess: false,
        trackLoading: true,
        error: undefined
      });
    case agendaConstants.ADD_TRACK_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), (_action$result$agenda = (_action$result = action.result) === null || _action$result === void 0 ? void 0 : _action$result.agenda) !== null && _action$result$agenda !== void 0 ? _action$result$agenda : {}), {}, {
        trackSuccess: true,
        trackLoading: false,
        error: undefined
      });
    case agendaConstants.ADD_TRACK_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        trackSuccess: false,
        trackLoading: false,
        error: action.error
      });
    case agendaConstants.ADD_SESSION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessionSuccess: false,
        sessionLoading: true,
        error: undefined
      });
    case agendaConstants.ADD_SESSION_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), (_action$result$agenda2 = (_action$result2 = action.result) === null || _action$result2 === void 0 ? void 0 : _action$result2.agenda) !== null && _action$result$agenda2 !== void 0 ? _action$result$agenda2 : {}), {}, {
        sessionSuccess: true,
        sessionLoading: false,
        error: undefined
      });
    case agendaConstants.ADD_SESSION_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessionSuccess: false,
        sessionLoading: false,
        error: action.error
      });
    case agendaConstants.UPDATE_TRACK_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        trackSuccess: false,
        trackLoading: true,
        error: undefined
      });
    case agendaConstants.UPDATE_TRACK_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        trackSuccess: true,
        trackLoading: false,
        error: undefined
      });
    case agendaConstants.UPDATE_TRACK_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        trackSuccess: false,
        trackLoading: false,
        error: action.error
      });
    case agendaConstants.UPDATE_SESSION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessionSuccess: false,
        sessionLoading: true,
        error: undefined
      });
    case agendaConstants.UPDATE_SESSION_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result.agenda), {}, {
        sessionSuccess: true,
        sessionLoading: false,
        error: undefined
      });
    case agendaConstants.UPDATE_SESSION_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessionSuccess: false,
        sessionLoading: false,
        error: action.error
      });
    case agendaConstants.DELETE_TRACK_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        trackSuccess: false,
        trackLoading: true,
        error: undefined
      });
    case agendaConstants.DELETE_TRACK_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        trackSuccess: true,
        trackLoading: false,
        error: undefined
      });
    case agendaConstants.DELETE_TRACK_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        trackSuccess: false,
        trackLoading: false,
        error: action.error
      });
    case agendaConstants.DELETE_SESSION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessionSuccess: false,
        sessionLoading: true,
        error: undefined
      });
    case agendaConstants.DELETE_SESSION_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        sessionSuccess: true,
        sessionLoading: false,
        error: undefined
      });
    case agendaConstants.DELETE_SESSION_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessionSuccess: false,
        sessionLoading: false,
        error: action.error
      });
    case agendaConstants.CLEAR_ERRORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: undefined
      });
    case agendaConstants.GET_MYAGENDA_REQUEST:
      return {
        success: false,
        loading: true,
        error: undefined
      };
    case agendaConstants.GET_MYAGENDA_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        success: true,
        loading: false,
        error: undefined
      });
    case agendaConstants.GET_MYAGENDA_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case agendaConstants.GET_SESSIONS_BY_EVENT_ID_REQUEST:
      return {
        success: false,
        loading: true,
        error: undefined
      };
    case agendaConstants.GET_SESSIONS_BY_EVENT_ID_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessions: action.result,
        success: true,
        loading: false,
        error: undefined
      });
    case agendaConstants.GET_SESSIONS_BY_EVENT_ID_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessions: [],
        success: false,
        loading: false,
        error: action.error
      });
    default:
      return state;
  }
}