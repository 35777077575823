import "core-js/modules/es.object.keys.js";
import jwtDecode from 'jwt-decode';
function getUser() {
  if (localStorage.getItem('user')) {
    return JSON.parse(localStorage.getItem('user'));
  }
  return null;
}
function getIdToken() {
  if (localStorage.getItem('tokens')) {
    var _JSON$parse;
    return (_JSON$parse = JSON.parse(localStorage.getItem('tokens'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.id_token;
  }
  return null;
}
function getDecodedToken() {
  var idToken = getIdToken();
  return idToken && jwtDecode(idToken);
}
function isAdminUser() {
  var token = getDecodedToken();
  if (token) {
    return token.role !== undefined && (token.role === 'Admin' || token.role.indexOf('Admin') > -1);
  }
  return false;
}
function getUserWorkspaceCount() {
  var user = getUser();
  return (user === null || user === void 0 ? void 0 : user.workspaceCount) || 0;
}
function isPartnerUser() {
  var token = getDecodedToken();
  if (token) {
    return token.role !== undefined && (token.role === 'Partner' || token.role.indexOf('Partner') > -1);
  }
  return false;
}
function isAdminOrPartner() {
  var token = getDecodedToken();
  if (token) {
    return token.role !== undefined && (token.role === 'Partner' || token.role.indexOf('Partner') > -1 || token.role === 'Admin' || token.role.indexOf('Admin') > -1);
  }
  return false;
}
export { isAdminUser, isPartnerUser, isAdminOrPartner, getUserWorkspaceCount };