import liveDocumentsService from '../services/live-documents-service';
import { liveDocumentsConstants } from '../constants/actions';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var liveDocumentsActions = {
  getDocuments: getDocuments,
  createDocuments: createDocuments,
  updateDocuments: updateDocuments,
  updatePartialDocuments: updatePartialDocuments,
  deleteDocuments: deleteDocuments,
  reorderDocuments: reorderDocuments
};
function getDocuments(eventId) {
  return function (dispatch) {
    dispatch(request());
    liveDocumentsService.getDocuments(eventId).then(function (documents) {
      return dispatch(success(documents));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: liveDocumentsConstants.GET_DOCUMENTS
    };
  }
  function success(items) {
    return {
      type: liveDocumentsConstants.GET_DOCUMENTS_SUCCESS,
      items: items
    };
  }
  function failure(error) {
    return {
      type: liveDocumentsConstants.GET_DOCUMENTS_FAILURE,
      error: error
    };
  }
}
function createDocuments(eventId, document, onContextUpdated) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(createDocument());
    liveDocumentsService.createDocuments(eventId, document).then(function (message) {
      dispatch(success(message));
      if (onContextUpdated) {
        onContextUpdated();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Documents.create_successful',
        defaultMessage: 'Document was successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function createDocument() {
    return {
      type: liveDocumentsConstants.CREATE_DOCUMENTS
    };
  }
  function success() {
    return {
      type: liveDocumentsConstants.CREATE_DOCUMENTS_SUCCESS,
      document: document
    };
  }
  function failure(error) {
    return {
      type: liveDocumentsConstants.CREATE_DOCUMENTS_FAILURE,
      error: error
    };
  }
}
function updateDocuments(eventId, documentId, document, onContextUpdated) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(updateDocument(document));
    liveDocumentsService.updateDocuments(eventId, documentId, document).then(function (item) {
      dispatch(success(item));
      if (onContextUpdated) {
        onContextUpdated();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Documents.changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function updateDocument() {
    return {
      type: liveDocumentsConstants.UPDATE_DOCUMENTS,
      document: document
    };
  }
  function success(item) {
    return {
      type: liveDocumentsConstants.UPDATE_DOCUMENTS_SUCCESS,
      document: item
    };
  }
  function failure(error) {
    return {
      type: liveDocumentsConstants.UPDATE_DOCUMENTS_FAILURE,
      error: error
    };
  }
}
function updatePartialDocuments(eventId, entryId, document) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(updateDocument({
      id: entryId,
      request: document
    }));
    liveDocumentsService.updatePartialDocuments(eventId, entryId, document).then(function (item) {
      dispatch(success(item));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Documents.changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      dispatch(failure(entryId, error));
      dispatch(messageActions.error(error));
    });
  };
  function updateDocument(item) {
    return {
      type: liveDocumentsConstants.UPDATE_DOCUMENTS_PARTIAL,
      document: item
    };
  }
  function success(item) {
    return {
      type: liveDocumentsConstants.UPDATE_DOCUMENTS_PARTIAL_SUCCESS,
      document: item
    };
  }
  function failure(error) {
    return {
      type: liveDocumentsConstants.UPDATE_DOCUMENTS_PARTIAL_FAILURE,
      document: {
        entryId: entryId,
        error: error
      }
    };
  }
}
function deleteDocuments(eventId, documentId) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(documentId));
    liveDocumentsService.deleteDocuments(eventId, documentId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Documents.delete_successful',
        defaultMessage: 'Document was successfully deleted'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: liveDocumentsConstants.DELETE_DOCUMENTS,
      documentId: documentId
    };
  }
  function success(isSuccess) {
    var result = {
      isSuccess: isSuccess,
      documentId: documentId
    };
    return {
      type: liveDocumentsConstants.DELETE_DOCUMENTS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: liveDocumentsConstants.DELETE_DOCUMENTS_FAILURE,
      error: error
    };
  }
}
function reorderDocuments(eventId, documentIds, onContextUpdated) {
  return function (dispatch) {
    dispatch(createMessage());
    liveDocumentsService.reorderDocuments(eventId, documentIds).then(function (documents) {
      dispatch(success(documents));
      if (onContextUpdated) {
        onContextUpdated();
      }
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function createMessage() {
    return {
      type: liveDocumentsConstants.REORDER_DOCUMENTS
    };
  }
  function success(documents) {
    return {
      type: liveDocumentsConstants.REORDER_DOCUMENTS_SUCCESS,
      documents: documents
    };
  }
  function failure(error) {
    return {
      type: liveDocumentsConstants.REORDER_DOCUMENTS_FAILURE,
      error: error
    };
  }
}
export default liveDocumentsActions;