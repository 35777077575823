var liveDocumentsConstants = {
  GET_DOCUMENTS: 'DOCUMENTS_GET',
  GET_DOCUMENTS_SUCCESS: 'DOCUMENTS_GET_SUCCESS',
  GET_DOCUMENTS_FAILURE: 'DOCUMENTS_GET_FAILURE',
  CREATE_DOCUMENTS: 'DOCUMENTS_CREATE',
  CREATE_DOCUMENTS_SUCCESS: 'DOCUMENTS_CREATE_SUCCESS',
  CREATE_DOCUMENTS_FAILURE: 'DOCUMENTS_CREATE_FAILURE',
  UPDATE_DOCUMENTS: 'DOCUMENTS_UPDATE',
  UPDATE_DOCUMENTS_SUCCESS: 'DOCUMENTS_UPDATE_SUCCESS',
  UPDATE_DOCUMENTS_FAILURE: 'DOCUMENTS_UPDATE_FAILURE',
  UPDATE_DOCUMENTS_PARTIAL: 'DOCUMENTS_PARTIAL_UPDATE',
  UPDATE_DOCUMENTS_PARTIAL_SUCCESS: 'DOCUMENTS_PARTIAL_UPDATE_SUCCESS',
  UPDATE_DOCUMENTS_PARTIAL_FAILURE: 'DOCUMENTS_PARTIAL_UPDATE_FAILURE',
  DELETE_DOCUMENTS: 'DOCUMENTS_DELETE',
  DELETE_DOCUMENTS_SUCCESS: 'DOCUMENTS_DELETE_SUCCESS',
  DELETE_DOCUMENTS_FAILURE: 'DOCUMENTS_DELETE_FAILURE',
  REORDER_DOCUMENTS: 'REORDER_DOCUMENTS',
  REORDER_DOCUMENTS_SUCCESS: 'REORDER_DOCUMENTS_SUCCESS',
  REORDER_DOCUMENTS_FAILURE: 'REORDER_DOCUMENTS_FAILURE'
};
export default liveDocumentsConstants;