import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponse, handleResponseWithFormFields } from '../helpers/request-helpers';
var partnerService = {
  getAll: getAll,
  getPartner: getPartner,
  createPartner: createPartner,
  updatePartner: updatePartner,
  updatePartnerUsers: updatePartnerUsers,
  deletePartnerUser: deletePartnerUser
};
function getAll() {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/partner"), requestOptions).then(handleResponse);
}
function getPartner(partnerId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/partner/").concat(partnerId), requestOptions).then(handleResponse);
}
function createPartner(partner) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify(partner)
  };
  return fetch("".concat(config.apiUrl, "/partner/create"), requestOptions).then(handleResponseWithFormFields);
}
function updatePartner(partner, partnerId) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify(partner)
  };
  return fetch("".concat(config.apiUrl, "/partner/").concat(partnerId), requestOptions).then(handleResponseWithFormFields);
}
function updatePartnerUsers(users, partnerId) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify({
      partnerId: partnerId,
      users: users
    })
  };
  return fetch("".concat(config.apiUrl, "/partner/users"), requestOptions).then(handleResponse);
}
function deletePartnerUser(partnerId, email) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader(),
    body: JSON.stringify({
      partnerId: partnerId,
      email: email
    })
  };
  return fetch("".concat(config.apiUrl, "/partner/user"), requestOptions).then(handleResponse);
}
export default partnerService;