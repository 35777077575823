var eventStep = {
  DASHBOARD: 0,
  GENERAL: 1,
  FORM: 2,
  LANDING_PAGE: 3,
  SUCCESS_MESSAGE: 4,
  SUCCESS_EMAIL: 5,
  LOBBY_PAGE: 6,
  BADGES: 7
};
export default eventStep;