function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.string.trim-start.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
// import React from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { SortOrder } from 'react-base-table';
function setSticky(item, columns) {
  if (existsInArray(columns, item)) {
    return 'left';
  }
  return undefined;
}
function existsInArray(array, field) {
  return array.indexOf(field) > -1;
}
function getStickyValue(column) {
  if (column.sticky === 'left' || column.sticky === 'right') {
    return column.sticky;
  }
  if (column.parent) {
    return getStickyValue(column.parent);
  }
  return null;
}
function setColumnWidth(data, accessor, headerText) {
  var maxWidth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 480;
  var cellLength = Math.max.apply(Math, _toConsumableArray(data.map(function (row) {
    var _row$children;
    var value = '';
    if (!(row !== null && row !== void 0 && row[accessor]) && (row === null || row === void 0 || (_row$children = row.children) === null || _row$children === void 0 ? void 0 : _row$children.length) > 0) {
      var _row$children$map$sor = row.children.map(function (childRow) {
        return childRow === null || childRow === void 0 ? void 0 : childRow[accessor];
      }).sort(function (a, b) {
        var _b$length, _a$length;
        return ((_b$length = b === null || b === void 0 ? void 0 : b.length) !== null && _b$length !== void 0 ? _b$length : 0) - ((_a$length = a === null || a === void 0 ? void 0 : a.length) !== null && _a$length !== void 0 ? _a$length : 0);
      });
      var _row$children$map$sor2 = _slicedToArray(_row$children$map$sor, 1);
      value = _row$children$map$sor2[0];
    } else if (typeof accessor === 'string') {
      value = row === null || row === void 0 ? void 0 : row[accessor];
    } else {
      value = accessor(row);
    }
    if (Array.isArray(value)) {
      value = value.join(', ');
    }
    if (typeof value === 'number') return value.toString().length;
    return (value || '').length;
  })).concat([headerText.length]));
  var magicSpacing = 6.5;
  var arrowSize = 32;
  return Math.min(maxWidth, Math.round(cellLength * magicSpacing) + arrowSize);
}
function columnIsLastLeftSticky(columnId, myColumns) {
  var index = myColumns.findIndex(function (_ref) {
    var id = _ref.id;
    return id === columnId;
  });
  var column = myColumns[index];
  var nextColumn = myColumns[index + 1];
  var columnIsLeftSticky = getStickyValue(column) === 'left';
  var nextColumnIsLeftSticky = nextColumn && getStickyValue(nextColumn) === 'left';
  return columnIsLeftSticky && !nextColumnIsLeftSticky;
}
function columnIsFirstRightSticky(columnId, myColumns) {
  var index = myColumns.findIndex(function (_ref2) {
    var id = _ref2.id;
    return id === columnId;
  });
  var column = myColumns[index];
  var prevColumn = myColumns[index - 1];
  var columnIsRightSticky = getStickyValue(column) === 'right';
  var prevColumnIsRightSticky = prevColumn && getStickyValue(prevColumn) === 'right';
  return columnIsRightSticky && !prevColumnIsRightSticky;
}
function getMarginRight(columnId, myColumns) {
  var currentIndex = myColumns.findIndex(function (_ref3) {
    var id = _ref3.id;
    return id === columnId;
  });
  var rightMargin = 0;
  for (var i = currentIndex + 1; i < myColumns.length; i += 1) {
    if (myColumns[i].isVisible !== false) {
      rightMargin += myColumns[i].width;
    }
  }
  return rightMargin;
}
function sortOn(prop, list) {
  var order = list.reduce(function (obj, key, idx) {
    return Object.assign(obj, _defineProperty({}, key, idx + 1));
  }, {});
  var getVal = function getVal(item) {
    return order[item[prop]] || Infinity;
  };
  return function (a, b) {
    return getVal(a) - getVal(b);
  };
}
function expandableTextDataGetter(cellData) {
  var maxCharacters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 90;
  if (_.isArray(cellData)) {
    var _cellData$join, _cellData$join2;
    return {
      fullText: cellData === null || cellData === void 0 ? void 0 : cellData.join(),
      truncatedText: cellData === null || cellData === void 0 || (_cellData$join = cellData.join()) === null || _cellData$join === void 0 ? void 0 : _cellData$join.substring(0, maxCharacters),
      isTruncated: (cellData === null || cellData === void 0 || (_cellData$join2 = cellData.join()) === null || _cellData$join2 === void 0 ? void 0 : _cellData$join2.length) > maxCharacters
    };
  }
  var text = cellData === null || cellData === void 0 ? void 0 : cellData.toString();
  return {
    fullText: text,
    truncatedText: text === null || text === void 0 ? void 0 : text.substring(0, maxCharacters),
    isTruncated: (text === null || text === void 0 ? void 0 : text.length) > maxCharacters
  };
}
function convertForSort(item, sort, specificDataConverter) {
  var _item$sort$key$toStri, _item$sort$key;
  if (specificDataConverter) {
    return specificDataConverter(item, sort);
  }
  if ((sort === null || sort === void 0 ? void 0 : sort.key) instanceof Date) {
    return item[sort.key] ? DateTime.fromFormat(item[sort.key], 'dd.MM.yyyy HH:mm:ss').toSeconds() : 0;
  }
  return (_item$sort$key$toStri = (_item$sort$key = item[sort.key]) === null || _item$sort$key === void 0 ? void 0 : _item$sort$key.toString().trimStart()) !== null && _item$sort$key$toStri !== void 0 ? _item$sort$key$toStri : '';
}
var sortingCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base'
});
function sortTable(tableData, sortBy, specificDataConverter) {
  var order = sortBy.order === SortOrder.ASC ? 1 : -1;
  return _toConsumableArray(tableData).sort(function (a, b) {
    return order * sortingCollator.compare(convertForSort(a, sortBy, specificDataConverter), convertForSort(b, sortBy, specificDataConverter));
  });
}
export { setSticky, setColumnWidth, columnIsLastLeftSticky, sortOn, columnIsFirstRightSticky, getMarginRight, expandableTextDataGetter, convertForSort, sortingCollator, sortTable };