import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import config from 'config/environment';
import Flow from '@flowjs/flow.js';
import { v4 as uuid } from 'uuid';
import { fileConstants } from '../constants/actions';
import uploadService from '../services/upload-service';
var fileActions = {
  upload: upload,
  uploadVideo: uploadVideo,
  uploadInvitees: uploadInvitees,
  importEvent: importEvent,
  uploadLargeVideo: uploadLargeVideo
};
function upload(eventId, files, resizeTo, convertTo, successCallback, errorCallback) {
  if (files && files.length > 1) {
    successCallback([]);
    return function (dispatch) {
      return dispatch(failure('Could not upload multiple Files!'));
    };
  }
  return function (dispatch) {
    dispatch(request());
    uploadService.upload(eventId, files, resizeTo, convertTo).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: fileConstants.UPLOAD_REQUEST
    };
  }
  function success(result) {
    var _result$fileNames = _slicedToArray(result.fileNames, 1),
      fileName = _result$fileNames[0];
    if (successCallback) {
      successCallback(fileName);
    }
    return {
      type: fileConstants.UPLOAD_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    if (errorCallback) {
      errorCallback(error);
    }
    return {
      type: fileConstants.UPLOAD_FAILURE,
      error: error
    };
  }
}
function uploadLargeVideo(eventId, file, successCallback, bytesChunk) {
  var tokens = JSON.parse(localStorage.getItem('tokens'));
  var BYTES_PER_CHUNK = bytesChunk;
  var fileGuid = uuid();
  var flow = null;
  var eTags = [];
  return function (dispatch) {
    dispatch(request());
    flow = new Flow({
      target: "".concat(config.apiUrl, "/upload/").concat(eventId, "/files/large"),
      method: 'POST',
      testChunks: false,
      headers: {
        Authorization: "Bearer ".concat(tokens === null || tokens === void 0 ? void 0 : tokens.access_token)
      },
      chunkSize: BYTES_PER_CHUNK,
      maxChunkRetries: 5,
      simultaneousUploads: 1,
      forceChunkSize: false,
      fileParameterName: 'file',
      query: function query(flowFile) {
        var chunkObj = flowFile.chunks[0].xhr.response;
        flowFile.params = {
          fileguid: fileGuid,
          rawFileName: file[0].name,
          eTags: eTags,
          uploadId: ''
        };
        if (chunkObj !== '') {
          var parsedChunk = JSON.parse(chunkObj);
          var parsedEtags = parseResponsePerChunk(flowFile.chunks);
          flowFile.params.uploadId = parsedChunk.result.uploadId;
          flowFile.params.eTags = JSON.stringify(parsedEtags);
        }
        return flowFile.params;
      }
    });
    flow.addFile(file[0]);
    flow.upload();
    flow.on('fileProgress', function (f) {
      var progress = f.progress();
      var percent = Math.round(progress * 100);
      dispatch(setUploadProgress(percent));
    });
    flow.on('fileSuccess', function (f, response) {
      var _responseToJson$resul;
      var responseToJson = JSON.parse(response);
      if (responseToJson !== null && responseToJson !== void 0 && (_responseToJson$resul = responseToJson.result) !== null && _responseToJson$resul !== void 0 && _responseToJson$resul.fileNames) {
        var _responseToJson$resul2, _responseToJson$resul3;
        Object.assign(responseToJson === null || responseToJson === void 0 || (_responseToJson$resul2 = responseToJson.result) === null || _responseToJson$resul2 === void 0 ? void 0 : _responseToJson$resul2.fileNames, responseToJson === null || responseToJson === void 0 || (_responseToJson$resul3 = responseToJson.result) === null || _responseToJson$resul3 === void 0 ? void 0 : _responseToJson$resul3.fileNames.map(function (item) {
          return "".concat(config.apiUrl, "/asset/").concat(item);
        }));
        dispatch(success(responseToJson.result));
      }
    });
    flow.on('fileError', function () {
      return dispatch(setUploadError('Upload video error'));
    });
    function request() {
      return {
        type: fileConstants.UPLOAD_REQUEST
      };
    }
    function success(result) {
      var _result$fileNames2 = _slicedToArray(result.fileNames, 1),
        fileName = _result$fileNames2[0];
      if (successCallback) {
        successCallback(fileName);
      }
      return {
        type: fileConstants.UPLOAD_SUCCESS,
        result: result
      };
    }
    function setUploadProgress(result) {
      return {
        type: fileConstants.SET_UPLOAD_PROGRESS,
        result: result
      };
    }
    function setUploadError(error) {
      return {
        type: fileConstants.SET_UPLOAD_PROGRESS_ERROR,
        error: error
      };
    }
    function parseResponsePerChunk(chunks) {
      var eTag = [];
      var validResponses = chunks.filter(function (c) {
        var _c$xhr, _c$xhr2;
        return c.xhr !== null && ((_c$xhr = c.xhr) === null || _c$xhr === void 0 ? void 0 : _c$xhr.response) !== null && ((_c$xhr2 = c.xhr) === null || _c$xhr2 === void 0 ? void 0 : _c$xhr2.response) !== '';
      });
      validResponses.forEach(function (x) {
        var parsedResponse = JSON.parse(x.xhr.response);
        var eTagObj = {
          eTag: parsedResponse.result.eTag,
          partNumber: parsedResponse.result.partNumber
        };
        eTag.push(eTagObj);
      });
      return eTag;
    }
  };
}
function uploadVideo(eventId, files, successCallback) {
  var tokens = JSON.parse(localStorage.getItem('tokens'));
  var data = new FormData();
  files.forEach(function (file) {
    if (file !== null && file !== void 0 && file.blob) {
      data.append('files', file.blob(), file.name());
    } else {
      data.append('files', file, file.name);
    }
  });
  return function (dispatch) {
    dispatch(request());
    var XML = new XMLHttpRequest();
    XML.upload.addEventListener('progress', progressHandler, false);
    XML.open('POST', "".concat(config.apiUrl, "/upload/").concat(eventId, "/files"));
    XML.setRequestHeader('Authorization', "Bearer ".concat(tokens === null || tokens === void 0 ? void 0 : tokens.access_token));
    XML.onreadystatechange = function handleResponse() {
      if (XML.readyState == 4 && XML.status == 200) {
        var _responseToJson$resul4;
        var responseToJson = JSON.parse(XML.response);
        if (responseToJson !== null && responseToJson !== void 0 && (_responseToJson$resul4 = responseToJson.result) !== null && _responseToJson$resul4 !== void 0 && _responseToJson$resul4.fileNames) {
          var _responseToJson$resul5, _responseToJson$resul6;
          Object.assign(responseToJson === null || responseToJson === void 0 || (_responseToJson$resul5 = responseToJson.result) === null || _responseToJson$resul5 === void 0 ? void 0 : _responseToJson$resul5.fileNames, responseToJson === null || responseToJson === void 0 || (_responseToJson$resul6 = responseToJson.result) === null || _responseToJson$resul6 === void 0 ? void 0 : _responseToJson$resul6.fileNames.map(function (item) {
            return "".concat(config.apiUrl, "/asset/").concat(item);
          }));
          dispatch(success(responseToJson.result));
        }
      }
      if (XML.status === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('eventId');
        window.location.reload(true);
      }
      if (XML.status === 403) {
        Promise.reject(new Error('Forbidden access to resource'));
      }
    };
    XML.send(data);
    function progressHandler(event) {
      var percent = Math.round(event.loaded / event.total * 100);
      dispatch(setUploadProgress(percent));
    }
  };
  function request() {
    return {
      type: fileConstants.UPLOAD_REQUEST
    };
  }
  function success(result) {
    var _result$fileNames3 = _slicedToArray(result.fileNames, 1),
      fileName = _result$fileNames3[0];
    if (successCallback) {
      successCallback(fileName);
    }
    return {
      type: fileConstants.UPLOAD_SUCCESS,
      result: result
    };
  }
  function setUploadProgress(result) {
    return {
      type: fileConstants.SET_UPLOAD_PROGRESS,
      result: result
    };
  }
}
function uploadInvitees(eventId, payload, callback) {
  if (payload !== null && payload !== void 0 && payload.files && payload.files.length > 1) {
    callback([]);
    return function (dispatch) {
      return dispatch(failure('Could not upload multiple Files!'));
    };
  }
  return function (dispatch) {
    dispatch(request());
    uploadService.uploadInviteeList(eventId, payload).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: fileConstants.UPLOAD_INVITEE_REQUEST
    };
  }
  function success(response) {
    callback(response.result, null);
    return {
      type: fileConstants.UPLOAD_INVITEE_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    callback(undefined, error.message);
    return {
      type: fileConstants.UPLOAD_INVITEE_FAILURE,
      error: error
    };
  }
}
function importEvent(workspaceId, files, metadata, successCallback, errorCallback) {
  if (files && files.length > 1) {
    successCallback([]);
    return function (dispatch) {
      return dispatch(failure('Could not upload multiple Files!'));
    };
  }
  return function (dispatch) {
    dispatch(request());
    uploadService.importEvent(workspaceId, files, metadata).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: fileConstants.UPLOAD_REQUEST
    };
  }
  function success(result) {
    if (successCallback) {
      successCallback(result);
    }
    return {
      type: fileConstants.UPLOAD_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    if (errorCallback) {
      errorCallback(error);
    }
    return {
      type: fileConstants.UPLOAD_FAILURE,
      error: error
    };
  }
}
export default fileActions;