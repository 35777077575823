import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
/* eslint-disable no-console */
import { appIntl } from '../../../IntlGlobalProvider';
export default function addTemplatePlugin(editor, templates) {
  var templatesList = templates === null || templates === void 0 ? void 0 : templates.map(function (template) {
    return {
      text: template.title,
      value: template.id
    };
  });
  var intl = appIntl();
  var dialogConfig = {
    title: intl.formatMessage({
      id: 'insert_template',
      defaultMessage: 'Insert template'
    }),
    body: {
      type: 'panel',
      items: [{
        type: 'selectbox',
        name: 'customTemplate',
        label: intl.formatMessage({
          id: 'select_template',
          defaultMessage: 'Select template'
        }),
        size: 1,
        items: templatesList
      }]
    },
    buttons: [{
      type: 'cancel',
      name: 'closeButton',
      text: 'Cancel'
    }, {
      type: 'submit',
      name: 'submitButton',
      text: 'Insert',
      primary: true
    }],
    initialData: {
      customTemplate: ''
    },
    onSubmit: function onSubmit(api) {
      var data = api.getData();
      var template = templates.find(function (t) {
        return t.id === data.customTemplate;
      });
      editor.execCommand('mceInsertContent', false, template === null || template === void 0 ? void 0 : template.content);
      if (template !== null && template !== void 0 && template.callback) {
        template.callback(template);
      }
      api.close();
    }
  };
  editor.ui.registry.addButton('add-template', {
    icon: 'template',
    text: intl.formatMessage({
      id: 'Template',
      defaultMessage: 'Template'
    }),
    onAction: function onAction() {
      editor.windowManager.open(dialogConfig);
    }
  });
}