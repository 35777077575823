import eventService from '../services/event-service';
import { eventConstants } from '../constants/actions';
import { historyPush } from '../helpers/history';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var landingPageActions = {
  saveLandingPage: saveLandingPage,
  enterPreviewMode: enterPreviewMode,
  exitPreviewMode: exitPreviewMode
};
function saveLandingPage(eventId, eventLandingPage, templates, navigateTo, callback) {
  var showSuccessMessage = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
  var isDirty = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
  var isPreview = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    eventService.saveLandingPage(eventId, eventLandingPage, templates, isDirty, isPreview).then(function (event) {
      dispatch(success(event, isPreview));
      if (navigateTo) {
        historyPush(navigateTo);
      }
      if (showSuccessMessage) {
        dispatch(messageActions.success(intl.formatMessage({
          id: 'EventPage.Changes_successful',
          defaultMessage: 'Changes were successfully saved'
        })));
      }
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.SAVE_LANDING_PAGE_REQUEST
    };
  }
  function success(event) {
    return {
      type: eventConstants.SAVE_LANDING_PAGE_SUCCESS,
      event: event,
      isPreview: isPreview
    };
  }
  function failure(error) {
    return {
      type: eventConstants.SAVE_LANDING_PAGE_FAILURE,
      error: error
    };
  }
}
function enterPreviewMode() {
  return function (dispatch) {
    return dispatch({
      type: eventConstants.ENTER_LANDING_PAGE_PREVIEW
    });
  };
}
function exitPreviewMode() {
  return function (dispatch) {
    return dispatch({
      type: eventConstants.EXIT_LANDING_PAGE_PREVIEW
    });
  };
}
export default landingPageActions;