import eventService from '../services/event-service';
import { smsConstants } from '../constants/actions';
var smsActions = {
  getAll: getAll,
  cancelSms: cancelSms,
  exportSmsLog: exportSmsLog
};
function getAll(eventId) {
  return function (dispatch) {
    dispatch(request());
    eventService.getAllSms(eventId).then(function (result) {
      return dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: smsConstants.GET_ALL
    };
  }
  function success(response) {
    return {
      type: smsConstants.GET_ALL_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: smsConstants.GET_ALL_FAILURE,
      response: response
    };
  }
}
function cancelSms(eventId, smsId) {
  return function (dispatch) {
    dispatch(request());
    eventService.cancelSms(eventId, smsId).then(function (result) {
      return dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: smsConstants.CANCEL
    };
  }
  function success(response) {
    return {
      type: smsConstants.CANCEL_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: smsConstants.CANCEL_FAILURE,
      response: response
    };
  }
}
function exportSmsLog(eventId) {
  return function (dispatch) {
    dispatch(request());
    eventService.exportSmsLog(eventId).then(function (smsList) {
      return dispatch(success(smsList));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: smsConstants.EXPORT_SMS_REQUEST
    };
  }
  function success(smsList) {
    return {
      type: smsConstants.EXPORT_SMS_SUCCESS,
      smsList: smsList
    };
  }
  function failure(error) {
    return {
      type: smsConstants.EXPORT_SMS_FAILURE,
      error: error
    };
  }
}
export default smsActions;