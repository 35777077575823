function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var _excluded = ["id", "title", "startDate", "endDate", "trackId"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { DateTime } from 'luxon';
function adjustTimezone(dateStr, fromTimezone, toTimezone) {
  return DateTime.fromISO(dateStr, {
    zone: fromTimezone
  }).setZone(toTimezone, {
    keepLocalTime: true
  }).toUTC().toISO();
}
function toBusinessObject(obj, sessions) {
  var _DateTime$fromJSDate, _obj$resource$id, _obj$resource, _obj$extendedProps;
  // this is here because of https://github.com/fullcalendar/fullcalendar/issues/6326
  var startDate = DateTime.fromJSDate(obj.start);
  var end = obj.end;
  if (!end) {
    var session = sessions.find(function (x) {
      return x.id === obj.id;
    });
    end = startDate.plus(getDuration(session.start, session.end));
  }
  return _objectSpread({
    id: obj === null || obj === void 0 ? void 0 : obj.id,
    title: obj === null || obj === void 0 ? void 0 : obj.title,
    startDate: startDate.toISO(),
    endDate: ((_DateTime$fromJSDate = DateTime.fromJSDate(obj.end)) !== null && _DateTime$fromJSDate !== void 0 ? _DateTime$fromJSDate : end).toISO(),
    trackId: (_obj$resource$id = obj === null || obj === void 0 || (_obj$resource = obj.resource) === null || _obj$resource === void 0 ? void 0 : _obj$resource.id) !== null && _obj$resource$id !== void 0 ? _obj$resource$id : obj === null || obj === void 0 || (_obj$extendedProps = obj.extendedProps) === null || _obj$extendedProps === void 0 ? void 0 : _obj$extendedProps.trackId
  }, (obj === null || obj === void 0 ? void 0 : obj.extendedProps) || {});
}
function toCalendarObject(obj, timezone) {
  if (!obj) {
    return null;
  }
  var id = obj.id,
    title = obj.title,
    startDate = obj.startDate,
    endDate = obj.endDate,
    trackId = obj.trackId,
    extendedProps = _objectWithoutProperties(obj, _excluded);
  return {
    id: id,
    title: title,
    start: adjustTimezone(startDate, timezone, 'utc'),
    end: adjustTimezone(endDate, timezone, 'utc'),
    resourceId: trackId,
    extendedProps: _objectSpread({
      trackId: obj.trackId
    }, extendedProps)
  };
}
function getSessionDuration(session) {
  if (!session) {
    return null;
  }
  var diff = getDuration(session.startDate, session.endDate);
  return DateTime.utc(2000, 1, 1, diff.hours, diff.minutes, 0).toISO();
}
function getDuration(start, end) {
  var startDate = DateTime.fromISO(start);
  var endDate = DateTime.fromISO(end);
  return endDate.diff(startDate, ['hours', 'minutes']);
}
export function getSessionOptions(sessions) {
  var _sessions$map;
  return (_sessions$map = sessions === null || sessions === void 0 ? void 0 : sessions.map(function (x) {
    return {
      label: x.title,
      value: x.id
    };
  })) !== null && _sessions$map !== void 0 ? _sessions$map : [];
}
export function getInitialSelectedSessions(sessionIds, sessions) {
  if (!sessions || !sessionIds) {
    return [];
  }
  return sessionIds.map(function (id) {
    var session = sessions.find(function (y) {
      return y.id === id;
    });
    return {
      label: session === null || session === void 0 ? void 0 : session.title,
      value: session === null || session === void 0 ? void 0 : session.id
    };
  });
}
export { adjustTimezone, toBusinessObject, toCalendarObject, getSessionDuration };