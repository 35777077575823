var communicationSubPageTypes = {
  EMAILS: 'emails',
  SMS: 'sms',
  SETTINGS: 'settings',
  TEMPLATES: 'templates'
};
var communicationShowTypes = {
  EMAILS: 'emails',
  SMS: 'sms'
};
export { communicationSubPageTypes, communicationShowTypes };