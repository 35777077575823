import { combineReducers } from 'redux';
import authentication from './authentication-reducer';
import users from './user-reducer';
import message from './message-reducer';
import { workspaces, simpleWorkspaces, workspace, workspaceLimits } from './workspace-reducer';
import event from './event-reducer';
import publicRegistration from './public-registration-reducer';
import registrations from './registrations-reducer';
import maps from './maps-reducer';
import { userConstants } from '../constants/actions';
import { partners, partner } from './partner-reducer';
import checkPayment from './payments-reducer';
import document from './document-reducer';
import sms from './sms-reducer';
import reservations from './reservation-reducer';
import integration from './integration-reducer';
import timeline from './timeline-reducer';
import promotedMessages from './promoted-messages-reducers';
import infoPages from './info-pages-reducers';
import liveDocuments from './live-documents-reducer';
import templates from './template-reducers';
import emailLog from './email-log-reducer';
import assistant from './assistant-reducer';
import polls from './polls-reducer';
import videos from './videos-reducer';
import videoUpload from './video-upload-reducer';
import changelogItems from './changelog-reducer';
import statistics from './statistics-reducer';
import events from './events-reducer';
import product from './product-reducer';
import billing from './billing-reducer';
import registrationGroups from './registration-group-reducer';
import addons from './addons-reducer';
import speakers from './speakers-reducer';
import agenda from './agenda-reducer';
import sponsors from './sponsors-reducer';
import printer from './print-reducer';
import categories from './categories-reducer';
import myAgenda from './my-agenda-reducer';
var appReducer = combineReducers({
  authentication: authentication,
  users: users,
  message: message,
  workspaces: workspaces,
  simpleWorkspaces: simpleWorkspaces,
  workspace: workspace,
  workspaceLimits: workspaceLimits,
  event: event,
  publicRegistration: publicRegistration,
  registrations: registrations,
  maps: maps,
  partners: partners,
  partner: partner,
  checkPayment: checkPayment,
  document: document,
  sms: sms,
  reservations: reservations,
  integration: integration,
  timeline: timeline,
  promotedMessages: promotedMessages,
  infoPages: infoPages,
  liveDocuments: liveDocuments,
  templates: templates,
  emailLog: emailLog,
  assistant: assistant,
  polls: polls,
  videos: videos,
  videoUpload: videoUpload,
  changelogItems: changelogItems,
  statistics: statistics,
  events: events,
  product: product,
  billing: billing,
  registrationGroups: registrationGroups,
  addons: addons,
  speakers: speakers,
  agenda: agenda,
  sponsors: sponsors,
  printer: printer,
  categories: categories,
  myAgenda: myAgenda
});
var rootReducer = function rootReducer(state, action) {
  if (action.type === userConstants.LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;