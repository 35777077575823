import speakersService from '../services/speakers-service';
import { speakersConstants } from '../constants/actions';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var speakerActions = {
  getSpeakers: getSpeakers,
  createSpeaker: createSpeaker,
  updateSpeaker: updateSpeaker,
  deleteSpeaker: deleteSpeaker,
  reorderSpeakers: reorderSpeakers,
  clearErrors: clearErrors
};
function getSpeakers(eventId) {
  return function (dispatch) {
    dispatch(request());
    speakersService.getSpeakers(eventId).then(function (speakers) {
      return dispatch(success(speakers));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: speakersConstants.GET_SPEAKERS
    };
  }
  function success(items) {
    return {
      type: speakersConstants.GET_SPEAKERS_SUCCESS,
      items: items
    };
  }
  function failure(error) {
    return {
      type: speakersConstants.GET_SPEAKERS_FAILURE,
      error: error
    };
  }
}
function createSpeaker(eventId, speaker, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    speakersService.createSpeaker(eventId, speaker).then(function (item) {
      dispatch(success(item));
      if (callback) {
        callback();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Speakers.create_successful',
        defaultMessage: 'Speaker was successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: speakersConstants.CREATE_SPEAKER
    };
  }
  function success(item) {
    return {
      type: speakersConstants.CREATE_SPEAKER_SUCCESS,
      item: item
    };
  }
  function failure(error) {
    return {
      type: speakersConstants.CREATE_SPEAKER_FAILURE,
      error: error
    };
  }
}
function updateSpeaker(eventId, speakerId, speaker) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    speakersService.updateSpeaker(eventId, speakerId, speaker).then(function (item) {
      dispatch(success(item));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Speakers.changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: speakersConstants.UPDATE_SPEAKER
    };
  }
  function success(item) {
    return {
      type: speakersConstants.UPDATE_SPEAKER_SUCCESS,
      item: item
    };
  }
  function failure(error) {
    return {
      type: speakersConstants.UPDATE_SPEAKER_FAILURE,
      error: error
    };
  }
}
function deleteSpeaker(eventId, speakerId) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(speakerId));
    speakersService.deleteSpeaker(eventId, speakerId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Speakers.delete_successful',
        defaultMessage: 'Speaker was successfully deleted'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: speakersConstants.DELETE_SPEAKER
    };
  }
  function success(isSuccess) {
    var result = {
      isSuccess: isSuccess,
      speakerId: speakerId
    };
    return {
      type: speakersConstants.DELETE_SPEAKER_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: speakersConstants.DELETE_SPEAKER_FAILURE,
      error: error
    };
  }
}
function reorderSpeakers(eventId, speakerIds, onContextUpdated) {
  return function (dispatch) {
    dispatch(createMessage());
    speakersService.reorderSpeakers(eventId, speakerIds).then(function (speakers) {
      dispatch(success(speakers));
      if (onContextUpdated) {
        onContextUpdated();
      }
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function createMessage() {
    return {
      type: speakersConstants.REORDER_SPEAKERS
    };
  }
  function success(speakers) {
    return {
      type: speakersConstants.REORDER_SPEAKERS_SUCCESS,
      speakers: speakers
    };
  }
  function failure(error) {
    return {
      type: speakersConstants.REORDER_SPEAKERS_FAILURE,
      error: error
    };
  }
}
function clearErrors() {
  return function (dispatch) {
    dispatch({
      type: speakersConstants.CLEAR_ERRORS
    });
  };
}
export default speakerActions;