import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.match.js";
var SmsCounter = function SmsCounter() {};
SmsCounter.gsm7bitChars = '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà';
SmsCounter.gsm7bitExChar = '\\^{}\\\\\\[~\\]|€';
SmsCounter.gsm7bitRegExp = RegExp("^[".concat(SmsCounter.gsm7bitChars, "]*$"));
SmsCounter.gsm7bitExRegExp = RegExp("^[".concat(SmsCounter.gsm7bitChars).concat(SmsCounter.gsm7bitExChar, "]*$"));
SmsCounter.gsm7bitExOnlyRegExp = RegExp("^[\\".concat(SmsCounter.gsm7bitExChar, "]*$"));
SmsCounter.GSM_7BIT = 'GSM_7BIT';
SmsCounter.GSM_7BIT_EX = 'GSM_7BIT_EX';
SmsCounter.UTF16 = 'UTF16';
SmsCounter.messageLength = {
  GSM_7BIT: 160,
  GSM_7BIT_EX: 160,
  UTF16: 70
};
SmsCounter.multiMessageLength = {
  GSM_7BIT: 153,
  GSM_7BIT_EX: 153,
  UTF16: 67
};
SmsCounter.count = function (text) {
  var length;
  var per_message;
  var remaining;
  var encoding = SmsCounter.detectEncoding(text);
  length = text.length;
  if (encoding === SmsCounter.GSM_7BIT_EX) {
    length += SmsCounter.countGsm7bitEx(text);
  }
  per_message = SmsCounter.messageLength[encoding];
  if (length > per_message) {
    per_message = SmsCounter.multiMessageLength[encoding];
  }
  var messages = Math.ceil(length / per_message);
  remaining = per_message * messages - length;
  if (remaining === 0 && messages === 0) {
    remaining = per_message;
  }
  return {
    encoding: encoding,
    length: length,
    per_message: per_message,
    remaining: remaining,
    messages: messages
  };
};
SmsCounter.detectEncoding = function (text) {
  switch (false) {
    case text.match(SmsCounter.gsm7bitRegExp) == null:
      return SmsCounter.GSM_7BIT;
    case text.match(SmsCounter.gsm7bitExRegExp) == null:
      return SmsCounter.GSM_7BIT_EX;
    default:
      return SmsCounter.UTF16;
  }
};
SmsCounter.countGsm7bitEx = function (text) {
  var char2;
  var chars = function chars() {
    var _i;
    var _len;
    var _results = [];
    for (_i = 0, _len = text.length; _i < _len; _i + 1) {
      char2 = text[_i];
      if (char2.match(SmsCounter.gsm7bitExOnlyRegExp) != null) {
        _results.push(char2);
      }
    }
    return _results;
  };
  return chars.length;
};
export default SmsCounter;