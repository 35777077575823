var billingConstants = {
  GET_BILLING_INFO_REQUEST: 'GET_BILLING_INFO_REQUEST',
  GET_BILLING_INFO_SUCCESS: 'GET_BILLING_INFO_SUCCESS',
  GET_BILLING_INFO_ERROR: 'GET_BILLING_INFO_ERROR',
  GET_BILLING_HISTORY_REQUEST: 'GET_BILLING_HISTORY_REQUEST',
  GET_BILLING_HISTORY_SUCCESS: 'GET_BILLING_HISTORY_SUCCESS',
  GET_BILLING_HISTORY_ERROR: 'GET_BILLING_HISTORY_ERROR',
  GET_INVOICE_PDF_REQUEST: 'GET_INVOICE_PDF_REQUEST',
  GET_INVOICE_PDF_SUCCESS: 'GET_INVOICE_PDF_SUCCESS',
  GET_INVOICE_PDF_ERROR: 'GET_INVOICE_PDF_ERROR'
};
export default billingConstants;