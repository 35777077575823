import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
export function getInitialSelectedGroups(groupIds, registrationGroups) {
  if (!(registrationGroups !== null && registrationGroups !== void 0 && registrationGroups.items) || !groupIds) {
    return [];
  }
  return groupIds.map(function (id) {
    var group = registrationGroups.items.find(function (y) {
      return y.id === id;
    });
    return {
      label: group === null || group === void 0 ? void 0 : group.name,
      value: group === null || group === void 0 ? void 0 : group.id
    };
  });
}
export function getRegistrationGroupOptions(registrationGroups) {
  var _registrationGroups$i, _registrationGroups$i2;
  return (_registrationGroups$i = registrationGroups === null || registrationGroups === void 0 || (_registrationGroups$i2 = registrationGroups.items) === null || _registrationGroups$i2 === void 0 ? void 0 : _registrationGroups$i2.map(function (x) {
    return {
      label: x.name,
      value: x.id
    };
  })) !== null && _registrationGroups$i !== void 0 ? _registrationGroups$i : [];
}