import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import config from 'config/environment';
import { requestHeader, handleResponsePlainText } from '../helpers/request-helpers';
var documentService = {
  getSignupTermsDocument: getSignupTermsDocument
};
function getSignupTermsDocument() {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/document/signupTerms"), requestOptions).then(handleResponsePlainText);
}
export default documentService;