var changeLogItemConstants = {
  GET_CHANGE_LOG_ITEMS: 'GET_CHANGE_LOG_ITEMS',
  GET_CHANGE_LOG_ITEMS_SUCCESS: 'GET_CHANGE_LOG_ITEMS_SUCCESS',
  GET_CHANGE_LOG_ITEMS_ERROR: 'GET_CHANGE_LOG_ITEMS_ERROR',
  CREATE_CHANGE_LOG_ITEMS: 'CREATE_CHANGE_LOG_ITEMS_REQUEST',
  CREATE_CHANGE_LOG_ITEMS_SUCCESS: 'CREATE_CHANGE_LOG_ITEMS_SUCCESS',
  CREATE_CHANGE_LOG_ITEMS_ERROR: 'CREATE_CHANGE_LOG_ITEMS_ERROR',
  UPDATE_CHANGE_LOG_ITEMS: 'UPDATE_CHANGE_LOG_ITEMS_REQUEST',
  UPDATE_CHANGE_LOG_ITEMS_SUCCESS: 'UPDATE_CHANGE_LOG_ITEMS_SUCCESS',
  UPDATE_CHANGE_LOG_ITEMS_ERROR: 'UPDATE_CHANGE_LOG_ITEMS_ERROR',
  DELETE_CHANGE_LOG_ITEMS: 'DELETE_CHANGE_LOG_ITEMS',
  DELETE_CHANGE_LOG_ITEMS_SUCCESS: 'DELETE_CHANGE_LOG_ITEMS_SUCCESS',
  DELETE_CHANGE_LOG_ITEMS_FAILURE: 'DELETE_CHANGE_LOG_ITEMS_FAILURE',
  SET_LAST_SEEN_CHANGE_LOG: 'SET_LAST_SEEN_CHANGE_LOG',
  SET_LAST_SEEN_CHANGE_LOG_SUCCESS: 'SET_LAST_SEEN_CHANGE_LOG_SUCCESS',
  SET_LAST_SEEN_CHANGE_LOG_FAILURE: 'SET_LAST_SEEN_CHANGE_LOG_FAILURE'
};
export default changeLogItemConstants;