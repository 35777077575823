import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { removeDiacritics } from './helpers';
function isSearchWithoutDiacritics(search) {
  var _removeDiacritics;
  return ((_removeDiacritics = removeDiacritics(search)) === null || _removeDiacritics === void 0 ? void 0 : _removeDiacritics.toLowerCase()) == (search === null || search === void 0 ? void 0 : search.toLowerCase());
}
function isFoundBySearch(value, search) {
  var predicate = search === null || search === void 0 ? void 0 : search.toLowerCase();
  return isSearchWithoutDiacritics(search) ? removeDiacritics("".concat(value !== null && value !== void 0 ? value : '').toLowerCase()).includes(predicate) : "".concat(value !== null && value !== void 0 ? value : '').toLowerCase().includes(predicate);
}
export default isFoundBySearch;