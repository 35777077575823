var smsStatus = {
  UNKNOWN: 0,
  SCHEDULED: 1,
  SENT: 2,
  CANCELED: 3,
  SENDING: 7,
  ERROR: 98,
  BATCH: 99
};
export default smsStatus;
export { smsStatus };