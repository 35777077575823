function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { workspaceConstants, eventConstants } from '../constants/actions';
function workspaces() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case workspaceConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case workspaceConstants.GETALL_SUCCESS:
      return {
        items: action.workspaces
      };
    case workspaceConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
function simpleWorkspaces() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case workspaceConstants.GETALL_SIMPLE_REQUEST:
      return {
        loading: true
      };
    case workspaceConstants.GETALL_SIMPLE_SUCCESS:
      return {
        items: action.workspaces
      };
    case workspaceConstants.GETALL_SIMPLE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
function workspace() {
  var _state$events;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case workspaceConstants.GET_REQUEST:
      return {
        loading: true
      };
    case workspaceConstants.GET_SUCCESS:
      return action.workspace;
    case workspaceConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case workspaceConstants.CREATE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case workspaceConstants.CREATE_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, action.workspace), {}, {
          success: true,
          loading: false
        });
      }
    case workspaceConstants.CREATE_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        success: false
      });
    case workspaceConstants.UPDATE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case workspaceConstants.UPDATE_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, action.workspace), {}, {
          success: true,
          loading: false
        });
      }
    case workspaceConstants.UPDATE_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        errors: action.error.errors,
        loading: false,
        success: false
      });
    case workspaceConstants.UPDATE_USERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case workspaceConstants.UPDATE_USERS_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, action.workspace), {}, {
          success: true,
          loading: false
        });
      }
    case workspaceConstants.UPDATE_USERS_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        success: false
      });
    case workspaceConstants.DELETE_USER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case workspaceConstants.DELETE_USER_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          users: state.users.filter(function (x) {
            return x.email !== action.email;
          }),
          success: true,
          loading: false
        });
      }
    case workspaceConstants.DELETE_USER_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        errors: action.error.errors,
        loading: false,
        success: false
      });
    case workspaceConstants.CLEAR:
      return {};
    case workspaceConstants.CLEAR_ERRORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        errors: null,
        success: true
      });
    case eventConstants.DELETE_EVENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: undefined
      });
    case eventConstants.DELETE_EVENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: undefined,
        events: (_state$events = state.events) === null || _state$events === void 0 ? void 0 : _state$events.filter(function (x) {
          return x.id !== action.eventId;
        })
      });
    case eventConstants.DELETE_EVENT_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case workspaceConstants.GET_WORKSPACE_EMAIL_IDENTITIES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: true,
        error: undefined
      });
    case workspaceConstants.GET_WORKSPACE_EMAIL_IDENTITIES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: undefined,
        emailIdentities: action.response
      });
    case workspaceConstants.GET_WORKSPACE_EMAIL_IDENTITIES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: action.error
      });
    case workspaceConstants.VERIFY_WORKSPACE_EMAIL_IDENTITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: true
      });
    case workspaceConstants.VERIFY_WORKSPACE_EMAIL_IDENTITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        verifiedEmail: action.response
      });
    case workspaceConstants.VERIFY_WORKSPACE_EMAIL_IDENTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: action.error
      });
    case workspaceConstants.UPDATE_WORKSPACE_EMAIL_IDENTITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: true
      });
    case workspaceConstants.UPDATE_WORKSPACE_EMAIL_IDENTITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        verifiedEmail: action.response
      });
    case workspaceConstants.UPDATE_WORKSPACE_EMAIL_IDENTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: action.error
      });
    case workspaceConstants.DELETE_WORKSPACE_EMAIL_IDENTITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: true
      });
    case workspaceConstants.DELETE_WORKSPACE_EMAIL_IDENTITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        verifiedEmail: action.response
      });
    case workspaceConstants.DELETE_WORKSPACE_EMAIL_IDENTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: action.error
      });
    case workspaceConstants.UPDATE_FEATURES_REQUEST:
      return state;
    case workspaceConstants.UPDATE_FEATURES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), action.workspace);
    case workspaceConstants.UPDATE_FEATURES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });
    default:
      return state;
  }
}
function workspaceLimits() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case workspaceConstants.GET_LIMITS_SUMMARY_REQUEST:
      return {
        loading: true,
        success: false,
        error: undefined
      };
    case workspaceConstants.GET_LIMITS_SUMMARY_SUCCESS:
      return _objectSpread(_objectSpread({}, action.limitsSummary), {}, {
        loading: false,
        success: true,
        error: undefined
      });
    case workspaceConstants.GET_LIMITS_SUMMARY_FAILURE:
      return {
        error: action.error,
        success: false,
        loading: false
      };
    default:
      return state;
  }
}
export { workspaces, simpleWorkspaces, workspace, workspaceLimits };