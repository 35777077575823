import { progressCardVisibility } from '../constants/types';
var progressActions = {
  setProgressCardVisibility: setProgressCardVisibility,
  getProgressCardVisibility: getProgressCardVisibility
};
function setProgressCardVisibility(eventId, isVisible) {
  localStorage.setItem("progressCardVisible_".concat(eventId), isVisible);
}
function getProgressCardVisibility(eventId) {
  var _localStorage$getItem;
  return (_localStorage$getItem = localStorage.getItem("progressCardVisible_".concat(eventId))) !== null && _localStorage$getItem !== void 0 ? _localStorage$getItem : progressCardVisibility.VISIBLE;
}
export default progressActions;