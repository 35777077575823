import "core-js/modules/es.array.map.js";
import { openAgendaDialog } from '../plugins/agenda-button';
import { openButtonDialog } from '../plugins/menu-button';
import { openDocumentstListDialog } from '../plugins/documents-button';
import { openinfopageDialog } from '../plugins/infopage-button';
import { openParticipantListDialog } from '../plugins/participant-list-button';
import { openPollDialog } from '../plugins/poll-button';
import { openSessionDialog } from '../plugins/session-button';
import { openSpeakerDialog } from '../plugins/speaker-button';
import { openSponsorDialog } from '../plugins/sponsor-button';
import { openUserProfileDialog } from '../plugins/user-profile-button';
export function getButtonsMenu(editor, event) {
  var _event$liveSettings, _event$liveSettings2, _event$liveSettings3, _event$liveSettings4, _event$liveSettings5, _event$liveSettings6, _event$liveSettings7;
  var buttonsList = [{
    label: 'Button',
    action: function action() {
      openButtonDialog(editor, null, event === null || event === void 0 ? void 0 : event.branding, event === null || event === void 0 ? void 0 : event.id);
    }
  }, {
    label: 'Poll',
    action: function action() {
      openPollDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
    }
  }, {
    label: 'User profile',
    action: function action() {
      openUserProfileDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
    }
  }];
  if ((event === null || event === void 0 || (_event$liveSettings = event.liveSettings) === null || _event$liveSettings === void 0 ? void 0 : _event$liveSettings.agenda.length) > 0) {
    buttonsList.push({
      label: 'Agenda',
      action: function action() {
        openAgendaDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
      }
    });
  }
  if ((event === null || event === void 0 || (_event$liveSettings2 = event.liveSettings) === null || _event$liveSettings2 === void 0 ? void 0 : _event$liveSettings2.agenda.length) > 0) {
    buttonsList.push({
      label: 'Stage session',
      action: function action() {
        openSessionDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
      }
    });
  }
  if ((event === null || event === void 0 || (_event$liveSettings3 = event.liveSettings) === null || _event$liveSettings3 === void 0 ? void 0 : _event$liveSettings3.sponsors.length) > 0) {
    buttonsList.push({
      label: 'Sponsor',
      action: function action() {
        openSponsorDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
      }
    });
  }
  if ((event === null || event === void 0 || (_event$liveSettings4 = event.liveSettings) === null || _event$liveSettings4 === void 0 ? void 0 : _event$liveSettings4.speakers.length) > 0) {
    buttonsList.push({
      label: 'Speaker',
      action: function action() {
        openSpeakerDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
      }
    });
  }
  if ((event === null || event === void 0 || (_event$liveSettings5 = event.liveSettings) === null || _event$liveSettings5 === void 0 ? void 0 : _event$liveSettings5.infoPages.length) > 0) {
    buttonsList.push({
      label: 'Info page',
      action: function action() {
        openinfopageDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
      }
    });
  }
  if ((event === null || event === void 0 || (_event$liveSettings6 = event.liveSettings) === null || _event$liveSettings6 === void 0 ? void 0 : _event$liveSettings6.documents.length) > 0) {
    buttonsList.push({
      label: 'Documents',
      action: function action() {
        openDocumentstListDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
      }
    });
  }
  if ((event === null || event === void 0 || (_event$liveSettings7 = event.liveSettings) === null || _event$liveSettings7 === void 0 ? void 0 : _event$liveSettings7.participants.length) > 0) {
    buttonsList.push({
      label: 'Participant list',
      action: function action() {
        openParticipantListDialog(editor, event === null || event === void 0 ? void 0 : event.id, event === null || event === void 0 ? void 0 : event.branding);
      }
    });
  }
  return buttonsList.map(function (button) {
    return {
      type: 'menuitem',
      text: button.label,
      onAction: button.action
    };
  });
}