var speakersConstants = {
  GET_SPEAKERS: 'GET_SPEAKERS',
  GET_SPEAKERS_SUCCESS: 'GET_SPEAKERS_SUCCESS',
  GET_SPEAKERS_FAILURE: 'GET_SPEAKERS_FAILURE',
  CREATE_SPEAKER: 'CREATE_SPEAKER',
  CREATE_SPEAKER_SUCCESS: 'CREATE_SPEAKER_SUCCESS',
  CREATE_SPEAKER_FAILURE: 'CREATE_SPEAKER_FAILURE',
  UPDATE_SPEAKER: 'UPDATE_SPEAKER',
  UPDATE_SPEAKER_SUCCESS: 'UPDATE_SPEAKER_SUCCESS',
  UPDATE_SPEAKER_FAILURE: 'UPDATE_SPEAKER_FAILURE',
  DELETE_SPEAKER: 'DELETE_SPEAKER',
  DELETE_SPEAKER_SUCCESS: 'DELETE_SPEAKER_SUCCESS',
  DELETE_SPEAKER_FAILURE: 'DELETE_SPEAKER_FAILURE',
  REORDER_SPEAKERS: 'REORDER_SPEAKER',
  REORDER_SPEAKERS_SUCCESS: 'REORDER_SPEAKER_SUCCESS',
  REORDER_SPEAKERS_FAILURE: 'REORDER_SPEAKER_FAILURE',
  CLEAR_ERRORS: 'SPEAKERS_CLEAR_ERRORS'
};
export default speakersConstants;