var profileTabs = {
  USER: 'user',
  WORKSPACE_USERS: 'workspace-users',
  EMAIL_SERNDER: 'email-sender',
  COMPANY: 'company',
  SUBSCRIPTION: 'subscription',
  PAYMENT_HISTORY: 'payment-history',
  PLANS: 'plans',
  PAYMENT_INFORMATION: 'payment-information'
};
export default profileTabs;