import timelineService from '../services/timeline-service';
import { timelineConstants } from '../constants/actions';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var timelineActions = {
  getTimeline: getTimeline,
  deleteEntry: deleteEntry,
  exportTimeline: exportTimeline
};
function getTimeline(eventId) {
  return function (dispatch) {
    dispatch(request());
    timelineService.getTimeline(eventId).then(function (timeline) {
      return dispatch(success(timeline));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: timelineConstants.GET_TIMELINE
    };
  }
  function success(timeline) {
    return {
      type: timelineConstants.GET_TIMELINE_SUCCESS,
      timeline: timeline
    };
  }
  function failure(error) {
    return {
      type: timelineConstants.GET_TIMELINE_FAILURE,
      error: error
    };
  }
}
function deleteEntry(eventId, entryId, parentId) {
  var intl = appIntl();
  var postDeleteText = intl.formatMessage({
    id: 'EventPage.Post_deleted_successfully',
    defaultMessage: 'Post was successfully deleted'
  });
  var commentDeleteText = intl.formatMessage({
    id: 'EventPage.Comment_deleted_successfully',
    defaultMessage: 'Comment was successfully deleted'
  });
  var messageText = parentId ? commentDeleteText : postDeleteText;
  return function (dispatch) {
    dispatch(request(entryId));
    timelineService.deleteTimelineEntry(eventId, entryId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(messageText));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: timelineConstants.DELETE_TIMELINE_ENTRY,
      entryId: entryId
    };
  }
  function success(isSuccess) {
    var message = {
      isSuccess: isSuccess,
      entryId: entryId
    };
    return {
      type: timelineConstants.DELETE_TIMELINE_ENTRY_SUCCESS,
      message: message
    };
  }
  function failure(error) {
    return {
      type: timelineConstants.DELETE_TIMELINE_ENTRY_FAILURE,
      error: error
    };
  }
}
function exportTimeline(eventId) {
  return function (dispatch) {
    dispatch(request());
    timelineService.exportTimeline(eventId).then(function (timeline) {
      return dispatch(success(timeline));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: timelineConstants.EXPORT_TIMELINE
    };
  }
  function success(timeline) {
    return {
      type: timelineConstants.EXPORT_TIMELINE_SUCCESS,
      timeline: timeline
    };
  }
  function failure(error) {
    return {
      type: timelineConstants.EXPORT_TIMELINE_FAILURE,
      error: error
    };
  }
}
export default timelineActions;