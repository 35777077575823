function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/* eslint-disable no-restricted-syntax */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-template */
import { elementType } from '../constants/types';
var getDefaultElementValue = function getDefaultElementValue(item) {
  switch (item.type) {
    case elementType.CHECKBOX:
      return false;
    default:
      return '';
  }
};
function getInitialValues(items) {
  return items.map(function (item) {
    var _item$options;
    if (item !== null && item !== void 0 && (_item$options = item.options) !== null && _item$options !== void 0 && _item$options.elements) {
      var elements = item.options.elements;
      if (elements.every(function (x) {
        return x.type === elementType.CHECKBOX;
      })) {
        return elements.map(function (subItem) {
          return {
            key: subItem.id,
            value: getDefaultElementValue(subItem)
          };
        });
      }
    }
    return {
      key: item.id,
      value: getDefaultElementValue(item)
    };
  }).flat(2).reduce(function (obj, item) {
    return Object.assign(obj, _defineProperty({}, item.key, item.value));
  }, {});
}
function idWithPrefix(id) {
  return "{{context}}|".concat(id);
}
function guestContextId(index) {
  return "guest_".concat(index);
}
function applyContext(obj, contextId) {
  return JSON.parse(JSON.stringify(obj).replace(/\{\{context\}\}/g, contextId));
}
function traverseAndFlatten(currentNode, target, flattenedKey) {
  for (var key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      var newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = flattenedKey + '.' + key;
      }
      var value = currentNode[key];
      if (_typeof(value) === 'object') {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
}
function flattenFormElements(elements) {
  return elements.flatMap(function (x) {
    var _x$options;
    return x.type === elementType.GROUPED && !((_x$options = x.options) !== null && _x$options !== void 0 && (_x$options = _x$options.elements) !== null && _x$options !== void 0 && _x$options.every(function (y) {
      return y.type === elementType.CHECKBOX || y.type === elementType.RADIO;
    })) ? x.options.elements : x;
  });
}
export default getInitialValues;
export { getInitialValues, idWithPrefix, guestContextId, applyContext, traverseAndFlatten, flattenFormElements };