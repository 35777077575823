var sponsorsConstants = {
  GET_SPONSORS: 'GET_SPONSORS',
  GET_SPONSORS_SUCCESS: 'GET_SPONSORS_SUCCESS',
  GET_SPONSORS_FAILURE: 'GET_SPONSORS_FAILURE',
  DELETE_SPONSOR: 'DELETE_SPONSOR',
  DELETE_SPONSORS_SUCCESS: 'DELETE_SPONSORS_SUCCESS',
  DELETE_SPONSORS_FAILURE: 'DELETE_SPONSORS_FAILURE',
  REORDER_SPONSORS: 'REORDER_SPONSORS',
  REORDER_SPONSORS_SUCCESS: 'REORDER_SPONSORS_SUCCESS',
  REORDER_SPONSORS_FAILURE: 'REORDER_SPONSORS_FAILURE',
  CREATE_SPONSOR: 'CREATE_SPONSORS',
  CREATE_SPONSOR_SUCCESS: 'CREATE_SPONSOR_SUCCESS',
  CREATE_SPONSOR_FAILURE: 'CREATE_SPONSOR_FAILURE',
  UPDATE_SPONSOR: 'UPDATE_SPONSOR',
  UPDATE_SPONSOR_SUCCESS: 'UPDATE_SPONSOR_SUCCESS',
  UPDATE_SPONSOR_FAILURE: 'UPDATE_SPONSOR_FAILURE',
  CLEAR_ERRORS: 'SPONSORS_CLEAR_ERRORS'
};
export default sponsorsConstants;