import logService from '../services/log-service';
import logConstants from '../constants/actions/log-constants';
var landingPageActions = {
  information: information,
  debug: debug,
  error: error
};
function information(text) {
  return function (dispatch) {
    dispatch(request(text));
    logService.logInformation(text).then(function () {
      dispatch(success());
    }, function (e) {
      return dispatch(failure(e));
    });
  };
  function request(payload) {
    return {
      type: logConstants.REQUEST,
      payload: payload
    };
  }
  function success() {
    return {
      type: logConstants.SUCCESS
    };
  }
  function failure(e) {
    return {
      type: logConstants.ERROR,
      error: e
    };
  }
}
function debug(text) {
  return function (dispatch) {
    dispatch(request(text));
    logService.logDebug(text).then(function () {
      dispatch(success());
    }, function (e) {
      return dispatch(failure(e));
    });
  };
  function request(payload) {
    return {
      type: logConstants.REQUEST,
      payload: payload
    };
  }
  function success() {
    return {
      type: logConstants.SUCCESS
    };
  }
  function failure(e) {
    return {
      type: logConstants.ERROR,
      error: e
    };
  }
}
function error(errorObj) {
  return function (dispatch) {
    dispatch(request(errorObj));
    logService.logError(errorObj).then(function () {
      dispatch(success());
    }, function (e) {
      return dispatch(failure(e));
    });
  };
  function request(payload) {
    return {
      type: logConstants.REQUEST,
      payload: payload
    };
  }
  function success() {
    return {
      type: logConstants.SUCCESS
    };
  }
  function failure(e) {
    return {
      type: logConstants.ERROR,
      error: e
    };
  }
}
export default landingPageActions;