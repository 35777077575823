import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
/* eslint-disable no-console */
import config from 'config/environment';
import { handleResponse, requestHeader } from '../helpers/request-helpers';
var addonsService = {
  isInvoiceExist: isInvoiceExist,
  purchaseAddon: purchaseAddon
};
function purchaseAddon(workspaceId, addon) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      workspaceId: workspaceId,
      addonId: addon.id
    })
  };
  return fetch("".concat(config.apiUrl, "/addon/purchase"), requestOptions).then(handleResponse);
}
function isInvoiceExist(workspaceId, invoiceId) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      workspaceId: workspaceId,
      invoiceId: invoiceId
    })
  };
  return fetch("".concat(config.apiUrl, "/addon/isInvoicePaid"), requestOptions).then(handleResponse);
}
export default addonsService;