var fileConstants = {
  UPLOAD_REQUEST: 'UPLOAD_REQUEST',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'UPLOAD_FAILURE',
  UPLOAD_INVITEE_REQUEST: 'UPLOAD_INVITEE_REQUEST',
  UPLOAD_INVITEE_SUCCESS: 'UPLOAD_INVITEE_SUCCESS',
  UPLOAD_INVITEE_FAILURE: 'UPLOAD_INVITEE_FAILURE',
  SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
  SET_UPLOAD_PROGRESS_ERROR: 'SET_UPLOAD_PROGRESS_ERROR'
};
export default fileConstants;