import { assistantConstants } from '../constants/actions';
import assistantService from '../services/assistant-service';
var assistantActions = {
  getContent: getContent,
  setContent: setContent
};
function getContent(type, path) {
  return function (dispatch) {
    dispatch(request());
    assistantService.getContent(type, path).then(function (response) {
      return dispatch(success(response));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: assistantConstants.GET_CONTENT_REQUEST
    };
  }
  function success(response) {
    return {
      type: assistantConstants.GET_CONTENT_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: assistantConstants.GET_CONTENT_FAILURE,
      error: error
    };
  }
}
function setContent(type, path, content) {
  return function (dispatch) {
    dispatch(request());
    assistantService.setContent(type, path, content).then(function (response) {
      return dispatch(success(response));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: assistantConstants.SET_CONTENT_REQUEST
    };
  }
  function success(response) {
    return {
      type: assistantConstants.SET_CONTENT_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: assistantConstants.SET_CONTENT_FAILURE,
      error: error
    };
  }
}
export default assistantActions;