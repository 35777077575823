import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import userService from '../services/user-service';
import messageActions from './message-actions';
import { userConstants } from '../constants/actions';
import { historyPush } from '../helpers/history';
import { isAdminUser, isPartnerUser } from '../helpers/token-helper';
import { appIntl } from '../IntlGlobalProvider';
var userActions = {
  login: login,
  logout: logout,
  sendResetPassword: sendResetPassword,
  resetPassword: resetPassword,
  validateResetPasswordToken: validateResetPasswordToken,
  getAll: getAll,
  signUp: signUp,
  resendConfirmationEmail: resendConfirmationEmail,
  getCurrentUser: getCurrentUser,
  updateCurrentUser: updateCurrentUser,
  setProfileComplete: setProfileComplete,
  setUserNames: setUserNames,
  confirmUserEmail: confirmUserEmail,
  setEventViewType: setEventViewType,
  setWorkspaceViewType: setWorkspaceViewType,
  getEventViewType: getEventViewType,
  getWorkspaceViewType: getWorkspaceViewType,
  changeWorkspace: changeWorkspace,
  setSelectedWorkspace: setSelectedWorkspace,
  setIsPlanUpgraded: setIsPlanUpgraded,
  setCompanyDetails: setCompanyDetails
};
function login(username, password, lastLocation) {
  return function (dispatch) {
    dispatch(request({
      username: username
    }));
    dispatch(messageActions.clear());
    userService.login(username, password).then(function (tokens) {
      localStorage.setItem('tokens', JSON.stringify(tokens));
      userService.getCurrentUser().then(function (user) {
        dispatch(success(user));
        localStorage.setItem('user', JSON.stringify(user));
        navigateUser(user, lastLocation);
      }, function (error) {
        dispatch(failure(error));
        dispatch(messageActions.error(error));
      });
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request(user) {
    return {
      type: userConstants.LOGIN_REQUEST,
      user: user
    };
  }
  function success(user) {
    return {
      type: userConstants.LOGIN_SUCCESS,
      user: user
    };
  }
  function failure(error) {
    return {
      type: userConstants.LOGIN_FAILURE,
      error: error
    };
  }
}
function logout() {
  userService.logout();
  localStorage.removeItem('selectedWorkspaceId');
  sessionStorage.removeItem('company');
  return {
    type: userConstants.LOGOUT
  };
}
function sendResetPassword(email) {
  return function (dispatch) {
    dispatch(request({
      email: email
    }));
    userService.sendResetPassword(email).then(function (user) {
      dispatch(success(user));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request(user) {
    return {
      type: userConstants.SEND_RESET_PASSWORD_REQUEST,
      user: user
    };
  }
  function success(user) {
    return {
      type: userConstants.SEND_RESET_PASSWORD_SUCCESS,
      user: user
    };
  }
  function failure(error) {
    return {
      type: userConstants.SEND_RESET_PASSWORD_FAILURE,
      error: error
    };
  }
}
function resetPassword(userId, token, newPassword) {
  return function (dispatch) {
    dispatch(request({
      userId: userId
    }));
    userService.resetPassword(userId, token, newPassword).then(function (user) {
      dispatch(success(user));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request(user) {
    return {
      type: userConstants.RESET_PASSWORD_REQUEST,
      user: user
    };
  }
  function success(user) {
    return {
      type: userConstants.RESET_PASSWORD_SUCCESS,
      user: user
    };
  }
  function failure(error) {
    return {
      type: userConstants.RESET_PASSWORD_FAILURE,
      error: error
    };
  }
}
function validateResetPasswordToken(userId, token) {
  return function (dispatch) {
    dispatch(request({
      userId: userId
    }));
    userService.validateResetPasswordToken(userId, token).then(function (result) {
      dispatch(success(result));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request(result) {
    return {
      type: userConstants.VALIDATE_RESET_PASSWORD_TOKEN_REQUEST,
      result: result
    };
  }
  function success(result) {
    return {
      type: userConstants.VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: userConstants.VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
      error: error
    };
  }
}
function getAll() {
  return function (dispatch) {
    dispatch(request());
    userService.getAll().then(function (users) {
      return dispatch(success(users));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: userConstants.GETALL_REQUEST
    };
  }
  function success(users) {
    return {
      type: userConstants.GETALL_SUCCESS,
      users: users
    };
  }
  function failure(error) {
    return {
      type: userConstants.GETALL_FAILURE,
      error: error
    };
  }
}
function signUp(signUpRequest) {
  return function (dispatch) {
    dispatch(request());
    userService.signUp(signUpRequest).then(function () {
      return dispatch(success());
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: userConstants.REGISTER_USER_REQUEST
    };
  }
  function success() {
    return {
      type: userConstants.REGISTER_USER_SUCCESS
    };
  }
  function failure(error) {
    return {
      type: userConstants.REGISTER_USER_FAILURE,
      error: error !== null && error !== void 0 ? error : 'An error has occoured while signing up user'
    };
  }
}
function resendConfirmationEmail(username) {
  return function (dispatch) {
    dispatch(request());
    dispatch(messageActions.clear());
    userService.resendConfirmationEmail(username).then(function () {
      return dispatch(success());
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: userConstants.RESEND_EMAIL_REQUEST
    };
  }
  function success() {
    return {
      type: userConstants.RESEND_EMAIL_SUCCESS
    };
  }
  function failure(error) {
    return {
      type: userConstants.RESEND_EMAIL_FAILURE,
      error: error
    };
  }
}
function getCurrentUser() {
  return function (dispatch) {
    dispatch(request());
    userService.getCurrentUser().then(function (user) {
      return dispatch(success(user));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: userConstants.GET_CURRENT_USER_REQUEST
    };
  }
  function success(user) {
    return {
      type: userConstants.GET_CURRENT_USER_SUCCESS,
      user: user
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_CURRENT_USER_FAILURE,
      error: error
    };
  }
}
function updateCurrentUser(updateRequest, navigateTo, callback, message) {
  return function (dispatch) {
    dispatch(request());
    userService.updateCurrentUser(updateRequest).then(function (user) {
      dispatch(success(user));
      if (callback) {
        callback(user);
      }
      if (navigateTo) {
        historyPush(navigateTo);
      }
      if (message) {
        dispatch(messageActions.success(message));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: userConstants.UPDATE_CURRENT_USER_REQUEST
    };
  }
  function success(user) {
    return {
      type: userConstants.UPDATE_CURRENT_USER_SUCCESS,
      user: user
    };
  }
  function failure(error) {
    return {
      type: userConstants.UPDATE_CURRENT_USER_FAILURE,
      error: error
    };
  }
}
function confirmUserEmail(confirmationId) {
  return function (dispatch) {
    dispatch(request());
    userService.confirmUserEmail(confirmationId).then(function () {
      return dispatch(success());
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: userConstants.CONFIRM_EMAIL_REQUEST
    };
  }
  function success() {
    return {
      type: userConstants.CONFIRM_EMAIL_SUCCESS
    };
  }
  function failure() {
    return {
      type: userConstants.CONFIRM_EMAIL_FAILURE
    };
  }
}
function setProfileComplete(user) {
  var storageUser = localStorage.getItem('user');
  var storedUser = JSON.parse(storageUser);
  storedUser.profileCompleted = true;
  if (user) {
    storedUser.name = user.name;
    storedUser.lastName = user.lastName;
  }
  localStorage.setItem('user', JSON.stringify(storedUser));
  navigateUser(storedUser);
}
function setUserNames(user) {
  if (!user) {
    return;
  }
  var storageUser = localStorage.getItem('user');
  var storedUser = JSON.parse(storageUser);
  storedUser.name = user.name;
  storedUser.lastName = user.lastName;
  storedUser.phoneNumber = user.phoneNumber;
  localStorage.setItem('user', JSON.stringify(storedUser));
}
function setCompanyDetails(company) {
  if (!company) {
    return;
  }
  var storedUser = {
    companyName: company.name,
    companyId: company.companyId,
    addressLine1: company.addressLine1,
    addressLine2: company.addressLine2,
    city: company.city,
    zipCode: company.zipCode,
    state: company.state,
    country: company.country,
    contactName: company.contactName,
    contactLastName: company.contactLastName,
    invoiceEmail: company.invoiceEmail
  };
  sessionStorage.setItem('company', JSON.stringify(storedUser));
}
function navigateUser(user, lastLocation) {
  var _lastLocation$from, _lastLocation$from2;
  var isAdmin = isAdminUser();
  var isPartner = isPartnerUser();
  if (!user.profileCompleted) {
    historyPush('/create-profile/user');
  } else if (lastLocation !== null && lastLocation !== void 0 && (_lastLocation$from = lastLocation.from) !== null && _lastLocation$from !== void 0 && _lastLocation$from.pathname && (lastLocation === null || lastLocation === void 0 || (_lastLocation$from2 = lastLocation.from) === null || _lastLocation$from2 === void 0 ? void 0 : _lastLocation$from2.pathname) !== '/') {
    historyPush(lastLocation.from.pathname);
  } else if (isAdmin) {
    historyPush('/dashboard');
  } else if (user.workspaceCount > 1 || isPartner) {
    historyPush('/workspaces');
  } else {
    historyPush('/');
  }
}
function setWorkspaceViewType(type) {
  localStorage.setItem('workspaceView', type);
}
function setEventViewType(type) {
  localStorage.setItem('eventView', type);
}
function getWorkspaceViewType() {
  var _localStorage$getItem;
  return (_localStorage$getItem = localStorage.getItem('workspaceView')) !== null && _localStorage$getItem !== void 0 ? _localStorage$getItem : 'grid';
}
function getEventViewType() {
  var _localStorage$getItem2;
  return (_localStorage$getItem2 = localStorage.getItem('eventView')) !== null && _localStorage$getItem2 !== void 0 ? _localStorage$getItem2 : 'grid';
}
function changeWorkspace(workspaceId) {
  return function (dispatch) {
    dispatch(request());
    userService.changeWorkspace(workspaceId).then(function (user) {
      dispatch(success(user));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: userConstants.CHANGE_WORKSPACE_REQUEST
    };
  }
  function success(user) {
    return {
      type: userConstants.CHANGE_WORKSPACE_SUCCESS,
      user: user
    };
  }
  function failure(error) {
    return {
      type: userConstants.CHANGE_WORKSPACE_FAILURE,
      error: error
    };
  }
}
function setSelectedWorkspace(workspaceId, workspaceName, workspaceSlug, workspaceProduct) {
  return function (dispatch) {
    var storageUser = localStorage.getItem('user');
    var storedUser = JSON.parse(storageUser);
    storedUser.selectedWorkspaceId = workspaceId;
    storedUser.selectedWorkspaceName = workspaceName;
    storedUser.selectedWorkspaceSlug = workspaceSlug;
    storedUser.selectedWorkspaceProduct = workspaceProduct;
    localStorage.setItem('user', JSON.stringify(storedUser));
    dispatch(success(storedUser));
  };
  function success(user) {
    return {
      type: userConstants.SET_SELECTED_WORKSPACE,
      user: user
    };
  }
}
function setIsPlanUpgraded() {
  return function (dispatch) {
    var intl = appIntl();
    dispatch(messageActions.success(intl.formatMessage({
      id: 'plan_changed_successfully',
      defaultMessage: 'Plan was changed successfully'
    })));
  };
}
export default userActions;