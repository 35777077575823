import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { registrationsConstants, fileConstants } from '../constants/actions';
function registrations() {
  var _action$error;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case registrationsConstants.GET_REGISTRATIONS_REQUEST:
      return {
        header: state === null || state === void 0 ? void 0 : state.header,
        table: state === null || state === void 0 ? void 0 : state.table,
        columnsToExcludeFromHeader: state === null || state === void 0 ? void 0 : state.columnsToExcludeFromHeader,
        latestRevisionColumns: state === null || state === void 0 ? void 0 : state.latestRevisionColumns,
        success: false,
        loading: true,
        error: undefined,
        message: undefined,
        errors: undefined,
        isBulkEditLoading: undefined,
        isBulkEditSuccess: undefined,
        pagingLoading: action.isComputedOnServer
      };
    case registrationsConstants.GET_REGISTRATIONS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.registrations), {}, {
        success: true,
        loading: false,
        error: undefined,
        message: undefined,
        errors: undefined,
        pagingLoading: undefined
      });
    case registrationsConstants.GET_REGISTRATIONS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error,
        pagingLoading: undefined
      });
    case registrationsConstants.CREATE_REGISTRATION_REQUEST:
    case registrationsConstants.UPDATE_REGISTRATION_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdateSuccess: false,
        isUpdateLoading: true,
        message: undefined,
        error: undefined,
        errors: undefined
      });
    case registrationsConstants.CREATE_REGISTRATION_SUCCESS:
    case registrationsConstants.UPDATE_REGISTRATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdateSuccess: true,
        isUpdateLoading: false,
        message: undefined,
        error: undefined,
        errors: undefined
      });
    case registrationsConstants.CREATE_REGISTRATION_FAILURE:
    case registrationsConstants.UPDATE_REGISTRATION_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUpdateSuccess: false,
        isUpdateLoading: false,
        message: action.response.message,
        error: action.response.error,
        errors: action.response.errors
      });
    case registrationsConstants.BULK_EDIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isBulkEditSuccess: false,
        isBulkEditLoading: true,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.BULK_EDIT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isBulkEditSuccess: true,
        isBulkEditLoading: false,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.BULK_EDIT_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isBulkEditSuccess: false,
        isBulkEditLoading: false,
        message: action.response.message,
        errors: action.response.errors,
        errorCodes: action.response.errorCodes,
        error: action.response.error
      });
    case registrationsConstants.SEND_EMAIL_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isEmailSuccess: false,
        isEmailLoading: true,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.SEND_EMAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isEmailSuccess: true,
        isEmailLoading: false,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.SEND_EMAIL_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isEmailSuccess: false,
        isEmailLoading: false,
        message: action.response.message,
        errors: action.response.errors,
        errorCodes: action.response.errorCodes,
        error: action.response.error
      });
    case registrationsConstants.SEND_SMS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendDateTime: action.sendDateTime,
        isSmsSuccess: false,
        isSmsLoading: true,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.SEND_SMS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSmsSuccess: true,
        isSmsLoading: false,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.SEND_SMS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSmsSuccess: false,
        isSmsLoading: false,
        message: action.response.message,
        errors: action.response.errors,
        errorCodes: action.response.errorCodes,
        error: action.response.error
      });
    case registrationsConstants.ADD_VOTING_POWER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVotingPowerSuccess: false,
        isVotingPowerLoading: true,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.ADD_VOTING_POWER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVotingPowerSuccess: true,
        isVotingPowerLoading: false,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.ADD_VOTING_POWER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVotingPowerSuccess: false,
        isVotingPowerLoading: false,
        message: action.response.message,
        errors: action.response.errors,
        errorCodes: action.response.errorCodes,
        error: action.response.error
      });
    case registrationsConstants.ADD_VOUCHERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVouchersSuccess: false,
        isVouchersLoading: true,
        voucherAction: 'add',
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.ADD_VOUCHERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVouchersSuccess: true,
        isVouchersLoading: false,
        voucherAction: 'add',
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.ADD_VOUCHERS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVouchersSuccess: false,
        isVouchersLoading: false,
        voucherAction: 'add',
        message: action.response.message,
        errors: action.response.errors,
        errorCodes: action.response.errorCodes,
        error: action.response.error
      });
    case registrationsConstants.REMOVE_VOUCHERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVouchersSuccess: false,
        isVouchersLoading: true,
        voucherAction: 'remove',
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.REMOVE_VOUCHERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVouchersSuccess: true,
        isVouchersLoading: false,
        voucherAction: 'remove',
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.REMOVE_VOUCHERS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVouchersSuccess: false,
        isVouchersLoading: false,
        voucherAction: 'remove',
        message: action.response.message,
        errors: action.response.errors,
        errorCodes: action.response.errorCodes,
        error: action.response.error
      });
    case registrationsConstants.CREATE_SHARE_LINK_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isShareLinkLoading: true,
        isShareLinkSuccess: false,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.CREATE_SHARE_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isShareLinkLoading: false,
        isShareLinkSuccess: true,
        message: undefined,
        error: undefined,
        errors: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.CREATE_SHARE_LINK_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isShareLinkLoading: false,
        isShareLinkSuccess: false,
        message: action.response.message,
        errors: action.response.errors,
        errorCodes: action.response.errorCodes,
        error: action.response.error
      });
    case registrationsConstants.GET_SHARE_LINK_LIST_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isShareLinkListLoading: true,
        isShareLinkListSuccess: false,
        error: undefined,
        message: undefined
      });
    case registrationsConstants.GET_SHARE_LINK_LIST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        links: action.links,
        isShareLinkListLoading: false,
        isShareLinkListSuccess: true,
        error: undefined,
        message: undefined
      });
    case registrationsConstants.GET_SHARE_LINK_LIST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isShareLinkListLoading: false,
        isShareLinkListSuccess: false,
        error: action.error,
        message: (_action$error = action.error) === null || _action$error === void 0 ? void 0 : _action$error.message
      });
    case registrationsConstants.CLEAR_SHARED_LINK_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        links: null,
        isShareLinkListLoading: false,
        isShareLinkListSuccess: false,
        error: undefined,
        message: undefined
      });
    case registrationsConstants.SAVE_GUEST_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        registrationId: undefined
      });
    case registrationsConstants.SAVE_GUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), action.response);
    case registrationsConstants.SAVE_GUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        registrationId: undefined,
        message: action.response.message,
        error: action.response.error,
        errors: action.response.errors
      });
    case registrationsConstants.CLEAR_MESSAGES:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSmsSuccess: undefined,
        isSmsLoading: undefined,
        isEmailSuccess: undefined,
        isEmailLoading: undefined,
        registrationId: undefined,
        isUpdateSuccess: undefined,
        isBulkEditLoading: undefined,
        isBulkEditSuccess: undefined,
        message: undefined,
        errors: undefined,
        error: undefined,
        errorCodes: undefined
      });
    case registrationsConstants.DELETE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: undefined
      });
    case registrationsConstants.DELETE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: undefined
      });
    case registrationsConstants.DELETE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.response.error
      });
    case fileConstants.UPLOAD_INVITEE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        importInviteesLoading: true
      });
    case fileConstants.UPLOAD_INVITEE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        importInviteesLoading: false
      });
    case fileConstants.UPLOAD_INVITEE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        importInviteesLoading: false
      });
    case registrationsConstants.EXPORT_REGISTRATIONS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: true
      });
    case registrationsConstants.EXPORT_REGISTRATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false,
        exportSuccess: true
      });
    case registrationsConstants.EXPORT_REGISTRATIONS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false,
        exportSuccess: false
      });
    case registrationsConstants.GET_REGISTRATIONS_VOTING_POWER_REQUEST:
      return {
        success: false,
        loading: true,
        error: undefined,
        message: undefined,
        errors: undefined,
        isBulkEditLoading: undefined,
        isBulkEditSuccess: undefined,
        pagingLoading: action.isComputedOnServer
      };
    case registrationsConstants.GET_REGISTRATIONS_VOTING_POWER_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.registrations), {}, {
        success: true,
        loading: false,
        error: undefined,
        message: undefined,
        errors: undefined,
        pagingLoading: undefined
      });
    case registrationsConstants.GET_REGISTRATIONS_VOTING_POWER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error,
        pagingLoading: undefined
      });
    case registrationsConstants.GET_REGISTRATIONS_VOUCHERS_REQUEST:
      return {
        success: false,
        loading: true,
        error: undefined,
        message: undefined,
        errors: undefined,
        isBulkEditLoading: undefined,
        isBulkEditSuccess: undefined,
        pagingLoading: action.isComputedOnServer
      };
    case registrationsConstants.GET_REGISTRATIONS_VOUCHERS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.registrations), {}, {
        success: true,
        loading: false,
        error: undefined,
        message: undefined,
        errors: undefined,
        pagingLoading: undefined
      });
    case registrationsConstants.GET_REGISTRATIONS_VOUCHERS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error,
        pagingLoading: undefined
      });
    case registrationsConstants.GET_VOUCHERS_TRANSACTIONS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: undefined,
        message: undefined,
        errors: undefined,
        isBulkEditLoading: undefined,
        isBulkEditSuccess: undefined,
        pagingLoading: undefined,
        isLoadingSelectedRegistration: true
      });
    case registrationsConstants.GET_VOUCHERS_TRANSACTIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedRegistration: {
          id: action.registrationId,
          voucherBalance: action.voucherBalance
        },
        isLoadingSelectedRegistration: false,
        success: true,
        loading: false,
        error: undefined,
        message: undefined,
        errors: undefined,
        pagingLoading: undefined
      });
    case registrationsConstants.GET_VOUCHERS_TRANSACTIONS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error,
        pagingLoading: undefined,
        isLoadingSelectedRegistration: false
      });
    case registrationsConstants.CLEAR_VOUCHERS_TRANSACTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedRegistration: null,
        success: true,
        loading: false,
        error: undefined,
        pagingLoading: undefined
      });
    case registrationsConstants.GET_SCANNED_REGISTRATION_REQUEST:
      return {
        success: false,
        loading: true,
        error: undefined,
        message: undefined,
        errors: undefined,
        isBulkEditLoading: undefined,
        isBulkEditSuccess: undefined,
        pagingLoading: action.isComputedOnServer
      };
    case registrationsConstants.GET_SCANNED_REGISTRATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        registration: action.result,
        success: true,
        loading: false,
        error: undefined,
        message: undefined,
        errors: undefined,
        pagingLoading: undefined
      });
    case registrationsConstants.GET_SCANNED_REGISTRATION_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error,
        pagingLoading: undefined
      });
    default:
      return state;
  }
}
export default registrations;