import eventService from '../services/event-service';
import { eventConstants } from '../constants/actions';
import { historyPush } from '../helpers/history';
import { appIntl } from '../IntlGlobalProvider';
import messageActions from './message-actions';
var templatePageActions = {
  saveTemplatePage: saveTemplatePage
};
function saveTemplatePage(eventId, eventTemplatePage, navigateTo) {
  var isDirty = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    eventService.saveTemplatePage(eventId, eventTemplatePage, isDirty).then(function (templates) {
      dispatch(success(templates));
      if (navigateTo) {
        historyPush(navigateTo);
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'EventPage.Changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.SAVE_TEMPLATE_PAGE
    };
  }
  function success(templates) {
    return {
      type: eventConstants.SAVE_TEMPLATE_PAGE_SUCCESS,
      templates: templates
    };
  }
  function failure(error) {
    return {
      type: eventConstants.SAVE_TEMPLATE_PAGE_FAILURE,
      error: error
    };
  }
}
export default templatePageActions;