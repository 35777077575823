import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import config from 'config/environment';
import { requestHeader, handleResponse } from '../helpers/request-helpers';
var liveDocumentsService = {
  getDocuments: getDocuments,
  createDocuments: createDocuments,
  updateDocuments: updateDocuments,
  updatePartialDocuments: updatePartialDocuments,
  deleteDocuments: deleteDocuments,
  reorderDocuments: reorderDocuments
};
function getDocuments(eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/documents"), requestOptions).then(handleResponse);
}
function createDocuments(eventId, document) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      title: document.title,
      description: document.description,
      documentUrl: document.documentUrl,
      isHidden: document.isHidden,
      groupIds: document.groupIds,
      platformPages: document.platformPages,
      sessionIds: document.sessionIds
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/documents/create"), requestOptions).then(handleResponse);
}
function updateDocuments(eventId, documentId, document) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify({
      title: document.title,
      description: document.description,
      documentUrl: document.documentUrl,
      isHidden: document.isHidden,
      groupIds: document.groupIds,
      platformPages: document.platformPages,
      sessionIds: document.sessionIds
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/documents/").concat(documentId), requestOptions).then(handleResponse);
}
function updatePartialDocuments(eventId, entryId, document) {
  var requestOptions = {
    method: 'PATCH',
    headers: requestHeader(),
    body: JSON.stringify({
      isHidden: document.isHidden
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/documents/").concat(entryId), requestOptions).then(handleResponse);
}
function deleteDocuments(eventId, documentId) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/documents/").concat(documentId), requestOptions).then(handleResponse);
}
function reorderDocuments(eventId, documentIds) {
  var requestOptions = {
    method: 'PATCH',
    headers: requestHeader(),
    body: JSON.stringify({
      documentIds: documentIds
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/documents/reorder"), requestOptions).then(handleResponse);
}
export default liveDocumentsService;