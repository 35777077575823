function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import eventService from '../services/event-service';
import paymentService from '../services/payment-service';
import { eventConstants, registrationsConstants, paymentsConstants } from '../constants/actions';
import { historyPush } from '../helpers/history';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var eventActions = {
  get: get,
  create: create,
  initialize: initialize,
  update: update,
  publish: publish,
  deleteEvent: deleteEvent,
  duplicateEvent: duplicateEvent,
  exportEvent: exportEvent,
  getRegistrations: getRegistrations,
  exportRegistrations: exportRegistrations,
  getRegistrationsVouchers: getRegistrationsVouchers,
  exportRegistrationsVouchers: exportRegistrationsVouchers,
  getVouchersTransactions: getVouchersTransactions,
  clearVouchersTransactions: clearVouchersTransactions,
  exportExample: exportExample,
  onNavigate: onNavigate,
  checkPaymentConnection: checkPaymentConnection,
  getPaymentSecretKey: getPaymentSecretKey,
  saveBranding: saveBranding,
  deleteBrandingItems: deleteBrandingItems,
  saveLiveSettings: saveLiveSettings,
  clearErrors: clearErrors,
  validateSlug: validateSlug,
  getEventStatistics: getEventStatistics,
  getEmailIdentities: getEmailIdentities,
  verifyEmailIdentity: verifyEmailIdentity,
  updateEmailIdentity: updateEmailIdentity,
  deleteEmailIdentity: deleteEmailIdentity,
  updateEventFeature: updateEventFeature,
  getEventScannerSettings: getEventScannerSettings,
  removeDevice: removeDevice,
  getSharedLinkList: getSharedLinkList,
  clearSharedLinkList: clearSharedLinkList,
  createParticipantPassword: createParticipantPassword,
  getParticipantById: getParticipantById
};
function initialize() {
  return function (dispatch) {
    dispatch({
      type: eventConstants.INITIALIZE_EVENT
    });
  };
}
function get(eventId) {
  return function (dispatch) {
    dispatch(request());
    eventService.get(eventId).then(function (event) {
      return dispatch(success(event));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.GET_REQUEST
    };
  }
  function success(event) {
    return {
      type: eventConstants.GET_SUCCESS,
      event: event
    };
  }
  function failure(error) {
    return {
      type: eventConstants.GET_FAILURE,
      error: error
    };
  }
}
function create(payload, navigateTo) {
  var isDirty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var intl = appIntl();
  return function (dispatch) {
    dispatch(createEvent());
    eventService.create(payload, isDirty).then(function (event) {
      var _event$slug;
      dispatch(success(event));
      historyPush("".concat((_event$slug = event.slug) !== null && _event$slug !== void 0 ? _event$slug : event.id, "/").concat(navigateTo));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'EventPage.Changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function createEvent() {
    return {
      type: eventConstants.CREATE_REQUEST
    };
  }
  function success(event) {
    return {
      type: eventConstants.CREATE_REQUEST_SUCCESS,
      event: event
    };
  }
  function failure(error) {
    return {
      type: eventConstants.CREATE_REQUEST_FAILURE,
      error: error
    };
  }
}
function update(payload, eventId, navigateTo) {
  var isDirty = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var intl = appIntl();
  return function (dispatch) {
    dispatch(updateEvent(payload));
    eventService.update(payload, eventId, isDirty).then(function (event) {
      var _event$slug2;
      dispatch(success(event));
      historyPush(navigateTo === null || navigateTo === void 0 ? void 0 : navigateTo.replace('{{eventId}}', (_event$slug2 = event.slug) !== null && _event$slug2 !== void 0 ? _event$slug2 : event.id));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'EventPage.Changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function updateEvent(event) {
    return {
      type: eventConstants.UPDATE_REQUEST,
      event: event
    };
  }
  function success(event) {
    return {
      type: eventConstants.UPDATE_REQUEST_SUCCESS,
      event: event
    };
  }
  function failure(error) {
    return {
      type: eventConstants.UPDATE_REQUEST_FAILURE,
      error: error
    };
  }
}
function publish(eventId, isUpdate, navigateTo) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(publishEvent(eventId));
    eventService.publish(eventId).then(function (result) {
      dispatch(success(result));
      historyPush(navigateTo);
      if (isUpdate) {
        dispatch(messageActions.success(intl.formatMessage({
          id: 'EventPage.Republish_successful',
          defaultMessage: 'Event was successfully republished'
        })));
      } else {
        dispatch(messageActions.success(intl.formatMessage({
          id: 'EventPage.Publish_successful',
          defaultMessage: 'Event was successfully published'
        })));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function publishEvent(event) {
    return {
      type: eventConstants.PUBLISH_REQUEST,
      event: event
    };
  }
  function success(result) {
    return {
      type: eventConstants.PUBLISH_REQUEST_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: eventConstants.PUBLISH_REQUEST_FAILURE,
      error: error
    };
  }
}
function deleteEvent(eventId) {
  return function (dispatch) {
    dispatch(request());
    eventService.deleteEvent(eventId).then(function (result) {
      dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.DELETE_EVENT_REQUEST
    };
  }
  function success() {
    return {
      type: eventConstants.DELETE_EVENT_SUCCESS,
      eventId: eventId
    };
  }
  function failure(error) {
    return {
      type: eventConstants.DELETE_EVENT_FAILURE,
      error: error
    };
  }
}
function duplicateEvent(eventId, successCallback, failureCallback) {
  return function (dispatch) {
    dispatch(request());
    eventService.duplicateEvent(eventId).then(function (result) {
      dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.DUPLICATE_EVENT_REQUEST
    };
  }
  function success(result) {
    if (successCallback) {
      successCallback(result);
    }
    return {
      type: eventConstants.DUPLICATE_EVENT_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    if (failureCallback) {
      failureCallback(error);
    }
    return {
      type: eventConstants.DUPLICATE_EVENT_FAILURE,
      error: error
    };
  }
}
function exportEvent(eventId) {
  return function (dispatch) {
    dispatch(request());
    eventService.exportEvent(eventId).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.EXPORT_EVENT_REQUEST
    };
  }
  function success(result) {
    return {
      type: eventConstants.EXPORT_EVENT_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: eventConstants.EXPORT_EVENT_FAILURE,
      error: error
    };
  }
}
function saveBranding(eventId, branding) {
  return function (dispatch) {
    dispatch(request(eventId));
    eventService.saveBranding(eventId, branding).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.SAVE_BRANDING_REQUEST
    };
  }
  function success(result) {
    return {
      type: eventConstants.SAVE_BRANDING_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: eventConstants.SAVE_BRANDING_FAILURE,
      error: error
    };
  }
}
function deleteBrandingItems(eventId, branding) {
  return function (dispatch) {
    dispatch(request({
      eventId: eventId,
      branding: branding
    }));
    eventService.deleteBrandingItems(eventId, branding).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.DELETE_BRANDING_ITEMS_REQUEST
    };
  }
  function success(result) {
    return {
      type: eventConstants.DELETE_BRANDING_ITEMS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: eventConstants.DELETE_BRANDING_ITEMS_FAILURE,
      error: error
    };
  }
}
function saveLiveSettings(eventId, liveSettings, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(eventId));
    eventService.saveLiveSettings(eventId, liveSettings).then(function (result) {
      dispatch(success(result.liveSettingsPage));
      dispatch(setLiveEventUrl(result.liveEventUrl));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'EventLiveSettings.Saved_successfully',
        defaultMessage: 'Settings were successfully saved'
      })));
      if (callback) callback();
    }, function (error) {
      dispatch(failure(error));
      if (callback) callback();
    });
  };
  function request() {
    return {
      type: eventConstants.SAVE_LIVE_SETTINGS_REQUEST
    };
  }
  function success(result) {
    return {
      type: eventConstants.SAVE_LIVE_SETTINGS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: eventConstants.SAVE_LIVE_SETTINGS_FAILURE,
      error: error
    };
  }
  function setLiveEventUrl(result) {
    return {
      type: eventConstants.UPDATE_LIVE_EVENT_URL,
      result: result
    };
  }
}
function getRegistrations(eventId, params) {
  return function (dispatch) {
    dispatch(getEventRegistrations(params.isComputedOnServer));
    eventService.getRegistrations(eventId, params).then(function (registrations) {
      return dispatch(success(registrations));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function getEventRegistrations(isComputedOnServer) {
    return {
      type: registrationsConstants.GET_REGISTRATIONS_REQUEST,
      eventId: eventId,
      isComputedOnServer: isComputedOnServer
    };
  }
  function success(registrations) {
    return {
      type: registrationsConstants.GET_REGISTRATIONS_SUCCESS,
      eventId: eventId,
      registrations: registrations
    };
  }
  function failure(error) {
    return {
      type: registrationsConstants.GET_REGISTRATIONS_FAILURE,
      error: error
    };
  }
}
function exportRegistrations(eventId, filter, selectedColumns) {
  return function (dispatch) {
    dispatch(request(eventId));
    eventService.exportRegistrations(eventId, filter, selectedColumns).then(function (registrations) {
      return dispatch(success(registrations));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request(id) {
    return {
      type: registrationsConstants.EXPORT_REGISTRATIONS_REQUEST,
      eventId: id
    };
  }
  function success(registrations) {
    return {
      type: registrationsConstants.EXPORT_REGISTRATIONS_SUCCESS,
      registrations: registrations
    };
  }
  function failure(error) {
    return {
      type: registrationsConstants.EXPORT_REGISTRATIONS_FAILURE,
      error: error
    };
  }
}
function getRegistrationsVouchers(eventId, params) {
  return function (dispatch) {
    dispatch(getEventRegistrationsVouchers(params.isComputedOnServer));
    eventService.getRegistrationsVouchers(eventId, params).then(function (registrations) {
      return dispatch(success(registrations));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function getEventRegistrationsVouchers(isComputedOnServer) {
    return {
      type: registrationsConstants.GET_REGISTRATIONS_VOUCHERS_REQUEST,
      eventId: eventId,
      isComputedOnServer: isComputedOnServer
    };
  }
  function success(registrations) {
    return {
      type: registrationsConstants.GET_REGISTRATIONS_VOUCHERS_SUCCESS,
      eventId: eventId,
      registrations: registrations
    };
  }
  function failure(error) {
    return {
      type: registrationsConstants.GET_REGISTRATIONS_VOUCHERS_FAILURE,
      error: error
    };
  }
}
function exportRegistrationsVouchers(eventId, filter) {
  return function (dispatch) {
    dispatch(request(eventId));
    eventService.exportRegistrationsVouchers(eventId, filter).then(function (registrations) {
      return dispatch(success(registrations));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request(id) {
    return {
      type: registrationsConstants.EXPORT_REGISTRATIONS_VOUCHERS_REQUEST,
      eventId: id
    };
  }
  function success(registrations) {
    return {
      type: registrationsConstants.EXPORT_REGISTRATIONS_VOUCHERS_SUCCESS,
      registrations: registrations
    };
  }
  function failure(error) {
    return {
      type: registrationsConstants.EXPORT_REGISTRATIONS_VOUCHERS_FAILURE,
      error: error
    };
  }
}
function getVouchersTransactions(eventId, registrationId) {
  return function (dispatch) {
    dispatch(getRegistrationsVouchersTransaction(registrationId));
    eventService.getVouchersTransactions(eventId, registrationId).then(function (response) {
      dispatch(success(response));
    }).catch(function (error) {
      return dispatch(failure(error));
    });
  };
  function getRegistrationsVouchersTransaction() {
    return {
      type: registrationsConstants.GET_VOUCHERS_TRANSACTIONS_REQUEST,
      eventId: eventId
    };
  }
  function success(response) {
    return _objectSpread({
      type: registrationsConstants.GET_VOUCHERS_TRANSACTIONS_SUCCESS,
      eventId: eventId
    }, response);
  }
  function failure(error) {
    return {
      type: registrationsConstants.GET_VOUCHERS_TRANSACTIONS_FAILURE,
      error: error
    };
  }
}
function clearVouchersTransactions(eventId) {
  return {
    type: registrationsConstants.CLEAR_VOUCHERS_TRANSACTIONS,
    eventId: eventId
  };
}
function exportExample(eventId) {
  return function (dispatch) {
    dispatch(request(eventId));
    eventService.exportExample(eventId).then(function (registrations) {
      return dispatch(success(registrations));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request(id) {
    return {
      type: registrationsConstants.EXPORT_EXAMPLE_REQUEST,
      eventId: id
    };
  }
  function success(registrations) {
    return {
      type: registrationsConstants.EXPORT_EXAMPLE_SUCCESS,
      registrations: registrations
    };
  }
  function failure(error) {
    return {
      type: registrationsConstants.EXPORT_EXAMPLE_FAILURE,
      error: error
    };
  }
}
function checkPaymentConnection(paymentApiSecret, callback) {
  return function (dispatch) {
    dispatch(request());
    paymentService.checkPaymentConnection(paymentApiSecret).then(function () {
      return dispatch(success());
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    callback({
      loading: true
    });
    return {
      type: paymentsConstants.CHECK_PAYMENT_CONNECTION_REQUEST
    };
  }
  function success() {
    callback({
      loading: false,
      success: true
    });
    return {
      type: paymentsConstants.CHECK_PAYMENT_CONNECTION_SUCCESS
    };
  }
  function failure(error) {
    callback({
      loading: false,
      success: false,
      error: error
    });
    return {
      type: paymentsConstants.CHECK_PAYMENT_CONNECTION_FAILURE,
      error: error
    };
  }
}
function getPaymentSecretKey(eventId, callback) {
  return function (dispatch) {
    dispatch(request());
    paymentService.getPaymentSecretKey(eventId).then(function (key) {
      return dispatch(success(key));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    callback({
      loading: true
    });
    return {
      type: paymentsConstants.GET_PAYMENT_SECRET_REQUEST
    };
  }
  function success(key) {
    callback({
      loading: false,
      success: true,
      key: key
    });
    return {
      type: paymentsConstants.GET_PAYMENT_SECRET_SUCCESS
    };
  }
  function failure(error) {
    callback({
      loading: false,
      success: false,
      error: error
    });
    return {
      type: paymentsConstants.GET_PAYMENT_SECRET_FAILURE,
      error: error
    };
  }
}
function onNavigate(navigateTo) {
  return function () {
    historyPush(navigateTo);
  };
}
function clearErrors(event) {
  return {
    type: eventConstants.CLEAR_ERRORS,
    event: event
  };
}
function validateSlug(eventId, slug, callback) {
  return function (dispatch) {
    dispatch(request());
    eventService.validateSlug(eventId, slug).then(function (response) {
      dispatch(success(response));
      callback(response);
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.VALIDATE_SLUG_REQUEST
    };
  }
  function success(response) {
    return {
      type: eventConstants.VALIDATE_SLUG_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: eventConstants.VALIDATE_SLUG_FAILURE,
      error: error
    };
  }
}
function getEventStatistics(eventId) {
  return function (dispatch) {
    dispatch(request());
    eventService.getEventStatistics(eventId).then(function (response) {
      return dispatch(success(response));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: eventConstants.GET_EVENT_STATISTICS
    };
  }
  function success(response) {
    return {
      type: eventConstants.GET_EVENT_STATISTICS_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: eventConstants.GET_EVENT_STATISTICS_FAILURE,
      error: error
    };
  }
}
function getEmailIdentities(eventIdOrSlug) {
  return function (dispatch) {
    dispatch(request());
    eventService.getEmailIdentities(eventIdOrSlug).then(function (response) {
      return dispatch(success(response));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: eventConstants.GET_EVENT_EMAIL_IDENTITIES_REQUEST
    };
  }
  function success(response) {
    return {
      type: eventConstants.GET_EVENT_EMAIL_IDENTITIES_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: eventConstants.GET_EVENT_EMAIL_IDENTITIES_FAILURE,
      error: error
    };
  }
}
function verifyEmailIdentity(eventIdOrSlug, emailIdentity, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    eventService.verifyEmailIdentity(eventIdOrSlug, emailIdentity).then(function (response) {
      dispatch(success(response));
      if (callback) {
        callback();
      }
    }, function (error) {
      dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.VERIFY_EVENT_EMAIL_IDENTITY_REQUEST
    };
  }
  function success(response) {
    var successMessage = intl.formatMessage({
      id: 'email_successfully_updated',
      defaultMessage: 'Email successfully updated'
    });
    return {
      type: eventConstants.VERIFY_EVENT_EMAIL_IDENTITY_SUCCESS,
      response: response,
      message: emailIdentity.isFromWorkspace ? successMessage : undefined
    };
  }
  function failure(error) {
    return {
      type: eventConstants.VERIFY_EVENT_EMAIL_IDENTITY_FAILURE,
      error: error
    };
  }
}
function updateEmailIdentity(eventIdOrSlug, emailIdentity, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    eventService.updateEmailIdentity(eventIdOrSlug, emailIdentity).then(function (response) {
      dispatch(success(response));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'email_successfully_updated',
        defaultMessage: 'Email successfully updated'
      })));
      if (callback) {
        callback();
      }
    }, function (error) {
      dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.UPDATE_EVENT_EMAIL_IDENTITY_REQUEST
    };
  }
  function success(response) {
    return {
      type: eventConstants.UPDATE_EVENT_EMAIL_IDENTITY_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: eventConstants.UPDATE_EVENT_EMAIL_IDENTITY_FAILURE,
      error: error
    };
  }
}
function deleteEmailIdentity(eventIdOrSlug, emailIdentityId, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    eventService.deleteEmailIdentity(eventIdOrSlug, emailIdentityId).then(function (response) {
      dispatch(success(response));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'email_successfully_deleted',
        defaultMessage: 'Email successfully deleted'
      })));
      if (callback) {
        callback();
      }
    }, function (error) {
      dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.DELETE_EVENT_EMAIL_IDENTITY_REQUEST
    };
  }
  function success(response) {
    return {
      type: eventConstants.DELETE_EVENT_EMAIL_IDENTITY_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: eventConstants.DELETE_EVENT_EMAIL_IDENTITY_FAILURE,
      error: error
    };
  }
}
function updateEventFeature(eventId, feature) {
  return function (dispatch) {
    dispatch(request());
    eventService.updateEventFeature(eventId, feature).then(function (response) {
      dispatch(success(response));
    }, function (error) {
      dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.UPDATE_EVENT_FEATURE_REQUEST
    };
  }
  function success(response) {
    return {
      type: eventConstants.UPDATE_EVENT_FEATURE_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: eventConstants.UPDATE_EVENT_FEATURE_FAILURE,
      error: error
    };
  }
}
function getEventScannerSettings(eventId) {
  return function (dispatch) {
    dispatch(request());
    eventService.getEventScannerSettings(eventId).then(function (response) {
      dispatch(success(response));
    }, function (error) {
      dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.GET_EVENT_DEVICES_REQUEST
    };
  }
  function success(response) {
    return {
      type: eventConstants.GET_EVENT_DEVICES_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: eventConstants.GET_EVENT_DEVICES_FAILURE,
      error: error
    };
  }
}
export function removeDevice(eventId, deviceId) {
  return function (dispatch) {
    dispatch(request());
    eventService.removeDevice(eventId, deviceId).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.REMOVE_DEVICE_REQUEST
    };
  }
  function success(result) {
    return {
      type: eventConstants.REMOVE_DEVICE_SUCCESS,
      result: result,
      deviceId: deviceId
    };
  }
  function failure(error) {
    return {
      type: eventConstants.REMOVE_DEVICE_ERROR,
      error: error
    };
  }
}
function getSharedLinkList(eventId) {
  return function (dispatch) {
    dispatch(getEventLinkList(eventId));
    eventService.getSharedLinkList(eventId).then(function (response) {
      dispatch(success(response));
    }).catch(function (error) {
      dispatch(failure(error));
    });
  };
  function getEventLinkList() {
    return {
      type: registrationsConstants.GET_SHARE_LINK_LIST_REQUEST,
      eventId: eventId
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.GET_SHARE_LINK_LIST_SUCCESS,
      eventId: eventId,
      links: response
    };
  }
  function failure(error) {
    return {
      type: registrationsConstants.GET_SHARE_LINK_LIST_FAILURE,
      error: error
    };
  }
}
function clearSharedLinkList(eventId) {
  return {
    type: registrationsConstants.CLEAR_SHARED_LINK_LIST,
    eventId: eventId
  };
}
function createParticipantPassword(eventId, password) {
  return function (dispatch) {
    dispatch(request());
    eventService.createParticipantPassword(eventId, password).then(function (result) {
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: eventConstants.CREATE_PARTICIPANT_PASSWORD_REQUEST
    };
  }
  function success(response) {
    return {
      type: eventConstants.CREATE_PARTICIPANT_PASSWORD_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: eventConstants.CREATE_PARTICIPANT_PASSWORD_ERROR,
      response: response
    };
  }
}
function getParticipantById(eventId, userId) {
  return function (dispatch) {
    dispatch(request());
    eventService.getParticipantById(eventId, userId).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: registrationsConstants.GET_SCANNED_REGISTRATION_REQUEST,
      eventId: eventId
    };
  }
  function success(result) {
    return {
      type: registrationsConstants.GET_SCANNED_REGISTRATION_SUCCESS,
      eventId: eventId,
      result: result
    };
  }
  function failure(error) {
    return {
      type: registrationsConstants.GET_SCANNED_REGISTRATION_ERROR,
      error: error
    };
  }
}
export default eventActions;