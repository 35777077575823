import { appIntl } from '../../IntlGlobalProvider';
var registrationType = {
  REGISTERED: 0,
  INVITE_PENDING: 1,
  INVITE_SENT: 2,
  INVITE_DECLINED: 3,
  CANCELLED: 5,
  HAS_LOGGED_IN: 98,
  NOT_LOGGED_IN: 99
};
function getStatusOptions() {
  var intl = appIntl();
  return [{
    label: intl.formatMessage({
      id: 'attending',
      defaultMessage: 'Attending'
    }),
    value: registrationType.REGISTERED,
    color: 'green'
  }, {
    label: intl.formatMessage({
      id: 'awaiting_reply',
      defaultMessage: 'Awaiting reply'
    }),
    value: registrationType.INVITE_SENT,
    color: 'orange'
  }, {
    label: intl.formatMessage({
      id: 'not_attending',
      defaultMessage: 'Not attending'
    }),
    value: registrationType.INVITE_DECLINED,
    color: 'red'
  }, {
    label: intl.formatMessage({
      id: 'invitation_not_sent',
      defaultMessage: 'Invitation not sent'
    }),
    value: registrationType.INVITE_PENDING,
    color: 'grey'
  }, {
    label: intl.formatMessage({
      id: 'registration_cancelled',
      defaultMessage: 'Cancelled'
    }),
    value: registrationType.CANCELLED,
    color: 'black'
  }];
}
export default registrationType;
export { registrationType, getStatusOptions };