import "core-js/modules/es.string.link.js";
import { widgetType } from '../../../constants/types';
import { appIntl } from '../../../IntlGlobalProvider';
function validateItem(item) {
  var type = item.type,
    options = item.options;
  var intl = appIntl();
  switch (type) {
    case widgetType.TEXT:
      if (!options.value) {
        return {
          widgetId: item.id,
          optionId: 'value',
          message: intl.formatMessage({
            id: 'LandingPageBuilder.Validation.Text_required',
            defaultMessage: 'Text is required'
          })
        };
      }
      break;
    case widgetType.FORM:
      return null;
    case widgetType.IMAGE:
      if (Array.isArray(options.fileUrl)) {
        return {
          widgetId: item.id,
          optionId: 'fileUrl',
          message: intl.formatMessage({
            id: 'LandingPageBuilder.Validation.Single_file',
            defaultMessage: 'You can only upload one image file'
          })
        };
      }
      if (!options.fileUrl) {
        return {
          widgetId: item.id,
          optionId: 'fileUrl',
          message: intl.formatMessage({
            id: 'LandingPageBuilder.Validation.File_required',
            defaultMessage: 'Please upload an image'
          })
        };
      }
      break;
    case widgetType.VIDEO:
      if (!options.link) {
        return {
          widgetId: item.id,
          optionId: 'link',
          message: intl.formatMessage({
            id: 'LandingPageBuilder.Validation.Link_required',
            defaultMessage: 'Link is required'
          })
        };
      }
      break;
    case widgetType.COUNTDOWN:
      if (!options.date) {
        return {
          widgetId: item.id,
          optionId: 'date',
          message: intl.formatMessage({
            id: 'LandingPageBuilder.Validation.Date_required',
            defaultMessage: 'Date is required'
          })
        };
      }
      break;
    case widgetType.LOCATION:
      if (!options.location) {
        return {
          widgetId: item.id,
          optionId: 'location',
          message: intl.formatMessage({
            id: 'LandingPageBuilder.Validation.Location_required',
            defaultMessage: 'Location is required'
          })
        };
      }
      break;
    default:
      if (!options.label) {
        return {
          widgetId: item.id,
          optionId: 'label',
          message: intl.formatMessage({
            id: 'LandingPageBuilder.Validation.Label_required',
            defaultMessage: 'Label is required'
          })
        };
      }
      break;
  }
  return null;
}
export default validateItem;