import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponse } from '../helpers/request-helpers';
var changelogItemService = {
  getChangelogItems: getChangelogItems,
  createChangelogItem: createChangelogItem,
  updateChangelogItem: updateChangelogItem,
  deleteChangelogItem: deleteChangelogItem,
  setLastChangelogSeenAt: setLastChangelogSeenAt
};
function getChangelogItems() {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/change-log"), requestOptions).then(handleResponse);
}
function createChangelogItem(changelogItem) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      title: changelogItem.title,
      description: changelogItem.description,
      notificationDate: changelogItem.notificationDate
    })
  };
  return fetch("".concat(config.apiUrl, "/change-log"), requestOptions).then(handleResponse);
}
function updateChangelogItem(changelogItem, changelogItemId) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify({
      title: changelogItem.title,
      description: changelogItem.description,
      notificationDate: changelogItem.notificationDate
    })
  };
  return fetch("".concat(config.apiUrl, "/change-log/").concat(changelogItemId), requestOptions).then(handleResponse);
}
function deleteChangelogItem(entryId) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/change-log/").concat(entryId), requestOptions).then(handleResponse);
}
function setLastChangelogSeenAt(date) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/change-log?date=").concat(date), requestOptions).then(handleResponse);
}
export default changelogItemService;