var settingType = {
  TEXT: 1,
  TEXTAREA: 2,
  CHECKBOX: 3,
  SEPARATOR: 4,
  CHOICES: 5,
  DROPDOWN: 6,
  COLOR: 7,
  ALIGN: 8,
  SLIDER: 9,
  LINK: 10,
  DATE: 11,
  LOCATION: 12,
  FILE: 13,
  LOGIC_COMPONENT: 14,
  LOGIC_COMPONENTS: 15,
  FIELD_SELECTOR: 16,
  CHECKBOXES: 17,
  POSITION: 18,
  EDIT_LINK: 98,
  FORM: 99
};
export default settingType;