import shortid from 'shortid';
import { appIntl } from '../../../IntlGlobalProvider';
import { elementType, dropdownType, layoutType, titleType } from '../../../constants/types';
import registrationColumn from '../../../constants/types/registration-column-constants';
export function getToolboxElements() {
  var intl = appIntl();
  var company = {
    id: 'toolboxCompany',
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Company',
      defaultMessage: 'Company'
    }),
    icon: 'ELEMENT.COMPANY',
    element: {
      isConstantId: true,
      id: 'companyGroup',
      type: elementType.GROUPED,
      options: {
        elements: [{
          isConstantId: true,
          id: 'company',
          type: elementType.INPUT,
          options: {
            label: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Company_label',
              defaultMessage: 'Company'
            }),
            placeholder: '',
            hint: '',
            type: 'text',
            minLength: 0,
            maxLength: 500,
            required: false,
            isHidden: false
          },
          mandatory: false
        }, {
          isConstantId: true,
          id: 'jobTitle',
          type: elementType.INPUT,
          options: {
            label: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.JobTitle_label',
              defaultMessage: 'Job title'
            }),
            placeholder: '',
            hint: '',
            type: 'text',
            minLength: 0,
            maxLength: 500,
            required: false,
            isHidden: false
          },
          mandatory: false
        }]
      }
    },
    mandatory: false
  };
  var guests = {
    id: 'toolboxGuests',
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Guests',
      defaultMessage: 'Guests'
    }),
    icon: 'GUEST',
    element: {
      isConstantId: true,
      id: 'guests',
      type: elementType.GUESTS,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Guests_inserted',
          defaultMessage: 'Guests'
        }),
        maxGuests: '',
        fields: [registrationColumn.nameGroup, registrationColumn.emailGroup, registrationColumn.companyGroup, registrationColumn.phone]
      }
    },
    mandatory: false
  };
  var title = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Title',
      defaultMessage: 'Title'
    }),
    icon: 'ELEMENT.TITLE',
    element: {
      id: shortid.generate(),
      type: elementType.TITLE,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Title_inserted',
          defaultMessage: 'Title'
        }),
        type: titleType.H1
      }
    },
    mandatory: false
  };
  var text = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Text',
      defaultMessage: 'Text'
    }),
    icon: 'ELEMENT.TEXT',
    element: {
      id: shortid.generate(),
      type: elementType.TEXT,
      options: {
        value: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Text_value',
          defaultMessage: 'Text'
        })
      }
    },
    mandatory: false
  };
  var pageBreak = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Break',
      defaultMessage: 'Break'
    }),
    icon: 'ELEMENT.BREAK',
    element: {
      id: shortid.generate(),
      type: elementType.BREAK,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Break_label',
          defaultMessage: 'Page break'
        })
      },
      mandatory: false
    }
  };
  var spacer = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Spacer',
      defaultMessage: 'Spacer'
    }),
    icon: 'ELEMENT.SPACER',
    element: {
      id: shortid.generate(),
      type: elementType.SPACER,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Spacer_label',
          defaultMessage: 'Spacer'
        }),
        spacing: 30
      },
      mandatory: false
    }
  };
  var input = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Input',
      defaultMessage: 'Input'
    }),
    icon: 'ELEMENT.INPUT',
    element: {
      id: shortid.generate(),
      type: elementType.INPUT,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Input_label',
          defaultMessage: 'Input label'
        }),
        placeholder: '',
        hint: '',
        type: 'text',
        minLength: 0,
        maxLength: 500,
        required: false,
        isHidden: false
      },
      mandatory: false
    }
  };
  var textarea = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Textarea',
      defaultMessage: 'Textarea'
    }),
    icon: 'ELEMENT.TEXTAREA',
    element: {
      id: shortid.generate(),
      type: elementType.TEXTAREA,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Textarea_label',
          defaultMessage: 'Textarea label'
        }),
        placeholder: '',
        hint: '',
        minLength: 0,
        maxLength: 2000,
        required: false,
        isHidden: false
      },
      mandatory: false
    }
  };
  var date = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Date',
      defaultMessage: 'Date'
    }),
    icon: 'ELEMENT.DATE',
    element: {
      id: shortid.generate(),
      type: elementType.INPUT,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Date_label',
          defaultMessage: 'Date label'
        }),
        placeholder: '',
        hint: '',
        type: 'date',
        minLength: 0,
        maxLength: 20,
        required: false,
        isHidden: false
      },
      mandatory: false
    }
  };
  var email = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Email',
      defaultMessage: 'Email'
    }),
    icon: 'ELEMENT.EMAIL',
    element: {
      id: shortid.generate(),
      type: elementType.INPUT,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Email_label',
          defaultMessage: 'Email label'
        }),
        placeholder: '',
        hint: '',
        type: 'email',
        minLength: 0,
        maxLength: 500,
        required: false,
        isHidden: false
      },
      mandatory: false
    }
  };
  var phone = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Phone',
      defaultMessage: 'Phone'
    }),
    icon: 'ELEMENT.PHONE',
    element: {
      id: shortid.generate(),
      type: elementType.INPUT,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Phone_label',
          defaultMessage: 'Phone number label'
        }),
        placeholder: '',
        hint: '',
        type: 'tel',
        minLength: 0,
        maxLength: 20,
        required: false,
        isHidden: false
      },
      mandatory: false
    }
  };
  var number = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Number',
      defaultMessage: 'Number'
    }),
    icon: 'ELEMENT.NUMBER',
    element: {
      id: shortid.generate(),
      type: elementType.INPUT,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Number_label',
          defaultMessage: 'Number label'
        }),
        placeholder: '',
        hint: '',
        type: 'number',
        minLength: 0,
        maxLength: 30,
        required: false,
        isHidden: false
      },
      mandatory: false
    }
  };
  var checkbox = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Checkbox',
      defaultMessage: 'Checkbox'
    }),
    icon: 'ELEMENT.CHECKBOX',
    element: {
      id: shortid.generate(),
      type: elementType.GROUPED,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Checkbox_label',
          defaultMessage: 'Checkbox label'
        }),
        hint: '',
        required: false,
        layout: layoutType.SINGLE_COLUMN,
        elements: [{
          id: shortid.generate(),
          type: elementType.CHECKBOX,
          options: {
            label: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Checkbox_option_1',
              defaultMessage: 'Checkbox'
            }),
            value: 'true',
            price: '',
            isHidden: false
          },
          mandatory: false
        }, {
          id: shortid.generate(),
          type: elementType.CHECKBOX,
          options: {
            label: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Checkbox_option_2',
              defaultMessage: 'Checkbox 2'
            }),
            value: 'true',
            price: '',
            isHidden: false
          },
          mandatory: false
        }]
      },
      mandatory: false
    }
  };
  var radioButton = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Radio_button',
      defaultMessage: 'Radio button'
    }),
    icon: 'ELEMENT.RADIOBUTTON',
    element: {
      id: shortid.generate(),
      type: elementType.GROUPED,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Radio_button_label',
          defaultMessage: 'Radio button label'
        }),
        hint: '',
        required: true,
        layout: layoutType.SINGLE_COLUMN,
        elements: [{
          id: shortid.generate(),
          type: elementType.RADIO,
          options: {
            label: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Radio_button_option_1',
              defaultMessage: 'Radio button'
            }),
            value: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Radio_button_option_1',
              defaultMessage: 'Radio button'
            }),
            // must be the same as label
            price: '',
            isHidden: false
          },
          mandatory: false
        }, {
          id: shortid.generate(),
          type: elementType.RADIO,
          options: {
            label: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Radio_button_option_2',
              defaultMessage: 'Radio button'
            }),
            value: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Radio_button_option_2',
              defaultMessage: 'Radio button'
            }),
            // must be the same as label
            price: '',
            isHidden: false
          },
          mandatory: false
        }]
      },
      mandatory: false
    }
  };
  var dropdown = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Dropdown',
      defaultMessage: 'Dropdown'
    }),
    icon: 'ELEMENT.DROPDOWN',
    element: {
      id: shortid.generate(),
      type: elementType.DROPDOWN,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Dropdown_label',
          defaultMessage: 'Dropdown label'
        }),
        placeholder: '',
        hint: '',
        type: dropdownType.MANUAL,
        elements: [{
          id: shortid.generate(),
          options: {
            label: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Dropdown_option_1',
              defaultMessage: 'Dropdown value'
            }),
            value: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Dropdown_option_1',
              defaultMessage: 'Dropdown value'
            }),
            price: '',
            isHidden: false
          }
        }, {
          id: shortid.generate(),
          options: {
            label: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Dropdown_option_2',
              defaultMessage: 'Dropdown value 2'
            }),
            value: intl.formatMessage({
              id: 'EventFormBuilder.Toolbox.Dropdown_option_2',
              defaultMessage: 'Dropdown value 2'
            }),
            price: '',
            isHidden: false
          }
        }],
        required: false,
        isHidden: false
      },
      mandatory: false
    }
  };
  var countries = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Countries',
      defaultMessage: 'Countries'
    }),
    icon: 'ELEMENT.COUNTRIES',
    element: {
      id: shortid.generate(),
      type: elementType.DROPDOWN,
      options: {
        label: intl.formatMessage({
          id: 'EventFormBuilder.Toolbox.Countries_label',
          defaultMessage: 'Countries label'
        }),
        placeholder: '',
        hint: '',
        type: dropdownType.COUNTRIES,
        required: false,
        isHidden: false
      },
      mandatory: false
    }
  };
  var formElements = [guests, title, text, pageBreak, spacer, input, textarea, date, company, email, phone, number, checkbox, radioButton, dropdown, countries];
  return formElements;
}
export default getToolboxElements;