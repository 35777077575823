function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { partnerConstants } from '../constants/actions';
function partners() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case partnerConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case partnerConstants.GETALL_SUCCESS:
      return {
        items: action.partners
      };
    case partnerConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
function partner() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case partnerConstants.GET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case partnerConstants.GET_SUCCESS:
      return action.partner;
    case partnerConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case partnerConstants.CREATE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case partnerConstants.CREATE_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, action.partner), {}, {
          success: true,
          loading: false
        });
      }
    case partnerConstants.CREATE_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error.message,
        errors: action.error.errors,
        loading: false,
        success: false
      });
    case partnerConstants.UPDATE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case partnerConstants.UPDATE_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, action.partner), {}, {
          success: true,
          loading: false
        });
      }
    case partnerConstants.UPDATE_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error.message,
        errors: action.error.errors,
        loading: false,
        success: false
      });
    case partnerConstants.UPDATE_USERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case partnerConstants.UPDATE_USERS_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, action.partner), {}, {
          success: true,
          loading: false
        });
      }
    case partnerConstants.UPDATE_USERS_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        success: false
      });
    case partnerConstants.DELETE_USER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case partnerConstants.DELETE_USER_REQUEST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          users: state.users.filter(function (x) {
            return x.email !== action.email;
          }),
          success: true,
          loading: false
        });
      }
    case partnerConstants.DELETE_USER_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        success: false
      });
    case partnerConstants.CLEAR:
      return {};
    case partnerConstants.CLEAR_ERRORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        errors: null,
        success: true
      });
    case partnerConstants.UPDATE_FEATURES_REQUEST:
      return state;
    case partnerConstants.UPDATE_FEATURES_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), action.workspace);
      }
    case partnerConstants.UPDATE_FEATURES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error.message,
        errors: action.error.errors
      });
    default:
      return state;
  }
}
export { partners, partner };