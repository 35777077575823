import { appIntl } from '../../../IntlGlobalProvider';
import { moduleType } from '../../../constants/types';
function validateItem(item) {
  var type = item.type,
    options = item.options;
  var intl = appIntl();
  switch (type) {
    case moduleType.INFO_PAGE:
    case moduleType.SPEAKER:
    case moduleType.AGENDA:
    case moduleType.SPONSOR:
      if (!options.label) {
        return {
          elementId: item.id,
          optionId: 'label',
          message: intl.formatMessage({
            id: 'LobbyPageBuilder.Validation.Label_required',
            defaultMessage: 'Label is required'
          })
        };
      }
      break;
    case moduleType.HEADER:
      if (!options.participantsLabel) {
        return {
          elementId: item.id,
          optionId: 'participantsLabel',
          message: intl.formatMessage({
            id: 'LobbyPageBuilder.Validation.Label_required',
            defaultMessage: 'Label is required'
          })
        };
      }
      if (!options.mainStageLabel) {
        return {
          elementId: item.id,
          optionId: 'mainStageLabel',
          message: intl.formatMessage({
            id: 'LobbyPageBuilder.Validation.Label_required',
            defaultMessage: 'Label is required'
          })
        };
      }
      break;
    default:
      break;
  }
  return null;
}
export default validateItem;