function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { userConstants } from '../constants/actions';
var storageUser = localStorage.getItem('user');
var user;
try {
  user = JSON.parse(storageUser);
} catch (e) {
  user = undefined;
}
var initialState = user ? {
  loggedIn: true,
  user: user
} : {};
export default function authentication() {
  var _action$user$lastChan;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.UPDATE_CURRENT_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: _objectSpread(_objectSpread({}, state.user), {}, {
          name: action.user.name,
          lastName: action.user.lastName,
          lastChangelogEntrySeenAt: (_action$user$lastChan = action.user.lastChangelogEntrySeenAt) !== null && _action$user$lastChan !== void 0 ? _action$user$lastChan : state.user.lastChangelogEntrySeenAt
        })
      });
    case userConstants.UPDATE_WORKSPACE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: _objectSpread(_objectSpread({}, state.user), {}, {
          selectedWorkspaceId: action.workspace.id,
          selectedWorkspaceName: action.workspace.name,
          selectedWorkspaceSlug: action.workspace.slug,
          selectedWorkspaceProduct: action.workspace.selectedProduct
        })
      });
    case userConstants.CHANGE_WORKSPACE_SUCCESS:
    case userConstants.SET_SELECTED_WORKSPACE:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: _objectSpread(_objectSpread({}, state.user), {}, {
          selectedWorkspaceId: action.user.selectedWorkspaceId,
          selectedWorkspaceName: action.user.selectedWorkspaceName,
          selectedWorkspaceSlug: action.user.selectedWorkspaceSlug,
          selectedWorkspaceProduct: action.user.selectedWorkspaceProduct
        })
      });
    case userConstants.LOGIN_FAILURE:
      return {
        error: {
          message: action.error.message,
          code: action.error.errorCode
        }
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}