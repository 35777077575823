import reservationService from '../services/reservation-service';
import { reservationConstants } from '../constants/actions';
var reservationActions = {
  getAvailableQuantities: getAvailableQuantities,
  reserve: reserve,
  getReservations: getReservations,
  clearReservations: clearReservations,
  releaseReservations: releaseReservations,
  getReservationsStatistics: getReservationsStatistics
};
function getAvailableQuantities(eventId) {
  return function (dispatch) {
    dispatch(request());
    reservationService.getAvailableQuantities(eventId).then(function (result) {
      return dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: reservationConstants.GET_QUANTITY
    };
  }
  function success(response) {
    return {
      type: reservationConstants.GET_QUANTITY_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: reservationConstants.GET_QUANTITY_FAILURE,
      response: response
    };
  }
}
function reserve(eventId, contextId, entityId, language, isRegistrationPage) {
  return function (dispatch) {
    dispatch(request());
    reservationService.reserve(eventId, contextId, entityId, language, isRegistrationPage).then(function (result) {
      return dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: reservationConstants.RESERVE
    };
  }
  function success(response) {
    return {
      type: reservationConstants.RESERVE_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: reservationConstants.RESERVE_FAILURE,
      response: response
    };
  }
}
function getReservations(eventId, registrationIdOrAccessKey) {
  return function (dispatch) {
    dispatch(request());
    reservationService.getReservations(eventId, registrationIdOrAccessKey).then(function (result) {
      return dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: reservationConstants.GET_RESERVATIONS
    };
  }
  function success(response) {
    return {
      type: reservationConstants.GET_RESERVATIONS_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: reservationConstants.GET_RESERVATIONS_FAILURE,
      response: response
    };
  }
}
function clearReservations() {
  return function (dispatch) {
    dispatch(request());
  };
  function request() {
    return {
      type: reservationConstants.CLEAR_RESERVATIONS
    };
  }
}
function releaseReservations(eventId, reservationIds) {
  return function (dispatch) {
    dispatch(request());
    reservationService.releaseReservations(eventId, reservationIds).then(function (result) {
      return dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: reservationConstants.RELEASE_RESERVATIONS
    };
  }
  function success(response) {
    return {
      type: reservationConstants.RELEASE_RESERVATIONS_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: reservationConstants.RELEASE_RESERVATIONS_FAILURE,
      response: response
    };
  }
}
function getReservationsStatistics(eventId) {
  return function (dispatch) {
    dispatch(request());
    reservationService.getReservationsStatistics(eventId).then(function (response) {
      return dispatch(success(response));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: reservationConstants.GET_RESERVATIONS_STATISTICS
    };
  }
  function success(response) {
    return {
      type: reservationConstants.GET_RESERVATIONS_STATISTICS_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: reservationConstants.GET_RESERVATIONS_STATISTICS_FAILURE,
      error: error
    };
  }
}
export default reservationActions;