import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { mapConstants } from '../constants/actions';
var mapActions = {
  initializeMaps: initializeMaps,
  disposeMaps: disposeMaps,
  getPlacePredictions: getPlacePredictions,
  findPlaceByNameAndId: findPlaceByNameAndId
};
function initializeMaps(map, maps) {
  return function (dispatch) {
    var placeService = new maps.places.PlacesService(map);
    var autocompleteService = new maps.places.AutocompleteService();
    var session = new maps.places.AutocompleteSessionToken();
    var payload = {
      map: map,
      maps: maps,
      placeService: placeService,
      autocompleteService: autocompleteService,
      session: session
    };
    dispatch({
      type: mapConstants.INITIALIZE_MAPS,
      payload: payload
    });
  };
}
function disposeMaps() {
  return function (dispatch) {
    return dispatch({
      type: mapConstants.DISPOSE_MAPS
    });
  };
}
function getPlacePredictions(query) {
  return function (dispatch, getState) {
    var state = getState();
    dispatch({
      type: mapConstants.GET_AUTOCOMPLETE_REQUEST
    });
    var _state$maps = state.maps,
      autocompleteService = _state$maps.autocompleteService,
      session = _state$maps.session;
    var request = {
      input: query,
      sessionToken: session,
      types: ['establishment']
    };
    autocompleteService.getPlacePredictions(request, function (results, status) {
      if (status !== 'OK' && status !== 'ZERO_RESULTS') {
        dispatch({
          type: mapConstants.GET_AUTOCOMPLETE_FAILURE
        });
        return;
      }
      var autocomplete = results === null || results === void 0 ? void 0 : results.map(mapAutocomplete);
      dispatch({
        type: mapConstants.GET_AUTOCOMPLETE_SUCCESS,
        autocomplete: autocomplete
      });
    });
  };
}
function findPlaceByNameAndId(id, query) {
  return function (dispatch, getState) {
    var state = getState();
    var placeService = state.maps.placeService;
    dispatch({
      type: mapConstants.GET_LOCATIONS_REQUEST
    });
    var request = {
      query: query,
      fields: ['name', 'geometry', 'place_id']
    };
    placeService.findPlaceFromQuery(request, function (results, status) {
      if (status !== 'OK' && status !== 'ZERO_RESULTS') {
        dispatch({
          type: mapConstants.GET_LOCATIONS_FAILURE
        });
        return;
      }
      var location = null;
      results.forEach(function (element) {
        if (element.place_id == id) {
          location = mapLocationResult(element);
        }
      });
      dispatch({
        type: mapConstants.GET_LOCATIONS_SUCCESS,
        location: location
      });
    });
  };
}
function mapLocationResult(location) {
  var _location$name;
  return {
    id: location.place_id,
    name: (_location$name = location.name) !== null && _location$name !== void 0 ? _location$name : location.description,
    lat: location.geometry.location.lat(),
    lng: location.geometry.location.lng()
  };
}
function mapAutocomplete(location) {
  var _location$name2;
  return {
    id: location.place_id,
    name: (_location$name2 = location.name) !== null && _location$name2 !== void 0 ? _location$name2 : location.structured_formatting.main_text
  };
}
export default mapActions;