var agendaConstants = {
  GET_AGENDA_BY_EVENT_ID_REQUEST: 'GET_AGENDA_BY_EVENT_ID_REQUEST',
  GET_AGENDA_BY_EVENT_ID_SUCCESS: 'GET_AGENDA_BY_EVENT_ID_SUCCESS',
  GET_AGENDA_BY_EVENT_ID_ERROR: 'GET_AGENDA_BY_EVENT_ID_ERROR',
  ADD_TRACK_REQUEST: 'ADD_TRACK_REQUEST',
  ADD_TRACK_SUCCESS: 'ADD_TRACK_SUCCESS',
  ADD_TRACK_ERROR: 'ADD_TRACK_ERROR',
  ADD_SESSION_REQUEST: 'ADD_SESSION_REQUEST',
  ADD_SESSION_SUCCESS: 'ADD_SESSION_SUCCESS',
  ADD_SESSION_ERROR: 'ADD_SESSION_ERROR',
  UPDATE_TRACK_REQUEST: 'UPDATE_TRACK_REQUEST',
  UPDATE_TRACK_SUCCESS: 'UPDATE_TRACK_SUCCESS',
  UPDATE_TRACK_ERROR: 'UPDATE_TRACK_ERROR',
  UPDATE_SESSION_REQUEST: 'UPDATE_SESSION_REQUEST',
  UPDATE_SESSION_SUCCESS: 'UPDATE_SESSION_SUCCESS',
  UPDATE_SESSION_ERROR: 'UPDATE_SESSION_ERROR',
  DELETE_TRACK_REQUEST: 'DELETE_TRACK_REQUEST',
  DELETE_TRACK_SUCCESS: 'DELETE_TRACK_SUCCESS',
  DELETE_TRACK_ERROR: 'DELETE_TRACK_ERROR',
  DELETE_SESSION_REQUEST: 'DELETE_SESSION_REQUEST',
  DELETE_SESSION_SUCCESS: 'DELETE_SESSION_SUCCESS',
  DELETE_SESSION_ERROR: 'DELETE_SESSION_ERROR',
  CLEAR_ERRORS: 'AGENDA_CLEAR_ERRORS',
  GET_SESSIONS_BY_EVENT_ID_REQUEST: 'GET_SESSIONS_BY_EVENT_ID_REQUEST',
  GET_SESSIONS_BY_EVENT_ID_SUCCESS: 'GET_SESSIONS_BY_EVENT_ID_SUCCESS',
  GET_SESSIONS_BY_EVENT_ID_ERROR: 'GET_SESSIONS_BY_EVENT_ID_ERROR'
};
export default agendaConstants;