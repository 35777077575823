import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { handleResponse, requestHeader } from '../helpers/request-helpers';
var categoriesService = {
  getCategories: getCategories,
  deleteCategory: deleteCategory,
  updateCategories: updateCategories
};
function getCategories(eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/categories"), requestOptions).then(handleResponse);
}
function updateCategories(eventId, categories) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify(categories)
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/categories"), requestOptions).then(handleResponse);
}
function deleteCategory(eventId, categoryId) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/categories/").concat(categoryId), requestOptions).then(handleResponse);
}
export default categoriesService;