import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { appIntl } from '../IntlGlobalProvider';
import { registrationType, getStatusOptions } from '../constants/types/registration-type-constants';
function getStatusOptionColor(status) {
  var statusOptions = getStatusOptions();
  return statusOptions.find(function (item) {
    return item.value === status;
  }).color;
}
function getStatusOptionLabel(status) {
  var statusOptions = getStatusOptions();
  return statusOptions.find(function (item) {
    return item.value === status;
  }).label;
}
function getParticipantsChartData(dataObj) {
  return [{
    label: getStatusOptionLabel(registrationType.REGISTERED),
    value: dataObj.attendingParticipants,
    order: '0',
    color: getStatusOptionColor(registrationType.REGISTERED),
    type: registrationType.REGISTERED
  }, {
    label: getStatusOptionLabel(registrationType.INVITE_SENT),
    value: dataObj.awaitingReplyParticipants,
    order: '1',
    color: getStatusOptionColor(registrationType.INVITE_SENT),
    type: registrationType.INVITE_SENT
  }, {
    label: getStatusOptionLabel(registrationType.INVITE_DECLINED),
    value: dataObj.notAttendingParticipants,
    order: '2',
    color: getStatusOptionColor(registrationType.INVITE_DECLINED),
    type: registrationType.INVITE_DECLINED
  }, {
    label: getStatusOptionLabel(registrationType.INVITE_PENDING),
    value: dataObj.invitationsNotSentParticipants,
    order: '3',
    color: getStatusOptionColor(registrationType.INVITE_PENDING),
    type: registrationType.INVITE_PENDING
  }];
}
function getLoginData(dataObj) {
  var intl = appIntl();
  return [{
    label: intl.formatMessage({
      id: 'logged_in',
      defaultMessage: 'Logged In'
    }),
    value: dataObj.loggedInParticipants,
    order: '0',
    color: 'blue',
    type: registrationType.HAS_LOGGED_IN
  }, {
    label: intl.formatMessage({
      id: 'not_logged_in',
      defaultMessage: 'Not logged in'
    }),
    value: dataObj.attendingParticipants - dataObj.loggedInParticipants,
    order: '1',
    type: registrationType.NOT_LOGGED_IN
  }];
}
export { getParticipantsChartData, getLoginData };