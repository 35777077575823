import documentService from '../services/document-service';
import { documentConstants } from '../constants/actions';
var documentActions = {
  getSignupTermsDocument: getSignupTermsDocument
};
function getSignupTermsDocument() {
  return function (dispatch) {
    dispatch(request());
    documentService.getSignupTermsDocument().then(function (document) {
      return dispatch(success(document));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: documentConstants.GETSIGNUPTERMS_REQUEST
    };
  }
  function success(document) {
    return {
      type: documentConstants.GETSIGNUPTERMS_SUCCESS,
      document: document
    };
  }
  function failure(error) {
    return {
      type: documentConstants.GETSIGNUPTERMS_FAILURE,
      error: error
    };
  }
}
export default documentActions;