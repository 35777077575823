var profilePages = {
  USER: 'user',
  COMPANY_SETTINGS: 'company-settings',
  WORKSPACE: 'workspace'
};
var profileSubPages = {
  WORKSPACE_SETTINGS: 'details',
  WORKSPACE_BILLING: 'billing',
  PLANS: 'plans',
  PAYMENT_INFORMATION: 'payment-information',
  PAYMENT_HISTORY: 'payment-history'
};
export { profilePages, profileSubPages };