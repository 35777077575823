var videosConstants = {
  GET_VIDEOS: 'GET_VIDEOS',
  GET_VIDEOS_SUCCESS: 'GET_VIDEOS_SUCCESS',
  GET_VIDEOS_ERROR: 'GET_VIDEOS_ERROR',
  GET_SINGLEVIDEO: 'GET_SINGLEVIDEO',
  GET_SINGLEVIDEOS_SUCCESS: 'GET_SINGLEVIDEO_SUCCESS',
  GET_SINGLEVIDEO_ERROR: 'GET_SINGLEVIDEO_ERROR',
  CREATE_VIDEO: 'CREATE_VIDEO_REQUEST',
  CREATE_VIDEO_SUCCESS: 'CREATE_VIDEO_SUCCESS',
  CREATE_VIDEO_ERROR: 'CREATE_VIDEO_ERROR',
  UPDATE_VIDEO: 'UPDATE_VIDEO_REQUEST',
  UPDATE_VIDEO_SUCCESS: 'UPDATE_VIDEO_SUCCESS',
  UPDATE_VIDEO_ERROR: 'UPDATE_VIDEO_ERROR',
  DELETE_VIDEO: 'DELETE_VIDEO',
  DELETE_VIDEO_SUCCESS: 'DELETE_VIDEO_SUCCESS',
  DELETE_VIDEO_FAILURE: 'DELETE_VIDEO_FAILURE',
  RESET_VIDEOS_SUCCESS: 'RESET_VIDEOS_SUCCESS',
  RESET_VIDEOS_FAILURE: 'RESET_VIDEOS_FAILURE'
};
export default videosConstants;