import eventService from '../services/event-service';
import { eventConstants } from '../constants/actions';
import { historyPush } from '../helpers/history';
import { appIntl } from '../IntlGlobalProvider';
import messageActions from './message-actions';
var eventFormActions = {
  saveForm: saveForm
};
function saveForm(eventId, eventForm, formMetadata, isDirty, navigateTo) {
  var showSuccessMessage = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    eventService.saveForm(eventId, eventForm, formMetadata, isDirty).then(function (event) {
      dispatch(success(event));
      historyPush(navigateTo);
      if (showSuccessMessage) {
        dispatch(messageActions.success(intl.formatMessage({
          id: 'EventPage.Changes_successful',
          defaultMessage: 'Changes were successfully saved'
        })));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: eventConstants.SAVEFORM_REQUEST
    };
  }
  function success(event) {
    return {
      type: eventConstants.SAVEFORM_REQUEST_SUCCESS,
      event: event
    };
  }
  function failure(error) {
    return {
      type: eventConstants.SAVEFORM_REQUEST_FAILURE,
      error: error
    };
  }
}
export default eventFormActions;