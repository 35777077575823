var addonTypes = {
  SMS: 0,
  EMAILS: 1,
  EVENTS: 2,
  ADMINS: 3
};
var addonPaymentType = {
  CURRENT: 0,
  NEW: 1
};
export default addonTypes;
export { addonTypes, addonPaymentType };