import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
import { DateTime } from 'luxon';
function fromDateToString(date, timezone) {
  if (!date) {
    return null;
  }
  var hours = date.getHours();
  if (hours < 10) {
    hours = "0".concat(hours);
  }
  var minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = "0".concat(minutes);
  }
  var month = date.getMonth() + 1;
  if (month < 10) {
    month = "0".concat(month);
  }
  var day = date.getDate();
  if (day < 10) {
    day = "0".concat(day);
  }
  var datestring = "".concat(date.getFullYear(), "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":00");
  if (Number.isNaN(new Date(datestring).getTime())) {
    return null;
  }
  var zonedDateString = DateTime.fromISO(datestring, {
    zone: timezone
  }).toUTC().toISO({
    suppressMilliseconds: true
  });
  return zonedDateString;
}
function fromStringToDate(dateString, timezone) {
  if (!dateString) {
    return null;
  }
  var dateStringFormatted = dateString;
  if (dateString.length === 10) {
    dateStringFormatted = DateTime.fromFormat(dateString, 'dd.MM.yyyy', {
      zone: timezone
    }).toISO();
  }
  return changeTimezone(DateTime.fromISO(dateStringFormatted).toJSDate(), timezone);
}
function changeTimezone(date, timezone) {
  var forDifferential = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: timezone
  }));
  var diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() + (forDifferential ? 1 : -1) * diff);
}
function changeTimezoneUp(date, timezone) {
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: timezone
  }));
  var diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() + diff);
}
function getTimezoneHint(timezone) {
  var hint = "Current time: ".concat(DateTime.local().setZone(timezone !== null && timezone !== void 0 ? timezone : 'utc').toFormat('dd.MM.yyyy HH:mm:ss'));
  return hint;
}
export default {
  fromDateToString: fromDateToString,
  fromStringToDate: fromStringToDate,
  getTimezoneHint: getTimezoneHint,
  changeTimezone: changeTimezone,
  changeTimezoneUp: changeTimezoneUp
};
export { fromDateToString, fromStringToDate, getTimezoneHint, changeTimezone, changeTimezoneUp };