function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { productConstants } from '../constants/actions';
import { productType } from '../constants/types/product-constants';
export default function product() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case productConstants.GET_PRODUCT:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        error: undefined
      });
    case productConstants.GET_PRODUCT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: action.result.filter(function (item) {
          return item.type === productType.PLAN;
        }),
        addons: action.result.filter(function (item) {
          return item.type === productType.ADDON;
        }),
        charges: action.result.filter(function (item) {
          return item.type === productType.CHARGE;
        }),
        success: true,
        loading: false,
        error: undefined
      });
    case productConstants.GET_PRODUCT_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case productConstants.GET_SELECTED_PRODUCT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedProduct: undefined
      });
    case productConstants.GET_SELECTED_PRODUCT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedProduct: action.result
      });
    case productConstants.GET_SELECTED_PRODUCT_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });
    case productConstants.CREATE_INTENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        intent: action.result.intent
      });
    case productConstants.CREATE_INTENT_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });
    case productConstants.UPDATE_PAYMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentUpdated: false
      });
    case productConstants.UPDATE_PAYMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentUpdated: true
      });
    case productConstants.UPDATE_PAYMENT_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });
    case productConstants.CANCEL_SUBSCRIPTION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        subscriptionCanceled: true
      });
    case productConstants.CANCEL_SUBSCRIPTION_ERROR:
      return _objectSpread({}, state);
    default:
      return state;
  }
}