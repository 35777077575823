var registrationGroupConstants = {
  GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE: 'GET_GROUPS_FAILURE',
  CREATE_GROUP_REQUEST: 'CREATE_GROUP_REQUEST',
  CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FAILURE: 'CREATE_GROUP_FAILURE',
  UPDATE_GROUP_REQUEST: 'UPDATE_GROUP_REQUEST',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_FAILURE: 'UPDATE_GROUP_FAILURE',
  DELETE_GROUP_REQUEST: 'DELETE_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_FAILURE: 'DELETE_GROUP_FAILURE',
  DELETE_GROUP_USERS_REQUEST: 'DELETE_GROUP_USERS_REQUEST',
  DELETE_GROUP_USERS_SUCCESS: 'DELETE_GROUP_USERS_SUCCESS',
  DELETE_GROUP_USERS_FAILURE: 'DELETE_GROUP_USERS_FAILURE',
  CLEAR_ERRORS: 'REGISTRATION_GROUP_CLEAR_ERRORS'
};
export default registrationGroupConstants;