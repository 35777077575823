import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponse } from '../helpers/request-helpers';
var actions = {
  getBillingInfo: getBillingInfo,
  getBillingHistory: getBillingHistory,
  getInvoicePdf: getInvoicePdf
};
function getBillingInfo(workspaceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/billing/").concat(workspaceId), requestOptions).then(handleResponse);
}
function getBillingHistory(workspaceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/billing/").concat(workspaceId, "/history"), requestOptions).then(handleResponse);
}
function getInvoicePdf(workspaceId, invoiceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/billing/").concat(workspaceId, "/pdf/").concat(invoiceId), requestOptions).then(handleResponse);
}
export default actions;