var integrationConstants = {
  GET_ALL_CLIENTS_REQUEST: 'INTEGRATION_GET_ALL_CLIENTS_REQUEST',
  GET_ALL_CLIENTS_SUCCESS: 'INTEGRATION_GET_ALL_CLIENTS_SUCCESS',
  GET_ALL_CLIENTS_FAILURE: 'INTEGRATION_GET_ALL_CLIENTS_FAILURE',
  GET_ALL_WORKSPACES_REQUEST: 'INTEGRATION_GET_ALL_WORKSPACES_REQUEST',
  GET_ALL_WORKSPACES_SUCCESS: 'INTEGRATION_GET_ALL_WORKSPACES_SUCCESS',
  GET_ALL_WORKSPACES_FAILURE: 'INTEGRATION_GET_ALL_WORKSPACES_FAILURE',
  GET_WORKSPACE_DETAILS_REQUEST: 'INTEGRATION_GET_WORKSPACE_DETAILS_REQUEST',
  GET_WORKSPACE_DETAILS_SUCCESS: 'INTEGRATION_GET_WORKSPACE_DETAILS_SUCCESS',
  GET_WORKSPACE_DETAILS_FAILURE: 'INTEGRATION_GET_WORKSPACE_DETAILS_FAILURE',
  GET_EVENT_DETAILS_REQUEST: 'INTEGRATION_GET_EVENT_DETAILS_REQUEST',
  GET_EVENT_DETAILS_SUCCESS: 'INTEGRATION_GET_EVENT_DETAILS_SUCCESS',
  GET_EVENT_DETAILS_FAILURE: 'INTEGRATION_GET_EVENT_DETAILS_FAILURE',
  GET_ALL_WORKSPACE_LINKS_REQUEST: 'INTEGRATION_GET_ALL_WORKSPACE_LINKS_REQUEST',
  GET_ALL_WORKSPACE_LINKS_SUCCESS: 'INTEGRATION_GET_ALL_WORKSPACE_LINKS_SUCCESS',
  GET_ALL_WORKSPACE_LINKS_FAILURE: 'INTEGRATION_GET_ALL_WORKSPACE_LINKS_FAILURE',
  LINK_REQUEST: 'INTEGRATION_LINK_REQUEST',
  LINK_SUCCESS: 'INTEGRATION_LINK_SUCCESS',
  LINK_FAILURE: 'INTEGRATION_LINK_FAILURE',
  GET_EVENT_LINKS_REQUEST: 'INTEGRATION_GET_EVENT_LINKS_REQUEST',
  GET_EVENT_LINKS_SUCCESS: 'INTEGRATION_GET_EVENT_LINKS_SUCCESS',
  GET_EVENT_LINKS_FAILURE: 'INTEGRATION_GET_EVENT_LINKS_FAILURE',
  LINK_EVENT_REQUEST: 'INTEGRATION_LINK_EVENT_REQUEST',
  LINK_EVENT_SUCCESS: 'INTEGRATION_LINK_EVENT_SUCCESS',
  LINK_EVENT_FAILURE: 'INTEGRATION_LINK_EVENT_FAILURE',
  GET_EXTERNAL_EVENTS_REQUEST: 'INTEGRATION_GET_EXTERNAL_EVENTS_REQUEST',
  GET_EXTERNAL_EVENTS_SUCCESS: 'INTEGRATION_GET_EXTERNAL_EVENTS_SUCCESS',
  GET_EXTERNAL_EVENTS_FAILURE: 'INTEGRATION_GET_EXTERNAL_EVENTS_FAILURE',
  GET_REGISTRATION_LINKS_REQUEST: 'INTEGRATION_GET_REGISTRATION_LINKS_REQUEST',
  GET_REGISTRATION_LINKS_SUCCESS: 'INTEGRATION_GET_REGISTRATION_LINKS_SUCCESS',
  GET_REGISTRATION_LINKS_FAILURE: 'INTEGRATION_GET_REGISTRATION_LINKS_FAILURE',
  LINK_REGISTRATION_REQUEST: 'INTEGRATION_LINK_REGISTRATION_REQUEST',
  LINK_REGISTRATION_SUCCESS: 'INTEGRATION_LINK_REGISTRATION_SUCCESS',
  LINK_REGISTRATION_FAILURE: 'INTEGRATION_LINK_REGISTRATION_FAILURE',
  LINK_REGISTRATIONS_REQUEST: 'INTEGRATION_LINK_REGISTRATIONS_REQUEST',
  LINK_REGISTRATIONS_SUCCESS: 'INTEGRATION_LINK_REGISTRATIONS_SUCCESS',
  LINK_REGISTRATIONS_FAILURE: 'INTEGRATION_LINK_REGISTRATIONS_FAILURE'
};
export default integrationConstants;