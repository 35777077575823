function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { promotedMessagesConstants } from '../constants/actions';
export default function promotedMessages() {
  var _state$messages, _state$messages2, _state$messages3, _state$messages4, _state$messages5;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case promotedMessagesConstants.GET_PROMOTED_MESSAGES:
      return _objectSpread(_objectSpread({}, state), {}, {
        messages: undefined,
        loading: true,
        error: undefined
      });
    case promotedMessagesConstants.GET_PROMOTED_MESSAGES_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.promotedMessages), {}, {
        loading: false,
        error: undefined
      });
    case promotedMessagesConstants.GET_PROMOTED_MESSAGES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        messages: undefined,
        loading: false,
        error: action.error
      });
    case promotedMessagesConstants.CREATE_PROMOTED_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: undefined
      });
    case promotedMessagesConstants.CREATE_PROMOTED_MESSAGE_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.promotedMessages), {}, {
        loading: false,
        error: undefined
      });
    case promotedMessagesConstants.CREATE_PROMOTED_MESSAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });
    case promotedMessagesConstants.UPDATE_PROMOTED_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: undefined
      });
    case promotedMessagesConstants.UPDATE_PROMOTED_MESSAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        messages: (_state$messages = state.messages) === null || _state$messages === void 0 ? void 0 : _state$messages.map(function (message) {
          var _action$promotedMessa;
          return (message === null || message === void 0 ? void 0 : message.id) === ((_action$promotedMessa = action.promotedMessage) === null || _action$promotedMessa === void 0 ? void 0 : _action$promotedMessa.id) ? _objectSpread({}, action.promotedMessage) : message;
        }),
        error: undefined
      });
    case promotedMessagesConstants.UPDATE_PROMOTED_MESSAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case promotedMessagesConstants.UPDATE_PROMOTED_PARTIAL_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        messages: (_state$messages2 = state.messages) === null || _state$messages2 === void 0 ? void 0 : _state$messages2.map(function (message) {
          var _action$message;
          return (message === null || message === void 0 ? void 0 : message.id) === ((_action$message = action.message) === null || _action$message === void 0 ? void 0 : _action$message.id) ? _objectSpread(_objectSpread({}, message), {}, {
            updating: Object.keys(action.message.request)
          }) : message;
        }),
        error: undefined
      });
    case promotedMessagesConstants.UPDATE_PROMOTED_PARTIAL_MESSAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        messages: (_state$messages3 = state.messages) === null || _state$messages3 === void 0 ? void 0 : _state$messages3.map(function (message) {
          var _action$message2;
          return (message === null || message === void 0 ? void 0 : message.id) === ((_action$message2 = action.message) === null || _action$message2 === void 0 ? void 0 : _action$message2.id) ? _objectSpread(_objectSpread({}, action.message), {}, {
            updating: undefined
          }) : message;
        }),
        error: undefined
      });
    case promotedMessagesConstants.UPDATE_PROMOTED_PARTIAL_MESSAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        messages: (_state$messages4 = state.messages) === null || _state$messages4 === void 0 ? void 0 : _state$messages4.map(function (message) {
          var _action$message3;
          return (message === null || message === void 0 ? void 0 : message.id) === ((_action$message3 = action.message) === null || _action$message3 === void 0 ? void 0 : _action$message3.messageId) ? _objectSpread(_objectSpread({}, message), {}, {
            updating: undefined
          }) : message;
        }),
        error: action.message.error
      });
    case promotedMessagesConstants.DELETE_PROMOTED_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: undefined
      });
    case promotedMessagesConstants.DELETE_PROMOTED_MESSAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        messages: (_state$messages5 = state.messages) === null || _state$messages5 === void 0 ? void 0 : _state$messages5.filter(function (x) {
          return x.id !== action.message.messageId;
        }),
        loading: false,
        error: undefined
      });
    case promotedMessagesConstants.DELETE_PROMOTED_MESSAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    default:
      return state;
  }
}