import categoriesConstants from '../constants/actions/categories-constants';
import { appIntl } from '../IntlGlobalProvider';
import categoriesService from '../services/categories-service';
import messageActions from './message-actions';
var categoriesActions = {
  getCategories: getCategories,
  deleteCategory: deleteCategory,
  updateCategories: updateCategories
};
function getCategories(eventId) {
  return function (dispatch) {
    dispatch(request());
    categoriesService.getCategories(eventId).then(function (categories) {
      return dispatch(success(categories));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: categoriesConstants.GET_CATEGORIES
    };
  }
  function success(items) {
    return {
      type: categoriesConstants.GET_CATEGORIES_SUCCESS,
      items: items
    };
  }
  function failure(error) {
    return {
      type: categoriesConstants.GET_CATEGORIES_FAILURE,
      error: error
    };
  }
}
function updateCategories(eventId, categories, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    categoriesService.updateCategories(eventId, categories).then(function (item) {
      dispatch(success(item));
      if (callback) {
        callback();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Sponsors.changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: categoriesConstants.UPDATE_CATEGORIES
    };
  }
  function success(item) {
    return {
      type: categoriesConstants.UPDATE_CATEGORIES_SUCCESS,
      item: item
    };
  }
  function failure(error) {
    return {
      type: categoriesConstants.UPDATE_CATEGORIES_FAILURE,
      error: error
    };
  }
}
function deleteCategory(eventId, categoryId) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(categoryId));
    categoriesService.deleteCategory(eventId, categoryId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'category.delete_successful',
        defaultMessage: 'category was successfully deleted'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: categoriesConstants.DELETE_CATEGORIES
    };
  }
  function success(isSuccess) {
    var result = {
      isSuccess: isSuccess,
      categoryId: categoryId
    };
    return {
      type: categoriesConstants.DELETE_CATEGORIES_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: categoriesConstants.DELETE_CATEGORIES_FAILURE,
      error: error
    };
  }
}
export default categoriesActions;