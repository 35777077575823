import ReactGA from 'react-ga4';
function createAnalyticsEvent(action, category, label) {
  ReactGA.event({
    category: category,
    label: label,
    action: action
  });
}
function createAnalyticsPageView(path, title) {
  ReactGA.send({
    hitType: 'pageview',
    page: path,
    title: title
  });
}
export { createAnalyticsEvent, createAnalyticsPageView };