var productConstants = {
  GET_PRODUCT: 'GET_PRODUCT',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_ERROR: 'GET_PRODUCT_ERROR',
  GET_SELECTED_PRODUCT: 'GET_SELECTED_PRODUCT',
  GET_SELECTED_PRODUCT_SUCCESS: 'GET_SELECTED_PRODUCT_SUCCESS',
  GET_SELECTED_PRODUCT_ERROR: 'GET_SELECTED_PRODUCT_ERROR',
  GET_INTENT: 'GET_INTENT',
  GET_INTENT_SUCCESS: 'GET_INTENT_SUCCESS',
  GET_INTENT_ERROR: 'GET_INTENT_ERROR',
  CREATE_INTENT: 'CREATE_INTENT',
  CREATE_INTENT_SUCCESS: 'CREATE_INTENT_SUCCESS',
  CREATE_INTENT_ERROR: 'CREATE_INTENT_ERROR',
  UPDATE_PAYMENT: 'UPDATE_PAYMENT',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_ERROR: 'UPDATE_PAYMENT_ERROR',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR: 'CANCEL_SUBSCRIPTION_ERROR'
};
export default productConstants;