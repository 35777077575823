var eventPageType = {
  CREATE: 'create',
  GENERAL: 'general',
  FORM: 'form',
  LANDING_PAGE: 'landing-page',
  LOBBY_PAGE: 'lobby-page',
  REGISTRATIONS: 'registrations',
  PARTICIPANTS: 'participants',
  VOUCHERS: 'vouchers',
  COMMUNICATION: 'communication',
  PLATFORM_SETTINGS: 'platform-settings',
  BRANDING: 'branding',
  TIMELINE: 'timeline',
  PROMOTED_MESSAGES: 'promoted-messages',
  INFO_PAGES: 'info-pages',
  DOCUMENTS: 'documents',
  POLLS: 'polls',
  VIDEOS: 'videos',
  DASHBOARD: 'dashboard',
  EVENT_STATISTICS: 'event-statistics',
  SPEAKERS: 'speakers',
  AGENDA: 'agenda',
  SPONSORS: 'sponsors',
  POLL_MANAGEMENT: 'poll-management',
  SCANNER: 'scanner',
  QUIZ: 'quiz',
  QUIZ_MANAGEMENT: 'quiz-management',
  QUIZ_OPEN_TEXT: 'quiz-open-text',
  TREASURE_HUNT: 'treasure-hunt',
  TREASURE_HUNT_MANAGEMENT: 'treasure-hunt-management',
  TREASURE_HUNT_OPEN_TEXT: 'treasure-hunt-open-text',
  LOGIN_PRIVATE_RESULTS: 'login-poll-results',
  PRIVATE_QUIZ_RESULTS: 'private-quiz-results',
  LOGIN_PARTICIPANTS_PRIVATE_RESULTS: 'login-participants-results',
  QR_TICKET_VIEW: 'qrticket',
  BADGES: 'badges',
  PRINTER_SETTINGS: 'printer-settings',
  BADGE_PRINTING: 'badge-printing',
  POLLS_STATISTICS: 'polls-statistics',
  AGENDA_STATISTICS: 'agenda-statistics',
  PARTICIPANTS_STATISTICS: 'participants-statistics'
};
var eventPageSubtype = {
  SMS: 'sms',
  EMAILS: 'emails',
  SETTINGS: 'settings',
  TEMPLATES: 'templates',
  NEW: 'new'
};
export { eventPageType, eventPageSubtype };