import partnerService from '../services/partner-service';
import { partnerConstants, userConstants } from '../constants/actions';
import messageActions from './message-actions';
import { historyPush } from '../helpers/history';
import workspaceService from '../services/workspace-service';
import { workspaceFeatureState } from '../constants/types';
var partnerActions = {
  getAll: getAll,
  getPartner: getPartner,
  createPartner: createPartner,
  updatePartner: updatePartner,
  updatePartnerUsers: updatePartnerUsers,
  deletePartnerUser: deletePartnerUser,
  clearPartner: clearPartner,
  clearErrors: clearErrors,
  updateAvailableFeatures: updateAvailableFeatures
};
function getAll() {
  return function (dispatch) {
    dispatch(request());
    partnerService.getAll().then(function (partners) {
      return dispatch(success(partners));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: partnerConstants.GETALL_REQUEST
    };
  }
  function success(partners) {
    return {
      type: partnerConstants.GETALL_SUCCESS,
      partners: partners
    };
  }
  function failure(error) {
    return {
      type: partnerConstants.GETALL_FAILURE,
      error: error
    };
  }
}
function getPartner(partnerId) {
  return function (dispatch) {
    dispatch(request());
    partnerService.getPartner(partnerId).then(function (partner) {
      return dispatch(success(partner));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: partnerConstants.GET_REQUEST
    };
  }
  function success(partner) {
    return {
      type: partnerConstants.GET_SUCCESS,
      partner: partner
    };
  }
  function failure(error) {
    return {
      type: partnerConstants.GET_FAILURE,
      error: error
    };
  }
}
function createPartner(payload, redirectUrl) {
  return function (dispatch) {
    dispatch(requestCreatePartner());
    partnerService.createPartner(payload).then(function (partner) {
      dispatch(success(partner));
      historyPush(redirectUrl);
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function requestCreatePartner() {
    return {
      type: partnerConstants.CREATE_REQUEST
    };
  }
  function success(partner) {
    return {
      type: partnerConstants.CREATE_REQUEST_SUCCESS,
      partner: partner
    };
  }
  function failure(error) {
    return {
      type: partnerConstants.CREATE_REQUEST_FAILURE,
      error: error
    };
  }
}
function updatePartner(payload, partnerId, redirectUrl, callback, message) {
  return function (dispatch) {
    dispatch(requestUpdatePartner(payload));
    partnerService.updatePartner(payload, partnerId).then(function (partner) {
      dispatch(success(partner));
      dispatch(userSelectedWorkspace(partner));
      if (redirectUrl) {
        historyPush(redirectUrl);
      }
      if (callback) {
        callback();
      }
      if (message) {
        dispatch(messageActions.success(message));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function requestUpdatePartner(partner) {
    return {
      type: partnerConstants.UPDATE_REQUEST,
      partner: partner
    };
  }
  function success(partner) {
    return {
      type: partnerConstants.UPDATE_REQUEST_SUCCESS,
      partner: partner
    };
  }
  function failure(error) {
    return {
      type: partnerConstants.UPDATE_REQUEST_FAILURE,
      error: error
    };
  }
  function userSelectedWorkspace(workspace) {
    return {
      type: userConstants.UPDATE_WORKSPACE_SUCCESS,
      workspace: workspace
    };
  }
}
function updatePartnerUsers(payload, partnerId, message) {
  return function (dispatch) {
    dispatch(requestUpdatePartnerUsers(payload));
    partnerService.updatePartnerUsers(payload, partnerId).then(function (partner) {
      dispatch(success(partner));
      if (message) {
        dispatch(messageActions.success(message));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function requestUpdatePartnerUsers(users) {
    return {
      type: partnerConstants.UPDATE_USERS_REQUEST,
      users: users
    };
  }
  function success(partner) {
    return {
      type: partnerConstants.UPDATE_USERS_REQUEST_SUCCESS,
      partner: partner
    };
  }
  function failure(error) {
    return {
      type: partnerConstants.UPDATE_USERS_REQUEST_FAILURE,
      error: error
    };
  }
}
function deletePartnerUser(partnerId, email, message) {
  return function (dispatch) {
    dispatch(request({
      partnerId: partnerId,
      email: email
    }));
    partnerService.deletePartnerUser(partnerId, email).then(function () {
      dispatch(success());
      if (message) {
        dispatch(messageActions.success(message));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request(payload) {
    return {
      type: partnerConstants.DELETE_USER_REQUEST,
      payload: payload
    };
  }
  function success() {
    return {
      type: partnerConstants.DELETE_USER_REQUEST_SUCCESS,
      email: email
    };
  }
  function failure(error) {
    return {
      type: partnerConstants.DELETE_USER_REQUEST_FAILURE,
      error: error
    };
  }
}
function updateAvailableFeatures(payload, workspaceId, callback) {
  return function (dispatch) {
    dispatch(request(payload));
    workspaceService.updateWorkspaceFeatures(payload, workspaceId, workspaceFeatureState.AVAILABLE).then(function (workspace) {
      dispatch(success(workspace));
      if (callback) {
        callback(workspace);
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: partnerConstants.UPDATE_FEATURES_REQUEST
    };
  }
  function success(workspace) {
    return {
      type: partnerConstants.UPDATE_FEATURES_SUCCESS,
      workspace: workspace
    };
  }
  function failure(error) {
    return {
      type: partnerConstants.UPDATE_FEATURES_FAILURE,
      error: error
    };
  }
}
function clearPartner() {
  return function (dispatch) {
    dispatch({
      type: partnerConstants.CLEAR
    });
  };
}
function clearErrors() {
  return function (dispatch) {
    dispatch({
      type: partnerConstants.CLEAR_ERRORS
    });
  };
}
export default partnerActions;