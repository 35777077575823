import emailLogService from '../services/email-log-service';
import { emailLogConstants } from '../constants/actions';
var emailActions = {
  getEmailLog: getEmailLog,
  cancelEmail: cancelEmail,
  exportEmailLog: exportEmailLog,
  getEmailLogById: getEmailLogById
};
function getEmailLog(eventId, params) {
  return function (dispatch) {
    dispatch(request());
    emailLogService.getEmailLog(eventId, params).then(function (items) {
      return dispatch(success(items));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: emailLogConstants.GET_EMAIL_LOG_REQUEST
    };
  }
  function success(items) {
    return {
      type: emailLogConstants.GET_EMAIL_LOG_SUCCESS,
      items: items
    };
  }
  function failure(error) {
    return {
      type: emailLogConstants.GET_EMAIL_LOG_FAILURE,
      error: error
    };
  }
}
function cancelEmail(eventId, emailId) {
  var isBatch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return function (dispatch) {
    dispatch(request());
    emailLogService.cancelEmailLog(eventId, emailId, isBatch).then(function (result) {
      return dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: emailLogConstants.CANCEL_EMAIL_LOG_REQUEST
    };
  }
  function success(response) {
    return {
      type: emailLogConstants.CANCEL_EMAIL_LOG_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: emailLogConstants.CANCEL_EMAIL_LOG_FAILURE,
      response: response
    };
  }
}
function exportEmailLog(eventId, selection, search) {
  return function (dispatch) {
    dispatch(request());
    emailLogService.exportEmailLog(eventId, selection, search).then(function (emailLog) {
      return dispatch(success(emailLog));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: emailLogConstants.EXPORT_EMAIL_LOG_REQUEST
    };
  }
  function success(emailLog) {
    return {
      type: emailLogConstants.EXPORT_EMAIL_LOG_SUCCESS,
      emailLog: emailLog
    };
  }
  function failure(error) {
    return {
      type: emailLogConstants.EXPORT_EMAIL_LOG_FAILURE,
      error: error
    };
  }
}
function getEmailLogById(eventId, emailId, archive) {
  return emailLogService.getEmailLogById(eventId, emailId, archive);
}
export default emailActions;