var currencyList = [{
  label: 'NOK',
  value: 'NOK'
}, {
  label: 'DKK',
  value: 'DKK'
}, {
  label: 'SEK',
  value: 'SEK'
}, {
  label: 'EUR',
  value: 'EUR'
}, {
  label: 'GBP',
  value: 'GBP'
}, {
  label: 'USD',
  value: 'USD'
}];
var minimalChargeAmount = {
  NOK: 3,
  DKK: 2.5,
  SEK: 3,
  EUR: 0.5,
  GBP: 0.3,
  USD: 0.5
};
export default currencyList;
export { currencyList, minimalChargeAmount };