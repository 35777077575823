function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { changelogItemConstants, userConstants } from '../constants/actions';
import changelogItemService from '../services/changelog-service';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var changelogItemActions = {
  getChangelogItems: getChangelogItems,
  createChangelogItem: createChangelogItem,
  updateChangelogItem: updateChangelogItem,
  deleteChangelogItem: deleteChangelogItem,
  setLastChangelogSeenAt: setLastChangelogSeenAt
};
export function getChangelogItems() {
  return function (dispatch) {
    dispatch(request());
    changelogItemService.getChangelogItems().then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: changelogItemConstants.GET_CHANGE_LOG_ITEMS
    };
  }
  function success(result) {
    return {
      type: changelogItemConstants.GET_CHANGE_LOG_ITEMS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: changelogItemConstants.GET_CHANGE_LOG_ITEMS_ERROR,
      error: error
    };
  }
}
export function createChangelogItem(changelogItem, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    changelogItemService.createChangelogItem(changelogItem).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'ChangelogItem.create_successful',
        defaultMessage: 'Change log item was successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: changelogItemConstants.CREATE_CHANGE_LOG_ITEMS
    };
  }
  function success(result) {
    return {
      type: changelogItemConstants.CREATE_CHANGE_LOG_ITEMS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: changelogItemConstants.CREATE_CHANGE_LOG_ITEMS_ERROR,
      error: error
    };
  }
}
export function updateChangelogItem(entryId, changelogItem, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    changelogItemService.updateChangelogItem(changelogItem, entryId).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'ChangelogItem.updated_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: changelogItemConstants.UPDATE_CHANGE_LOG_ITEMS
    };
  }
  function success(result) {
    return {
      type: changelogItemConstants.UPDATE_CHANGE_LOG_ITEMS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: changelogItemConstants.UPDATE_CHANGE_LOG_ITEMS_ERROR,
      error: error
    };
  }
}
function deleteChangelogItem(entryId) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(entryId));
    changelogItemService.deleteChangelogItem(entryId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'ChangelogItem.delete_successful',
        defaultMessage: 'Change log item was successfully deleted'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: changelogItemConstants.DELETE_CHANGE_LOG_ITEMS,
      entryId: entryId
    };
  }
  function success(isSuccess) {
    var result = {
      isSuccess: isSuccess,
      entryId: entryId
    };
    return {
      type: changelogItemConstants.DELETE_CHANGE_LOG_ITEMS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: changelogItemConstants.DELETE_CHANGE_LOG_ITEMS_FAILURE,
      error: error
    };
  }
}
export function setLastChangelogSeenAt(date, callback) {
  return function (dispatch) {
    dispatch(request());
    changelogItemService.setLastChangelogSeenAt(date).then(function (result) {
      dispatch(success(result));
      dispatch(updateUserOnSuccess(result));
      var user = JSON.parse(localStorage.getItem('user'));
      localStorage.setItem('user', JSON.stringify(_objectSpread(_objectSpread({}, user), {}, {
        lastChangelogEntrySeenAt: result.lastChangelogEntrySeenAt
      })));
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: changelogItemConstants.UPDATE_CHANGE_LOG_ITEMS
    };
  }
  function success(result) {
    return {
      type: changelogItemConstants.SET_LAST_SEEN_CHANGE_LOG_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: changelogItemConstants.UPDATE_CHANGE_LOG_ITEMS_ERROR,
      error: error
    };
  }
  function updateUserOnSuccess(result) {
    return {
      type: userConstants.UPDATE_CURRENT_USER_SUCCESS,
      user: result
    };
  }
}
export default changelogItemActions;