var infoPagesConstants = {
  GET_INFO_PAGES: 'INFO_PAGES_GET',
  GET_INFO_PAGES_SUCCESS: 'INFO_PAGES_GET_SUCCESS',
  GET_INFO_PAGES_FAILURE: 'INFO_PAGES_GET_FAILURE',
  CREATE_INFO_PAGE: 'INFO_PAGE_CREATE',
  CREATE_INFO_PAGE_SUCCESS: 'INFO_PAGE_CREATE_SUCCESS',
  CREATE_INFO_PAGE_FAILURE: 'INFO_PAGE_CREATE_FAILURE',
  UPDATE_INFO_PAGE: 'INFO_PAGE_UPDATE',
  UPDATE_INFO_PAGE_SUCCESS: 'INFO_PAGE_UPDATE_SUCCESS',
  UPDATE_INFO_PAGE_FAILURE: 'INFO_PAGE_UPDATE_FAILURE',
  UPDATE_INFO_PAGE_PARTIAL: 'INFO_PAGE_PARTIAL_UPDATE',
  UPDATE_INFO_PAGE_PARTIAL_SUCCESS: 'INFO_PAGE_PARTIAL_UPDATE_SUCCESS',
  UPDATE_INFO_PAGE_PARTIAL_FAILURE: 'INFO_PAGE_PARTIAL_UPDATE_FAILURE',
  DELETE_INFO_PAGE: 'INFO_PAGE_DELETE',
  DELETE_INFO_PAGE_SUCCESS: 'INFO_PAGE_DELETE_SUCCESS',
  DELETE_INFO_PAGE_FAILURE: 'INFO_PAGE_DELETE_FAILURE',
  REORDER_INFO_PAGES: 'REORDER_INFO_PAGES',
  REORDER_INFO_PAGES_SUCCESS: 'REORDER_INFO_PAGES_SUCCESS',
  REORDER_INFO_PAGES_FAILURE: 'REORDER_INFO_PAGES_FAILURE',
  GET_INFO_PAGES_COUNT_REQUEST: 'GET_INFO_PAGES_COUNT_REQUEST',
  GET_INFO_PAGES_COUNT_SUCCESS: 'GET_INFO_PAGES_COUNT_SUCCESS',
  GET_INFO_PAGES_COUNT_FAILURE: 'GET_INFO_PAGES_COUNT_FAILURE'
};
export default infoPagesConstants;