var promotedMessagesConstants = {
  GET_PROMOTED_MESSAGES: 'PROMOTED_MESSAGES_GET',
  GET_PROMOTED_MESSAGES_SUCCESS: 'PROMOTED_MESSAGES_GET_SUCCESS',
  GET_PROMOTED_MESSAGES_FAILURE: 'PROMOTED_MESSAGES_GET_FAILURE',
  CREATE_PROMOTED_MESSAGE: 'PROMOTED_MESSAGE_CREATE',
  CREATE_PROMOTED_MESSAGE_SUCCESS: 'PROMOTED_MESSAGE_CREATE_SUCCESS',
  CREATE_PROMOTED_MESSAGE_FAILURE: 'PROMOTED_MESSAGE_CREATE_FAILURE',
  UPDATE_PROMOTED_MESSAGE: 'PROMOTED_MESSAGE_UPDATE',
  UPDATE_PROMOTED_MESSAGE_SUCCESS: 'PROMOTED_MESSAGE_UPDATE_SUCCESS',
  UPDATE_PROMOTED_MESSAGE_FAILURE: 'PROMOTED_MESSAGE_UPDATE_FAILURE',
  UPDATE_PROMOTED_PARTIAL_MESSAGE: 'PROMOTED_MESSAGE_PARTIAL_UPDATE',
  UPDATE_PROMOTED_PARTIAL_MESSAGE_SUCCESS: 'PROMOTED_MESSAGE_PARTIAL_UPDATE_SUCCESS',
  UPDATE_PROMOTED_PARTIAL_MESSAGE_FAILURE: 'PROMOTED_MESSAGE_PARTIAL_UPDATE_FAILURE',
  DELETE_PROMOTED_MESSAGE: 'PROMOTED_MESSAGE_DELETE',
  DELETE_PROMOTED_MESSAGE_SUCCESS: 'PROMOTED_MESSAGE_DELETE_SUCCESS',
  DELETE_PROMOTED_MESSAGE_FAILURE: 'PROMOTED_MESSAGE_DELETE_FAILURE'
};
export default promotedMessagesConstants;