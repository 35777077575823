import workspaceService from '../services/workspace-service';
import { userConstants, workspaceConstants } from '../constants/actions';
import { historyPush } from '../helpers/history';
import { isAdminUser } from '../helpers/token-helper';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
import { workspaceFeatureState } from '../constants/types';
var workspaceActions = {
  getAll: getAll,
  getAllSimple: getAllSimple,
  getWorkspace: getWorkspace,
  createWorkspace: createWorkspace,
  updateWorkspace: updateWorkspace,
  updateWorkspaceUsers: updateWorkspaceUsers,
  deleteWorkspaceUser: deleteWorkspaceUser,
  clearWorkspace: clearWorkspace,
  clearErrors: clearErrors,
  getWorkspaceLimitsSummary: getWorkspaceLimitsSummary,
  getWorkspaceStatistics: getWorkspaceStatistics,
  getEmailIdentities: getEmailIdentities,
  verifyEmailIdentity: verifyEmailIdentity,
  updateEmailIdentity: updateEmailIdentity,
  deleteEmailIdentity: deleteEmailIdentity,
  updateActiveFeatures: updateActiveFeatures
};
function getAll() {
  return function (dispatch) {
    dispatch(request());
    workspaceService.getAll().then(function (workspaces) {
      return dispatch(success(workspaces));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.GETALL_REQUEST
    };
  }
  function success(workspaces) {
    return {
      type: workspaceConstants.GETALL_SUCCESS,
      workspaces: workspaces
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.GETALL_FAILURE,
      error: error
    };
  }
}
function getAllSimple() {
  return function (dispatch) {
    dispatch(request());
    workspaceService.getAll().then(function (workspaces) {
      return dispatch(success(workspaces));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.GETALL_SIMPLE_REQUEST
    };
  }
  function success(workspaces) {
    return {
      type: workspaceConstants.GETALL_SIMPLE_SUCCESS,
      workspaces: workspaces
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.GETALL_SIMPLE_FAILURE,
      error: error
    };
  }
}
function getWorkspace(workspaceId, suppressNotFound, keepState) {
  return function (dispatch) {
    dispatch(request());
    workspaceService.getWorkspace(workspaceId, suppressNotFound).then(function (workspace) {
      return dispatch(success(workspace));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.GET_REQUEST,
      keepState: keepState
    };
  }
  function success(workspace) {
    return {
      type: workspaceConstants.GET_SUCCESS,
      workspace: workspace
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.GET_FAILURE,
      error: error
    };
  }
}
function createWorkspace(payload, callback, message) {
  return function (dispatch) {
    dispatch(request());
    workspaceService.createWorkspace(payload).then(function (workspace) {
      dispatch(success(workspace));
      if (callback) {
        callback(workspace);
      } else {
        historyPush(isAdminUser() ? '/dashboard' : '/workspaces');
      }
      if (message) {
        dispatch(messageActions.success(message));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.CREATE_REQUEST
    };
  }
  function success(workspace) {
    return {
      type: workspaceConstants.CREATE_REQUEST_SUCCESS,
      workspace: workspace
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.CREATE_REQUEST_FAILURE,
      error: error
    };
  }
}
function updateWorkspace(payload, workspaceId, callback, message) {
  return function (dispatch) {
    dispatch(request(payload));
    workspaceService.updateWorkspace(payload, workspaceId).then(function (workspace) {
      dispatch(success(workspace));
      dispatch(userSelectedWorkspace(workspace));
      if (callback) {
        callback(workspace);
      } else {
        historyPush("/partner/".concat(workspaceId));
      }
      if (message) {
        dispatch(messageActions.success(message));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request(workspace) {
    return {
      type: workspaceConstants.UPDATE_REQUEST,
      workspace: workspace
    };
  }
  function success(workspace) {
    return {
      type: workspaceConstants.UPDATE_REQUEST_SUCCESS,
      workspace: workspace
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.UPDATE_REQUEST_FAILURE,
      error: error
    };
  }
  function userSelectedWorkspace(workspace) {
    return {
      type: userConstants.UPDATE_WORKSPACE_SUCCESS,
      workspace: workspace
    };
  }
}
function updateWorkspaceUsers(payload, workspaceId, message) {
  return function (dispatch) {
    dispatch(request(payload));
    workspaceService.updateWorkspaceUsers(payload, workspaceId).then(function (workspace) {
      dispatch(success(workspace));
      if (message) {
        dispatch(messageActions.success(message));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request(users) {
    return {
      type: workspaceConstants.UPDATE_USERS_REQUEST,
      users: users
    };
  }
  function success(workspace) {
    return {
      type: workspaceConstants.UPDATE_USERS_REQUEST_SUCCESS,
      workspace: workspace
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.UPDATE_USERS_REQUEST_FAILURE,
      error: error
    };
  }
}
function deleteWorkspaceUser(workspaceId, email, message) {
  return function (dispatch) {
    dispatch(request({
      workspaceId: workspaceId,
      email: email
    }));
    workspaceService.deleteWorkspaceUser(workspaceId, email).then(function () {
      dispatch(success());
      if (message) {
        dispatch(messageActions.success(message));
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request(payload) {
    return {
      type: workspaceConstants.DELETE_USER_REQUEST,
      payload: payload
    };
  }
  function success() {
    return {
      type: workspaceConstants.DELETE_USER_REQUEST_SUCCESS,
      email: email
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.DELETE_USER_REQUEST_FAILURE,
      error: error
    };
  }
}
function clearWorkspace() {
  return function (dispatch) {
    dispatch({
      type: workspaceConstants.CLEAR
    });
  };
}
function clearErrors() {
  return function (dispatch) {
    dispatch({
      type: workspaceConstants.CLEAR_ERRORS
    });
  };
}
function getWorkspaceLimitsSummary(workspaceId) {
  return function (dispatch) {
    dispatch(request());
    workspaceService.getWorkspaceLimitsSummary(workspaceId).then(function (response) {
      return dispatch(success(response));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: workspaceConstants.GET_LIMITS_SUMMARY_REQUEST
    };
  }
  function success(response) {
    return {
      type: workspaceConstants.GET_LIMITS_SUMMARY_SUCCESS,
      limitsSummary: response.result
    };
  }
  function failure(response) {
    return {
      type: workspaceConstants.GET_LIMITS_SUMMARY_FAILURE,
      error: response.error
    };
  }
}
function getWorkspaceStatistics(workspaceId) {
  return function (dispatch) {
    dispatch(request());
    workspaceService.getWorkspaceStatistics(workspaceId).then(function (response) {
      return dispatch(success(response));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.GET_WORKSPACE_STATISTICS
    };
  }
  function success(response) {
    return {
      type: workspaceConstants.GET_WORKSPACE_STATISTICS_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.GET_WORKSPACE_STATISTICS_FAILURE,
      error: error
    };
  }
}
function getEmailIdentities(workspaceIdOrSlug) {
  return function (dispatch) {
    dispatch(request());
    workspaceService.getEmailIdentities(workspaceIdOrSlug).then(function (response) {
      return dispatch(success(response));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.GET_WORKSPACE_EMAIL_IDENTITIES_REQUEST
    };
  }
  function success(response) {
    return {
      type: workspaceConstants.GET_WORKSPACE_EMAIL_IDENTITIES_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.GET_WORKSPACE_EMAIL_IDENTITIES_FAILURE,
      error: error
    };
  }
}
function verifyEmailIdentity(workspaceIdOrSlug, emailIdentity, callback) {
  return function (dispatch) {
    dispatch(request());
    workspaceService.verifyEmailIdentity(workspaceIdOrSlug, emailIdentity).then(function (response) {
      dispatch(success(response));
      if (callback) {
        callback();
      }
    }, function (error) {
      dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.VERIFY_WORKSPACE_EMAIL_IDENTITY_REQUEST
    };
  }
  function success(response) {
    return {
      type: workspaceConstants.VERIFY_WORKSPACE_EMAIL_IDENTITY_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.VERIFY_WORKSPACE_EMAIL_IDENTITY_FAILURE,
      error: error
    };
  }
}
function updateEmailIdentity(workspaceIdOrSlug, emailIdentity, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    workspaceService.updateEmailIdentity(workspaceIdOrSlug, emailIdentity).then(function (response) {
      dispatch(success(response));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'email_successfully_updated',
        defaultMessage: 'Email successfully updated'
      })));
      if (callback) {
        callback();
      }
    }, function (error) {
      dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.UPDATE_WORKSPACE_EMAIL_IDENTITY_REQUEST
    };
  }
  function success(response) {
    return {
      type: workspaceConstants.UPDATE_WORKSPACE_EMAIL_IDENTITY_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.UPDATE_WORKSPACE_EMAIL_IDENTITY_FAILURE,
      error: error
    };
  }
}
function deleteEmailIdentity(workspaceIdOrSlug, emailIdentityId, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    workspaceService.deleteEmailIdentity(workspaceIdOrSlug, emailIdentityId).then(function (response) {
      dispatch(success(response));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'email_successfully_deleted',
        defaultMessage: 'Email successfully deleted'
      })));
      if (callback) {
        callback();
      }
    }, function (error) {
      dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: workspaceConstants.DELETE_WORKSPACE_EMAIL_IDENTITY_REQUEST
    };
  }
  function success(response) {
    return {
      type: workspaceConstants.DELETE_WORKSPACE_EMAIL_IDENTITY_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.DELETE_WORKSPACE_EMAIL_IDENTITY_FAILURE,
      error: error
    };
  }
}
function updateActiveFeatures(payload, workspaceId, callback) {
  return function (dispatch) {
    dispatch(request(payload));
    workspaceService.updateWorkspaceFeatures(payload, workspaceId, workspaceFeatureState.ACTIVE).then(function (workspace) {
      dispatch(success(workspace));
      if (callback) {
        callback(workspace);
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request(workspace) {
    return {
      type: workspaceConstants.UPDATE_FEATURES_REQUEST,
      workspace: workspace
    };
  }
  function success(workspace) {
    return {
      type: workspaceConstants.UPDATE_FEATURES_SUCCESS,
      workspace: workspace
    };
  }
  function failure(error) {
    return {
      type: workspaceConstants.UPDATE_FEATURES_FAILURE,
      error: error
    };
  }
}
export default workspaceActions;