import { backgroundRepeatToString } from '../constants/types/background-repeat-type-constacts';
import { positionToString } from '../constants/types/background-position-constants';
function getBackgroundImage(backgroundImageUrl) {
  if (!backgroundImageUrl) {
    return '';
  }
  return "url('".concat(backgroundImageUrl, "')");
}
function getBackgroundRepeat(backgroundRepeat) {
  if (!backgroundRepeat && backgroundRepeat != 0) {
    return '';
  }
  return backgroundRepeatToString(backgroundRepeat);
}
function getbackgroundPosition(backgroundPosition) {
  if (!backgroundPosition && backgroundPosition != 0) {
    return '';
  }
  return positionToString(backgroundPosition);
}
function convertBrandingToStyle(branding) {
  var _branding$backgroundC;
  if (!branding) {
    return {
      backgroundColor: '',
      backgroundImage: '',
      backgroundRepeat: '',
      backgroundPosition: '',
      backgroundAttachment: ''
    };
  }
  var backgroundColor = (_branding$backgroundC = branding.backgroundColor) !== null && _branding$backgroundC !== void 0 ? _branding$backgroundC : '';
  var backgroundImage = getBackgroundImage(branding.backgroundImageUrl);
  var backgroundRepeat = getBackgroundRepeat(branding.backgroundRepeat);
  var backgroundPosition = getbackgroundPosition(branding.backgroundPosition);
  var backgroundAttachment = branding.fixedBackground ? 'scroll' : 'fixed';
  var backgroundSize = backgroundRepeat.toLocaleLowerCase() === 'no-repeat' && backgroundAttachment.toLowerCase() === 'fixed' ? 'cover' : 'auto';
  return {
    backgroundColor: backgroundColor,
    // #00ff00
    backgroundImage: backgroundImage,
    // url(img/src.jpg)
    backgroundRepeat: backgroundRepeat,
    // repeat, repeat-x, repeat-y, no-repeat
    backgroundPosition: backgroundPosition,
    // top left, center center, bottom left, etc.
    backgroundAttachment: backgroundAttachment,
    // Fixed or Scroll
    backgroundSize: backgroundSize // auto or cover
  };
}

export default convertBrandingToStyle;