var pollsConstants = {
  GET_POLLS: 'GET_POLLS',
  GET_POLLS_SUCCESS: 'GET_POLLS_SUCCESS',
  GET_POLLS_ERROR: 'GET_POLLS_ERROR',
  CREATE_POLL: 'CREATE_POLL_REQUEST',
  CREATE_POLL_SUCCESS: 'CREATE_POLL_SUCCESS',
  CREATE_POLL_ERROR: 'CREATE_POLL_ERROR',
  UPDATE_POLL: 'UPDATE_POLL_REQUEST',
  UPDATE_POLL_SUCCESS: 'UPDATE_POLL_SUCCESS',
  UPDATE_POLL_ERROR: 'UPDATE_POLL_ERROR',
  DELETE_POLL: 'DELETE_POLL',
  DELETE_POLL_SUCCESS: 'DELETE_POLL_SUCCESS',
  DELETE_POLL_FAILURE: 'DELETE_POLL_FAILURE',
  REORDER_POLLS: 'REORDER_POLLS',
  REORDER_POLLS_SUCCESS: 'REORDER_POLLS_SUCCESS',
  REORDER_POLLS_ERROR: 'REORDER_POLLS_ERROR',
  EXPORT_POLLS_REQUEST: 'EXPORT_POLLS_REQUEST',
  EXPORT_POLLS_SUCCESS: 'EXPORT_POLLS_SUCCESS',
  EXPORT_POLSS_FAILURE: 'EXPORT_POLLS_FAILURE',
  RESET_POLLS_REQUEST: 'RESET_POLLS_REQUEST',
  RESET_POLLS_SUCCESS: 'RESET_POLLS_SUCCESS',
  RESET_POLSS_FAILURE: 'RESET_POLSS_FAILURE',
  EXPORT_CODEDPOLLS_REQUEST: 'EXPORT_CODEDPOLLS_REQUEST',
  EXPORT_CODEDPOLLS_SUCCESS: 'EXPORT_CODEDPOLLS_SUCCESS',
  EXPORT_CODEDPOLLS_FAILURE: 'EXPORT_CODEDPOLLS_FAILURE',
  EXPORT_ALLPOLLS_REQUEST: 'EXPORT_ALL_POLLS_REQUEST',
  EXPORT_ALLPOLLS_SUCCESS: 'EXPORT_ALLPOLLS_SUCCESS',
  EXPORT_ALLPOLLS_FAILURE: 'EXPORT_ALLPOLLS_FAILURE',
  GET_POLLS_STATISTICS_REQUEST: 'GET_POLLS_STATISTICS_REQUEST',
  GET_POLLS_STATISTICS_SUCCESS: 'GET_POLLS_STATISTICS_SUCCESS',
  GET_POLLS_STATISTICS_ERROR: 'GET_POLLS_STATISTICS_ERROR',
  EXPORT_POLLS_STATISTICS_REQUEST: 'EXPORT_POLLS_STATISTICS_REQUEST',
  EXPORT_POLLS_STATISTICS_SUCCESS: 'EXPORT_POLLS_STATISTICS_SUCCESS',
  EXPORT_POLLS_STATISTICS_ERROR: 'EXPORT_POLLS_STATISTICS_ERROR',
  CREATE_GENERIC_PASSWORD_REQUEST: 'CREATE_GENERIC_PASSWORD_REQUEST',
  CREATE_GENERIC_PASSWORD_SUCCESS: 'CREATE_GENERIC_PASSWORD_SUCCESS',
  CREATE_GENERIC_PASSWORD_ERROR: 'CREATE_GENERIC_PASSWORD_ERROR',
  GET_POLLS_COUNT_REQUEST: 'GET_POLLS_COUNT_REQUEST',
  GET_POLLS_COUNT_SUCCESS: 'GET_POLLS_COUNT_SUCCESS',
  GET_POLLS_COUNT_ERROR: 'GET_POLLS_COUNT_ERROR',
  GET_POLL_REGISTRATIONS_REQUEST: 'GET_POLL_REGISTRATIONS_REQUEST',
  GET_POLL_REGISTRATIONS_SUCCESS: 'GET_POLL_REGISTRATIONS_SUCCESS',
  GET_POLL_REGISTRATIONS_ERROR: 'GET_POLL_REGISTRATIONS_ERROR',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_FAILURE: 'DELETE_TEMPLATE_FAILURE',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_ERROR: 'CREATE_TEMPLATE_ERROR',
  UPDATE_TEMPLATE: 'UPDATE_POLL_REQUEST',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_POLL_SUCCESS',
  UPDATE_TEMPLATE_ERROR: 'UPDATE_POLL_ERROR',
  GET_POLL_TEMPLATE_REGISTRATIONS_REQUEST: 'GET_POLL_TEMPLATE_REGISTRATIONS_REQUEST',
  GET_POLL_TEMPLATE_REGISTRATIONS_SUCCESS: 'GET_POLL_TEMPLATE_REGISTRATIONS_SUCCESS',
  GET_POLL_TEMPLATE_REGISTRATIONS_ERROR: 'GET_POLL_TEMPLATE_REGISTRATIONS_ERROR',
  DELETE_ANSWER_REQUEST: 'DELETE_ANSWER_REQUEST',
  DELETE_ANSWER_SUCCESS: 'DELETE_ANSWER_SUCCESS',
  DELETE_ANSWER_ERROR: 'DELETE_ANSWER_ERROR'
};
export default pollsConstants;