var emailStatus = {
  SCHEDULED: 0,
  SUCCESS: 1,
  ERROR: 2,
  SENDING: 3,
  CANCELED: 4,
  BATCH: 99
};
var emailVerificationStatus = {
  PENDING: 0,
  SUCCESS: 1,
  FAILED: 2,
  TEMPORARY_FAILURE: 3
};
export default emailStatus;
export { emailStatus, emailVerificationStatus };