import { billingConstants } from '../constants/actions';
import billingService from '../services/billing-service';
var billingActions = {
  getBillingInfo: getBillingInfo,
  getBillingHistory: getBillingHistory,
  getInvoicePdf: getInvoicePdf
};
export function getBillingInfo(workspaceId) {
  return function (dispatch) {
    dispatch(request());
    billingService.getBillingInfo(workspaceId).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: billingConstants.GET_BILLING_INFO_REQUEST
    };
  }
  function success(result) {
    return {
      type: billingConstants.GET_BILLING_INFO_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: billingConstants.GET_BILLING_INFO_ERROR,
      error: error
    };
  }
}
export function getBillingHistory(workspaceId) {
  return function (dispatch) {
    dispatch(request());
    billingService.getBillingHistory(workspaceId).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: billingConstants.GET_BILLING_HISTORY_REQUEST
    };
  }
  function success(result) {
    return {
      type: billingConstants.GET_BILLING_HISTORY_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: billingConstants.GET_BILLING_HISTORY_ERROR,
      error: error
    };
  }
}
export function getInvoicePdf(workspaceId, invoiceId, callback) {
  return function (dispatch) {
    dispatch(request());
    billingService.getInvoicePdf(workspaceId, invoiceId).then(function (result) {
      dispatch(success(result));
      callback(result === null || result === void 0 ? void 0 : result.url);
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: billingConstants.GET_INVOICE_PDF_REQUEST
    };
  }
  function success(result) {
    return {
      type: billingConstants.GET_INVOICE_PDF_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: billingConstants.GET_INVOICE_PDF_ERROR,
      error: error
    };
  }
}
export default billingActions;