import { registrationGroupConstants } from '../constants/actions';
import registrationGroupService from '../services/registration-group-service';
var registrationGroupActions = {
  getGroups: getGroups,
  createGroup: createGroup,
  updateGroup: updateGroup,
  deleteGroup: deleteGroup,
  deleteGroupUsers: deleteGroupUsers,
  clearErrors: clearErrors
};
function getGroups(eventIdOrSlug) {
  return function (dispatch) {
    dispatch(request());
    registrationGroupService.getGroups(eventIdOrSlug).then(function (response) {
      return dispatch(success(response));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: registrationGroupConstants.GET_GROUPS_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationGroupConstants.GET_GROUPS_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: registrationGroupConstants.GET_GROUPS_FAILURE,
      error: error
    };
  }
}
function createGroup(eventId, values, callback) {
  return function (dispatch) {
    dispatch(request());
    registrationGroupService.createGroup(eventId, values).then(function (response) {
      dispatch(success(response));
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: registrationGroupConstants.CREATE_GROUP_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationGroupConstants.CREATE_GROUP_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: registrationGroupConstants.CREATE_GROUP_FAILURE,
      error: error
    };
  }
}
function updateGroup(eventId, entityId, values, callback) {
  return function (dispatch) {
    dispatch(request());
    registrationGroupService.updateGroup(eventId, entityId, values).then(function (response) {
      dispatch(success(response));
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: registrationGroupConstants.UPDATE_GROUP_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationGroupConstants.UPDATE_GROUP_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: registrationGroupConstants.UPDATE_GROUP_FAILURE,
      error: error
    };
  }
}
function deleteGroup(eventId, entityId, callback) {
  return function (dispatch) {
    dispatch(request());
    registrationGroupService.deleteGroup(eventId, entityId).then(function () {
      dispatch(success());
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: registrationGroupConstants.DELETE_GROUP_REQUEST
    };
  }
  function success() {
    return {
      type: registrationGroupConstants.DELETE_GROUP_SUCCESS,
      entityId: entityId
    };
  }
  function failure(error) {
    return {
      type: registrationGroupConstants.DELETE_GROUP_FAILURE,
      error: error
    };
  }
}
function deleteGroupUsers(eventId, filter, groupIds, callback) {
  return function (dispatch) {
    dispatch(request());
    registrationGroupService.deleteGroupUsers(eventId, filter, groupIds).then(function () {
      dispatch(success());
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: registrationGroupConstants.DELETE_GROUP_USERS_REQUEST
    };
  }
  function success() {
    return {
      type: registrationGroupConstants.DELETE_GROUP_USERS_SUCCESS
    };
  }
  function failure(error) {
    return {
      type: registrationGroupConstants.DELETE_GROUP_USERS_FAILURE,
      error: error
    };
  }
}
function clearErrors() {
  return function (dispatch) {
    dispatch({
      type: registrationGroupConstants.CLEAR_ERRORS
    });
  };
}
export default registrationGroupActions;