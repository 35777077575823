var invoiceStatus = {
  UNKNOWN: 0,
  PAID: 1,
  POSTED: 2,
  PAYMENT_DUE: 3,
  NOT_PAID: 4,
  VOIDED: 5,
  PENDING: 6
};
export default invoiceStatus;
export { invoiceStatus };