import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
export default function toggleFullscreen(isFullscreen) {
  if (isFullscreen) {
    document.body.classList.remove('fullscreen');
  } else if (document.exitFullscreen) {
    document.body.classList.add('fullscreen');
  }
}
export function getInitialSelectedDocuments(docIds, documents) {
  if (!(documents !== null && documents !== void 0 && documents.items) || !docIds) {
    return [];
  }
  return docIds.map(function (id) {
    var doc = documents.items.find(function (y) {
      return y.id === id;
    });
    return {
      label: doc === null || doc === void 0 ? void 0 : doc.title,
      value: doc === null || doc === void 0 ? void 0 : doc.id
    };
  });
}
export function getDocumentOptions(documents) {
  var _documents$items$map, _documents$items;
  return (_documents$items$map = documents === null || documents === void 0 || (_documents$items = documents.items) === null || _documents$items === void 0 ? void 0 : _documents$items.map(function (x) {
    return {
      label: x.title,
      value: x.id
    };
  })) !== null && _documents$items$map !== void 0 ? _documents$items$map : [];
}