import templateService from '../services/template-service';
import { templateConstants } from '../constants/actions';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var templateActions = {
  getTemplates: getTemplates,
  clearTemplates: clearTemplates,
  createTemplate: createTemplate,
  updateTemplate: updateTemplate,
  deleteTemplate: deleteTemplate
};
function getTemplates(eventId, type) {
  return function (dispatch) {
    dispatch(request());
    templateService.getAll(eventId, type).then(function (templates) {
      return dispatch(success(templates));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: templateConstants.GET_TEMPLATES_REQUEST
    };
  }
  function success(templates) {
    return {
      type: templateConstants.GET_TEMPLATES_SUCCESS,
      templates: templates
    };
  }
  function failure(error) {
    return {
      type: templateConstants.GET_TEMPLATES_FAILURE,
      error: error
    };
  }
}
function createTemplate(eventId, template, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(createMessage());
    templateService.create(eventId, template).then(function (message) {
      dispatch(success(message));
      if (callback) {
        callback();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'template_save_successful',
        defaultMessage: 'Template was successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function createMessage() {
    return {
      type: templateConstants.CREATE_TEMPLATE_REQUEST
    };
  }
  function success(response) {
    return {
      type: templateConstants.CREATE_TEMPLATE_SUCCESS,
      template: response
    };
  }
  function failure(error) {
    return {
      type: templateConstants.CREATE_TEMPLATE_FAILURE,
      error: error
    };
  }
}
function updateTemplate(eventId, messageId, template, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(updateMessage(template));
    templateService.update(eventId, messageId, template).then(function (response) {
      dispatch(success(response));
      if (callback) {
        callback();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Template.Changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function updateMessage() {
    return {
      type: templateConstants.UPDATE_TEMPLATE_REQUEST,
      template: template
    };
  }
  function success(response) {
    return {
      type: templateConstants.UPDATE_TEMPLATE_SUCCESS,
      template: response
    };
  }
  function failure(error) {
    return {
      type: templateConstants.UPDATE_TEMPLATE_FAILURE,
      error: error
    };
  }
}
function deleteTemplate(eventId, entryId) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(entryId));
    templateService.deleteTemplate(eventId, entryId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Template.Delete_successful',
        defaultMessage: 'Template was successfully deleted'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: templateConstants.DELETE_TEMPLATE_REQUEST,
      entryId: entryId
    };
  }
  function success(isSuccess) {
    var response = {
      isSuccess: isSuccess,
      entryId: entryId
    };
    return {
      type: templateConstants.DELETE_TEMPLATE_SUCCESS,
      response: response
    };
  }
  function failure(error) {
    return {
      type: templateConstants.DELETE_TEMPLATE_FAILURE,
      error: error
    };
  }
}
function clearTemplates(eventId) {
  return {
    type: templateConstants.CLEAR_TEMPLATES,
    eventId: eventId
  };
}
export default templateActions;