import sponsorsService from '../services/sponsors-service';
import { sponsorsConstants } from '../constants/actions';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var sponsorsActions = {
  getSponsors: getSponsors,
  deleteSponsor: deleteSponsor,
  reorderSponsors: reorderSponsors,
  createSponsor: createSponsor,
  updateSponsor: updateSponsor,
  clearErrors: clearErrors
};
function getSponsors(eventId) {
  return function (dispatch) {
    dispatch(request());
    sponsorsService.getSponsors(eventId).then(function (sponsors) {
      return dispatch(success(sponsors));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: sponsorsConstants.GET_SPONSORS
    };
  }
  function success(items) {
    return {
      type: sponsorsConstants.GET_SPONSORS_SUCCESS,
      items: items
    };
  }
  function failure(error) {
    return {
      type: sponsorsConstants.GET_SPONSORS_FAILURE,
      error: error
    };
  }
}
function deleteSponsor(eventId, sponsorId) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(sponsorId));
    sponsorsService.deleteSponsor(eventId, sponsorId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Sponsors.delete_successful',
        defaultMessage: 'Sponsor was successfully deleted'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: sponsorsConstants.DELETE_SPONSOR
    };
  }
  function success(isSuccess) {
    var result = {
      isSuccess: isSuccess,
      sponsorId: sponsorId
    };
    return {
      type: sponsorsConstants.DELETE_SPONSORS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: sponsorsConstants.DELETE_SPONSORS_FAILURE,
      error: error
    };
  }
}
function reorderSponsors(eventId, sponsorIds, onContextUpdated) {
  return function (dispatch) {
    dispatch(createMessage());
    sponsorsService.reorderSponsors(eventId, sponsorIds).then(function (sponsors) {
      dispatch(success(sponsors));
      if (onContextUpdated) {
        onContextUpdated();
      }
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function createMessage() {
    return {
      type: sponsorsConstants.REORDER_SPONSORS
    };
  }
  function success(sponsors) {
    return {
      type: sponsorsConstants.REORDER_SPONSORS_SUCCESS,
      sponsors: sponsors
    };
  }
  function failure(error) {
    return {
      type: sponsorsConstants.REORDER_SPONSORS_FAILURE,
      error: error
    };
  }
}
function createSponsor(eventId, sponsor, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    sponsorsService.createSponsor(eventId, sponsor).then(function (item) {
      dispatch(success(item));
      if (callback) {
        callback();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Sponsors.create_successful',
        defaultMessage: 'Sponsor was successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: sponsorsConstants.CREATE_SPONSOR
    };
  }
  function success(item) {
    return {
      type: sponsorsConstants.CREATE_SPONSOR_SUCCESS,
      item: item
    };
  }
  function failure(error) {
    return {
      type: sponsorsConstants.CREATE_SPONSOR_FAILURE,
      error: error
    };
  }
}
function updateSponsor(eventId, sponsorId, sponsor, callback) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request());
    sponsorsService.updateSponsor(eventId, sponsorId, sponsor).then(function (item) {
      dispatch(success(item));
      if (callback) {
        callback();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'Sponsors.changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: sponsorsConstants.UPDATE_SPONSOR
    };
  }
  function success(item) {
    return {
      type: sponsorsConstants.UPDATE_SPONSOR_SUCCESS,
      item: item
    };
  }
  function failure(error) {
    return {
      type: sponsorsConstants.UPDATE_SPONSOR_FAILURE,
      error: error
    };
  }
}
function clearErrors() {
  return function (dispatch) {
    dispatch({
      type: sponsorsConstants.CLEAR_ERRORS
    });
  };
}
export default sponsorsActions;