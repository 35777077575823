var _socialLinkTypeMap;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var socialLinkType = {
  UNKNOWN: 'globe',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  SNAPCHAT: 'snapchat',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  SLIDESHARE: 'slideshare'
};
var socialLinkTypeMap = (_socialLinkTypeMap = {}, _defineProperty(_socialLinkTypeMap, socialLinkType.UNKNOWN, 0), _defineProperty(_socialLinkTypeMap, socialLinkType.FACEBOOK, 1), _defineProperty(_socialLinkTypeMap, socialLinkType.TWITTER, 2), _defineProperty(_socialLinkTypeMap, socialLinkType.LINKEDIN, 3), _defineProperty(_socialLinkTypeMap, socialLinkType.SNAPCHAT, 4), _defineProperty(_socialLinkTypeMap, socialLinkType.INSTAGRAM, 5), _defineProperty(_socialLinkTypeMap, socialLinkType.YOUTUBE, 6), _defineProperty(_socialLinkTypeMap, socialLinkType.VIMEO, 7), _defineProperty(_socialLinkTypeMap, socialLinkType.SLIDESHARE, 8), _socialLinkTypeMap);
var urlSocialLinkMap = {
  'fb.me': socialLinkType.FACEBOOK,
  'fb.com': socialLinkType.FACEBOOK,
  'facebook.com': socialLinkType.FACEBOOK,
  'twitter.com': socialLinkType.TWITTER,
  'linkedin.com': socialLinkType.LINKEDIN,
  'snapchat.com': socialLinkType.SNAPCHAT,
  'instagram.com': socialLinkType.INSTAGRAM,
  'youtube.com': socialLinkType.YOUTUBE,
  'youtu.be': socialLinkType.YOUTUBE,
  'vimeo.com': socialLinkType.VIMEO,
  'slideshare.net': socialLinkType.SLIDESHARE
};
function getUrlIcon(url) {
  var type = Object.keys(urlSocialLinkMap).find(function (x) {
    return url.match("(http(s)?://)?".concat(x));
  });
  if (!type) {
    return 'globe';
  }
  return ['fab', urlSocialLinkMap[type]];
}
export { socialLinkType, socialLinkTypeMap, urlSocialLinkMap, getUrlIcon };