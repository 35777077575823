var templateConstants = {
  GET_TEMPLATES_REQUEST: 'TEMPLATES_GET_REQUEST',
  GET_TEMPLATES_SUCCESS: 'TEMPLATES_GET_SUCCESS',
  GET_TEMPLATES_FAILURE: 'TEMPLATES_GET_FAILURE',
  CLEAR_TEMPLATES: 'TEMPLATES_CLEAR',
  CREATE_TEMPLATE_REQUEST: 'TEMPLATE_CREATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS: 'TEMPLATE_CREATE_SUCCESS',
  CREATE_TEMPLATE_FAILURE: 'TEMPLATE_CREATE_FAILURE',
  UPDATE_TEMPLATE_REQUEST: 'TEMPLATE_UPDATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS: 'TEMPLATE_UPDATE_SUCCESS',
  UPDATE_TEMPLATE_FAILURE: 'TEMPLATE_UPDATE_FAILURE',
  DELETE_TEMPLATE_REQUEST: 'TEMPLATE_DELETE_REQUEST',
  DELETE_TEMPLATE_SUCCESS: 'TEMPLATE_DELETE_SUCCESS',
  DELETE_TEMPLATE_FAILURE: 'TEMPLATE_DELETE_FAILURE'
};
export default templateConstants;