import { useIntl } from 'react-intl';

/* This expression has dynamic translation id and translation extraction has been disabled for this file */
function DynamicTranslation(_ref) {
  var id = _ref.id;
  var intl = useIntl();
  return intl.formatMessage({
    id: id
  });
}
export default DynamicTranslation;