function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { Base64 } from 'js-base64';
import config from 'config/environment';
import { DateTime } from 'luxon';
import defaults from '../constants/actions/defaults';
import { getLiveEventUrl } from './url-helpers';
export default function registerHelpers(handlebarsContext) {
  handlebarsContext.registerHelper('registrationButton', function (text, backgroundColor, foregroundColor) {
    return new handlebarsContext.SafeString(buttonHtml('{{registrationUrl}}', text, backgroundColor, foregroundColor));
  });
  handlebarsContext.registerHelper('declineButton', function (text, backgroundColor, foregroundColor) {
    return new handlebarsContext.SafeString(buttonHtml('{{declineUrl}}', text, backgroundColor, foregroundColor));
  });
  handlebarsContext.registerHelper('appPasswordStatic', function () {
    return new handlebarsContext.SafeString('[appPassword]');
  });
  function buttonHtml(urlTag, text, backgroundColor, foregroundColor) {
    return "<a href=\"#\" style=\"border-radius: 4px;display: inline-block;font-size: 14px;font-weight: bold;line-height: 24px;padding: 12px 24px;text-align: center;text-decoration: none !important;transition: opacity 0.1s ease-in;color: ".concat(foregroundColor, " !important;background-color: ").concat(backgroundColor, ";font-family: Ubuntu, sans-serif;\">").concat(text, "</a>");
  }
}
function codeObjectKeys(obj) {
  var hashedObj = {};
  Object.keys(obj).forEach(function (key) {
    var hashedKey = Base64.encode(key);
    hashedObj[hashedKey] = obj[key];
  });
  return hashedObj;
}
function getTagReplacements(event, row) {
  var _event$slug;
  var codedRow = codeObjectKeys(row);
  var parsedStartDate = DateTime.fromISO(event.startDate, {
    zone: event === null || event === void 0 ? void 0 : event.timezone
  });
  var eventStartDate = parsedStartDate.isValid && "".concat(parsedStartDate.toFormat(defaults.DATETIME_FORMAT));
  var liveEventUrl = event !== null && event !== void 0 && event.isLiveEventEnabled ? getLiveEventUrl(event) : "".concat(config.oldSystemLiveEventUrl, "/").concat((_event$slug = event.slug) !== null && _event$slug !== void 0 ? _event$slug : event.id);
  return _objectSpread(_objectSpread({}, codedRow), {}, {
    eventName: event.name,
    eventUrl: event.shortUrl,
    eventStartDate: eventStartDate,
    eventCalendar: '[eventCalendar]',
    name: "".concat(row.name, " ").concat(row.middleName ? "".concat(row.middleName, " ") : ''),
    company: row.company,
    jobTitle: row.jobTitle,
    lastName: row.lastName,
    appUsername: row.email,
    appPassword: '[password]',
    appUrl: liveEventUrl,
    registrationUrl: "".concat(event.shortUrl, "#/").concat(''.padStart(60, '0')),
    declineUrl: "".concat(event.shortUrl, "#/").concat(''.padStart(60, '0'), "/decline"),
    submittedFormDetails: '[Submitted form details]',
    appPasswordlessLoginUrl: '[appPasswordlessLoginUrl]',
    appPasswordlessLoginUrlButtonTag: '[appPasswordlessLoginButton]',
    qrTicket: '[qrTicket]',
    qrTicketLink: '[qrTicketLink]'
  });
}
export { registerHelpers, getTagReplacements };