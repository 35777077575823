function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { DateTime } from 'luxon';
import { eventConstants, fileConstants, infoPagesConstants, liveDocumentsConstants, pollsConstants, promotedMessagesConstants, registrationsConstants, speakersConstants, sponsorsConstants, templateConstants, workspaceConstants } from '../constants/actions';
import agendaConstants from '../constants/actions/agenda-constants';
import { eventFeatureType } from '../constants/types';
import { codeKeys } from '../helpers/helpers';
function getAdjustedFeatures(features) {
  for (var _len = arguments.length, types = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    types[_key - 1] = arguments[_key];
  }
  var now = DateTime.utc();
  return features === null || features === void 0 ? void 0 : features.map(function (feature) {
    var _feature$children;
    return _objectSpread(_objectSpread({}, feature), {}, {
      completedOn: types.includes(feature.type) ? now : feature.completedOn,
      children: (_feature$children = feature.children) === null || _feature$children === void 0 ? void 0 : _feature$children.map(function (childFeature) {
        return _objectSpread(_objectSpread({}, childFeature), {}, {
          completedOn: types.includes(childFeature.type) ? now : childFeature.completedOn
        });
      })
    });
  });
}
function event() {
  var _action$result, _action$response, _action$response2;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case workspaceConstants.GET_REQUEST:
      return action.keepState ? state : {};
    case eventConstants.INITIALIZE_EVENT:
      return {};
    case eventConstants.GET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case eventConstants.GET_SUCCESS:
      {
        var _codeKeys = codeKeys(action.event.form.elements),
          encodedObjects = _codeKeys.encodedObjects,
          encodedIds = _codeKeys.encodedIds;
        return _objectSpread(_objectSpread({}, action.event), {}, {
          codedForm: {
            elements: encodedObjects,
            updatedOn: action.event.form.updatedOn
          },
          codedFormIds: encodedIds,
          emailIdentities: state.emailIdentities
        });
      }
    case eventConstants.GET_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error.message,
        errors: action.error.errors,
        errorCodes: action.error.errorCodes
      });
    case eventConstants.CLEAR_ERRORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null
      });
    case eventConstants.CREATE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case eventConstants.CREATE_REQUEST_SUCCESS:
      {
        var _codeKeys2 = codeKeys(action.event.form.elements),
          _encodedObjects = _codeKeys2.encodedObjects,
          _encodedIds = _codeKeys2.encodedIds;
        return _objectSpread(_objectSpread({}, action.event), {}, {
          success: true,
          loading: false,
          codedForm: {
            elements: _encodedObjects,
            updatedOn: action.event.form.updatedOn
          },
          codedFormIds: _encodedIds
        });
      }
    case eventConstants.CREATE_REQUEST_FAILURE:
      return {
        error: action.error.message,
        errors: action.error.errors,
        loading: false,
        success: false
      };
    case eventConstants.UPDATE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false
      });
    case eventConstants.UPDATE_REQUEST_SUCCESS:
      {
        var _codeKeys3 = codeKeys(action.event.form.elements),
          _encodedObjects2 = _codeKeys3.encodedObjects,
          _encodedIds2 = _codeKeys3.encodedIds;
        return _objectSpread(_objectSpread({}, action.event), {}, {
          success: true,
          loading: false,
          codedForm: {
            elements: _encodedObjects2,
            updatedOn: action.event.form.updatedOn
          },
          codedFormIds: _encodedIds2
        });
      }
    case eventConstants.UPDATE_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error.message,
        errors: action.error.errors,
        errorCodes: action.error.errorCodes,
        loading: false,
        success: false
      });
    case eventConstants.SAVEFORM_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        formLoading: false,
        fromSuccess: false
      });
    case eventConstants.SAVEFORM_REQUEST_SUCCESS:
      {
        var _codeKeys4 = codeKeys(action.event.form.elements),
          _encodedObjects3 = _codeKeys4.encodedObjects,
          _encodedIds3 = _codeKeys4.encodedIds;
        return _objectSpread(_objectSpread(_objectSpread({}, state), action.event), {}, {
          error: undefined,
          loading: false,
          fromSuccess: true,
          formLoading: false,
          codedForm: {
            elements: _encodedObjects3,
            updatedOn: action.event.form.updatedOn
          },
          codedFormIds: _encodedIds3
        });
      }
    case eventConstants.SAVEFORM_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        fromSuccess: false,
        formLoading: false
      });
    case eventConstants.SAVEFORM_SETTINGS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        formLoading: false,
        fromSuccess: false
      });
    case eventConstants.SAVEFORM_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        event: _objectSpread(_objectSpread({}, state.event), {}, {
          attendeeLimit: action.event.attendeeLimit,
          registrationStartDate: action.event.registrationStartDate,
          registrationEndDate: action.event.registrationEndDate
        }),
        loading: false,
        fromSuccess: true,
        formLoading: false
      });
    case eventConstants.SAVEFORM_SETTINGS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        fromSuccess: false,
        formLoading: false
      });
    case eventConstants.PUBLISH_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        publishSuccess: false,
        publishLoading: true
      });
    case eventConstants.PUBLISH_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        success: true,
        loading: false,
        publishSuccess: true,
        publishLoading: false,
        publishedVersion: state.publishedVersion ? state.publishedVersion + 1 : 1,
        features: getAdjustedFeatures(state.features, eventFeatureType.REGISTRATION_FORM, eventFeatureType.REGISTRATION_LANDING_PAGE, eventFeatureType.REGISTRATION_PUBLISH)
      });
    case eventConstants.PUBLISH_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        publishSuccess: false,
        publishLoading: false
      });
    case eventConstants.SAVE_LANDING_PAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        landingPageLoading: false,
        landingPageSuccess: false,
        publishSuccess: false
      });
    case eventConstants.SAVE_LANDING_PAGE_SUCCESS:
      {
        var _codeKeys5 = codeKeys(action.event.form.elements),
          _encodedObjects4 = _codeKeys5.encodedObjects,
          _encodedIds4 = _codeKeys5.encodedIds;
        if (action.isPreview) {
          return _objectSpread(_objectSpread({}, state), {}, {
            loading: false,
            landingPageSuccess: true,
            landingPageLoading: false,
            codedForm: {
              elements: _encodedObjects4,
              updatedOn: action.event.form.updatedOn
            },
            codedFormIds: _encodedIds4
          });
        }
        return _objectSpread(_objectSpread(_objectSpread({}, state), action.event), {}, {
          loading: false,
          landingPageSuccess: true,
          landingPageLoading: false,
          codedForm: {
            elements: _encodedObjects4,
            updatedOn: action.event.form.updatedOn
          }
        });
      }
    case eventConstants.SAVE_LANDING_PAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        landingPageSuccess: false,
        landingPageLoading: false
      });
    case eventConstants.ENTER_LANDING_PAGE_PREVIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        landingPagePreview: true
      });
    case eventConstants.EXIT_LANDING_PAGE_PREVIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        landingPagePreview: false
      });
    case eventConstants.SAVE_TEMPLATE_PAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        templatePageLoading: false,
        templatePageSuccess: false
      });
    case eventConstants.SAVE_TEMPLATE_PAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        templates: action.templates,
        loading: false,
        templatePageLoading: true,
        templatePageSuccess: false
      });
    case eventConstants.SAVE_TEMPLATE_PAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        templatePageLoading: false,
        templatePageSuccess: false
      });
    case eventConstants.SAVE_BRANDING_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        brandingLoading: true,
        brandingSuccess: false
      });
    case eventConstants.SAVE_BRANDING_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        branding: action.result,
        brandingLoading: false,
        brandingSuccess: true,
        features: getAdjustedFeatures(state.features, eventFeatureType.BRANDING)
      });
    case eventConstants.SAVE_BRANDING_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        brandingLoading: false,
        brandingSuccess: false
      });
    case eventConstants.DELETE_BRANDING_ITEMS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        brandingLoading: true,
        brandingSuccess: false
      });
    case eventConstants.DELETE_BRANDING_ITEMS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        branding: action.result,
        brandingLoading: false,
        brandingSuccess: true
      });
    case eventConstants.DELETE_BRANDING_ITEMS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        brandingLoading: false,
        brandingSuccess: false
      });
    case eventConstants.SAVE_LIVE_SETTINGS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        liveSettingsLoading: true,
        liveSettingsSuccess: false
      });
    case eventConstants.SAVE_LIVE_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        liveSettings: action.result,
        liveSettingsLoading: false,
        liveSettingsSuccess: true,
        features: (_action$result = action.result) !== null && _action$result !== void 0 && (_action$result = _action$result.stageStream) !== null && _action$result !== void 0 && _action$result.url ? getAdjustedFeatures(state.features, eventFeatureType.PLATFORM_SETTINGS) : state.features
      });
    case eventConstants.SAVE_LIVE_SETTINGS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        liveSettingsLoading: false,
        liveSettingsSuccess: false
      });
    case eventConstants.VALIDATE_SLUG_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        slugValidating: true
      });
    case eventConstants.VALIDATE_SLUG_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        slugValidating: false,
        slugValidation: action.response
      });
    case eventConstants.VALIDATE_SLUG_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        slugValidating: false,
        error: action.error
      });
    case eventConstants.GET_EVENT_EMAIL_IDENTITIES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: true,
        error: undefined
      });
    case eventConstants.GET_EVENT_EMAIL_IDENTITIES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: undefined,
        emailIdentities: action.response
      });
    case eventConstants.GET_EVENT_EMAIL_IDENTITIES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: action.error
      });
    case eventConstants.VERIFY_EVENT_EMAIL_IDENTITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: true
      });
    case eventConstants.VERIFY_EVENT_EMAIL_IDENTITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        verifiedEmail: action.message ? {
          message: action.message
        } : action.response,
        features: getAdjustedFeatures(state.features, eventFeatureType.EMAIL_SENDER)
      });
    case eventConstants.VERIFY_EVENT_EMAIL_IDENTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: action.error
      });
    case eventConstants.UPDATE_EVENT_EMAIL_IDENTITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: true
      });
    case eventConstants.UPDATE_EVENT_EMAIL_IDENTITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        verifiedEmail: action.response,
        features: getAdjustedFeatures(state.features, eventFeatureType.EMAIL_SENDER)
      });
    case eventConstants.UPDATE_EVENT_EMAIL_IDENTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: action.error
      });
    case eventConstants.DELETE_EVENT_EMAIL_IDENTITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: true
      });
    case eventConstants.DELETE_EVENT_EMAIL_IDENTITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        verifiedEmail: action.response
      });
    case eventConstants.DELETE_EVENT_EMAIL_IDENTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEmailIdentities: false,
        error: action.error
      });
    case eventConstants.SAVE_LOBBY_PAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        lobbyPageLoading: false,
        lobbyPageSuccess: false
      });
    case eventConstants.SAVE_LOBBY_PAGE_SUCCESS:
      {
        var _codeKeys6 = codeKeys(action.event.form.elements),
          _encodedObjects5 = _codeKeys6.encodedObjects,
          _encodedIds5 = _codeKeys6.encodedIds;
        if (action.isPreview) {
          return _objectSpread(_objectSpread({}, state), {}, {
            loading: false,
            lobbyPageSuccess: true,
            lobbyPageLoading: false,
            codedForm: {
              elements: _encodedObjects5,
              updatedOn: action.event.form.updatedOn
            },
            codedFormIds: _encodedIds5
          });
        }
        return _objectSpread(_objectSpread(_objectSpread({}, state), action.event), {}, {
          loading: false,
          lobbyPageSuccess: true,
          lobbyPageLoading: false
        });
      }
    case eventConstants.SAVE_LOBBY_PAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        lobbyPageSuccess: false,
        lobbyPageLoading: false
      });
    case eventConstants.ENTER_LOBBY_PAGE_PREVIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        lobbyPagePreview: true
      });
    case eventConstants.EXIT_LOBBY_PAGE_PREVIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        lobbyPagePreview: false
      });
    case eventConstants.UPDATE_EVENT_FEATURE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        featuresLoading: true
      });
    case eventConstants.UPDATE_EVENT_FEATURE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        featuresLoading: false,
        features: state.features.map(function (feature) {
          return feature.type === action.response.type ? _objectSpread({}, action.response) : feature;
        })
      });
    case eventConstants.UPDATE_EVENT_FEATURE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        featuresLoading: false
      });
    case templateConstants.CREATE_TEMPLATE_SUCCESS:
    case templateConstants.UPDATE_TEMPLATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.TEMPLATES)
      });
    case registrationsConstants.CREATE_REGISTRATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.PARTICIPANTS)
      });
    case infoPagesConstants.CREATE_INFO_PAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.INFO_PAGES)
      });
    case pollsConstants.CREATE_POLL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.POLLS)
      });
    case liveDocumentsConstants.CREATE_DOCUMENTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.DOCUMENTS)
      });
    case speakersConstants.CREATE_SPEAKER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.SPEAKERS)
      });
    case agendaConstants.ADD_SESSION_SUCCESS:
    case agendaConstants.ADD_TRACK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.AGENDA)
      });
    case promotedMessagesConstants.CREATE_PROMOTED_MESSAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.PROMOTED_MESSAGES)
      });
    case fileConstants.UPLOAD_INVITEE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.PARTICIPANTS)
      });
    case sponsorsConstants.CREATE_SPONSOR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        features: getAdjustedFeatures(state.features, eventFeatureType.SPONSORS)
      });
    case eventConstants.GET_EVENT_DEVICES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDevices: true,
        error: undefined
      });
    case eventConstants.GET_EVENT_DEVICES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDevices: false,
        successDevices: true,
        error: undefined,
        devices: (_action$response = action.response) === null || _action$response === void 0 ? void 0 : _action$response.devices,
        scannerUrl: (_action$response2 = action.response) === null || _action$response2 === void 0 ? void 0 : _action$response2.scannerUrl
      });
    case eventConstants.GET_EVENT_DEVICES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingDevices: false,
        successDevices: false,
        error: action.error
      });
    case eventConstants.REMOVE_DEVICE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        successDevices: false,
        loadingDevices: true,
        error: undefined
      });
    case eventConstants.REMOVE_DEVICE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        devices: state.devices.filter(function (x) {
          return x.id !== action.deviceId;
        }),
        successDevices: true,
        loadingDevices: false,
        error: undefined
      });
    case eventConstants.REMOVE_DEVICE_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        successDevices: false,
        loadingDevices: false,
        error: action.error
      });
    case eventConstants.CREATE_PARTICIPANT_PASSWORD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreateParticipantPasswordLoading: true,
        isCreateParticipantPasswordSuccess: false,
        error: undefined
      });
    case eventConstants.CREATE_PARTICIPANT_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreateParticipantPasswordLoading: false,
        isCreateParticipantPasswordSuccess: true,
        error: undefined
      });
    case eventConstants.CREATE_PARTICIPANT_PASSWORD_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreateParticipantPasswordLoading: false,
        isCreateParticipantPasswordSuccess: false,
        error: action.response.message
      });
    case eventConstants.SAVEBADGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        formLoading: false,
        fromSuccess: false
      });
    case eventConstants.SAVEBADGE_REQUEST_SUCCESS:
      {
        var _codeKeys7 = codeKeys(action.event.form.elements),
          _encodedObjects6 = _codeKeys7.encodedObjects,
          _encodedIds6 = _codeKeys7.encodedIds;
        return _objectSpread(_objectSpread(_objectSpread({}, state), action.event), {}, {
          error: undefined,
          loading: false,
          fromSuccess: true,
          formLoading: false,
          codedForm: {
            elements: _encodedObjects6,
            updatedOn: action.event.form.updatedOn
          },
          codedFormIds: _encodedIds6
        });
      }
    case eventConstants.SAVEBADGE_REQUEST_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false,
        fromSuccess: false,
        formLoading: false
      });
    case eventConstants.UPDATE_LIVE_EVENT_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        liveEventShortUrl: action.result
      });
    default:
      return state;
  }
}
export default event;