function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var _featurePath;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import { eventFeatureType, workspaceFeatureType } from '../constants/types';
import workspaceType from '../constants/types/workspace-type-constants';
function getFeatures(event, workspace, workspaceLimits) {
  var _event$features;
  var allFeatures = event === null || event === void 0 || (_event$features = event.features) === null || _event$features === void 0 ? void 0 : _event$features.flatMap(function (x) {
    var _x$children;
    return [x].concat(_toConsumableArray((_x$children = x === null || x === void 0 ? void 0 : x.children) !== null && _x$children !== void 0 ? _x$children : []));
  });
  var featureMap = allFeatures === null || allFeatures === void 0 ? void 0 : allFeatures.reduce(function (obj, feature) {
    return _objectSpread(_objectSpread({}, obj), {}, _defineProperty({}, feature.type, allFeatures.find(function (x) {
      return x.type === feature.type;
    })));
  }, {});
  var isSelfSignupWorkspace = (workspace === null || workspace === void 0 ? void 0 : workspace.partnerWorkspaceId) === null && (workspace === null || workspace === void 0 ? void 0 : workspace.workspaceType) === workspaceType.REGULAR;
  function isVisible(featureType) {
    var enabledInWorkspace = this.isAvailable(featureType);
    if (!enabledInWorkspace) {
      return false;
    }
    var feature = this.get(featureType);
    return !feature || !(feature !== null && feature !== void 0 && feature.disabledOn);
  }
  function isHidden(featureType) {
    return !this.isVisible(featureType);
  }
  function isAvailable(featureType) {
    var _workspaceFeatureType2, _workspace$features, _workspaceFeatureType3;
    if (isSelfSignupWorkspace) {
      var _workspaceFeatureType, _workspaceLimits$limi;
      var featurePropertyName = (_workspaceFeatureType = workspaceFeatureType[featureType]) === null || _workspaceFeatureType === void 0 ? void 0 : _workspaceFeatureType.limitProp;
      var featureLimit = workspaceLimits === null || workspaceLimits === void 0 || (_workspaceLimits$limi = workspaceLimits.limits) === null || _workspaceLimits$limi === void 0 ? void 0 : _workspaceLimits$limi[featurePropertyName];
      if (featurePropertyName && !featureLimit) {
        return false;
      }
      return featureLimit !== 0;
    }
    return !((_workspaceFeatureType2 = workspaceFeatureType[featureType]) !== null && _workspaceFeatureType2 !== void 0 && _workspaceFeatureType2.featureProp) || (workspace === null || workspace === void 0 || (_workspace$features = workspace.features) === null || _workspace$features === void 0 || (_workspace$features = _workspace$features.active) === null || _workspace$features === void 0 ? void 0 : _workspace$features[(_workspaceFeatureType3 = workspaceFeatureType[featureType]) === null || _workspaceFeatureType3 === void 0 ? void 0 : _workspaceFeatureType3.featureProp]);
  }
  function isCompleted(featureType) {
    var feature = this.get(featureType);
    return !!(feature !== null && feature !== void 0 && feature.completedOn);
  }
  function get(featureType) {
    return featureMap === null || featureMap === void 0 ? void 0 : featureMap[featureType];
  }
  return {
    isVisible: isVisible,
    isHidden: isHidden,
    isCompleted: isCompleted,
    isAvailable: isAvailable,
    get: get,
    map: featureMap
  };
}
var featurePath = (_featurePath = {}, _defineProperty(_featurePath, eventFeatureType.EVENT_DETAILS, 'general'), _defineProperty(_featurePath, eventFeatureType.BRANDING, 'branding'), _defineProperty(_featurePath, eventFeatureType.REGISTRATION_FORM, 'form'), _defineProperty(_featurePath, eventFeatureType.REGISTRATION_LANDING_PAGE, 'landing-page'), _defineProperty(_featurePath, eventFeatureType.TEMPLATES, 'communication/templates/emails'), _defineProperty(_featurePath, eventFeatureType.EMAIL_SENDER, 'communication/settings'), _defineProperty(_featurePath, eventFeatureType.PARTICIPANTS, 'participants'), _defineProperty(_featurePath, eventFeatureType.VOUCHERS, 'vouchers'), _defineProperty(_featurePath, eventFeatureType.INFO_PAGES, 'info-pages'), _defineProperty(_featurePath, eventFeatureType.POLLS, 'polls'), _defineProperty(_featurePath, eventFeatureType.DOCUMENTS, 'documents'), _defineProperty(_featurePath, eventFeatureType.SPEAKERS, 'speakers'), _defineProperty(_featurePath, eventFeatureType.AGENDA, 'agenda'), _defineProperty(_featurePath, eventFeatureType.LOBBY_PAGE, 'lobby-page'), _defineProperty(_featurePath, eventFeatureType.PLATFORM_SETTINGS, 'platform-settings'), _defineProperty(_featurePath, eventFeatureType.PROMOTED_MESSAGES, 'promoted-messages'), _defineProperty(_featurePath, eventFeatureType.TIMELINE, 'timeline'), _defineProperty(_featurePath, eventFeatureType.SPONSORS, 'sponsors'), _featurePath);
export { getFeatures, featurePath };