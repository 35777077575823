function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { reservationConstants } from '../constants/actions';
export default function reservations() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case reservationConstants.GET_QUANTITY:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false,
        error: undefined,
        errors: undefined,
        message: undefined
      });
    case reservationConstants.GET_QUANTITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        quantities: _objectSpread({}, action.response),
        loading: false,
        success: true,
        error: undefined,
        errors: undefined,
        message: undefined
      });
    case reservationConstants.GET_QUANTITY_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        success: false,
        error: action.response.error,
        errors: action.response.errors,
        message: action.response.message
      });
    case reservationConstants.GET_RESERVATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        success: false,
        error: undefined,
        errors: undefined,
        message: undefined
      });
    case reservationConstants.GET_RESERVATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: [].concat(_toConsumableArray(state.items || []), _toConsumableArray(action.response || [])),
        loading: false,
        success: true,
        error: undefined
      });
    case reservationConstants.GET_RESERVATIONS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        success: false,
        error: action.response.error,
        errors: action.response.errors,
        message: action.response.message
      });
    case reservationConstants.RESERVE:
      return _objectSpread(_objectSpread({}, state), {}, {
        errors: undefined,
        error: undefined,
        message: undefined,
        loadingReserve: true,
        successReserve: false
      });
    case reservationConstants.RESERVE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: [].concat(_toConsumableArray(state.items || []), [_objectSpread({}, action.response)]),
        loadingReserve: false,
        successReserve: true
      });
    case reservationConstants.RESERVE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        errors: action.response.errors,
        error: action.response.error,
        message: action.response.message,
        loadingReserve: false,
        successReserve: false
      });
    case reservationConstants.CLEAR_RESERVATIONS:
      return {};
    default:
      return state;
  }
}