import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponse } from '../helpers/request-helpers';
var promotedMessagesService = {
  getPromotedMessages: getPromotedMessages,
  createPromotedMessage: createPromotedMessage,
  deletePromotedMessage: deletePromotedMessage,
  updatePromotedMessage: updatePromotedMessage,
  updatePartialPromotedMessage: updatePartialPromotedMessage
};
function getPromotedMessages(eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/promoted-messages"), requestOptions).then(handleResponse);
}
function createPromotedMessage(eventId, promotedMessage) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      fromDate: promotedMessage.fromDate,
      toDate: promotedMessage.toDate,
      message: promotedMessage.message,
      isHidden: promotedMessage.isHidden,
      isVisibleOnTop: promotedMessage.isVisibleOnTop,
      groupIds: promotedMessage.groupIds,
      platformPages: promotedMessage.platformPages
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/promoted-messages/create"), requestOptions).then(handleResponse);
}
function updatePromotedMessage(eventId, entryId, promotedMessage) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify({
      fromDate: promotedMessage.fromDate,
      toDate: promotedMessage.toDate,
      message: promotedMessage.message,
      isHidden: promotedMessage.isHidden,
      isVisibleOnTop: promotedMessage.isVisibleOnTop,
      groupIds: promotedMessage.groupIds,
      platformPages: promotedMessage.platformPages
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/promoted-messages/").concat(entryId), requestOptions).then(handleResponse);
}
function updatePartialPromotedMessage(eventId, entryId, promotedMessage) {
  var requestOptions = {
    method: 'PATCH',
    headers: requestHeader(),
    body: JSON.stringify({
      isHidden: promotedMessage.isHidden,
      isVisibleOnTop: promotedMessage.isVisibleOnTop
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/promoted-messages/").concat(entryId), requestOptions).then(handleResponse);
}
function deletePromotedMessage(eventId, entryId) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/promoted-messages/").concat(entryId), requestOptions).then(handleResponse);
}
export default promotedMessagesService;