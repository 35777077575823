var printConstants = {
  CREATE_BADGES: 'CREATE_BADGES',
  CREATE_BADGES_SUCCESS: 'CREATE_BADGES_SUCCESS',
  CREATE_BADGES_ERROR: 'CREATE_BADGES_ERROR',
  SAVE_PRINTERSETTINGS: 'SAVE_PRINTERSETTINGS',
  SAVE_PRINTERSETTINGS_SUCCESS: 'SAVE_PRINTERSETTINGS_SUCCESS',
  SAVE_PRINTERSETTINGS_ERROR: 'SAVE_PRINTERSETTINGS_ERROR',
  GET_PRINTER: 'GET_PRINTER',
  GET_PRINTER_SUCCESS: 'GET_PRINTER_SUCCESS',
  GET_PRINTER_ERROR: 'GET_PRINTER_ERROR'
};
export default printConstants;