var partnerConstants = {
  GETALL_REQUEST: 'PARTNERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'PARTNERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'PARTNERS_GETALL_FAILURE',
  GET_REQUEST: 'PARTNER_GET_REQUEST',
  GET_SUCCESS: 'PARTNER_GET_SUCCESS',
  GET_FAILURE: 'PARTNER_GET_FAILURE',
  CREATE_REQUEST: 'PARTNER_CREATE_REQUEST',
  CREATE_REQUEST_SUCCESS: 'PARTNER_CREATE_REQUEST_SUCCESS',
  CREATE_REQUEST_FAILURE: 'PARTNER_CREATE_REQUEST_FAILURE',
  UPDATE_REQUEST: 'PARTNER_UPDATE_REQUEST',
  UPDATE_REQUEST_SUCCESS: 'PARTNER_UPDATE_REQUEST_SUCCESS',
  UPDATE_REQUEST_FAILURE: 'PARTNER_UPDATE_REQUEST_FAILURE',
  UPDATE_USERS_REQUEST: 'PARTNER_UPDATE_USERS_REQUEST',
  UPDATE_USERS_REQUEST_SUCCESS: 'PARTNER_UPDATE_USERS_REQUEST_SUCCESS',
  UPDATE_USERS_REQUEST_FAILURE: 'PARTNER_UPDATE_USERS_REQUEST_FAILURE',
  DELETE_USER_REQUEST: 'PARTNER_DELETE_USER_REQUEST',
  DELETE_USER_REQUEST_SUCCESS: 'PARTNER_DELETE_USER_REQUEST_SUCCESS',
  DELETE_USER_REQUEST_FAILURE: 'PARTNER_DELETE_USER_REQUEST_FAILURE',
  CLEAR: 'PARTNER_CLEAR',
  CLEAR_ERRORS: 'PARTNER_CLEAR_ERRORS',
  UPDATE_FEATURES_REQUEST: 'PARTNER_UPDATE_FEATURES_REQUEST',
  UPDATE_FEATURES_SUCCESS: 'PARTNER_UPDATE_FEATURES_SUCCESS',
  UPDATE_FEATURES_FAILURE: 'PARTNER_UPDATE_FEATURES_FAILURE'
};
export default partnerConstants;