import { appIntl } from '../../IntlGlobalProvider';
var platformPage = {
  MAIN_STAGE: 0,
  LOBBY: 1,
  WEB_APP: 2
};
function getPlatformPageOptions() {
  var intl = appIntl();
  return [{
    label: intl.formatMessage({
      id: 'digital',
      defaultMessage: 'Digital'
    }),
    value: platformPage.MAIN_STAGE
  }, {
    label: intl.formatMessage({
      id: 'lobby',
      defaultMessage: 'Lobby'
    }),
    value: platformPage.LOBBY
  }, {
    label: intl.formatMessage({
      id: 'web_app',
      defaultMessage: 'Web-app'
    }),
    value: platformPage.WEB_APP
  }];
}
export default platformPage;
export { platformPage, getPlatformPageOptions };