function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { pollsConstants } from '../constants/actions';
import { pollType } from '../constants/types/poll-type-constants';
export default function polls() {
  var _state$items, _state$items2, _state$items3, _action$result2, _state$items4, _state$items5, _state$items6, _state$items7;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case pollsConstants.GET_POLLS:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        error: undefined
      });
    case pollsConstants.GET_POLLS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.GET_POLLS_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.CREATE_POLL:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        error: undefined
      });
    case pollsConstants.CREATE_POLL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.CREATE_POLL_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.UPDATE_POLL:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        error: undefined
      });
    case pollsConstants.UPDATE_POLL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items = state.items) === null || _state$items === void 0 ? void 0 : _state$items.map(function (item) {
          var _action$result;
          return (item === null || item === void 0 ? void 0 : item.id) === ((_action$result = action.result) === null || _action$result === void 0 ? void 0 : _action$result.id) ? _objectSpread({}, action.result) : item;
        }),
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.UPDATE_POLL_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.DELETE_POLL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeleting: true,
        error: undefined
      });
    case pollsConstants.DELETE_POLL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items2 = state.items) === null || _state$items2 === void 0 ? void 0 : _state$items2.filter(function (x) {
          return x.id !== action.result.entryId;
        }),
        isDeleting: false,
        error: undefined
      });
    case pollsConstants.DELETE_POLL_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeleting: false,
        error: action.error
      });
    case pollsConstants.REORDER_POLLS:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        error: undefined
      });
    case pollsConstants.REORDER_POLLS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        success: true,
        error: undefined
      });
    case pollsConstants.REORDER_POLLS_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        error: action.error
      });
    case pollsConstants.EXPORT_POLLS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: true
      });
    case pollsConstants.EXPORT_POLLS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false
      });
    case pollsConstants.EXPORT_POLSS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false
      });
    case pollsConstants.RESET_POLLS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        resetLoading: true
      });
    case pollsConstants.RESET_POLLS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items3 = state.items) === null || _state$items3 === void 0 ? void 0 : _state$items3.map(function (item) {
          if ((item === null || item === void 0 ? void 0 : item.pollType) !== pollType.OPEN_TEXT) {
            return (item === null || item === void 0 ? void 0 : item.id) === action.pollId ? _objectSpread(_objectSpread({}, item), {}, {
              votes: []
            }) : item;
          }
          return (item === null || item === void 0 ? void 0 : item.id) === action.pollId ? _objectSpread(_objectSpread({}, item), {}, {
            items: []
          }) : item;
        }),
        resetLoading: false
      });
    case pollsConstants.RESET_POLSS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        resetLoading: false
      });
    case pollsConstants.EXPORT_CODEDPOLLS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: true
      });
    case pollsConstants.EXPORT_CODEDPOLLS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false
      });
    case pollsConstants.EXPORT_CODEDPOLLS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false
      });
    case pollsConstants.EXPORT_ALLPOLLS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: true
      });
    case pollsConstants.EXPORT_ALLPOLLS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false
      });
    case pollsConstants.EXPORT_ALLPOLLS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false
      });
    case pollsConstants.GET_POLLS_STATISTICS_REQUEST:
      return {
        success: false,
        loading: true,
        error: undefined
      };
    case pollsConstants.GET_POLLS_STATISTICS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.GET_POLLS_STATISTICS_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.EXPORT_POLLS_STATISTICS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: true
      });
    case pollsConstants.EXPORT_POLLS_STATISTICS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false
      });
    case pollsConstants.EXPORT_POLLS_STATISTICS_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        exportLoading: false,
        error: action.error
      });
    case pollsConstants.CREATE_GENERIC_PASSWORD_REQUEST:
      return {
        success: false,
        loading: true,
        error: undefined
      };
    case pollsConstants.CREATE_GENERIC_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.result), {}, {
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.CREATE_GENERIC_PASSWORD_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.GET_POLLS_COUNT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        count: undefined,
        success: false,
        loading: true,
        error: undefined
      });
    case pollsConstants.GET_POLLS_COUNT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        count: (_action$result2 = action === null || action === void 0 ? void 0 : action.result) !== null && _action$result2 !== void 0 ? _action$result2 : 0,
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.GET_POLLS_COUNT_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        count: undefined,
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.GET_POLL_REGISTRATIONS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        error: undefined
      });
    case pollsConstants.GET_POLL_REGISTRATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items4 = state.items) === null || _state$items4 === void 0 ? void 0 : _state$items4.map(function (item) {
          var _action$result3;
          return (item === null || item === void 0 ? void 0 : item.id) === ((_action$result3 = action.result) === null || _action$result3 === void 0 ? void 0 : _action$result3.id) ? _objectSpread({}, action.result) : item;
        }),
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.GET_POLL_REGISTRATIONS_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.DELETE_TEMPLATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeleting: true,
        error: undefined
      });
    case pollsConstants.DELETE_TEMPLATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items5 = state.items) === null || _state$items5 === void 0 ? void 0 : _state$items5.filter(function (x) {
          return x.id !== action.result.entryId;
        }),
        isDeleting: false,
        error: undefined
      });
    case pollsConstants.DELETE_TEMPLATE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeleting: false,
        error: action.error
      });
    case pollsConstants.CREATE_TEMPLATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        error: undefined
      });
    case pollsConstants.CREATE_TEMPLATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.CREATE_TEMPLATE_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.UPDATE_TEMPLATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        error: undefined
      });
    case pollsConstants.UPDATE_TEMPLATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items6 = state.items) === null || _state$items6 === void 0 ? void 0 : _state$items6.map(function (item) {
          var _action$result4;
          return (item === null || item === void 0 ? void 0 : item.id) === ((_action$result4 = action.result) === null || _action$result4 === void 0 ? void 0 : _action$result4.id) ? _objectSpread({}, action.result) : item;
        }),
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.UPDATE_TEMPLATE_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.GET_POLL_TEMPLATE_REGISTRATIONS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: true,
        error: undefined
      });
    case pollsConstants.GET_POLL_TEMPLATE_REGISTRATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items7 = state.items) === null || _state$items7 === void 0 ? void 0 : _state$items7.map(function (item) {
          var _action$result5;
          return (item === null || item === void 0 ? void 0 : item.id) === ((_action$result5 = action.result) === null || _action$result5 === void 0 ? void 0 : _action$result5.id) ? _objectSpread({}, action.result) : item;
        }),
        success: true,
        loading: false,
        error: undefined
      });
    case pollsConstants.GET_POLL_TEMPLATE_REGISTRATIONS_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: false,
        loading: false,
        error: action.error
      });
    case pollsConstants.DELETE_ANSWER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeletingAnswer: true,
        error: undefined
      });
    case pollsConstants.DELETE_ANSWER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        success: true,
        isDeletingAnswer: false,
        error: undefined
      });
    case pollsConstants.DELETE_ANSWER_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDeletingAnswer: false,
        error: action.error
      });
    default:
      return state;
  }
}