import { GET_UPDATED_REQUEST, GET_UPDATED_SUCCESS, GET_UPDATED_ERROR, GET_UPCOMING_REQUEST, GET_UPCOMING_SUCCESS, GET_UPCOMING_ERROR, GET_NEW_EVENTS_REQUEST, GET_NEW_EVENTS_SUCCESS, GET_NEW_EVENTS_ERROR } from '../constants/actions/events-constants';
import eventsService from '../services/events-service';
function getUpdatedEvents(limit) {
  return function (dispatch) {
    dispatch(request());
    eventsService.getUpdatedEvents(limit).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: GET_UPDATED_REQUEST
    };
  }
  function success(result) {
    return {
      type: GET_UPDATED_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: GET_UPDATED_ERROR,
      error: error
    };
  }
}
function getUpcomingEvents() {
  return function (dispatch) {
    dispatch(request());
    eventsService.getUpcomingEvents().then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: GET_UPCOMING_REQUEST
    };
  }
  function success(result) {
    return {
      type: GET_UPCOMING_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: GET_UPCOMING_ERROR,
      error: error
    };
  }
}
export function getNewEvents(limit) {
  return function (dispatch) {
    dispatch(request());
    eventsService.getNewEvents(limit).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: GET_NEW_EVENTS_REQUEST
    };
  }
  function success(result) {
    return {
      type: GET_NEW_EVENTS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: GET_NEW_EVENTS_ERROR,
      error: error
    };
  }
}
export default {
  getUpdatedEvents: getUpdatedEvents,
  getUpcomingEvents: getUpcomingEvents,
  getNewEvents: getNewEvents
};