import shortid from 'shortid';
import { appIntl } from '../../../IntlGlobalProvider';
import { badgeElementType } from '../../../constants/types/badge-elements-constants';
export function getToolboxElements() {
  var intl = appIntl();
  var fullName = {
    id: 'toolBoxFullName',
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Fullname',
      defaultMessage: 'Fullname'
    }),
    icon: 'GUEST',
    element: {
      id: shortid.generate(),
      type: badgeElementType.FULLNAME,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Toolbox.Fullname',
        defaultMessage: '{{Fullname}}'
      })
    },
    mandatory: false
  };
  var qr = {
    id: 'toolBoxQr',
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Qr',
      defaultMessage: 'Qr'
    }),
    icon: 'QR',
    element: {
      id: shortid.generate(),
      type: badgeElementType.QR
      // label: 'https://localhost:44347/api/asset/qr/{{qrTicket}}',
    },

    mandatory: false
  };
  var company = {
    id: 'toolboxCompany',
    title: intl.formatMessage({
      id: 'c',
      defaultMessage: 'Company'
    }),
    icon: 'ELEMENT.COMPANY',
    element: {
      id: shortid.generate(),
      type: badgeElementType.COMPANY,
      label: intl.formatMessage({
        id: 'c',
        defaultMessage: '{{Company}}'
      })
    },
    mandatory: false
  };
  var jobtitle = {
    id: 'jobTitle',
    title: intl.formatMessage({
      id: 'EventFormBuilder.Toolbox.Jobtitle',
      defaultMessage: 'Jobtitle'
    }),
    icon: 'ELEMENT.JOBTITLE',
    element: {
      id: shortid.generate(),
      type: badgeElementType.JOBTITLE,
      label: intl.formatMessage({
        id: 'im',
        defaultMessage: '{{Jobtitle}}'
      })
    },
    mandatory: false
  };
  var badgeElements = [fullName, company, jobtitle, qr];
  return badgeElements;
}
export default getToolboxElements;