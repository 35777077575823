function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { liveDocumentsConstants } from '../constants/actions';
export default function liveDocuments() {
  var _state$items, _state$items2, _state$items3, _state$items4, _state$items5;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case liveDocumentsConstants.GET_DOCUMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: undefined,
        loading: true,
        error: undefined
      });
    case liveDocumentsConstants.GET_DOCUMENTS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.items), {}, {
        loading: false,
        error: undefined
      });
    case liveDocumentsConstants.GET_DOCUMENTS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: undefined,
        loading: false,
        error: action.error
      });
    case liveDocumentsConstants.CREATE_DOCUMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: undefined
      });
    case liveDocumentsConstants.CREATE_DOCUMENTS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.items), {}, {
        loading: false,
        error: undefined
      });
    case liveDocumentsConstants.CREATE_DOCUMENTS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error
      });
    case liveDocumentsConstants.UPDATE_DOCUMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: undefined
      });
    case liveDocumentsConstants.UPDATE_DOCUMENTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items = state.items) === null || _state$items === void 0 ? void 0 : _state$items.map(function (item) {
          var _action$document;
          return (item === null || item === void 0 ? void 0 : item.id) === ((_action$document = action.document) === null || _action$document === void 0 ? void 0 : _action$document.id) ? _objectSpread({}, action.document) : item;
        }),
        error: undefined
      });
    case liveDocumentsConstants.UPDATE_DOCUMENTS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case liveDocumentsConstants.UPDATE_DOCUMENTS_PARTIAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items2 = state.items) === null || _state$items2 === void 0 ? void 0 : _state$items2.map(function (item) {
          var _action$document2;
          return (item === null || item === void 0 ? void 0 : item.id) === ((_action$document2 = action.document) === null || _action$document2 === void 0 ? void 0 : _action$document2.id) ? _objectSpread(_objectSpread({}, item), {}, {
            updating: Object.keys(action.document.request)
          }) : item;
        }),
        error: undefined
      });
    case liveDocumentsConstants.UPDATE_DOCUMENTS_PARTIAL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items3 = state.items) === null || _state$items3 === void 0 ? void 0 : _state$items3.map(function (item) {
          var _action$document3;
          return (item === null || item === void 0 ? void 0 : item.id) === ((_action$document3 = action.document) === null || _action$document3 === void 0 ? void 0 : _action$document3.id) ? _objectSpread(_objectSpread({}, action.document), {}, {
            updating: undefined
          }) : item;
        }),
        error: undefined
      });
    case liveDocumentsConstants.UPDATE_DOCUMENTS_PARTIAL_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items4 = state.items) === null || _state$items4 === void 0 ? void 0 : _state$items4.map(function (item) {
          var _action$document4;
          return (item === null || item === void 0 ? void 0 : item.id) === ((_action$document4 = action.document) === null || _action$document4 === void 0 ? void 0 : _action$document4.id) ? _objectSpread(_objectSpread({}, item), {}, {
            updating: undefined
          }) : item;
        }),
        error: action.error
      });
    case liveDocumentsConstants.DELETE_DOCUMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: undefined
      });
    case liveDocumentsConstants.DELETE_DOCUMENTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        items: (_state$items5 = state.items) === null || _state$items5 === void 0 ? void 0 : _state$items5.filter(function (x) {
          return x.id !== action.result.documentId;
        }),
        loading: false,
        error: undefined
      });
    case liveDocumentsConstants.DELETE_DOCUMENTS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case liveDocumentsConstants.REORDER_DOCUMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        updating: true,
        error: undefined
      });
    case liveDocumentsConstants.REORDER_DOCUMENTS_SUCCESS:
      return _objectSpread(_objectSpread(_objectSpread({}, state), action.documents), {}, {
        updating: false,
        error: undefined
      });
    case liveDocumentsConstants.REORDER_DOCUMENTS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        updating: false,
        error: action.error
      });
    default:
      return state;
  }
}