function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import config from 'config/environment';
import { handleResponse, getAuthorizationHeader } from '../helpers/request-helpers';
var uploadService = {
  upload: upload,
  uploadInviteeList: uploadInviteeList,
  importEvent: importEvent
};
function upload(eventId, files, resizeTo, convertTo) {
  var data = new FormData();
  files.forEach(function (file) {
    if (file !== null && file !== void 0 && file.blob) {
      data.append('files', file.blob(), file.name());
    } else {
      data.append('files', file, file.name);
    }
  });
  if (resizeTo) {
    data.append('resizeTo', resizeTo);
  }
  if (convertTo) {
    data.append('convertTo', convertTo);
  }
  var requestOptions = {
    method: 'POST',
    headers: _objectSpread({}, getAuthorizationHeader()),
    body: data,
    keepalive: true
  };
  return fetch("".concat(config.apiUrl, "/upload/").concat(eventId, "/files"), requestOptions).then(handleFileUploadResponse);
}
function uploadInviteeList(eventId, payload) {
  var data = new FormData();
  payload.files.forEach(function (file) {
    data.append('files', file, file.name);
  });
  data.append('metadataJson', JSON.stringify(payload));
  var requestOptions = {
    method: 'POST',
    headers: _objectSpread({}, getAuthorizationHeader()),
    body: data
  };
  return fetch("".concat(config.apiUrl, "/upload/").concat(eventId, "/invitees"), requestOptions).then(handleResponse);
}
function importEvent(workspaceId, files) {
  var data = new FormData();
  files.forEach(function (file) {
    if (file !== null && file !== void 0 && file.blob) {
      data.append('files', file.blob(), file.name());
    } else {
      data.append('files', file, file.name);
    }
  });
  var requestOptions = {
    method: 'POST',
    headers: _objectSpread({}, getAuthorizationHeader()),
    body: data
  };
  return fetch("".concat(config.apiUrl, "/upload/").concat(workspaceId), requestOptions).then(handleFileUploadResponse);
}
function handleFileUploadResponse(response) {
  return handleResponse(response).then(function (data) {
    var _data$result;
    if (data !== null && data !== void 0 && (_data$result = data.result) !== null && _data$result !== void 0 && _data$result.fileNames) {
      Object.assign(data.result.fileNames, data.result.fileNames.map(function (item) {
        return "".concat(config.apiUrl, "/asset/").concat(item);
      }));
      return data.result;
    }
    return data;
  });
}
export default uploadService;