import integrationService from '../services/integration-service';
import { integrationConstants } from '../constants/actions';
var integrationActions = {
  getAllClients: getAllClients,
  getAllWorkspaces: getAllWorkspaces,
  getWorkspaceDetails: getWorkspaceDetails,
  getEventDetails: getEventDetails,
  getAllWorkspaceLinks: getAllWorkspaceLinks,
  linkClientWorkspace: linkClientWorkspace,
  getAllSyncEvents: getAllSyncEvents,
  linkEvent: linkEvent,
  getExternalEvents: getExternalEvents,
  getAllSyncRegistrations: getAllSyncRegistrations,
  linkRegistrations: linkRegistrations,
  linkRegistration: linkRegistration
};
function getAllClients(destination) {
  return function (dispatch) {
    dispatch(request());
    integrationService.getAllClients(destination).then(function (clients) {
      return dispatch(success(clients));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.GET_ALL_CLIENTS_REQUEST
    };
  }
  function success(clients) {
    return {
      type: integrationConstants.GET_ALL_CLIENTS_SUCCESS,
      clients: clients
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.GET_ALL_CLIENTS_FAILURE,
      error: error
    };
  }
}
function getAllWorkspaces() {
  return function (dispatch) {
    dispatch(request());
    integrationService.getAllWorkspaces().then(function (workspaces) {
      return dispatch(success(workspaces));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.GET_ALL_WORKSPACES_REQUEST
    };
  }
  function success(workspaces) {
    return {
      type: integrationConstants.GET_ALL_WORKSPACES_SUCCESS,
      workspaces: workspaces
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.GET_ALL_WORKSPACES_FAILURE,
      error: error
    };
  }
}
function getWorkspaceDetails(workspaceId) {
  return function (dispatch) {
    dispatch(request());
    integrationService.getWorkspaceDetails(workspaceId).then(function (data) {
      return dispatch(success(data));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.GET_WORKSPACE_DETAILS_REQUEST
    };
  }
  function success(data) {
    return {
      type: integrationConstants.GET_WORKSPACE_DETAILS_SUCCESS,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.GET_WORKSPACE_DETAILS_FAILURE,
      error: error
    };
  }
}
function getEventDetails(eventId) {
  return function (dispatch) {
    dispatch(request());
    integrationService.getEventDetails(eventId).then(function (data) {
      return dispatch(success(data));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.GET_EVENT_DETAILS_REQUEST
    };
  }
  function success(data) {
    return {
      type: integrationConstants.GET_EVENT_DETAILS_SUCCESS,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.GET_EVENT_DETAILS_FAILURE,
      error: error
    };
  }
}
function getAllWorkspaceLinks(destination) {
  return function (dispatch) {
    dispatch(request());
    integrationService.getAllWorkspaceLinks(destination).then(function (data) {
      return dispatch(success(data));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.GET_ALL_WORKSPACE_LINKS_REQUEST,
      destination: destination
    };
  }
  function success(data) {
    return {
      type: integrationConstants.GET_ALL_WORKSPACE_LINKS_SUCCESS,
      destination: destination,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.GET_ALL_WORKSPACE_LINKS_FAILURE,
      destination: destination,
      error: error
    };
  }
}
function linkClientWorkspace(destination, workspaceId, clientId, name, isUnlink, callback) {
  return function (dispatch) {
    dispatch(request());
    integrationService.linkClientWorkspace(destination, workspaceId, clientId, name, isUnlink).then(function (data) {
      dispatch(success(data));
      if (callback) callback();
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.LINK_REQUEST
    };
  }
  function success(data) {
    return {
      type: integrationConstants.LINK_SUCCESS,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.LINK_FAILURE,
      error: error
    };
  }
}
function getAllSyncEvents(destination, workspaceId) {
  return function (dispatch) {
    dispatch(request());
    integrationService.getAllSyncEvents(destination, workspaceId).then(function (data) {
      return dispatch(success(data));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.GET_EVENT_LINKS_REQUEST,
      destination: destination
    };
  }
  function success(data) {
    return {
      type: integrationConstants.GET_EVENT_LINKS_SUCCESS,
      destination: destination,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.GET_EVENT_LINKS_FAILURE,
      destination: destination,
      error: error
    };
  }
}
function linkEvent(destination, eventId, externalEventId, name, isUnlink, callback) {
  return function (dispatch) {
    dispatch(request());
    integrationService.linkEvent(destination, eventId, externalEventId, name, isUnlink).then(function (data) {
      dispatch(success(data));
      if (callback) callback();
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.LINK_EVENT_REQUEST
    };
  }
  function success(data) {
    return {
      type: integrationConstants.LINK_EVENT_SUCCESS,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.LINK_EVENT_FAILURE,
      error: error
    };
  }
}
function getExternalEvents(destination, workspaceId) {
  return function (dispatch) {
    dispatch(request());
    integrationService.getExternalEvents(destination, workspaceId).then(function (data) {
      return dispatch(success(data));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.GET_EXTERNAL_EVENTS_REQUEST
    };
  }
  function success(data) {
    return {
      type: integrationConstants.GET_EXTERNAL_EVENTS_SUCCESS,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.GET_EXTERNAL_EVENTS_FAILURE,
      error: error
    };
  }
}
function getAllSyncRegistrations(destination, eventId) {
  return function (dispatch) {
    dispatch(request());
    integrationService.getAllSyncRegistrations(destination, eventId).then(function (data) {
      return dispatch(success(data));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.GET_REGISTRATION_LINKS_REQUEST,
      destination: destination
    };
  }
  function success(data) {
    return {
      type: integrationConstants.GET_REGISTRATION_LINKS_SUCCESS,
      destination: destination,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.GET_REGISTRATION_LINKS_FAILURE,
      destination: destination,
      error: error
    };
  }
}
function linkRegistrations(destination, eventId, registrationIds, isUnlink, contextId, callback) {
  return function (dispatch) {
    dispatch(request());
    integrationService.linkRegistrations(destination, eventId, registrationIds, isUnlink, contextId).then(function (data) {
      dispatch(success(data));
      if (callback) callback();
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.LINK_REGISTRATIONS_REQUEST
    };
  }
  function success(data) {
    return {
      type: integrationConstants.LINK_REGISTRATIONS_SUCCESS,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.LINK_REGISTRATIONS_FAILURE,
      error: error
    };
  }
}
function linkRegistration(destination, eventId, registrationId, isUnlink, contextId, callback) {
  return function (dispatch) {
    dispatch(request());
    integrationService.linkRegistration(destination, eventId, registrationId, isUnlink, contextId).then(function (data) {
      dispatch(success(data));
      if (callback) callback();
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: integrationConstants.LINK_REGISTRATION_REQUEST
    };
  }
  function success(data) {
    return {
      type: integrationConstants.LINK_REGISTRATION_SUCCESS,
      data: data
    };
  }
  function failure(error) {
    return {
      type: integrationConstants.LINK_REGISTRATION_FAILURE,
      error: error
    };
  }
}
export default integrationActions;