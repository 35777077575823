import { addonsConstants } from '../constants/actions';
import addonsService from '../services/addons-service';
var addonsActions = {
  purchaseAddon: purchaseAddon,
  paymentVerificationSuccess: paymentVerificationSuccess,
  togglePopupVisible: togglePopupVisible
};
export function togglePopupVisible(isVisible) {
  return {
    type: addonsConstants.POPUP_VISIBLE,
    isVisible: isVisible
  };
}
export function paymentVerificationSuccess() {
  return {
    type: addonsConstants.PURCHASE_ADDON_SUCCESS
  };
}
export function purchaseAddon(workspaceId, addon) {
  return function (dispatch) {
    dispatch(request());
    addonsService.purchaseAddon(workspaceId, addon).then(function (result) {
      if (result.error) {
        if (result.collectUrl) {
          return dispatch(failure({
            message: result.error
          }, result.collectUrl));
        }
      }
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error, false));
    });
  };
  function request() {
    return {
      type: addonsConstants.PURCHASE_ADDON
    };
  }
  function success(result) {
    return {
      type: addonsConstants.PURCHASE_ADDON_SUCCESS,
      result: result
    };
  }
  function failure(error, additionalVerificationNeeded) {
    return {
      type: addonsConstants.PURCHASE_ADDON_ERROR,
      error: error,
      additionalVerificationNeeded: additionalVerificationNeeded
    };
  }
}
export default addonsActions;