var mapConstants = {
  INITIALIZE_MAPS: 'INITIALIZE_MAPS',
  DISPOSE_MAPS: 'DISPOSE_MAPS',
  GET_LOCATIONS_REQUEST: 'GET_LOCATIONS_REQUEST',
  GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAILURE: 'GET_LOCATIONS_FAILURE',
  GET_AUTOCOMPLETE_REQUEST: 'GET_AUTOCOMPLETE_REQUEST',
  GET_AUTOCOMPLETE_SUCCESS: 'GET_AUTOCOMPLETE_SUCCESS',
  GET_AUTOCOMPLETE_FAILURE: 'GET_AUTOCOMPLETE_FAILURE'
};
export default mapConstants;