import { getButtonsMenu } from '../helpers/buttons';
import { addAgenda } from './agenda-button';
import { addMenuButton } from './menu-button';
import { addDocuments } from './documents-button';
import { addInfoPage } from './infopage-button';
import { addParticipantList } from './participant-list-button';
import { addPoll } from './poll-button';
import { addStageSession } from './session-button';
import { addSpeaker } from './speaker-button';
import { addSponsor } from './sponsor-button';
import { addUserProfile } from './user-profile-button';
export function addButtonsMenu(editor, event) {
  var _event$liveSettings, _event$liveSettings2, _event$liveSettings3, _event$liveSettings4, _event$liveSettings5, _event$liveSettings6, _event$liveSettings7;
  editor.ui.registry.addIcon('button', '<svg width="24" height="24"><path d="M3 18a3 3 0 01-3-3V9a3 3 0 013-3h18a3 3 0 013 3v6a3 3 0 01-3 3zM2 9v6a1 1 0 001 1h18a1 1 0 001-1V9a1 1 0 00-1-1H3a1 1 0 00-1 1zm4 4a1 1 0 110-2h12a1 1 0 110 2z" /></svg>');
  addMenuButton(editor);
  addPoll(editor);
  addInfoPage(editor, event === null || event === void 0 || (_event$liveSettings = event.liveSettings) === null || _event$liveSettings === void 0 ? void 0 : _event$liveSettings.infoPages);
  addStageSession(editor, event === null || event === void 0 || (_event$liveSettings2 = event.liveSettings) === null || _event$liveSettings2 === void 0 ? void 0 : _event$liveSettings2.agenda);
  addSponsor(editor, event === null || event === void 0 || (_event$liveSettings3 = event.liveSettings) === null || _event$liveSettings3 === void 0 ? void 0 : _event$liveSettings3.sponsors);
  addSpeaker(editor, event === null || event === void 0 || (_event$liveSettings4 = event.liveSettings) === null || _event$liveSettings4 === void 0 ? void 0 : _event$liveSettings4.speakers);
  addAgenda(editor, event === null || event === void 0 || (_event$liveSettings5 = event.liveSettings) === null || _event$liveSettings5 === void 0 ? void 0 : _event$liveSettings5.agenda);
  addUserProfile(editor);
  addParticipantList(editor, event === null || event === void 0 || (_event$liveSettings6 = event.liveSettings) === null || _event$liveSettings6 === void 0 ? void 0 : _event$liveSettings6.participants);
  addDocuments(editor, event === null || event === void 0 || (_event$liveSettings7 = event.liveSettings) === null || _event$liveSettings7 === void 0 ? void 0 : _event$liveSettings7.documents);
  editor.ui.registry.addMenuButton('buttons', {
    text: 'Buttons',
    icon: 'button',
    fetch: function fetch(callback) {
      callback(getButtonsMenu(editor, event));
    }
  });
}