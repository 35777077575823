var wherebyParameter = {
  isChatEnabled: 'chat',
  isPeopleEnabled: 'people',
  isLeaveButtonEnabled: 'leaveButton',
  isRecordingEnabled: 'recording',
  isScreenShareEnabled: 'screenshare',
  isBackgroundEnabled: 'background',
  isVideoEnabled: 'video',
  isAudioEnabled: 'audio',
  isBreakoutEnabled: 'breakout'
};
export default wherebyParameter;
export { wherebyParameter };