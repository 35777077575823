import infoPagesService from '../services/info-pages-service';
import { infoPagesConstants } from '../constants/actions';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var infoPagesActions = {
  getInfoPages: getInfoPages,
  createInfoPage: createInfoPage,
  updateInfoPage: updateInfoPage,
  updatePartialInfoPage: updatePartialInfoPage,
  deleteInfoPage: deleteInfoPage,
  reorderInfoPages: reorderInfoPages,
  countInfoPages: countInfoPages
};
function getInfoPages(eventId) {
  return function (dispatch) {
    dispatch(request());
    infoPagesService.getInfoPages(eventId).then(function (infoPages) {
      return dispatch(success(infoPages));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: infoPagesConstants.GET_INFO_PAGES
    };
  }
  function success(items) {
    return {
      type: infoPagesConstants.GET_INFO_PAGES_SUCCESS,
      items: items
    };
  }
  function failure(error) {
    return {
      type: infoPagesConstants.GET_INFO_PAGES_FAILURE,
      error: error
    };
  }
}
function createInfoPage(eventId, infoPage, onContextUpdated) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(createMessage());
    infoPagesService.createInfoPage(eventId, infoPage).then(function (message) {
      dispatch(success(message));
      if (onContextUpdated) {
        onContextUpdated();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'InfoPages.create_successful',
        defaultMessage: 'Info page was successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function createMessage() {
    return {
      type: infoPagesConstants.CREATE_INFO_PAGE
    };
  }
  function success() {
    return {
      type: infoPagesConstants.CREATE_INFO_PAGE_SUCCESS,
      infoPage: infoPage
    };
  }
  function failure(error) {
    return {
      type: infoPagesConstants.CREATE_INFO_PAGE_FAILURE,
      error: error
    };
  }
}
function updateInfoPage(eventId, messageId, infoPage) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(updateMessage(infoPage));
    infoPagesService.updateInfoPage(eventId, messageId, infoPage).then(function (item) {
      dispatch(success(item));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'InfoPages.changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function updateMessage() {
    return {
      type: infoPagesConstants.UPDATE_INFO_PAGE,
      infoPage: infoPage
    };
  }
  function success(item) {
    return {
      type: infoPagesConstants.UPDATE_INFO_PAGE_SUCCESS,
      infoPage: item
    };
  }
  function failure(error) {
    return {
      type: infoPagesConstants.UPDATE_INFO_PAGE_FAILURE,
      error: error
    };
  }
}
function updatePartialInfoPage(eventId, entryId, infoPage) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(updateMessage({
      id: entryId,
      request: infoPage
    }));
    infoPagesService.updatePartialInfoPage(eventId, entryId, infoPage).then(function (item) {
      dispatch(success(item));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'InfoPages.changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      dispatch(failure(entryId, error));
      dispatch(messageActions.error(error));
    });
  };
  function updateMessage(item) {
    return {
      type: infoPagesConstants.UPDATE_INFO_PAGE_PARTIAL,
      infoPage: item
    };
  }
  function success(item) {
    return {
      type: infoPagesConstants.UPDATE_INFO_PAGE_PARTIAL_SUCCESS,
      infoPage: item
    };
  }
  function failure(error) {
    return {
      type: infoPagesConstants.UPDATE_INFO_PAGE_PARTIAL_FAILURE,
      infoPage: {
        entryId: entryId,
        error: error
      }
    };
  }
}
function deleteInfoPage(eventId, entryId) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(entryId));
    infoPagesService.deleteInfoPage(eventId, entryId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'InfoPages.delete_successful',
        defaultMessage: 'Info page was successfully deleted'
      })));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: infoPagesConstants.DELETE_INFO_PAGE,
      entryId: entryId
    };
  }
  function success(isSuccess) {
    var result = {
      isSuccess: isSuccess,
      entryId: entryId
    };
    return {
      type: infoPagesConstants.DELETE_INFO_PAGE_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: infoPagesConstants.DELETE_INFO_PAGE_FAILURE,
      error: error
    };
  }
}
function reorderInfoPages(eventId, infoPageIds, onContextUpdated) {
  return function (dispatch) {
    dispatch(createMessage());
    infoPagesService.reorderInfoPages(eventId, infoPageIds).then(function (infoPages) {
      dispatch(success(infoPages));
      if (onContextUpdated) {
        onContextUpdated();
      }
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function createMessage() {
    return {
      type: infoPagesConstants.REORDER_INFO_PAGES
    };
  }
  function success(infoPages) {
    return {
      type: infoPagesConstants.REORDER_INFO_PAGES_SUCCESS,
      infoPages: infoPages
    };
  }
  function failure(error) {
    return {
      type: infoPagesConstants.REORDER_INFO_PAGES_FAILURE,
      error: error
    };
  }
}
function countInfoPages(eventId) {
  return function (dispatch) {
    dispatch(request());
    infoPagesService.countInfoPages(eventId).then(function (infoPages) {
      return dispatch(success(infoPages));
    }, function (error) {
      dispatch(failure(error));
      dispatch(messageActions.error(error));
    });
  };
  function request() {
    return {
      type: infoPagesConstants.GET_INFO_PAGES_COUNT_REQUEST
    };
  }
  function success(items) {
    return {
      type: infoPagesConstants.GET_INFO_PAGES_COUNT_SUCCESS,
      items: items
    };
  }
  function failure(error) {
    return {
      type: infoPagesConstants.GET_INFO_PAGES_COUNT_FAILURE,
      error: error
    };
  }
}
export default infoPagesActions;