import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import { messageConstants } from '../constants/actions';
var messageActions = {
  success: success,
  error: error,
  clear: clear
};
function success(message) {
  return {
    type: messageConstants.SUCCESS,
    message: message,
    key: Date.now()
  };
}
function error(message) {
  var response = _typeof(message) === 'object' ? message : {
    message: message
  };
  return {
    type: messageConstants.ERROR,
    response: response,
    key: Date.now()
  };
}
function clear() {
  return {
    type: messageConstants.CLEAR
  };
}
export default messageActions;