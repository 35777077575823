var registrationsConstants = {
  GET_REGISTRATIONS_REQUEST: 'GET_REGISTRATIONS_REQUEST',
  GET_REGISTRATIONS_SUCCESS: 'GET_REGISTRATIONS_SUCCESS',
  GET_REGISTRATIONS_FAILURE: 'GET_REGISTRATIONS_FAILURE',
  GET_REGISTRATIONS_VOTING_POWER_REQUEST: 'GET_REGISTRATIONS_VOTING_POWER_REQUEST',
  GET_REGISTRATIONS_VOTING_POWER_SUCCESS: 'GET_REGISTRATIONS_VOTING_POWER_SUCCESS',
  GET_REGISTRATIONS_VOTING_POWER_FAILURE: 'GET_REGISTRATIONS_VOTING_POWER_FAILURE',
  GET_REGISTRATIONS_VOUCHERS_REQUEST: 'GET_REGISTRATIONS_VOUCHERS_REQUEST',
  GET_REGISTRATIONS_VOUCHERS_SUCCESS: 'GET_REGISTRATIONS_VOUCHERS_SUCCESS',
  GET_REGISTRATIONS_VOUCHERS_FAILURE: 'GET_REGISTRATIONS_VOUCHERS_FAILURE',
  GET_VOUCHERS_TRANSACTIONS_REQUEST: 'GET_VOUCHERS_TRANSACTIONS_REQUEST',
  GET_VOUCHERS_TRANSACTIONS_SUCCESS: 'GET_VOUCHERS_TRANSACTIONS_SUCCESS',
  GET_VOUCHERS_TRANSACTIONS_FAILURE: 'GET_VOUCHERS_TRANSACTIONS_FAILURE',
  EXPORT_REGISTRATIONS_VOUCHERS_REQUEST: 'EXPORT_REGISTRATIONS_VOUCHERS_REQUEST',
  EXPORT_REGISTRATIONS_VOUCHERS_SUCCESS: 'EXPORT_REGISTRATIONS_VOUCHERS_SUCCESS',
  EXPORT_REGISTRATIONS_VOUCHERS_FAILURE: 'EXPORT_REGISTRATIONS_VOUCHERS_FAILURE',
  CLEAR_VOUCHERS_TRANSACTIONS: 'CLEAR_VOUCHERS_TRANSACTIONS',
  EXPORT_REGISTRATIONS_REQUEST: 'EXPORT_REGISTRATIONS_REQUEST',
  EXPORT_REGISTRATIONS_SUCCESS: 'EXPORT_REGISTRATIONS_SUCCESS',
  EXPORT_REGISTRATIONS_FAILURE: 'EXPORT_REGISTRATIONS_FAILURE',
  EXPORT_EXAMPLE_REQUEST: 'EXPORT_EXAMPLE_REQUEST',
  EXPORT_EXAMPLE_SUCCESS: 'EXPORT_EXAMPLE_SUCCESS',
  EXPORT_EXAMPLE_FAILURE: 'EXPORT_EXAMPLE_FAILURE',
  CREATE_REGISTRATION_REQUEST: 'CREATE_REGISTRATION_REQUEST',
  CREATE_REGISTRATION_SUCCESS: 'CREATE_REGISTRATION_SUCCESS',
  CREATE_REGISTRATION_FAILURE: 'CREATE_REGISTRATION_FAILURE',
  UPDATE_REGISTRATION_REQUEST: 'UPDATE_REGISTRATION_REQUEST',
  UPDATE_REGISTRATION_SUCCESS: 'UPDATE_REGISTRATION_SUCCESS',
  UPDATE_REGISTRATION_FAILURE: 'UPDATE_REGISTRATION_FAILURE',
  BULK_EDIT_REQUEST: 'BULK_EDIT_REQUEST',
  BULK_EDIT_SUCCESS: 'BULK_EDIT_SUCCESS',
  BULK_EDIT_FAILURE: 'BULK_EDIT_FAILURE',
  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE',
  SEND_SMS_REQUEST: 'SEND_SMS_REQUEST',
  SEND_SMS_SUCCESS: 'SEND_SMS_SUCCESS',
  SEND_SMS_FAILURE: 'SEND_SMS_FAILURE',
  ADD_VOTING_POWER_REQUEST: 'ADD_VOTING_POWER_REQUEST',
  ADD_VOTING_POWER_SUCCESS: 'ADD_VOTING_POWER_SUCCESS',
  ADD_VOTING_POWER_FAILURE: 'ADD_VOTING_POWER_FAILURE',
  ADD_VOUCHERS_REQUEST: 'ADD_VOUCHERS_REQUEST',
  ADD_VOUCHERS_SUCCESS: 'ADD_VOUCHERS_SUCCESS',
  ADD_VOUCHERS_FAILURE: 'ADD_VOUCHERS_FAILURE',
  REMOVE_VOTING_POWER_REQUEST: 'REMOVE_VOTING_POWER_REQUEST',
  REMOVE_VOTING_POWER_SUCCESS: 'REMOVE_VOTING_POWER_SUCCESS',
  REMOVE_VOTING_POWER_FAILURE: 'REMOVE_VOTING_POWER_FAILURE',
  REMOVE_VOUCHERS_REQUEST: 'REMOVE_VOUCHERS_REQUEST',
  REMOVE_VOUCHERS_SUCCESS: 'REMOVE_VOUCHERS_SUCCESS',
  REMOVE_VOUCHERS_FAILURE: 'REMOVE_VOUCHERS_FAILURE',
  CREATE_SHARE_LINK_REQUEST: 'CREATE_SHARE_LINK_REQUEST',
  CREATE_SHARE_LINK_SUCCESS: 'CREATE_SHARE_LINK_SUCCESS',
  CREATE_SHARE_LINK_FAILURE: 'CREATE_SHARE_LINK_FAILURE',
  GET_SHARE_LINK_LIST_REQUEST: 'GET_SHARE_LINK_LIST_REQUEST',
  GET_SHARE_LINK_LIST_SUCCESS: 'GET_SHARE_LINK_LIST_SUCCESS',
  GET_SHARE_LINK_LIST_FAILURE: 'GET_SHARE_LINK_LIST_FAILURE',
  CLEAR_SHARED_LINK_LIST: 'CLEAR_SHARED_LINK_LIST',
  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',
  CLEAR_MESSAGES: 'CLEAR_MESSAGES',
  SAVE_GUEST_REQUEST: 'SAVE_GUEST_REQUEST',
  SAVE_GUEST_SUCCESS: 'SAVE_GUEST_SUCCESS',
  SAVE_GUEST_FAILURE: 'SAVE_GUEST_FAILURE',
  GET_SCANNED_REGISTRATION_REQUEST: 'GET_SCANNED_REGISTRATION_REQUEST',
  GET_SCANNED_REGISTRATION_SUCCESS: 'GET_SCANNED_REGISTRATION_SUCCESS',
  GET_SCANNED_REGISTRATION_ERROR: 'GET_SCANNED_REGISTRATION_ERROR'
};
export default registrationsConstants;