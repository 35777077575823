var widgetType = {
  FORM: 1,
  TITLE: 2,
  TEXT: 3,
  IMAGE: 4,
  VIDEO: 5,
  COUNTDOWN: 6,
  LOCATION: 7,
  BREAK: 8
};
export default widgetType;