function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import shortid from 'shortid';
import { appIntl } from '../../../IntlGlobalProvider';
import { dropdownType, elementsWithoutValue, elementType, getDropdownListByType, layoutType, logicAction, logicOperator, settingType, spacingTypes, titleType } from '../../../constants/types';
import registrationColumn from '../../../constants/types/registration-column-constants';
import { stripHTML } from '../../../helpers/helpers';
import styles from '../Event.scss';
function setting(options) {
  var id = options.id;
  return _objectSpread(_objectSpread({}, options), {}, {
    key: shortid.generate(),
    id: id || shortid.generate(),
    section: 'options'
  });
}
function logic(options) {
  var id = options.id;
  return _objectSpread(_objectSpread({}, options), {}, {
    key: shortid.generate(),
    id: id || shortid.generate(),
    section: 'logic'
  });
}
function choiceSetting(id, label) {
  return setting({
    refId: id,
    id: 'label',
    type: settingType.TEXT,
    name: 'Label',
    value: label,
    maxLength: 300,
    required: true
  });
}
function priceSetting(id, price) {
  var intl = appIntl();
  return setting({
    refId: id,
    id: 'price',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Price',
      defaultMessage: 'Price'
    }),
    value: price !== null && price !== void 0 ? price : '',
    maxLength: 10,
    required: true
  });
}
function quantitySetting(id, quantity) {
  var intl = appIntl();
  return setting({
    refId: id,
    id: 'quantity',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Quantity',
      defaultMessage: 'Quantity:'
    }),
    value: quantity !== null && quantity !== void 0 ? quantity : '',
    maxLength: 10,
    required: true
  });
}
function dropdownChoicesSetting(element) {
  var intl = appIntl();
  if (!element.options.type || element.options.type === dropdownType.MANUAL) {
    return [setting({
      refId: element.id,
      type: settingType.CHOICES,
      name: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Choices',
        defaultMessage: 'Choices'
      }),
      value: element.options.elements.map(function (item) {
        return [choiceSetting(item.id, item.options.label), priceSetting(item.id, item.options.price), quantitySetting(item.id, item.options.quantity)];
      }),
      required: true
    })];
  }
  return [];
}
function labelSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'label',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Label',
      defaultMessage: 'Label'
    }),
    value: element.options.label,
    maxLength: 300,
    required: true
  });
}
function hintSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'hint',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Hint',
      defaultMessage: 'Hint'
    }),
    value: element.options.hint || '',
    maxLength: 300,
    required: false,
    small: true
  });
}
function isRequiredSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'required',
    type: settingType.CHECKBOX,
    name: intl.formatMessage({
      id: 'required',
      defaultMessage: 'Required'
    }),
    value: element.options.required,
    required: false
  });
}
function isHiddenSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'isHidden',
    type: settingType.CHECKBOX,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Hidden',
      defaultMessage: 'Hidden'
    }),
    value: element.options.isHidden,
    required: false
    // advanced: true,
  });
}

function isFullWidthSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'isFullWidth',
    type: settingType.CHECKBOX,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Full_width',
      defaultMessage: 'Full width'
    }),
    value: element.options.isFullWidth,
    required: false,
    advanced: true
  });
}
function layoutSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'layout',
    type: settingType.DROPDOWN,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Display_values',
      defaultMessage: 'Display values'
    }),
    value: element.options.layout,
    options: [{
      value: layoutType.SINGLE_COLUMN,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Inline',
        defaultMessage: 'Inline'
      })
    }, {
      value: layoutType.INLINE,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Column',
        defaultMessage: 'Column'
      })
    }],
    required: true
  });
}
function maxGuestsSetting(element) {
  var _element$options$maxG;
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'maxGuests',
    type: settingType.TEXT,
    inputType: 'number',
    inputSubType: 'integer',
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Max_guests',
      defaultMessage: 'Max guests'
    }),
    value: (_element$options$maxG = element.options.maxGuests) !== null && _element$options$maxG !== void 0 ? _element$options$maxG : 1,
    maxLength: 2,
    required: true
  });
}
function groupedElementsChoicesSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    type: settingType.CHOICES,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Choices',
      defaultMessage: 'Choices'
    }),
    value: element.options.elements.map(function (item) {
      return getSettingsByType(item, element.mandatory);
    }),
    required: true
  });
}
function conditionalLogicSetting(element, elements) {
  var _element$logic;
  var intl = appIntl();
  return [logic({
    refId: element.id,
    id: 'isEnabled',
    type: settingType.CHECKBOX,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Conditional_logic',
      defaultMessage: 'Conditional logic'
    }),
    value: (_element$logic = element.logic) === null || _element$logic === void 0 ? void 0 : _element$logic.isEnabled,
    required: false,
    advanced: true
  })].concat(_toConsumableArray(getConditionalLogicRows(element, elements)));
}
function getLogicRows(element) {
  if (!element || !element.logic) {
    return [];
  }
  var _element$logic2 = element.logic,
    isEnabled = _element$logic2.isEnabled,
    components = _element$logic2.components;
  if (!isEnabled) {
    return [];
  }
  if (components !== null && components !== void 0 && components.length) {
    return components;
  }
  return [];
}
function getLogicActionSetting(elementId, component) {
  var intl = appIntl();
  return logic({
    refId: elementId,
    componentId: component.id,
    id: 'action',
    type: settingType.DROPDOWN,
    value: component.action || 0,
    options: [{
      value: logicAction.SHOW,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Show',
        defaultMessage: 'Show'
      })
    }, {
      value: logicAction.HIDE,
      label: intl.formatMessage({
        id: 'hide',
        defaultMessage: 'Hide'
      })
    }],
    required: false,
    className: styles.settings__action
  });
}
function getFormFieldOptions(elements) {
  var elementId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return elements.filter(function (item) {
    return !elementsWithoutValue.includes(item.type) && item.id !== elementId && item.id !== 'terms' && item.id !== 'guests';
  }).flatMap(function (item) {
    var _item$options, _item$options2, _item$logic;
    if (item.type === elementType.GROUPED && item !== null && item !== void 0 && (_item$options = item.options) !== null && _item$options !== void 0 && (_item$options = _item$options.elements) !== null && _item$options !== void 0 && _item$options.every(function (x) {
      return x.type === elementType.INPUT;
    })) {
      return item.options.elements.filter(function (subItem) {
        return subItem.id !== elementId;
      }).map(function (subItem) {
        var _subItem$logic;
        return {
          value: subItem.id,
          label: subItem.options.label,
          type: subItem.type,
          hasLogicDependency: !!((_subItem$logic = subItem.logic) !== null && _subItem$logic !== void 0 && _subItem$logic.isEnabled)
        };
      });
    }
    return {
      value: item.id,
      label: item.options.label,
      type: item.type,
      subElements: (_item$options2 = item.options) === null || _item$options2 === void 0 ? void 0 : _item$options2.elements,
      hasLogicDependency: !!((_item$logic = item.logic) !== null && _item$logic !== void 0 && _item$logic.isEnabled)
    };
  });
}
function getLogicFieldSetting(elementId, component, elements) {
  var otherFields = getFormFieldOptions(elements, elementId).map(function (item) {
    return {
      value: item.value,
      label: item.label
    };
  });
  return logic({
    refId: elementId,
    componentId: component.id,
    id: 'fieldId',
    type: settingType.DROPDOWN,
    value: component.fieldId,
    options: otherFields,
    required: false
  });
}
function getLogicOperatorSetting(elementId, component) {
  var intl = appIntl();
  return logic({
    refId: elementId,
    componentId: component.id,
    id: 'operator',
    type: settingType.DROPDOWN,
    value: component.operator || 0,
    options: [{
      value: logicOperator.CONTAINS,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Contains',
        defaultMessage: 'Contains'
      })
    }, {
      value: logicOperator.EQUAL,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Equal',
        defaultMessage: 'Equal'
      })
    }, {
      value: logicOperator.NOT_EQUAL,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Not equal',
        defaultMessage: 'Not equal'
      })
    }],
    required: false,
    className: styles.settings__operator
  });
}
function getLogicValueSetting(element, component, elements) {
  var intl = appIntl();
  var id = element.id;
  var targetedElement = elements.find(function (item) {
    return item.id === component.fieldId;
  });
  var valueSettingType = getLogicValueType(targetedElement);
  return logic({
    refId: id,
    componentId: component.id,
    id: 'value',
    type: valueSettingType,
    placeholder: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Value',
      defaultMessage: 'Value'
    }),
    value: component.value,
    options: getLogicValueOptions(targetedElement, valueSettingType),
    required: false,
    className: styles.settings__value
  });
}
function getLogicValueType(targetedElement) {
  var type = (targetedElement === null || targetedElement === void 0 ? void 0 : targetedElement.type) || settingType.TEXT;
  switch (type) {
    case elementType.DROPDOWN:
    case elementType.GROUPED:
      return settingType.DROPDOWN;
    default:
      return settingType.TEXT;
  }
}
function getLogicValueOptions(element, type) {
  var _element$options, _element$options2;
  if (type !== settingType.DROPDOWN) {
    return null;
  }
  var predefinedValues = getDropdownListByType(element === null || element === void 0 || (_element$options = element.options) === null || _element$options === void 0 ? void 0 : _element$options.type);
  if (predefinedValues) {
    return predefinedValues.map(function (item) {
      return {
        id: item.id,
        label: item.options.label,
        value: item.options.value
      };
    });
  }
  var options = (element === null || element === void 0 || (_element$options2 = element.options) === null || _element$options2 === void 0 ? void 0 : _element$options2.elements) && element.options.elements.flatMap(function (item) {
    return {
      id: item.id,
      value: item.options.label,
      label: item.options.label
    };
  });
  return options;
}
function getConditionalLogicRows(element, elements) {
  var intl = appIntl();
  var logicRows = getLogicRows(element);
  return logicRows.map(function (component) {
    return logic({
      refId: element.id,
      id: component.id,
      type: settingType.LOGIC_COMPONENT,
      name: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Logic',
        defaultMessage: 'Logic'
      }),
      action: getLogicActionSetting(element.id, component),
      field: getLogicFieldSetting(element.id, component, elements),
      operator: getLogicOperatorSetting(element.id, component),
      value: getLogicValueSetting(element, component, elements),
      required: true,
      advanced: true
    });
  });
}
function headingSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'type',
    type: settingType.DROPDOWN,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Size',
      defaultMessage: 'Size'
    }),
    value: element.options.type,
    options: [{
      value: titleType.H1,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Heading_1',
        defaultMessage: 'Heading 1'
      })
    }, {
      value: titleType.H2,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Heading_2',
        defaultMessage: 'Heading 2'
      })
    }, {
      value: titleType.H3,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Heading_3',
        defaultMessage: 'Heading 3'
      })
    }, {
      value: titleType.H4,
      label: intl.formatMessage({
        id: 'EventFormBuilder.Settings.Heading_4',
        defaultMessage: 'Heading 4'
      })
    }],
    required: true
  });
}
function textSetting(element) {
  var intl = appIntl();
  return setting({
    refId: element.id,
    id: 'value',
    type: settingType.TEXTAREA,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Text',
      defaultMessage: 'Text'
    }),
    value: element.options.value,
    required: true
  });
}
function fieldSelectorSetting(element, elements) {
  var intl = appIntl();
  var formFields = elements.filter(function (item) {
    return item.type !== elementType.BREAK && item.id !== element.id && item.id !== registrationColumn.terms && item.id !== registrationColumn.guests;
  }).flatMap(function (item) {
    if (item.type === elementType.GROUPED && item.options.elements.every(function (x) {
      return x.type === elementType.INPUT;
    })) {
      if (item.id === registrationColumn.nameGroup) {
        return {
          value: item.id,
          label: intl.formatMessage({
            id: 'EventFormBuilder.Settings.Full_name',
            defaultMessage: 'Full name'
          }),
          logic: item.logic,
          isDisabled: true,
          subElements: item.options.elements,
          type: item.type
        };
      }
      if (item.id === registrationColumn.emailGroup) {
        return {
          value: item.id,
          label: intl.formatMessage({
            id: 'EventFormBuilder.Settings.Email',
            defaultMessage: 'Email'
          }),
          logic: item.logic,
          isDisabled: false,
          subElements: item.options.elements,
          type: item.type
        };
      }
      if (item.id === registrationColumn.companyGroup) {
        return {
          value: item.id,
          label: intl.formatMessage({
            id: 'EventFormBuilder.Settings.Company',
            defaultMessage: 'Company'
          }),
          logic: item.logic,
          isDisabled: false,
          subElements: item.options.elements,
          type: item.type
        };
      }
    }
    return {
      value: item.id,
      label: item.options.label || stripHTML(item.options.value),
      logic: item.logic,
      isDisabled: false,
      shouldTrimLabel: !item.options.label,
      type: item.type
    };
  });
  var otherFields = formFields.map(function (item) {
    var _item$subElements, _item$logic2, _element$options$fiel;
    return {
      label: item.label,
      name: item.value,
      hasLogicDependency: ((_item$subElements = item.subElements) === null || _item$subElements === void 0 ? void 0 : _item$subElements.some(function (x) {
        var _x$logic;
        return (_x$logic = x.logic) === null || _x$logic === void 0 ? void 0 : _x$logic.isEnabled;
      })) || !!((_item$logic2 = item.logic) !== null && _item$logic2 !== void 0 && _item$logic2.isEnabled),
      isSelected: ((_element$options$fiel = element.options.fields) !== null && _element$options$fiel !== void 0 ? _element$options$fiel : []).some(function (field) {
        return field === item.value;
      }),
      isDisabled: item.isDisabled,
      subElements: item.subElements,
      shouldTrimLabel: [elementType.TEXT, elementType.TITLE].includes(item.type)
    };
  });
  return setting({
    refId: element.id,
    id: 'fields',
    type: settingType.FIELD_SELECTOR,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Guests',
      defaultMessage: 'Select fields for guests registration form'
    }),
    fields: otherFields,
    value: element.options.fields,
    required: true
  });
}
function getSliderSetting(element, id, name, maxValue, minValue) {
  return setting({
    refId: element.id,
    key: shortid.generate(),
    id: id,
    type: settingType.SLIDER,
    name: name,
    value: element.options[id],
    required: true,
    maxValue: maxValue || 60,
    minValue: minValue || 10
  });
}
function getSettingsByType(element, isParentMandatory, elements) {
  var id = element.id,
    options = element.options;
  var intl = appIntl();
  var elementNames = {
    name: intl.formatMessage({
      id: 'first_name',
      defaultMessage: 'First name'
    }),
    lastName: intl.formatMessage({
      id: 'last_name',
      defaultMessage: 'Last name'
    }),
    middleName: intl.formatMessage({
      id: 'middle_name',
      defaultMessage: 'Middle name'
    }),
    phone: intl.formatMessage({
      id: 'phone_number',
      defaultMessage: 'Phone number'
    }),
    email: intl.formatMessage({
      id: 'email_address',
      defaultMessage: 'Email address'
    }),
    confirmEmail: intl.formatMessage({
      id: 'confirm_email_address',
      defaultMessage: 'Confirm email address'
    }),
    terms: intl.formatMessage({
      id: 'privacy_statement',
      defaultMessage: 'Privacy statement'
    })
  };
  switch (element.type) {
    case elementType.TEXT:
      return [textSetting(element), isHiddenSetting(element)].concat(_toConsumableArray(conditionalLogicSetting(element, elements)));
    case elementType.TITLE:
      return [labelSetting(element), headingSetting(element), isHiddenSetting(element)].concat(_toConsumableArray(conditionalLogicSetting(element, elements)));
    case elementType.INPUT:
      if (isParentMandatory && element.id !== registrationColumn.phone) {
        if (!element.mandatory) {
          return [labelSetting(element), isHiddenSetting(element), hintSetting(element)];
        }
        return [labelSetting(element), hintSetting(element)];
      }
      return [labelSetting(element), isRequiredSetting(element), hintSetting(element), isFullWidthSetting(element), isHiddenSetting(element)].concat(_toConsumableArray(conditionalLogicSetting(element, elements)));
    case elementType.TEXTAREA:
      return [labelSetting(element), isRequiredSetting(element), hintSetting(element), isHiddenSetting(element)].concat(_toConsumableArray(conditionalLogicSetting(element, elements)));
    case elementType.CHECKBOX:
      if (element.mandatory) {
        return [];
      }
      return [choiceSetting(id, options.label), priceSetting(id, options.price), quantitySetting(id, options.quantity)];
    case elementType.RADIO:
      return [choiceSetting(id, options.label), priceSetting(id, options.price), quantitySetting(id, options.quantity)];
    case elementType.DROPDOWN:
      return [labelSetting(element), isRequiredSetting(element)].concat(_toConsumableArray(dropdownChoicesSetting(element)), [hintSetting(element), isFullWidthSetting(element), isHiddenSetting(element)], _toConsumableArray(conditionalLogicSetting(element, elements)));
    case elementType.GROUPED:
      if (options.elements.every(function (x) {
        return x.type === elementType.INPUT;
      })) {
        return options.elements.map(function (item) {
          return [setting({
            refId: item.id,
            label: element.mandatory ? elementNames[item.id] : item.options.label,
            type: settingType.SEPARATOR
          }), getSettingsByType(item, element.mandatory, elements)];
        }).flat(2);
      }
      if (element.options.elements.every(function (x) {
        return x.type === elementType.CHECKBOX;
      })) {
        return [labelSetting(element), isRequiredSetting(element), hintSetting(element), groupedElementsChoicesSetting(element), layoutSetting(element), isHiddenSetting(element)].concat(_toConsumableArray(conditionalLogicSetting(element, elements)));
      }
      if (element.options.elements.every(function (x) {
        return x.type === elementType.RADIO;
      })) {
        return [labelSetting(element), hintSetting(element), groupedElementsChoicesSetting(element), layoutSetting(element), isHiddenSetting(element)].concat(_toConsumableArray(conditionalLogicSetting(element, elements)));
      }
      return [];
    case elementType.GUESTS:
      return [labelSetting(element), maxGuestsSetting(element), fieldSelectorSetting(element, elements)];
    case elementType.SPACER:
      return [getSliderSetting(element, spacingTypes.SPACING, intl.formatMessage({
        id: 'EventFormBuilder.Settings.Slider_height',
        defaultMessage: 'Height'
      })), isHiddenSetting(element)].concat(_toConsumableArray(conditionalLogicSetting(element, elements)));
    default:
      return [];
  }
}
export default getSettingsByType;
export { getSettingsByType, getFormFieldOptions, getLogicValueOptions };