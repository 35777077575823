import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponseWithFormFields, handleResponse } from '../helpers/request-helpers';
var reservationService = {
  getAvailableQuantities: getAvailableQuantities,
  getReservations: getReservations,
  reserve: reserve,
  releaseReservations: releaseReservations,
  getReservationsStatistics: getReservationsStatistics
};
function getAvailableQuantities(topParentReferenceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/reservation/getAvailableQuantities/").concat(topParentReferenceId), requestOptions).then(handleResponseWithFormFields);
}
function getReservations(topParentReferenceId, registrationIdOrAccessKey) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/reservation/getReservations/").concat(topParentReferenceId, "/").concat(registrationIdOrAccessKey), requestOptions).then(handleResponseWithFormFields);
}
function reserve(topParentReferenceId, contextId, entityId, language, isRegistrationPage) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      topParentReferenceId: topParentReferenceId,
      contextId: contextId,
      entityId: entityId,
      language: language,
      isRegistrationPage: isRegistrationPage
    })
  };
  return fetch("".concat(config.apiUrl, "/reservation/reserve"), requestOptions).then(function (response) {
    return handleResponseWithFormFields(response, false);
  });
}
function releaseReservations(eventId, ids) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      ids: ids
    })
  };
  return fetch("".concat(config.apiUrl, "/reservation/release"), requestOptions).then(function (response) {
    return handleResponseWithFormFields(response, false);
  });
}
function getReservationsStatistics(eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/reservation/getReservationsStatistics/").concat(eventId), requestOptions).then(handleResponse);
}
export default reservationService;