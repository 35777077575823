import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { eventPageType } from '../constants/types/event-page-type-constants';
function getLiveEventUrl(event) {
  var _event$slug;
  return (event === null || event === void 0 ? void 0 : event.liveEventShortUrl) || config.liveEventUrl.replace('{eventIdOrSlug}', (_event$slug = event.slug) !== null && _event$slug !== void 0 ? _event$slug : event.id);
}
function getIframeUrl(event, path) {
  var _event$slug2;
  var liveEventUrl = config.liveEventUrl.replace('{eventIdOrSlug}', (_event$slug2 = event.slug) !== null && _event$slug2 !== void 0 ? _event$slug2 : event.id);
  return "".concat(liveEventUrl, "/").concat(path);
}
function getParticipantsShareLinkUrl(event, pinCode) {
  var _event$slug3;
  var liveEventUrl = config.liveEventUrl.replace('{eventIdOrSlug}', (_event$slug3 = event.slug) !== null && _event$slug3 !== void 0 ? _event$slug3 : event.id);
  return "".concat(liveEventUrl, "/").concat(eventPageType.PARTICIPANTS, "/").concat(pinCode);
}
export { getLiveEventUrl, getParticipantsShareLinkUrl, getIframeUrl };