function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import { elementType } from '../../../constants/types';
import { appIntl } from '../../../IntlGlobalProvider';
function validateItem(item) {
  var type = item.type,
    options = item.options;
  var errors = [];
  var intl = appIntl();
  switch (type) {
    case elementType.TEXT:
      if (!options.value) {
        errors.push({
          elementId: item.id,
          optionId: 'value',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.text_required',
            defaultMessage: 'Text is required'
          })
        });
      }
      break;
    case elementType.GROUPED:
      if (options.elements.every(function (x) {
        return x.type !== elementType.INPUT;
      })) {
        if (!options.label) {
          errors.push({
            elementId: item.id,
            optionId: 'label',
            message: intl.formatMessage({
              id: 'EventFormBuilder.Validation.label_required',
              defaultMessage: 'Label is required'
            })
          });
        }
        errors.push.apply(errors, _toConsumableArray(validateDuplicates(options.elements)));
      }
      errors.push.apply(errors, _toConsumableArray(options.elements.flatMap(validateItem)));
      break;
    case elementType.DROPDOWN:
      if (!options.label) {
        errors.push({
          elementId: item.id,
          optionId: 'label',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.label_required',
            defaultMessage: 'Label is required'
          })
        });
      }
      errors.push.apply(errors, _toConsumableArray(validateDuplicates(options.elements)));
      errors.push.apply(errors, _toConsumableArray(options.elements.flatMap(validateDropdownItem)));
      break;
    case elementType.CHECKBOX:
    case elementType.RADIO:
      if (!options.label) {
        errors.push({
          elementId: item.id,
          optionId: 'label',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.label_required',
            defaultMessage: 'Label is required'
          })
        });
      }
      if (options.price && !"".concat(options.price).match(/^\d*((\.|,)\d{0,2})?$/)) {
        errors.push({
          elementId: item.id,
          optionId: 'price',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.price_decimal',
            defaultMessage: 'Price must be integer or number with 2 decimal places'
          })
        });
      }
      if (options.quantity && !"".concat(options.quantity).match(/^\d*$/)) {
        errors.push({
          elementId: item.id,
          optionId: 'quantity',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.quantity_integer',
            defaultMessage: 'Quantity must be integer'
          })
        });
      }
      if (options.quantity && options.quantity.toString().length > 9) {
        errors.push({
          elementId: item.id,
          optionId: 'quantity',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.quantity_integer_max_length',
            defaultMessage: 'Quantity must be lower than 9 digits'
          })
        });
      }
      break;
    case elementType.GUESTS:
      if (!options.label) {
        errors.push({
          elementId: item.id,
          optionId: 'label',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.label_required',
            defaultMessage: 'Label is required'
          })
        });
      }
      if (!options.maxGuests) {
        errors.push({
          elementId: item.id,
          optionId: 'maxGuests',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.max_guests_required',
            defaultMessage: 'Max guests is required'
          })
        });
      } else if (!/^\d+$/.test(options.maxGuests) || parseInt(options.maxGuests, 10) < 1) {
        errors.push({
          elementId: item.id,
          optionId: 'maxGuests',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.max_guests_integer',
            defaultMessage: 'Max guests must be more than 0 '
          })
        });
      } else if (parseInt(options.maxGuests, 10) > 99) {
        errors.push({
          elementId: item.id,
          optionId: 'maxGuests',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.max_guests_integer_less',
            defaultMessage: 'Max guests must be less than 100 '
          })
        });
      }
      break;
    default:
      if (!options.label) {
        errors.push({
          elementId: item.id,
          optionId: 'label',
          message: intl.formatMessage({
            id: 'EventFormBuilder.Validation.label_required',
            defaultMessage: 'Label is required'
          })
        });
      }
      break;
  }
  return errors;
}
function validateDropdownItem(item) {
  var options = item.options;
  var errors = [];
  var intl = appIntl();
  if (!options.label) {
    errors.push({
      elementId: item.id,
      optionId: 'label',
      message: intl.formatMessage({
        id: 'EventFormBuilder.Validation.label_required',
        defaultMessage: 'Label is required'
      })
    });
  }
  if (options.price && !"".concat(options.price).match(/^\d*((\.|,)\d{0,2})?$/)) {
    errors.push({
      elementId: item.id,
      optionId: 'price',
      message: intl.formatMessage({
        id: 'EventFormBuilder.Validation.price_decimal',
        defaultMessage: 'Price must be integer or number with 2 decimal places'
      })
    });
  }
  if (options.quantity && !"".concat(options.quantity).match(/^\d*$/)) {
    errors.push({
      elementId: item.id,
      optionId: 'quantity',
      message: intl.formatMessage({
        id: 'EventFormBuilder.Validation.quantity_integer',
        defaultMessage: 'Quantity must be integer'
      })
    });
  }
  if (options.quantity && options.quantity.toString().length > 9) {
    errors.push({
      elementId: item.id,
      optionId: 'quantity',
      message: intl.formatMessage({
        id: 'EventFormBuilder.Validation.quantity_integer_max_length',
        defaultMessage: 'Quantity must be lower than 9 digits'
      })
    });
  }
  return errors;
}
var findDuplicates = function findDuplicates(arr) {
  var groups = arr.map(function (name) {
    return {
      count: 1,
      name: name
    };
  }).reduce(function (a, b) {
    a[b.name] = (a[b.name] || 0) + b.count;
    return a;
  }, {});
  return Object.keys(groups).filter(function (a) {
    return groups[a] > 1;
  });
};
function validateDuplicates(items) {
  var intl = appIntl();
  var normalizedItems = items.map(function (x) {
    var _x$options$label;
    return {
      id: x.id,
      label: (_x$options$label = x.options.label) === null || _x$options$label === void 0 || (_x$options$label = _x$options$label.trim()) === null || _x$options$label === void 0 ? void 0 : _x$options$label.toLowerCase()
    };
  });
  var duplicates = findDuplicates(normalizedItems.map(function (item) {
    return item.label;
  }));
  return duplicates.map(function (label) {
    var _normalizedItems$find;
    return {
      elementId: (_normalizedItems$find = normalizedItems.find(function (x) {
        return x.label === label;
      })) === null || _normalizedItems$find === void 0 ? void 0 : _normalizedItems$find.id,
      optionId: 'label',
      message: intl.formatMessage({
        id: 'identical_choices',
        defaultMessage: 'Choices cannot be identical'
      })
    };
  });
}
export default validateItem;