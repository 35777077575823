import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import config from 'config/environment';
import { handleResponse, requestHeader } from '../helpers/request-helpers';
var videosService = {
  getVideos: getVideos,
  createVideo: createVideo,
  updateVideo: updateVideo,
  deleteVideo: deleteVideo,
  getSingleVideo: getSingleVideo
};
function getVideos(eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/videos"), requestOptions).then(handleResponse);
}
function getSingleVideo(eventId, videoId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/videos/").concat(videoId), requestOptions).then(handleResponse);
}
function createVideo(eventId, video) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      videoUrl: video.videoUrl,
      name: video.name,
      description: video.description
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/videos"), requestOptions).then(handleResponse);
}
function updateVideo(idVideo, video, eventId) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify({
      name: video.name,
      description: video.description
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/videos/").concat(idVideo), requestOptions).then(handleResponse);
}
function deleteVideo(eventId, idVideo) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/videos/").concat(idVideo), requestOptions).then(handleResponse);
}
export default videosService;