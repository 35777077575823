function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import _ from 'lodash';
import shortid from 'shortid';
import { appIntl } from '../../../IntlGlobalProvider';
import { settingType, moduleType, selectionType, eventFeatureType } from '../../../constants/types';
function label(module) {
  var isRequired = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'label',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'LobbyPageBuilder.Settings.Label',
      defaultMessage: 'Label'
    }),
    value: module.options.label,
    maxLength: 300,
    required: isRequired
  };
}
function textArea(module, customToolbar) {
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'text',
    type: settingType.TEXTAREA,
    name: intl.formatMessage({
      id: 'LobbyPageBuilder.Settings.Text',
      defaultMessage: 'Text'
    }),
    value: module.options.text,
    required: false,
    options: {
      customToolbar: customToolbar,
      fixedFontSize: true,
      availableFontSizes: '5pt 7pt 9pt 12pt 16pt 21pt 28pt 37pt 50pt 67pt 89pt 119pt 159pt'
    }
  };
}
function isMainStageEnabled(module) {
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'mainStageEnabled',
    type: settingType.CHECKBOX,
    name: intl.formatMessage({
      id: 'LobbyBuilder.Settings.Main_stage',
      defaultMessage: 'Main stage'
    }),
    value: module.options.mainStageEnabled,
    required: false
  };
}
function isParticipantsEnabled(module) {
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'participantsEnabled',
    type: settingType.CHECKBOX,
    name: intl.formatMessage({
      id: 'LobbyBuilder.Settings.Participants',
      defaultMessage: 'Participants'
    }),
    value: module.options.participantsEnabled,
    required: false
  };
}
function isSearchEnabled(module) {
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'isSearchEnabled',
    type: settingType.CHECKBOX,
    name: intl.formatMessage({
      id: 'LobbyBuilder.Settings.Filters',
      defaultMessage: 'Filters'
    }),
    value: module.options.isSearchEnabled,
    required: false
  };
}
function datesEnabled(module) {
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'isDatesEnabled',
    type: settingType.CHECKBOX,
    name: intl.formatMessage({
      id: 'LobbyBuilder.Settings.Show_dates',
      defaultMessage: 'Show dates'
    }),
    value: module.options.isDatesEnabled,
    required: false
  };
}
function fileUpload(module) {
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'fileUrl',
    type: settingType.FILE,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.File',
      defaultMessage: 'File'
    }),
    value: module.options.fileUrl,
    required: true
  };
}
function mainStageLabel(module) {
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'mainStageLabel',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'LobbyPageBuilder.Settings.Main_stage_label',
      defaultMessage: 'Main stage label'
    }),
    value: module.options.mainStageLabel,
    maxLength: 300,
    required: true
  };
}
function participantsLabel(module) {
  var intl = appIntl();
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'participantsLabel',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'LobbyPageBuilder.Settings.Participants_label',
      defaultMessage: 'Participants label'
    }),
    value: module.options.participantsLabel,
    maxLength: 300,
    required: true
  };
}
function topSponsorsSeparator(module) {
  var intl = appIntl();
  return {
    refId: module.id,
    id: shortid.generate(),
    key: shortid.generate(),
    type: settingType.SEPARATOR,
    label: intl.formatMessage({
      id: 'LobbyPageBuilder.Settings.Top_sponsors_label',
      defaultMessage: 'Sponsors'
    })
  };
}
function editLink(module, buttonName, path) {
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'editLink',
    path: path,
    type: settingType.EDIT_LINK,
    name: buttonName,
    value: module.options.participantsLabel,
    maxLength: 300,
    required: true
  };
}
function sponsorChoices(module, optionsPath) {
  var _options$elements;
  var intl = appIntl();
  var options = _.get(module, optionsPath);
  var elements = (_options$elements = options === null || options === void 0 ? void 0 : options.elements) !== null && _options$elements !== void 0 ? _options$elements : [];
  if (elements.length === 0 || elements[elements.length - 1].sponsorId) {
    elements.push({
      id: shortid.generate()
    });
    _.set(module, "".concat(optionsPath, ".elements"), elements);
  }
  return {
    refId: module.id,
    key: shortid.generate(),
    id: 'choices',
    type: settingType.CHOICES,
    selectionType: selectionType.SPONSOR,
    name: intl.formatMessage({
      id: 'EventFormBuilder.Settings.Choices',
      defaultMessage: 'Choices'
    }),
    optionsPath: optionsPath,
    value: elements.map(function (element) {
      return {
        refId: module.id,
        key: element.id,
        value: element.sponsorId
      };
    }),
    required: true
  };
}
function slider(module, optionsPath, name, maxValue) {
  return {
    refId: module.id,
    key: shortid.generate(),
    id: optionsPath,
    type: settingType.SLIDER,
    name: name,
    value: _.get(module, optionsPath) || 100,
    required: true,
    minValue: 60,
    maxValue: maxValue || 100,
    optionsPath: optionsPath
  };
}
function getSettingsByType(module, features) {
  var intl = appIntl();
  switch (module.type) {
    case moduleType.HEADER:
      return [isMainStageEnabled(module), mainStageLabel(module), isParticipantsEnabled(module), participantsLabel(module)];
    case moduleType.BANNER:
      return bannerSettings();
    case moduleType.INFO_PAGE:
      return [label(module), textArea(module), editLink(module, intl.formatMessage({
        id: 'LobbyPageBuilder.settings.Info_page_edit_link',
        defaultMessage: 'Edit info pages'
      }), './info-pages')];
    case moduleType.SPEAKER:
      return [label(module), isSearchEnabled(module), editLink(module, intl.formatMessage({
        id: 'LobbyPageBuilder.settings.Speakers_edit_link',
        defaultMessage: 'Edit speakers'
      }), './speakers')];
    case moduleType.AGENDA:
      return [label(module), editLink(module, intl.formatMessage({
        id: 'LobbyPageBuilder.settings.Agenda_edit_link',
        defaultMessage: 'Edit agenda'
      }), './agenda')];
    case moduleType.SPONSOR:
      return [label(module), isSearchEnabled(module), editLink(module, intl.formatMessage({
        id: 'LobbyPageBuilder.settings.Sponsor_edit_link',
        defaultMessage: 'Edit sponsor'
      }), './sponsors')];
    case moduleType.TOP_SPONSORS:
      return [label(module, false), slider(module, 'options.size', intl.formatMessage({
        id: 'LobbyPageBuilder.Settings.Slider_size',
        defaultMessage: 'Size'
      })), sponsorChoices(module, 'options'), editLink(module, intl.formatMessage({
        id: 'LobbyPageBuilder.settings.Sponsor_edit_link',
        defaultMessage: 'Edit sponsor'
      }), './sponsors')];
    default:
      return [];
  }
  function bannerSettings() {
    var topSponsorSettings = features.isVisible(eventFeatureType.SPONSORS) && [topSponsorsSeparator(module), slider(module, 'options.topSponsorOptions.size', intl.formatMessage({
      id: 'LobbyPageBuilder.Settings.Slider_size',
      defaultMessage: 'Size'
    })), sponsorChoices(module, 'options.topSponsorOptions')] || [];
    return [fileUpload(module), textArea(module, ['bold italic underline | alignleft aligncenter alignright', 'forecolor backcolor fontselect', 'fontsizeselect']), datesEnabled(module)].concat(_toConsumableArray(topSponsorSettings));
  }
}
export default getSettingsByType;
export { getSettingsByType };