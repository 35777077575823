import "core-js/modules/es.string.link.js";
import shortid from 'shortid';
import { appIntl } from '../../../IntlGlobalProvider';
import { widgetType, settingType, titleType, spacingTypes, borderTypes, colorTypeConstants } from '../../../constants/types';
function getLabelSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'label',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Label',
      defaultMessage: 'Label'
    }),
    value: widget.options.label,
    maxLength: 300,
    required: true
  };
}
function getAltTextSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'value',
    type: settingType.TEXTAREA,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.AltText',
      defaultMessage: 'Alternative (Alt) Text'
    }),
    value: widget.options.value,
    required: false
  };
}
function getSeparatorSetting(widget) {
  return {
    key: shortid.generate(),
    id: 'separator',
    label: widget,
    type: settingType.SEPARATOR
  };
}
function getTextareaSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'value',
    type: settingType.TEXTAREA,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Text',
      defaultMessage: 'Text'
    }),
    value: widget.options.value,
    required: true
  };
}
function getHeadingSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'type',
    type: settingType.DROPDOWN,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Size',
      defaultMessage: 'Size'
    }),
    value: widget.options.type,
    options: [{
      value: titleType.H1,
      label: intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Heading_1',
        defaultMessage: 'Heading 1'
      })
    }, {
      value: titleType.H2,
      label: intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Heading_2',
        defaultMessage: 'Heading 2'
      })
    }, {
      value: titleType.H3,
      label: intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Heading_3',
        defaultMessage: 'Heading 3'
      })
    }, {
      value: titleType.H4,
      label: intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Heading_4',
        defaultMessage: 'Heading 4'
      })
    }],
    required: true,
    inline: true
  };
}
function getColorSetting(widget, id, name) {
  var _widget$options;
  var defaultValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '#000000';
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: id !== null && id !== void 0 ? id : 'color',
    type: settingType.COLOR,
    name: name !== null && name !== void 0 ? name : intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Color',
      defaultMessage: 'Color'
    }),
    value: (_widget$options = widget.options[id !== null && id !== void 0 ? id : 'color']) !== null && _widget$options !== void 0 ? _widget$options : defaultValue,
    required: true
  };
}
function getAlignSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'align',
    type: settingType.ALIGN,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Align',
      defaultMessage: 'Align'
    }),
    value: widget.options.align,
    required: true
  };
}
function getSliderSetting(widget, id, name, maxValue) {
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: id,
    type: settingType.SLIDER,
    name: name,
    value: widget.options[id],
    required: true,
    maxValue: maxValue || 100
  };
}
function getLinkSetting(widget, required) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'link',
    type: settingType.LINK,
    placeholder: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Link_placeholder',
      defaultMessage: 'e.g. https://www.google.no'
    }),
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Link',
      defaultMessage: 'Link'
    }),
    value: widget.options.link,
    required: required
  };
}
function getVideoSetting(widget, required) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'link',
    type: settingType.TEXT,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Video_url',
      defaultMessage: 'Video URL'
    }),
    hint: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Video_hint',
      defaultMessage: 'Use Youtube or Vimeo links'
    }),
    value: widget.options.link,
    required: required
  };
}
function getDateSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'date',
    type: settingType.DATE,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Countdown',
      defaultMessage: 'Countdown to date, time'
    }),
    value: widget.options.date,
    required: true
  };
}
function getLocationSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'location',
    type: settingType.LOCATION,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Location',
      defaultMessage: 'Location'
    }),
    value: widget.options.location,
    required: true
  };
}
function getFileUploadSetting(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    id: 'fileUrl',
    type: settingType.FILE,
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.File',
      defaultMessage: 'File'
    }),
    value: widget.options.fileUrl,
    required: true
  };
}
function getFormEdit(widget) {
  var intl = appIntl();
  return {
    refId: widget.id,
    key: shortid.generate(),
    type: settingType.FORM,
    id: 'editForm',
    name: intl.formatMessage({
      id: 'LandingPageBuilder.Settings.Edit_form',
      defaultMessage: 'Edit form'
    })
  };
}
function getSettingsByType(widget) {
  var intl = appIntl();
  switch (widget.type) {
    case widgetType.TEXT:
      return [getTextareaSetting(widget), getSeparatorSetting(), getColorSetting(widget, colorTypeConstants.TEXT_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Text_color',
        defaultMessage: 'Text color'
      })), getColorSetting(widget, colorTypeConstants.BACKGROUND_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Background_color',
        defaultMessage: 'Background color'
      }), '#ffffff'), getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Spacing',
        defaultMessage: 'Spacing'
      })), getSliderSetting(widget, spacingTypes.TOP_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_top',
        defaultMessage: 'Top'
      })), getSliderSetting(widget, spacingTypes.BOTTOM_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_bottom',
        defaultMessage: 'Bottom'
      }))];
    case widgetType.TITLE:
      return [getLabelSetting(widget), getLinkSetting(widget, false), getHeadingSetting(widget), getAlignSetting(widget), getSeparatorSetting(), getColorSetting(widget), getColorSetting(widget, colorTypeConstants.BACKGROUND_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Background_color',
        defaultMessage: 'Background color'
      }), '#ffffff'), getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Spacing',
        defaultMessage: 'Spacing'
      })), getSliderSetting(widget, spacingTypes.TOP_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_top',
        defaultMessage: 'Top'
      })), getSliderSetting(widget, spacingTypes.BOTTOM_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_bottom',
        defaultMessage: 'Bottom'
      }))];
    case widgetType.IMAGE:
      return [getFileUploadSetting(widget), getAltTextSetting(widget), getSeparatorSetting(), getLinkSetting(widget, false), getAlignSetting(widget), getSeparatorSetting(), getColorSetting(widget, colorTypeConstants.BACKGROUND_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Background_color',
        defaultMessage: 'Background color'
      }), '#ffffff'), getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Spacing',
        defaultMessage: 'Spacing'
      })), getSliderSetting(widget, spacingTypes.TOP_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_top',
        defaultMessage: 'Top'
      })), getSliderSetting(widget, spacingTypes.BOTTOM_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_bottom',
        defaultMessage: 'Bottom'
      }))];
    case widgetType.VIDEO:
      return [getVideoSetting(widget, true), getAltTextSetting(widget), getSeparatorSetting(), getColorSetting(widget, colorTypeConstants.BACKGROUND_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Background_color',
        defaultMessage: 'Background color'
      }), '#ffffff'), getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Spacing',
        defaultMessage: 'Spacing'
      })), getSliderSetting(widget, spacingTypes.TOP_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_top',
        defaultMessage: 'Top'
      })), getSliderSetting(widget, spacingTypes.BOTTOM_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_bottom',
        defaultMessage: 'Bottom'
      }))];
    case widgetType.COUNTDOWN:
      return [getDateSetting(widget), getSeparatorSetting(), getColorSetting(widget), getColorSetting(widget, colorTypeConstants.BACKGROUND_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Background_color',
        defaultMessage: 'Background color'
      }), '#ffffff'), getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Spacing',
        defaultMessage: 'Spacing'
      })), getSliderSetting(widget, spacingTypes.TOP_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_top',
        defaultMessage: 'Top'
      })), getSliderSetting(widget, spacingTypes.BOTTOM_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_bottom',
        defaultMessage: 'Bottom'
      }))];
    case widgetType.LOCATION:
      return [getLocationSetting(widget), getSeparatorSetting(), getColorSetting(widget, colorTypeConstants.BACKGROUND_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Background_color',
        defaultMessage: 'Background color'
      }), '#ffffff'), getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Spacing',
        defaultMessage: 'Spacing'
      })), getSliderSetting(widget, spacingTypes.TOP_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_top',
        defaultMessage: 'Top'
      })), getSliderSetting(widget, spacingTypes.BOTTOM_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_bottom',
        defaultMessage: 'Bottom'
      }))];
    case widgetType.FORM:
      return [getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Button',
        defaultMessage: 'Button'
      })), getColorSetting(widget, colorTypeConstants.BUTTON_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Color',
        defaultMessage: 'Color'
      }), '#00ccc2'), getColorSetting(widget, colorTypeConstants.BUTTON_TEXT_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Text_color',
        defaultMessage: 'Text color'
      }), '#ffffff'), getSliderSetting(widget, borderTypes.BUTTON_BORDER, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Rounded_corners',
        defaultMessage: 'Rounded corners'
      }), 18), getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Form',
        defaultMessage: 'Form'
      })), getColorSetting(widget, colorTypeConstants.TEXT_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Text_color',
        defaultMessage: 'Text color'
      })), getColorSetting(widget, colorTypeConstants.BACKGROUND_COLOR, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Background_color',
        defaultMessage: 'Background color'
      }), '#ffffff'), getFormEdit(widget), getSeparatorSetting(intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Spacing',
        defaultMessage: 'Spacing'
      })), getSliderSetting(widget, spacingTypes.TOP_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_top',
        defaultMessage: 'Top'
      })), getSliderSetting(widget, spacingTypes.BOTTOM_SPACING, intl.formatMessage({
        id: 'LandingPageBuilder.Settings.Slider_bottom',
        defaultMessage: 'Bottom'
      }))];
    default:
      return [];
  }
}
export default getSettingsByType;