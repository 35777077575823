import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-plusplus */

var pusherInstance = null;
export var getPusherInstance = function getPusherInstance(metadata) {
  // Null object that will allow the consumers of this
  // function to act as if it worked, even if Pusher is not available here.
  var pusherFake = {
    bind: function bind(event) {
      console.log("Pusher: Fake called with bind with event: ".concat(event));
    },
    subscribe: function subscribe(channel) {
      console.log("Pusher: Fake called with subscribe on channel: ".concat(channel));
    }
  };
  if (!metadata.isReady) {
    console.log('Pusher: Used getPusher in utils.js before Redux store has loaded full event information');
    return pusherFake;
  }
  if (!pusherInstance) {
    pusherInstance = createWebsocket(metadata.event, null, metadata.currentUser);
  }
  return pusherInstance;
};
function createWebsocket(currentEvent, prevInstance, user) {
  var topicId = "admin-event-".concat(currentEvent.id);
  var socket = new WebSocket("".concat(currentEvent.notificationEndpoint, "?topicId=").concat(topicId));
  var topics = prevInstance ? prevInstance.topics : {};
  var subUid = prevInstance ? prevInstance.subUid : -1;
  if (prevInstance) {
    clearInterval(prevInstance.heartbeat);
  }
  var heartbeat = setInterval(function () {
    if (pusherInstance.socket.readyState === WebSocket.OPEN) {
      pusherInstance.socket.send(JSON.stringify({
        message: 'ping'
      }));
    }
  }, 540000);
  pusherInstance = {
    socket: socket,
    topics: topics,
    subUid: subUid,
    heartbeat: heartbeat,
    currentEvent: currentEvent,
    checkConnection: undefined,
    retryCount: 0,
    bind: function bind(topic, callback) {
      var _topics$topic;
      if (((_topics$topic = topics[topic]) === null || _topics$topic === void 0 ? void 0 : _topics$topic.length) > 0) {
        return null;
      }
      if (!topics[topic]) {
        topics[topic] = [];
      }
      var token = (++subUid).toString();
      topics[topic].push({
        token: token,
        func: callback
      });
      return token;
    },
    unbind: function unbind(tokens) {
      tokens.forEach(function (item, index) {
        topics[item.topic] = topics[item.topic].filter(function (x) {
          return x.token !== item.token;
        });
      });
      return null;
    }
  };
  socket.onopen = function (e) {
    console.log('[open] connected to wss');
    var channelName = "admin-event-".concat(currentEvent.id);
    socket.send(JSON.stringify({
      action: 'SUBSCRIBE',
      topicId: channelName,
      userId: user === null || user === void 0 ? void 0 : user.id
    }));
  };
  socket.onclose = function (event) {
    if (event.wasClean) {
      console.log("[close] Connection closed cleanly, code=".concat(event.code, " reason=").concat(event.reason));
      console.log('[close] Trying to reconnect...');
    } else {
      // e.g. server process killed or network down
      // event.code is usually 1006 in this case
      console.log('[close] Connection died');
    }

    // AWS 2 hour termination sends code 1001 - Going away
    if (event.code !== 1001) {
      pusherInstance.retryCount += 1;
    }
    if (pusherInstance.retryCount < 5 && event.code !== 1000) {
      var newInstance = createWebsocket(currentEvent, pusherInstance);
      pusherInstance.socket = newInstance.socket;
    }
    if (event.code === 1000) {
      pusherInstance = null;
    }
  };
  socket.onerror = function (error) {
    console.log("[error] ".concat(error));
  };
  socket.onmessage = function (message) {
    var data = JSON.parse(message.data);
    var topic = data.action;
    if (!pusherInstance.topics[topic]) {
      return false;
    }
    setTimeout(function () {
      var subscribers = pusherInstance.topics[topic];
      var len = subscribers ? subscribers.length : 0;
      while (len--) {
        subscribers[len].func(topic, data);
      }
    }, 0);
    pusherInstance.retryCount = 0;
    return true;
  };
  document.addEventListener('visibilitychange', function () {
    if (!document.hidden) {
      if (pusherInstance.socket.readyState === WebSocket.CLOSED) {
        var newInstance = createWebsocket(currentEvent, pusherInstance);
        pusherInstance.socket = newInstance.socket;
      }
    }
  });
  return pusherInstance;
}