import shortid from 'shortid';
import { widgetType, titleType, alignType } from '../../../constants/types';
import { appIntl } from '../../../IntlGlobalProvider';
export function getToolboxWidgets() {
  var intl = appIntl();
  var title = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'LandingPageBuilder.Toolbox.Title',
      defaultMessage: 'Title'
    }),
    icon: 'WIDGET.TITLE',
    widget: {
      id: shortid.generate(),
      type: widgetType.TITLE,
      options: {
        label: intl.formatMessage({
          id: 'LandingPageBuilder.Toolbox.Title_label',
          defaultMessage: 'Title'
        }),
        type: titleType.H1,
        color: '#000',
        align: alignType.LEFT,
        topSpacing: 0,
        bottomSpacing: 0
      }
    },
    mandatory: false
  };
  var text = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'LandingPageBuilder.Toolbox.Text',
      defaultMessage: 'Text'
    }),
    icon: 'WIDGET.TEXT',
    widget: {
      id: shortid.generate(),
      type: widgetType.TEXT,
      options: {
        label: intl.formatMessage({
          id: 'LandingPageBuilder.Toolbox.Text_label',
          defaultMessage: 'Text'
        }),
        value: intl.formatMessage({
          id: 'LandingPageBuilder.Toolbox.Text_value',
          defaultMessage: 'Text'
        }),
        topSpacing: 0,
        bottomSpacing: 0
      }
    },
    mandatory: false
  };
  var image = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'LandingPageBuilder.Toolbox.Image',
      defaultMessage: 'Image'
    }),
    icon: 'WIDGET.IMAGE',
    widget: {
      id: shortid.generate(),
      type: widgetType.IMAGE,
      options: {
        link: '',
        align: alignType.LEFT,
        topSpacing: 0,
        bottomSpacing: 0
      }
    },
    mandatory: false
  };
  var video = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'LandingPageBuilder.Toolbox.Video',
      defaultMessage: 'Video'
    }),
    icon: 'WIDGET.VIDEO',
    widget: {
      id: shortid.generate(),
      type: widgetType.VIDEO,
      options: {
        link: '',
        topSpacing: 0,
        bottomSpacing: 0
      }
    },
    mandatory: false
  };
  var countdown = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'LandingPageBuilder.Toolbox.Countdown',
      defaultMessage: 'Countdown'
    }),
    icon: 'WIDGET.COUNTER',
    widget: {
      id: shortid.generate(),
      type: widgetType.COUNTDOWN,
      options: {
        date: '',
        color: '#000',
        topSpacing: 0,
        bottomSpacing: 0
      }
    },
    mandatory: false
  };
  var location = {
    id: shortid.generate(),
    title: intl.formatMessage({
      id: 'LandingPageBuilder.Toolbox.Map',
      defaultMessage: 'Map'
    }),
    icon: 'WIDGET.MAP',
    widget: {
      id: shortid.generate(),
      type: widgetType.LOCATION,
      options: {
        location: '',
        topSpacing: 0,
        bottomSpacing: 0
      }
    },
    mandatory: false
  };
  var widgets = [title, text, image, video, countdown, location];
  return widgets;
}
export default getToolboxWidgets;