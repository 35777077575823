var eventConstants = {
  INITIALIZE_EVENT: 'INITIALIZE_EVENT',
  GET_REQUEST: 'EVENT_GET_REQUEST',
  GET_SUCCESS: 'EVENT_GET_SUCCESS',
  GET_FAILURE: 'EVENT_GET_FAILURE',
  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_REQUEST_SUCCESS: 'CREATE_REQUEST_SUCCESS',
  CREATE_REQUEST_FAILURE: 'CREATE_REQUEST_FAILURE',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_REQUEST_SUCCESS: 'UPDATE_REQUEST_SUCCESS',
  UPDATE_REQUEST_FAILURE: 'UPDATE_REQUEST_FAILURE',
  DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE',
  DUPLICATE_EVENT_REQUEST: 'DUPLICATE_EVENT_REQUEST',
  DUPLICATE_EVENT_SUCCESS: 'DUPLICATE_EVENT_SUCCESS',
  DUPLICATE_EVENT_FAILURE: 'DUPLICATE_EVENT_FAILURE',
  EXPORT_EVENT_REQUEST: 'EXPORT_EVENT_REQUEST',
  EXPORT_EVENT_SUCCESS: 'EXPORT_EVENT_SUCCESS',
  EXPORT_EVENT_FAILURE: 'EXPORT_EVENT_FAILURE',
  SAVEFORM_REQUEST: 'SAVEFORM_REQUEST',
  SAVEFORM_REQUEST_SUCCESS: 'SAVEFORM_REQUEST_SUCCESS',
  SAVEFORM_REQUEST_FAILURE: 'SAVEFORM_REQUEST_FAILURE',
  SAVE_LANDING_PAGE_REQUEST: 'SAVE_LANDING_PAGE_REQUEST',
  SAVE_LANDING_PAGE_SUCCESS: 'SAVE_LANDING_PAGE_SUCCESS',
  SAVE_LANDING_PAGE_FAILURE: 'SAVE_LANDING_PAGE_FAILURE',
  ENTER_LANDING_PAGE_PREVIEW: 'ENTER_LANDING_PAGE_PREVIEW',
  EXIT_LANDING_PAGE_PREVIEW: 'EXIT_LANDING_PAGE_PREVIEW',
  SAVE_LOBBY_PAGE_REQUEST: 'SAVE_LOBBY_PAGE_REQUEST',
  SAVE_LOBBY_PAGE_SUCCESS: 'SAVE_LOBBY_PAGE_SUCCESS',
  SAVE_LOBBY_PAGE_FAILURE: 'SAVE_LOBBY_PAGE_FAILURE',
  ENTER_LOBBY_PAGE_PREVIEW: 'ENTER_LOBBY_PAGE_PREVIEW',
  EXIT_LOBBY_PAGE_PREVIEW: 'EXIT_LOBBY_PAGE_PREVIEW',
  PUBLISH_REQUEST: 'PUBLISH_REQUEST',
  PUBLISH_REQUEST_SUCCESS: 'PUBLISH_REQUEST_SUCCESS',
  PUBLISH_REQUEST_FAILURE: 'PUBLISH_REQUEST_FAILURE',
  SAVE_TEMPLATE_PAGE: 'SAVE_TEMPLATE_PAGE',
  SAVE_TEMPLATE_PAGE_SUCCESS: 'SAVE_TEMPLATE_PAGE_SUCCESS',
  SAVE_TEMPLATE_PAGE_FAILURE: 'SAVE_TEMPLATE_PAGE_FAILURE',
  SAVE_BRANDING_REQUEST: 'SAVE_BRANDING_REQUEST',
  SAVE_BRANDING_SUCCESS: 'SAVE_BRANDING_SUCCESS',
  SAVE_BRANDING_FAILURE: 'SAVE_BRANDING_FAILURE',
  DELETE_BRANDING_ITEMS_REQUEST: 'DELETE_BRANDING_ITEMS_REQUEST',
  DELETE_BRANDING_ITEMS_SUCCESS: 'DELETE_BRANDING_ITEMS_SUCCESS',
  DELETE_BRANDING_ITEMS_FAILURE: 'DELETE_BRANDING_ITEMS_FAILURE',
  SAVE_LIVE_SETTINGS_REQUEST: 'SAVE_LIVE_SETTINGS_REQUEST',
  SAVE_LIVE_SETTINGS_SUCCESS: 'SAVE_LIVE_SETTINGS_SUCCESS',
  SAVE_LIVE_SETTINGS_FAILURE: 'SAVE_LIVE_SETTINGS_FAILURE',
  SAVEFORM_SETTINGS_REQUEST: 'SAVEFORM_SETTINGS_REQUEST',
  SAVEFORM_SETTINGS_SUCCESS: 'SAVEFORM_SETTINGS_SUCCESS',
  SAVEFORM_SETTINGS_FAILURE: 'SAVEFORM_SETTINGS_FAILURE',
  CLEAR_ERRORS: 'EVENT_CLEAR_ERRORS',
  VALIDATE_SLUG_REQUEST: 'VALIDATE_SLUG_REQUEST',
  VALIDATE_SLUG_SUCCESS: 'VALIDATE_SLUG_SUCCESS',
  VALIDATE_SLUG_FAILURE: 'VALIDATE_SLUG_FAILURE',
  GET_EVENT_STATISTICS: 'GET_EVENT_STATISTICS',
  GET_EVENT_STATISTICS_SUCCESS: 'GET_EVENT_STATISTICS_SUCCESS',
  GET_EVENT_STATISTICS_FAILURE: 'GET_EVENT_STATISTICS_FAILURE',
  GET_EVENT_EMAIL_IDENTITIES_REQUEST: 'GET_EVENT_EMAIL_IDENTITIES_REQUEST',
  GET_EVENT_EMAIL_IDENTITIES_SUCCESS: 'GET_EVENT_EMAIL_IDENTITIES_SUCCESS',
  GET_EVENT_EMAIL_IDENTITIES_FAILURE: 'GET_EVENT_EMAIL_IDENTITIES_FAILURE',
  VERIFY_EVENT_EMAIL_IDENTITY_REQUEST: 'VERIFY_EVENT_EMAIL_IDENTITY_REQUEST',
  VERIFY_EVENT_EMAIL_IDENTITY_SUCCESS: 'VERIFY_EVENT_EMAIL_IDENTITY_SUCCESS',
  VERIFY_EVENT_EMAIL_IDENTITY_FAILURE: 'VERIFY_EVENT_EMAIL_IDENTITY_FAILURE',
  UPDATE_EVENT_EMAIL_IDENTITY_REQUEST: 'UPDATE_EVENT_EMAIL_IDENTITY_REQUEST',
  UPDATE_EVENT_EMAIL_IDENTITY_SUCCESS: 'UPDATE_EVENT_EMAIL_IDENTITY_SUCCESS',
  UPDATE_EVENT_EMAIL_IDENTITY_FAILURE: 'UPDATE_EVENT_EMAIL_IDENTITY_FAILURE',
  DELETE_EVENT_EMAIL_IDENTITY_REQUEST: 'DELETE_EVENT_EMAIL_IDENTITY_REQUEST',
  DELETE_EVENT_EMAIL_IDENTITY_SUCCESS: 'DELETE_EVENT_EMAIL_IDENTITY_SUCCESS',
  DELETE_EVENT_EMAIL_IDENTITY_FAILURE: 'DELETE_EVENT_EMAIL_IDENTITY_FAILURE',
  UPDATE_EVENT_FEATURE_REQUEST: 'UPDATE_EVENT_FEATURE_REQUEST',
  UPDATE_EVENT_FEATURE_SUCCESS: 'UPDATE_EVENT_FEATURE_SUCCESS',
  UPDATE_EVENT_FEATURE_FAILURE: 'UPDATE_EVENT_FEATURE_FAILURE',
  GET_EVENT_DEVICES_REQUEST: 'GET_EVENT_DEVICES_REQUEST',
  GET_EVENT_DEVICES_SUCCESS: 'GET_EVENT_DEVICES_SUCCESS',
  GET_EVENT_DEVICES_FAILURE: 'GET_EVENT_DEVICES_FAILURE',
  REMOVE_DEVICE_REQUEST: 'REMOVE_DEVICE_REQUEST',
  REMOVE_DEVICE_SUCCESS: 'REMOVE_DEVICE_SUCCESS',
  REMOVE_DEVICE_ERROR: 'REMOVE_DEVICE_ERROR',
  CREATE_PARTICIPANT_PASSWORD_REQUEST: 'CREATE_PARTICIPANT_PASSWORD_REQUEST',
  CREATE_PARTICIPANT_PASSWORD_SUCCESS: 'CREATE_PARTICIPANT_PASSWORD_SUCCESS',
  CREATE_PARTICIPANT_PASSWORD_ERROR: 'CREATE_PARTICIPANT_PASSWORD_ERROR',
  SAVEBADGE_REQUEST: 'SAVEFORM_REQUEST',
  SAVEBADGE_REQUEST_SUCCESS: 'SAVEBADGE_REQUEST_SUCCESS',
  SAVEBADGE_REQUEST_FAILURE: 'SAVEBADGE_REQUEST_FAILURE',
  UPDATE_LIVE_EVENT_URL: 'UPDATE_LIVE_EVENT_URL'
};
export default eventConstants;