function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.anchor.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import { Base64 } from 'js-base64';
import config from 'config/environment';
import { elementsWithoutValue, registrationColumn } from '../../../constants/types';
import { flattenFormElements } from '../../../helpers/form-helpers';
import { isOneOf, stringToHash } from '../../../helpers/helpers';
import { appIntl } from '../../../IntlGlobalProvider';
import { openButtonDialog } from '../plugins/menu-button';
export var editorTagNames = {
  eventName: 'eventName',
  eventStartDate: 'eventStartDate',
  eventCalendar: 'eventCalendar',
  submittedFormDetails: 'submittedFormDetails',
  appUsername: 'appUsername',
  appPassword: 'appPassword',
  appPasswordlessLoginUrl: 'appPasswordlessLoginUrl',
  appUrl: 'appUrl',
  registrationUrl: 'registrationUrl',
  declineUrl: 'declineUrl',
  qrTicket: 'qrTicket',
  qrTicketLink: 'qrTicketLink',
  appPasswordlessLoginUrlButton: 'appPasswordlessLoginUrlButton',
  registrationButton: 'registrationButton',
  declineButton: 'declineButton'
};
export var editorCategoryNames = {
  participantDetails: 'participantDetails',
  eventAccess: 'eventAccess',
  eventDetails: 'eventDetails',
  registration: 'registration'
};
export function getEditorTags(event) {
  var _event$form, _event$branding$prima, _event$branding, _event$branding$prima2, _event$branding2, _event$branding$secon, _event$branding3, _event$branding$secon2, _event$branding4;
  if (!event) {
    return [];
  }
  var intl = appIntl();
  var formElements = flattenFormElements(event === null || event === void 0 || (_event$form = event.form) === null || _event$form === void 0 ? void 0 : _event$form.elements).filter(function (x) {
    return !isOneOf(x.id, registrationColumn.confirmEmail, registrationColumn.terms, registrationColumn.guests) && !elementsWithoutValue.includes(x.type);
  }).map(function (x) {
    return {
      id: Base64.encode(x.id),
      text: x.options.label,
      category: editorCategoryNames.participantDetails
    };
  });
  var primaryButtonTextColor = (_event$branding$prima = event === null || event === void 0 || (_event$branding = event.branding) === null || _event$branding === void 0 ? void 0 : _event$branding.primaryButtonTextColor) !== null && _event$branding$prima !== void 0 ? _event$branding$prima : '#ffffff';
  var primaryButtonBackgroundColor = (_event$branding$prima2 = event === null || event === void 0 || (_event$branding2 = event.branding) === null || _event$branding2 === void 0 ? void 0 : _event$branding2.primaryButtonBackgroundColor) !== null && _event$branding$prima2 !== void 0 ? _event$branding$prima2 : '#00ccc2';
  var secondaryButtonTextColor = (_event$branding$secon = event === null || event === void 0 || (_event$branding3 = event.branding) === null || _event$branding3 === void 0 ? void 0 : _event$branding3.secondaryButtonTextColor) !== null && _event$branding$secon !== void 0 ? _event$branding$secon : '#00ccc2';
  var secondaryButtonBackgroundColor = (_event$branding$secon2 = event === null || event === void 0 || (_event$branding4 = event.branding) === null || _event$branding4 === void 0 ? void 0 : _event$branding4.secondaryButtonBackgroundColor) !== null && _event$branding$secon2 !== void 0 ? _event$branding$secon2 : '#ffffff';
  var passwordlessLoginButtonLabel = intl.formatMessage({
    id: 'components.TextEditor.passwordless_login_button',
    defaultMessage: 'Magic link button (automatic login)'
  });
  var editorTags = [].concat(_toConsumableArray(formElements), [{
    id: editorTagNames.submittedFormDetails,
    text: intl.formatMessage({
      id: 'components.TextEditor.registration_details_tag',
      defaultMessage: 'Registration details'
    }),
    element: 'div',
    category: editorCategoryNames.participantDetails
  }, {
    id: editorTagNames.appPasswordlessLoginUrl,
    text: intl.formatMessage({
      id: 'components.TextEditor.user_passwordlessloginurl_tag',
      defaultMessage: 'Magic link (automatic login)'
    }),
    category: editorCategoryNames.eventAccess
  }, {
    id: editorTagNames.appPasswordlessLoginUrlButton,
    text: passwordlessLoginButtonLabel,
    anchor: {
      label: passwordlessLoginButtonLabel,
      url: '{{appPasswordlessLoginUrl}}',
      color: primaryButtonTextColor,
      backgroundColor: primaryButtonBackgroundColor
    },
    category: editorCategoryNames.eventAccess
  }, {
    id: editorTagNames.appUrl,
    text: intl.formatMessage({
      id: 'components.TextEditor.event_link_tag',
      defaultMessage: 'Event link (login needed)'
    }),
    category: editorCategoryNames.eventAccess
  }, {
    id: editorTagNames.appUsername,
    text: intl.formatMessage({
      id: 'components.TextEditor.username_tag',
      defaultMessage: 'Username / email'
    }),
    category: editorCategoryNames.eventAccess
  }, {
    id: editorTagNames.appPassword,
    text: intl.formatMessage({
      id: 'components.TextEditor.user_password_tag',
      defaultMessage: 'User password'
    }),
    category: editorCategoryNames.eventAccess
  }, {
    id: editorTagNames.qrTicket,
    text: intl.formatMessage({
      id: 'components.TextEditor.qr_ticket',
      defaultMessage: 'QR ticket'
    }),
    content: "<div class=\"qr-ticket\"><img src=\"".concat(config.apiUrl, "/asset/qr/{{qrTicket}}\" height=\"250\" width=\"250\" alt=\"ticket\" /></div>"),
    category: editorCategoryNames.eventAccess
  }, {
    id: editorTagNames.qrTicketLink,
    text: intl.formatMessage({
      id: 'components.TextEditor.qr_ticket_link',
      defaultMessage: 'QR ticket link'
    }),
    category: editorCategoryNames.eventAccess
  }, {
    id: editorTagNames.eventName,
    text: intl.formatMessage({
      id: 'components.TextEditor.event_name_tag',
      defaultMessage: 'Event name'
    }),
    category: editorCategoryNames.eventDetails
  }, {
    id: editorTagNames.eventStartDate,
    text: intl.formatMessage({
      id: 'components.TextEditor.event_start_date_tag',
      defaultMessage: 'Event start date'
    }),
    category: editorCategoryNames.eventDetails
  }, {
    id: editorTagNames.eventCalendar,
    text: intl.formatMessage({
      id: 'components.TextEditor.event_calendar_tag',
      defaultMessage: 'Event calendar'
    }),
    category: editorCategoryNames.eventDetails
  }, {
    id: editorTagNames.registrationButton,
    text: intl.formatMessage({
      id: 'components.TextEditor.registration_button',
      defaultMessage: 'Registration button'
    }),
    anchor: {
      label: intl.formatMessage({
        id: 'registration_page',
        defaultMessage: 'Registration page'
      }),
      url: '{{registrationUrl}}',
      color: primaryButtonTextColor,
      backgroundColor: primaryButtonBackgroundColor
    },
    category: editorCategoryNames.registration
  }, {
    id: editorTagNames.registrationUrl,
    text: intl.formatMessage({
      id: 'components.TextEditor.registration_url',
      defaultMessage: 'Registration URL'
    }),
    category: editorCategoryNames.registration
  }, {
    id: editorTagNames.declineButton,
    text: intl.formatMessage({
      id: 'components.TextEditor.decline_button',
      defaultMessage: 'Decline button'
    }),
    anchor: {
      label: intl.formatMessage({
        id: 'will_not_participate',
        defaultMessage: 'I can NOT attend'
      }),
      url: '{{declineUrl}}',
      color: secondaryButtonTextColor,
      backgroundColor: secondaryButtonBackgroundColor
    },
    category: editorCategoryNames.registration
  }, {
    id: editorTagNames.declineUrl,
    text: intl.formatMessage({
      id: 'components.TextEditor.decline_url',
      defaultMessage: 'Decline URL'
    }),
    category: editorCategoryNames.registration
  }]);
  return editorTags.map(function (x) {
    return _objectSpread(_objectSpread({}, x), {}, {
      style: getTagStyle(x)
    });
  });
}
function generateTag(editor, tagData) {
  var onAction = function onAction() {
    return editor.insertContent(editorTagHtml(tagData));
  };
  if (tagData.content) {
    onAction = function onAction() {
      return editor.insertContent(tagData.content);
    };
  }
  if (tagData.anchor) {
    onAction = function onAction() {
      return openButtonDialog(editor, {
        anchor: tagData.anchor
      });
    };
  }
  return {
    id: tagData.id,
    type: 'menuitem',
    text: tagData.text,
    onAction: onAction
  };
}
function getTagStyle(tagData) {
  var id = tagData.id;
  if (tagData.category === 'participantDetails') {
    id = stringToHash(tagData.id).toString();
  }
  var escapedLabel = tagData.text.replace('"', '\\"');
  return ".field-".concat(id, " { display: inline-block; font-size: 0px; }") + ".field-".concat(id, ":after { content: \"").concat(escapedLabel, "\"; font-size: 16px; }");
}
function getCategoryTags(editor, tags, category) {
  return tags.filter(function (x) {
    return x.category === category;
  }).map(function (x) {
    return generateTag(editor, x);
  });
}
function getTagsToShow(tagObj, editorTags) {
  if (!(typeof tagObj === 'string' || tagObj instanceof String)) {
    return tagObj === true ? editorTags : [];
  }
  var tags = tagObj.split(' ').filter(Boolean);
  var shownCategories = tags.filter(function (x) {
    return x.endsWith('.*');
  }).map(function (x) {
    return x.substring(0, x.length - 2);
  });
  var notShownTags = tags.filter(function (x) {
    return x.startsWith('!');
  }).map(function (x) {
    return x.substring(1);
  });
  var shownTags = tags.filter(function (x) {
    return !(x.startsWith('!') || x.endsWith('.*'));
  });
  return editorTags.filter(function (x) {
    return !notShownTags.includes(x.id);
  }).filter(function (x) {
    return shownTags.includes(x.id) || shownCategories.includes(x.category);
  });
}
export function editorTagHtml(tagData) {
  var _tagData$element;
  var element = (_tagData$element = tagData.element) !== null && _tagData$element !== void 0 ? _tagData$element : 'span';
  var id = tagData.id;
  if (tagData.category === 'participantDetails') {
    id = stringToHash(tagData.id).toString();
  }
  return "<".concat(element, " class=\"field-").concat(id, " non-editable\">{{{[").concat(tagData.id, "]}}}</").concat(element, ">");
}
export function getEditorTagsMenu(editor, tags, editorTags) {
  if (!editorTags) {
    return [];
  }
  var intl = appIntl();
  var showTags = getTagsToShow(tags, editorTags);
  var participantDetailTags = getCategoryTags(editor, showTags, editorCategoryNames.participantDetails);
  var eventAccessTags = getCategoryTags(editor, showTags, editorCategoryNames.eventAccess);
  var eventDetailsTags = getCategoryTags(editor, showTags, editorCategoryNames.eventDetails);
  var registrationTags = getCategoryTags(editor, showTags, editorCategoryNames.registration);
  return [participantDetailTags.length > 0 && {
    type: 'nestedmenuitem',
    text: intl.formatMessage({
      id: 'components.TextEditor.participant_details',
      defaultMessage: 'Participant details'
    }),
    getSubmenuItems: function getSubmenuItems() {
      return participantDetailTags;
    }
  }, eventAccessTags.length > 0 && {
    type: 'nestedmenuitem',
    text: intl.formatMessage({
      id: 'components.TextEditor.event_access',
      defaultMessage: 'Event access'
    }),
    getSubmenuItems: function getSubmenuItems() {
      return eventAccessTags;
    }
  }, eventDetailsTags.length > 0 && {
    type: 'nestedmenuitem',
    text: intl.formatMessage({
      id: 'components.TextEditor.event_details',
      defaultMessage: 'Event details'
    }),
    getSubmenuItems: function getSubmenuItems() {
      return eventDetailsTags;
    }
  }, registrationTags.length > 0 && {
    type: 'nestedmenuitem',
    text: intl.formatMessage({
      id: 'components.TextEditor.registration_tag',
      defaultMessage: 'Registration'
    }),
    getSubmenuItems: function getSubmenuItems() {
      return registrationTags;
    }
  }].filter(Boolean);
}