function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.string.anchor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable no-unused-vars */
import { appIntl } from '../../../IntlGlobalProvider';
import { buildAnchor, collect, toggleState } from './button-helpers';
export function addMenuButton(editor) {
  editor.addCommand('updateButton', function (ui, data) {
    var _node$tagName;
    var node = getButtonElement(editor);
    var anchor = buildAnchor(data, node);
    var isAnchor = (node === null || node === void 0 || (_node$tagName = node.tagName) === null || _node$tagName === void 0 ? void 0 : _node$tagName.toLowerCase()) === 'a';
    if (!isAnchor) {
      editor.focus();
      editor.selection.setContent(anchor.outerHTML);
    }
  });

  /*
  editor.ui.registry.addToggleButton('button', {
  	icon: 'button',
  	text: intl.formatMessage({
  		id: 'button',
  		defaultMessage: 'Button',
  	}),
  	tooltip: intl.formatMessage({
  		id: 'insert_edit_button',
  		defaultMessage: 'Insert/Edit button',
  	}),
  	onAction() {
  		openButtonDialog(editor, null, branding, eventId);
  	},
  	onSetup: toggleActiveState(editor),
  });
  */
}

function collectData(editor, branding) {
  var anchorNode = getButtonElement(editor);
  return collect(editor, anchorNode, branding);
}
export function getButtonElement(editor, selectedElm) {
  var elm = selectedElm || editor.selection.getNode();
  return editor.dom.getParent(elm, 'a[href]');
}
export function openButtonDialog(editor, info, branding, eventId) {
  var intl = appIntl();
  var data = info || collectData(editor, branding);
  var params = _objectSpread(_objectSpread({}, data === null || data === void 0 ? void 0 : data.anchor), {}, {
    eventId: eventId
  });
  editor.windowManager.openUrl({
    title: intl.formatMessage({
      id: 'update_tag_button',
      defaultMessage: 'Insert/Edit button'
    }),
    url: "/editor/edit-button-modal?".concat(new URLSearchParams(params).toString()),
    width: 480,
    height: 600,
    buttons: [{
      type: 'cancel',
      name: 'closeButton',
      text: 'Cancel'
    }, {
      type: 'custom',
      name: 'submitButton',
      text: 'Insert',
      primary: true
    }],
    onAction: function onAction(instance, button) {
      if ((button === null || button === void 0 ? void 0 : button.name) === 'submitButton') {
        instance.sendMessage({
          mceAction: 'submitButton'
        });
      }
    }
  });
}