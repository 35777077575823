import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponse } from '../helpers/request-helpers';
var integrationService = {
  getAllClients: getAllClients,
  getAllWorkspaces: getAllWorkspaces,
  getWorkspaceDetails: getWorkspaceDetails,
  getEventDetails: getEventDetails,
  getAllWorkspaceLinks: getAllWorkspaceLinks,
  linkClientWorkspace: linkClientWorkspace,
  getAllSyncEvents: getAllSyncEvents,
  linkEvent: linkEvent,
  getExternalEvents: getExternalEvents,
  getAllSyncRegistrations: getAllSyncRegistrations,
  linkRegistrations: linkRegistrations,
  linkRegistration: linkRegistration
};
function getAllClients(destination) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/clients"), requestOptions).then(handleResponse);
}
function getAllWorkspaces() {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/integration/workspaces"), requestOptions).then(handleResponse);
}
function getWorkspaceDetails(workspaceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/integration/workspaces/").concat(workspaceId), requestOptions).then(handleResponse);
}
function getEventDetails(eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/integration/event/").concat(eventId), requestOptions).then(handleResponse);
}
function getAllWorkspaceLinks(destination) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/workspaceClientLinks"), requestOptions).then(handleResponse);
}
function linkClientWorkspace(destination, workspaceId, clientId, name, isUnlink) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      workspaceId: workspaceId,
      clientId: clientId,
      name: name,
      isUnlink: isUnlink
    })
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/link"), requestOptions).then(handleResponse);
}
function getAllSyncEvents(destination, workspaceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/").concat(workspaceId, "/eventLinks"), requestOptions).then(handleResponse);
}
function linkEvent(destination, eventId, externalEventId, name, isUnlink) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      externalEventId: externalEventId,
      externalEventName: name,
      isUnlink: isUnlink
    })
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/linkEvent"), requestOptions).then(handleResponse);
}
function getExternalEvents(destination, workspaceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/").concat(workspaceId, "/externalEvents"), requestOptions).then(handleResponse);
}
function getAllSyncRegistrations(destination, eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/").concat(eventId, "/registrationLinks"), requestOptions).then(handleResponse);
}
function linkRegistrations(destination, eventId, registrationIds, isUnlink, contextId) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      registrationIds: registrationIds,
      isUnlink: isUnlink,
      contextId: contextId
    })
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/linkRegistrations"), requestOptions).then(handleResponse);
}
function linkRegistration(destination, eventId, registrationId, isUnlink, contextId) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      registrationId: registrationId,
      isUnlink: isUnlink,
      contextId: contextId
    })
  };
  return fetch("".concat(config.apiUrl, "/integration/").concat(destination, "/linkRegistration"), requestOptions).then(handleResponse);
}
export default integrationService;