import { appIntl } from '../IntlGlobalProvider';
import { invoiceStatus } from '../constants/types/invoice-status-constants';
function getInvoiceStatusTitle(status) {
  var intl = appIntl();
  var statusValue = {};
  switch (status) {
    case invoiceStatus.PAID:
      statusValue.title = intl.formatMessage({
        id: 'paid',
        defaultMessage: 'Paid'
      });
      statusValue.color = 'default';
      break;
    case invoiceStatus.NOT_PAID:
      statusValue.title = intl.formatMessage({
        id: 'not_paid',
        defaultMessage: 'Not paid'
      });
      statusValue.color = 'failed';
      break;
    case invoiceStatus.PAYMENT_DUE:
      statusValue.title = intl.formatMessage({
        id: 'payment_due',
        defaultMessage: 'Payment due'
      });
      statusValue.color = 'tertiary';
      break;
    case invoiceStatus.PENDING:
      statusValue.title = intl.formatMessage({
        id: 'pending',
        defaultMessage: 'Pending'
      });
      statusValue.color = 'tertiary';
      break;
    case invoiceStatus.POSTED:
      statusValue.title = intl.formatMessage({
        id: 'posted',
        defaultMessage: 'Posted'
      });
      statusValue.color = 'transparent';
      break;
    case invoiceStatus.VOIDED:
      statusValue.title = intl.formatMessage({
        id: 'voided',
        defaultMessage: 'Voided'
      });
      statusValue.color = 'transparent';
      break;
    default:
      statusValue.title = intl.formatMessage({
        id: 'unknown',
        defaultMessage: 'Unknown'
      });
      statusValue.color = 'secondary';
      break;
  }
  return statusValue;
}
export default getInvoiceStatusTitle;