function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { eventPageType } from '../constants/types/event-page-type-constants';
import { pollType } from '../constants/types/poll-type-constants';
import { appIntl } from '../IntlGlobalProvider';
export var pollStreamUrl = eventPageType.POLLS;
export var pollModeratorUrl = eventPageType.POLL_MANAGEMENT;
export var quizStreamUrl = eventPageType.QUIZ;
export var quizOpenTextStreamUrl = eventPageType.QUIZ_OPEN_TEXT;
export var quizModeratorUrl = eventPageType.QUIZ_MANAGEMENT;
export var treasureHuntStreamUrl = eventPageType.TREASURE_HUNT;
export var treasureHuntOpenTextUrl = eventPageType.TREASURE_HUNT_OPEN_TEXT;
export var treasureHuntModeratorUrl = eventPageType.TREASURE_HUNT_MANAGEMENT;
export function handlePollsURLS(polls, liveEventUrl) {
  var pollsData = polls.map(function (poll) {
    var _urls, _urls$poll$pollType, _urls$poll$pollType2;
    var id = poll.id,
      accessKey = poll.accessKey;
    var urls = (_urls = {}, _defineProperty(_urls, pollType.MULTIPLE_ANSWERS, {
      moderatorUrl: '',
      publicUrl: "".concat(liveEventUrl, "/").concat(eventPageType.POLLS, "/").concat(accessKey)
    }), _defineProperty(_urls, pollType.OPEN_TEXT, {
      moderatorUrl: "".concat(liveEventUrl, "/").concat(eventPageType.POLL_MANAGEMENT, "/").concat(accessKey),
      publicUrl: "".concat(liveEventUrl, "/").concat(eventPageType.POLLS, "/").concat(accessKey)
    }), _defineProperty(_urls, pollType.QUIZ, {
      moderatorUrl: "".concat(liveEventUrl, "/").concat(eventPageType.QUIZ_MANAGEMENT, "/").concat(accessKey),
      publicUrl: "".concat(liveEventUrl, "/").concat(eventPageType.QUIZ, "/").concat(accessKey),
      publicOpenTextUrl: "".concat(liveEventUrl, "/").concat(eventPageType.OPEN_TEXT, "/").concat(accessKey)
    }), _defineProperty(_urls, pollType.TREASURE_HUNT, {
      moderatorUrl: "".concat(liveEventUrl, "/").concat(eventPageType.TREASURE_HUNT_MANAGEMENT, "/").concat(accessKey),
      publicUrl: "".concat(liveEventUrl, "/").concat(eventPageType.TREASURE_HUNT, "/").concat(accessKey),
      publicOpenTextUrl: "".concat(liveEventUrl, "/").concat(eventPageType.TREASURE_HUNT_OPEN_TEXT, "/").concat(accessKey)
    }), _urls);
    return {
      pollId: id,
      moderatorUrl: ((_urls$poll$pollType = urls[poll === null || poll === void 0 ? void 0 : poll.pollType]) === null || _urls$poll$pollType === void 0 ? void 0 : _urls$poll$pollType.moderatorUrl) || '',
      publicUrl: ((_urls$poll$pollType2 = urls[poll === null || poll === void 0 ? void 0 : poll.pollType]) === null || _urls$poll$pollType2 === void 0 ? void 0 : _urls$poll$pollType2.publicUrl) || ''
    };
  });
  return pollsData;
}
export function handlePollTitle(poll) {
  var intl = appIntl();
  switch (poll.pollType) {
    case pollType.MULTIPLE_ANSWERS:
      return intl.formatMessage({
        id: 'codedPoll_MultipleAnswers',
        defaultMessage: 'Coded poll (multiple answers)'
      });
    case pollType.OPEN_TEXT:
      return intl.formatMessage({
        id: 'codedPoll_OpenText',
        defaultMessage: 'Coded poll (open text)'
      });
    case pollType.QUIZ:
      return "".concat(intl.formatMessage({
        id: 'codedQuiz',
        defaultMessage: 'Coded quiz '
      }), " (").concat(poll.items.length, ")");
    case pollType.TREASURE_HUNT:
      return "".concat(intl.formatMessage({
        id: 'codedTreasureHunt',
        defaultMessage: 'Coded treasure hunt'
      }), " (").concat(poll.items.length, ")");
    default:
      return '';
  }
}
export function handlePollPublicView(poll) {
  var intl = appIntl();
  switch (poll.pollType) {
    case pollType.MULTIPLE_ANSWERS:
    case pollType.OPEN_TEXT:
      return intl.formatMessage({
        id: 'poll_stream_url"',
        defaultMessage: 'Poll stream URLs'
      });
    case pollType.QUIZ:
      return "".concat(intl.formatMessage({
        id: 'quiz_stream_url',
        defaultMessage: 'Quiz stream URLs '
      }));
    case pollType.TREASURE_HUNT:
      return "".concat(intl.formatMessage({
        id: 'treasure_hunt_stream_url',
        defaultMessage: 'Treasure hunt stream URLs '
      }));
    default:
      return intl.formatMessage({
        id: 'poll_stream_url"',
        defaultMessage: 'Poll stream URLs'
      });
  }
}
export function isSinglePoll(poll) {
  return poll.pollType === pollType.OPEN_TEXT || poll.pollType === pollType.MULTIPLE_ANSWERS || poll.pollType === pollType.RATING;
}
export function isGroupPoll(poll) {
  return (poll.pollType === pollType.QUIZ || poll.pollType === pollType.TREASURE_HUNT) && poll.isTemplate === false;
}
export function isTemplate(poll) {
  return poll.isTemplate === true;
}