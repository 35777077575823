import { useIntl } from 'react-intl';

// 'intl' service singleton reference
var intl;
export function IntlGlobalProvider(_ref) {
  var children = _ref.children;
  intl = useIntl(); // Keep the 'intl' service reference
  return children;
}

// Getter function to expose the read-only 'intl' service
export function appIntl() {
  return intl;
}