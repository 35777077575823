var userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',
  SEND_RESET_PASSWORD_REQUEST: 'USERS_SEND_RESET_PASSWORD_REQUEST',
  SEND_RESET_PASSWORD_SUCCESS: 'USERS_SEND_RESET_PASSWORD_SUCCESS',
  SEND_RESET_PASSWORD_FAILURE: 'USERS_SEND_RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',
  VALIDATE_RESET_PASSWORD_TOKEN_REQUEST: 'USERS_VALIDATE_RESET_PASSWORD_TOKEN_REQUEST',
  VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS: 'USERS_VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS',
  VALIDATE_RESET_PASSWORD_TOKEN_FAILURE: 'USERS_VALIDATE_RESET_PASSWORD_TOKEN_FAILURE',
  REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  RESEND_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
  RESEND_EMAIL_FAILURE: 'RESEND_EMAIL_FAILURE',
  GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE: 'GET_CURRENT_USER_FAILURE',
  UPDATE_CURRENT_USER_REQUEST: 'UPDATE_CURRENT_USER_REQUEST',
  UPDATE_CURRENT_USER_SUCCESS: 'UPDATE_CURRENT_USER_SUCCESS',
  UPDATE_CURRENT_USER_FAILURE: 'UPDATE_CURRENT_USER_FAILURE',
  CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',
  CHANGE_WORKSPACE_REQUEST: 'CHANGE_WORKSPACE_USER_REQUEST',
  CHANGE_WORKSPACE_SUCCESS: 'CHANGE_WORKSPACE_USER_SUCCESS',
  CHANGE_WORKSPACE_FAILURE: 'CHANGE_WORKSPACE_USER_FAILURE',
  UPDATE_WORKSPACE_SUCCESS: 'UPDATE_WORKSPACE_SUCCESS',
  SET_SELECTED_WORKSPACE: 'SET_SELECTED_WORKSPACE'
};
export default userConstants;