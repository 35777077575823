import { useRef, useLayoutEffect, useEffect, useCallback } from 'react';
var useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
export default function useEventCallback(fn) {
  var ref = useRef(fn);
  useEnhancedEffect(function () {
    ref.current = fn;
  });
  return useCallback(function () {
    return (0, ref.current).apply(void 0, arguments);
  }, []);
}