var smsConstants = {
  GET_ALL: 'SMS_GET_ALL',
  GET_ALL_SUCCESS: 'SMS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'SMS_GET_ALL_FAILURE',
  CANCEL: 'SMS_CANCEL',
  CANCEL_SUCCESS: 'SMS_CANCEL_SUCCESS',
  CANCEL_FAILURE: 'SMS_CANCEL_FAILURE',
  EXPORT_SMS_REQUEST: 'EXPORT_SMS_REQUEST',
  EXPORT_SMS_SUCCESS: 'EXPORT_SMS_SUCCESS',
  EXPORT_SMS_FAILURE: 'EXPORT_SMS_FAILURE'
};
export default smsConstants;