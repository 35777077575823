import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { eventConstants, workspaceConstants, reservationConstants } from '../constants/actions';
export default function statistics() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case eventConstants.GET_EVENT_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case eventConstants.GET_EVENT_STATISTICS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        event: action.response.result,
        loading: false
      });
    case eventConstants.GET_EVENT_STATISTICS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false
      });
    case workspaceConstants.GET_WORKSPACE_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case workspaceConstants.GET_WORKSPACE_STATISTICS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        workspace: action.response.result,
        loading: false
      });
    case workspaceConstants.GET_WORKSPACE_STATISTICS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false
      });
    case reservationConstants.GET_RESERVATIONS_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        reservationsStatisticsLoading: true
      });
    case reservationConstants.GET_RESERVATIONS_STATISTICS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        reservations: action.response,
        reservationsStatisticsLoading: false
      });
    case reservationConstants.GET_RESERVATIONS_STATISTICS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        reservationsError: action.error,
        reservationsStatisticsLoading: false
      });
    default:
      return state;
  }
}