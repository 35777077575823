import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { userConstants } from '../constants/actions';
export default function users() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case userConstants.REGISTER_USER_REQUEST:
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
    case userConstants.REGISTER_USER_FAILURE:
    case userConstants.RESEND_EMAIL_FAILURE:
      return {
        error: action.error
      };
    case userConstants.REGISTER_USER_SUCCESS:
      return {
        registered: true
      };
    case userConstants.RESEND_EMAIL_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        resendSuccess: false,
        loading: true
      });
    case userConstants.RESEND_EMAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        resendSuccess: true,
        loading: false
      });
    case userConstants.GET_CURRENT_USER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentUser: null,
        loading: true
      });
    case userConstants.GET_CURRENT_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentUser: action.user,
        loading: false
      });
    case userConstants.GET_CURRENT_USER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentUser: null,
        loading: false
      });
    case userConstants.UPDATE_CURRENT_USER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        updateUserSuccess: false
      });
    case userConstants.UPDATE_CURRENT_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentUser: action.user,
        loading: false,
        updateUserSuccess: true
      });
    case userConstants.UPDATE_CURRENT_USER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        updateUserSuccess: false
      });
    case userConstants.CONFIRM_EMAIL_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        emailConfirmSuccess: false
      });
    case userConstants.CONFIRM_EMAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        emailConfirmSuccess: true
      });
    case userConstants.CONFIRM_EMAIL_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        emailConfirmSuccess: false
      });
    case userConstants.LOGIN_REQUEST:
      return {
        resendSuccess: false
      };
    case userConstants.SEND_RESET_PASSWORD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendResetPasswordSuccess: false,
        loading: true
      });
    case userConstants.SEND_RESET_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendResetPasswordSuccess: true,
        loading: false
      });
    case userConstants.SEND_RESET_PASSWORD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendResetPasswordSuccess: false,
        loading: false
      });
    case userConstants.RESET_PASSWORD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        resetPasswordSuccess: false,
        loading: true
      });
    case userConstants.RESET_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        resetPasswordSuccess: true,
        loading: false
      });
    case userConstants.RESET_PASSWORD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        resetPasswordSuccess: false,
        loading: false
      });
    case userConstants.VALIDATE_RESET_PASSWORD_TOKEN_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResetTokenValid: false,
        loading: true
      });
    case userConstants.VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResetTokenValid: action.result,
        loading: false
      });
    case userConstants.VALIDATE_RESET_PASSWORD_TOKEN_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResetTokenValid: false,
        loading: false
      });
    default:
      return state;
  }
}