var elementType = {
  TEXT: 1,
  INPUT: 2,
  CHECKBOX: 3,
  RADIO: 4,
  DROPDOWN: 5,
  TEXTAREA: 6,
  BREAK: 7,
  PRIVACY: 8,
  TITLE: 9,
  SPACER: 10,
  GROUPED: 99,
  GUESTS: 100
};
var elementsWithoutValue = [elementType.BREAK, elementType.TITLE, elementType.TEXT, elementType.SPACER];
export { elementType, elementsWithoutValue };