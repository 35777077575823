import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { handleResponse, requestHeader } from '../helpers/request-helpers';
var assistantService = {
  getContent: getContent,
  setContent: setContent
};
function getContent(type, path) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/helpText/").concat(type, "/").concat(path), requestOptions).then(handleResponse);
}
function setContent(type, path, content) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify({
      content: content
    })
  };
  return fetch("".concat(config.apiUrl, "/helpText/").concat(type, "/").concat(path), requestOptions).then(handleResponse);
}
export default assistantService;