import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.string.anchor.js";
/* eslint-disable no-console */
import { isOneOf } from '../../../helpers/helpers';
var registrationUrlTag = '{{registrationUrl}}';
var declineUrlTag = '{{declineUrl}}';
var elementType = {
  BUTTON: 'button',
  POLL: 'poll',
  INFOPAGE: 'infopage',
  SESSION: 'session',
  SPONSOR: 'sponsor',
  SPEAKER: 'speaker',
  AGENDA: 'agenda',
  USER_PROFILE: 'userProfile',
  PARTICIPANT_LIST: 'participantList',
  DOCUMENTS: 'documents'
};
function toggleState(editor, toggler) {
  editor.on('NodeChange', toggler);
  return function () {
    return editor.off('NodeChange', toggler);
  };
}
function rgb2hex(rgb) {
  if (!rgb) {
    return rgb;
  }
  if (/^#[0-9A-F]{6}$/i.test(rgb)) {
    return rgb;
  }
  var match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  function hex(x) {
    return "0".concat(parseInt(x, 10).toString(16)).slice(-2);
  }
  return "#".concat(hex(match[1])).concat(hex(match[2])).concat(hex(match[3])).toUpperCase();
}
function extractFromAnchor(editor, anchor, branding) {
  var _anchor$innerText, _ref, _rgb2hex, _ref2, _rgb2hex2;
  var dom = editor.dom;
  var label = (_anchor$innerText = anchor === null || anchor === void 0 ? void 0 : anchor.innerText) !== null && _anchor$innerText !== void 0 ? _anchor$innerText : '';
  var url = anchor ? dom.getAttrib(anchor, 'href') : '';
  var style = anchor ? getComputedStyle(anchor) : null;
  var pollId = anchor ? dom.getAttrib(anchor, 'data-tappin-poll-id') : null;
  var pollName = anchor ? dom.getAttrib(anchor, 'data-tappin-poll-name') : null;
  var pollType = anchor ? dom.getAttrib(anchor, 'data-tappin-poll-type') : null;
  var infoPageId = anchor ? dom.getAttrib(anchor, 'data-tappin-infopage-id') : null;
  var infoPageName = anchor ? dom.getAttrib(anchor, 'data-tappin-infopage-name') : null;
  var stageSessionId = anchor ? dom.getAttrib(anchor, 'data-tappin-session-id') : null;
  var stageSessionName = anchor ? dom.getAttrib(anchor, 'data-tappin-session-name') : null;
  var stageSessionLiveUrl = anchor ? dom.getAttrib(anchor, 'data-tappin-session-liveurl') : null;
  var sponsorId = anchor ? dom.getAttrib(anchor, 'data-tappin-sponsor-id') : null;
  var sponsorName = anchor ? dom.getAttrib(anchor, 'data-tappin-sponsor-name') : null;
  var speakerId = anchor ? dom.getAttrib(anchor, 'data-tappin-speaker-id') : null;
  var speakerName = anchor ? dom.getAttrib(anchor, 'data-tappin-speaker-name') : null;
  var agendaSession = anchor ? dom.getAttrib(anchor, 'data-tappin-agenda-session') : null;
  var agendaSessionName = anchor ? dom.getAttrib(anchor, 'data-tappin-agenda-session-name') : null;
  return {
    label: label,
    url: url,
    color: (_ref = (_rgb2hex = rgb2hex(style === null || style === void 0 ? void 0 : style.color)) !== null && _rgb2hex !== void 0 ? _rgb2hex : branding === null || branding === void 0 ? void 0 : branding.primaryButtonTextColor) !== null && _ref !== void 0 ? _ref : '#FFFFFF',
    backgroundColor: (_ref2 = (_rgb2hex2 = rgb2hex(style === null || style === void 0 ? void 0 : style.backgroundColor)) !== null && _rgb2hex2 !== void 0 ? _rgb2hex2 : branding === null || branding === void 0 ? void 0 : branding.primaryButtonBackgroundColor) !== null && _ref2 !== void 0 ? _ref2 : '#00CCC2',
    pollId: pollId,
    pollName: pollName,
    pollType: pollType,
    infoPageId: infoPageId,
    infoPageName: infoPageName,
    stageSessionId: stageSessionId,
    stageSessionLiveUrl: stageSessionLiveUrl,
    stageSessionName: stageSessionName,
    sponsorId: sponsorId,
    sponsorName: sponsorName,
    speakerId: speakerId,
    speakerName: speakerName,
    agendaSession: agendaSession,
    agendaSessionName: agendaSessionName
  };
}
function collect(editor, linkNode, branding) {
  var anchor = extractFromAnchor(editor, linkNode, branding);
  return {
    anchor: anchor
  };
}
export function buildAnchor(data, node) {
  var _data$color, _data$backgroundColor, _node$tagName, _data$label, _anchor$style;
  var platforms = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var element = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : elementType.BUTTON;
  var color = "".concat((_data$color = data.color) !== null && _data$color !== void 0 ? _data$color : '#97f7f3');
  var backgroundColor = "".concat((_data$backgroundColor = data.backgroundColor) !== null && _data$backgroundColor !== void 0 ? _data$backgroundColor : '#00183e');
  var isAnchor = (node === null || node === void 0 || (_node$tagName = node.tagName) === null || _node$tagName === void 0 ? void 0 : _node$tagName.toLowerCase()) === 'a';
  var anchor = isAnchor ? node : document.createElement('a');
  if (element === elementType.POLL) {
    var _data$poll, _data$poll2, _data$poll3;
    anchor.setAttribute('data-tappin-poll-id', data === null || data === void 0 || (_data$poll = data.poll) === null || _data$poll === void 0 ? void 0 : _data$poll.value);
    anchor.setAttribute('data-tappin-poll-name', data === null || data === void 0 || (_data$poll2 = data.poll) === null || _data$poll2 === void 0 ? void 0 : _data$poll2.label);
    anchor.setAttribute('data-tappin-poll-type', data === null || data === void 0 || (_data$poll3 = data.poll) === null || _data$poll3 === void 0 ? void 0 : _data$poll3.type);
    anchor.setAttribute('class', 'button');
  }
  if (element === elementType.BUTTON) {
    anchor.href = data.url || data.urlSelect;
    anchor.setAttribute('data-tappin-email', 'button');
    anchor.setAttribute('data-mce-href', data.url || data.urlSelect);
    anchor.setAttribute('target', '_blank');
  }
  if (element === elementType.INFOPAGE) {
    var _data$infoPage, _data$infoPage2;
    anchor.setAttribute('data-tappin-infoPage-id', data === null || data === void 0 || (_data$infoPage = data.infoPage) === null || _data$infoPage === void 0 ? void 0 : _data$infoPage.value);
    anchor.setAttribute('data-tappin-infoPage-name', data === null || data === void 0 || (_data$infoPage2 = data.infoPage) === null || _data$infoPage2 === void 0 ? void 0 : _data$infoPage2.label);
    anchor.setAttribute('class', 'button');
  }
  if (element === elementType.SESSION) {
    var _data$session, _data$session2, _data$session3;
    anchor.setAttribute('data-tappin-session-id', data === null || data === void 0 || (_data$session = data.session) === null || _data$session === void 0 ? void 0 : _data$session.value);
    anchor.setAttribute('data-tappin-session-name', data === null || data === void 0 || (_data$session2 = data.session) === null || _data$session2 === void 0 ? void 0 : _data$session2.label);
    anchor.setAttribute('data-tappin-session-liveUrl', data === null || data === void 0 || (_data$session3 = data.session) === null || _data$session3 === void 0 ? void 0 : _data$session3.liveUrl);
    anchor.setAttribute('class', 'button');
  }
  if (element === elementType.SPONSOR) {
    var _data$sponsor, _data$sponsor2;
    anchor.setAttribute('data-tappin-sponsor-id', data === null || data === void 0 || (_data$sponsor = data.sponsor) === null || _data$sponsor === void 0 ? void 0 : _data$sponsor.value);
    anchor.setAttribute('data-tappin-sponsor-name', data === null || data === void 0 || (_data$sponsor2 = data.sponsor) === null || _data$sponsor2 === void 0 ? void 0 : _data$sponsor2.label);
    anchor.setAttribute('class', 'button');
  }
  if (element === elementType.SPEAKER) {
    var _data$speaker, _data$speaker2;
    anchor.setAttribute('data-tappin-speaker-id', data === null || data === void 0 || (_data$speaker = data.speaker) === null || _data$speaker === void 0 ? void 0 : _data$speaker.value);
    anchor.setAttribute('data-tappin-speaker-name', data === null || data === void 0 || (_data$speaker2 = data.speaker) === null || _data$speaker2 === void 0 ? void 0 : _data$speaker2.label);
    anchor.setAttribute('class', 'button');
  }
  if (element === elementType.AGENDA) {
    var _data$agendaData, _data$agendaData2;
    anchor.setAttribute('data-tappin-agenda-session', data === null || data === void 0 || (_data$agendaData = data.agendaData) === null || _data$agendaData === void 0 ? void 0 : _data$agendaData.value);
    anchor.setAttribute('data-tappin-agenda-session-name', data === null || data === void 0 || (_data$agendaData2 = data.agendaData) === null || _data$agendaData2 === void 0 ? void 0 : _data$agendaData2.label);
    anchor.setAttribute('class', 'button');
  }
  if (element === elementType.USER_PROFILE) {
    var _data$userProfile;
    anchor.setAttribute('data-tappin-userProfile', data === null || data === void 0 || (_data$userProfile = data.userProfile) === null || _data$userProfile === void 0 ? void 0 : _data$userProfile.value);
    anchor.setAttribute('class', 'button');
  }
  if (element === elementType.PARTICIPANT_LIST) {
    var _data$participantList;
    anchor.setAttribute('data-tappin-participants', data === null || data === void 0 || (_data$participantList = data.participantList) === null || _data$participantList === void 0 ? void 0 : _data$participantList.value);
    anchor.setAttribute('class', 'button');
  }
  if (element === elementType.DOCUMENTS) {
    var _data$documents;
    anchor.setAttribute('data-tappin-documents', data === null || data === void 0 || (_data$documents = data.documents) === null || _data$documents === void 0 ? void 0 : _data$documents.value);
    anchor.setAttribute('class', 'button');
  }
  if (platforms.length === 1) {
    anchor.setAttribute('data-tappin-platforms', platforms[0]);
  }
  anchor.text = (_data$label = data.label) !== null && _data$label !== void 0 ? _data$label : 'Preview';
  anchor.setAttribute('data-tappin-color', color);
  anchor.setAttribute('data-tappin-background-color', backgroundColor);
  var styles = {
    borderRadius: '4px',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '24px',
    padding: '12px 24px',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'opacity 0.1s ease-in',
    color: color,
    backgroundColor: backgroundColor,
    fontFamily: 'Ubuntu, sans-serif'
  };
  Object.assign(anchor.style, styles);
  anchor.setAttribute('data-mce-style', anchor === null || anchor === void 0 || (_anchor$style = anchor.style) === null || _anchor$style === void 0 ? void 0 : _anchor$style.cssText);
  return anchor;
}
function getInitialData(info) {
  var _anchor$label, _anchor$url, _anchor$url2, _anchor$color, _anchor$backgroundCol;
  var anchor = info.anchor;
  return {
    label: (_anchor$label = anchor.label) !== null && _anchor$label !== void 0 ? _anchor$label : '',
    url: isOneOf(anchor.url, registrationUrlTag, declineUrlTag) ? '' : (_anchor$url = anchor.url) !== null && _anchor$url !== void 0 ? _anchor$url : '',
    urlSelect: !isOneOf(anchor.url, registrationUrlTag, declineUrlTag) ? '' : (_anchor$url2 = anchor.url) !== null && _anchor$url2 !== void 0 ? _anchor$url2 : '',
    color: (_anchor$color = anchor.color) !== null && _anchor$color !== void 0 ? _anchor$color : '',
    backgroundColor: (_anchor$backgroundCol = anchor.backgroundColor) !== null && _anchor$backgroundCol !== void 0 ? _anchor$backgroundCol : ''
  };
}
export { getInitialData, toggleState, collect, elementType };