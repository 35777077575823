import { appIntl } from '../../IntlGlobalProvider';
var productPeriodUnit = {
  UNKNOWN: 0,
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEAR: 4
};
var productPricingModel = {
  UNKNOWN: 0,
  FLAT_FEE: 1,
  PER_UNIT: 2,
  TIERED: 3,
  VOLUME: 4,
  STAIRSTEP: 5
};
var productStatus = {
  UNKNOWN: 0,
  ACTIVE: 1,
  ARCHIVED: 2,
  DELETED: 3
};
var productType = {
  UNKNOWN: 0,
  PLAN: 1,
  ADDON: 2,
  CHARGE: 3
};
function getProductPeriodUnitlabel() {
  var intl = appIntl();
  return [{
    unit: productPeriodUnit.DAY,
    label: intl.formatMessage({
      id: 'day',
      defaultMessage: 'Day'
    })
  }, {
    unit: productPeriodUnit.WEEK,
    label: intl.formatMessage({
      id: 'week',
      defaultMessage: 'Week'
    })
  }, {
    unit: productPeriodUnit.MONTH,
    label: intl.formatMessage({
      id: 'month',
      defaultMessage: 'Month'
    })
  }, {
    unit: productPeriodUnit.YEAR,
    label: intl.formatMessage({
      id: 'year',
      defaultMessage: 'Year'
    })
  }];
}
export { productPeriodUnit, productPricingModel, productStatus, productType, getProductPeriodUnitlabel };