import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponse } from '../helpers/request-helpers';
var infoPagesService = {
  getInfoPages: getInfoPages,
  createInfoPage: createInfoPage,
  deleteInfoPage: deleteInfoPage,
  updateInfoPage: updateInfoPage,
  updatePartialInfoPage: updatePartialInfoPage,
  reorderInfoPages: reorderInfoPages,
  countInfoPages: countInfoPages
};
function getInfoPages(eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/info-pages"), requestOptions).then(handleResponse);
}
function createInfoPage(eventId, infoPage) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify(infoPage)
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/info-pages/create"), requestOptions).then(handleResponse);
}
function updateInfoPage(eventId, entryId, infoPage) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify(infoPage)
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/info-pages/").concat(entryId), requestOptions).then(handleResponse);
}
function updatePartialInfoPage(eventId, entryId, infoPage) {
  var requestOptions = {
    method: 'PATCH',
    headers: requestHeader(),
    body: JSON.stringify({
      isHidden: infoPage.isHidden
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/info-pages/").concat(entryId), requestOptions).then(handleResponse);
}
function deleteInfoPage(eventId, entryId) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/info-pages/").concat(entryId), requestOptions).then(handleResponse);
}
function reorderInfoPages(eventId, infoPageIds) {
  var requestOptions = {
    method: 'PATCH',
    headers: requestHeader(),
    body: JSON.stringify({
      infoPageIds: infoPageIds
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/info-pages/reorder"), requestOptions).then(handleResponse);
}
function countInfoPages(eventId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/info-pages/count"), requestOptions).then(handleResponse);
}
export default infoPagesService;