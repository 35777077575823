var categoriesConstants = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
  UPDATE_CATEGORIES_FAILURE: 'UPDATE_CATEGORIES_FAILURE',
  DELETE_CATEGORIES: 'DELETE_CATEGORIES',
  DELETE_CATEGORIES_SUCCESS: 'DELETE_CATEGORIES_SUCCESS',
  DELETE_CATEGORIES_FAILURE: 'DELETE_CATEGORIES_FAILURE'
};
export default categoriesConstants;