import "core-js/modules/es.array.concat.js";
import { useEffect, useRef } from 'react';
export var useEventListener = function useEventListener(target, type, listener) {
  for (var _len = arguments.length, options = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    options[_key - 3] = arguments[_key];
  }
  if (!listener) {
    // eslint-disable-next-line no-console
    console.warn('listener is not set');
  }
  useEffect(function () {
    // eslint-disable-next-line no-prototype-builtins
    var targetIsRef = target.hasOwnProperty('current');
    var currentTarget = targetIsRef ? target.current : target;
    if (currentTarget) {
      currentTarget.addEventListener.apply(currentTarget, [type, listener].concat(options));
    }
    return function () {
      if (currentTarget) {
        currentTarget.removeEventListener.apply(currentTarget, [type, listener].concat(options));
      }
    };
  }, [target, type, listener, options]);
};
export function useOnClickOutside(ref, handler) {
  useEffect(function () {
    var listener = function listener(event) {
      // Do nothing if clicking ref's element or descendent elements

      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return function () {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
export function usePrevious(value) {
  var ref = useRef();
  useEffect(function () {
    ref.current = value;
  });
  return ref.current;
}