import "core-js/modules/es.object.keys.js";
function isUserLoggedIn() {
  var storageUser = localStorage.getItem('user');
  return !!storageUser;
}
function isProfileComplete() {
  var _user;
  var storageUser = localStorage.getItem('user');
  var user;
  try {
    user = JSON.parse(storageUser);
  } catch (e) {
    user = undefined;
  }
  return (_user = user) === null || _user === void 0 ? void 0 : _user.profileCompleted;
}
export default isProfileComplete;
export { isProfileComplete, isUserLoggedIn };