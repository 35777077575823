function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import { elementType } from '../constants/types';
export function isReservationsSelected(values, elements) {
  var elementsWithQuantities = elements.filter(function (x) {
    var _x$options, _x$options2;
    return (x.type === elementType.DROPDOWN || x.type === elementType.GROUPED && ((_x$options = x.options) === null || _x$options === void 0 || (_x$options = _x$options.elements) === null || _x$options === void 0 ? void 0 : _x$options.every(function (y) {
      return y.type === elementType.CHECKBOX || y.type === elementType.RADIO;
    }))) && ((_x$options2 = x.options) === null || _x$options2 === void 0 || (_x$options2 = _x$options2.elements) === null || _x$options2 === void 0 ? void 0 : _x$options2.some(function (y) {
      var _y$options;
      return ((_y$options = y.options) === null || _y$options === void 0 ? void 0 : _y$options.quantity) > 0;
    }));
  }).flatMap(function (element) {
    var _element$options$elem, _element$options;
    return _toConsumableArray((_element$options$elem = (_element$options = element.options) === null || _element$options === void 0 || (_element$options = _element$options.elements) === null || _element$options === void 0 || (_element$options = _element$options.filter(function (y) {
      var _y$options2;
      return ((_y$options2 = y.options) === null || _y$options2 === void 0 ? void 0 : _y$options2.quantity) > 0;
    })) === null || _element$options === void 0 ? void 0 : _element$options.map(function (subElement) {
      var _element$options2, _subElement$options;
      return {
        groupId: element.id,
        id: subElement.id,
        isLabelBased: element.type === elementType.DROPDOWN || element.type === elementType.GROUPED && ((_element$options2 = element.options) === null || _element$options2 === void 0 || (_element$options2 = _element$options2.elements) === null || _element$options2 === void 0 ? void 0 : _element$options2.every(function (x) {
          return x.type === elementType.RADIO;
        })),
        label: subElement === null || subElement === void 0 || (_subElement$options = subElement.options) === null || _subElement$options === void 0 ? void 0 : _subElement$options.label
      };
    })) !== null && _element$options$elem !== void 0 ? _element$options$elem : []);
  });
  return Object.entries(values).some(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    return elementsWithQuantities.some(function (x) {
      return x.isLabelBased ? x.groupId === key && x.label === value : x.id === key && !!value;
    });
  });
}
export default isReservationsSelected;