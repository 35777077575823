var reservationConstants = {
  GET_QUANTITY: 'RESERVATION_GET_QUANTITY',
  GET_QUANTITY_SUCCESS: 'RESERVATION_GET_QUANTITY_SUCCESS',
  GET_QUANTITY_FAILURE: 'RESERVATION_GET_QUANTITY_FAILURE',
  GET_RESERVATIONS: 'RESERVATION_GET_RESERVATIONS',
  GET_RESERVATIONS_SUCCESS: 'RESERVATION_GET_RESERVATIONS_SUCCESS',
  GET_RESERVATIONS_FAILURE: 'RESERVATION_GET_RESERVATIONS_FAILURE',
  RELEASE_RESERVATIONS: 'RESERVATION_RELEASE_RESERVATIONS',
  RELEASE_RESERVATIONS_SUCCESS: 'RESERVATION_RELEASE_RESERVATIONS_SUCCESS',
  RELEASE_RESERVATIONS_FAILURE: 'RESERVATION_RELEASE_RESERVATIONS_FAILURE',
  RESERVE: 'RESERVATION_RESERVE',
  RESERVE_SUCCESS: 'RESERVATION_RESERVE_SUCCESS',
  RESERVE_FAILURE: 'RESERVATION_RESERVE_FAILURE',
  CLEAR_RESERVATIONS: 'RESERVATION_CLEAR_RESERVATIONS',
  GET_RESERVATIONS_STATISTICS: 'GET_RESERVATIONS_STATISTICS',
  GET_RESERVATIONS_STATISTICS_SUCCESS: 'GET_RESERVATIONS_STATISTICS_SUCCESS',
  GET_RESERVATIONS_STATISTICS_FAILURE: 'GET_RESERVATIONS_STATISTICS_FAILURE'
};
export default reservationConstants;