import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneLines, faStar, faRankingStar } from '@fortawesome/pro-solid-svg-icons';
import shortid from 'shortid';
import { moduleType, lobbyElementCategory } from '../../../constants/types';
import { appIntl } from '../../../IntlGlobalProvider';
export function getToolboxElements() {
  var intl = appIntl();
  var banner = {
    id: 'toolboxBanner',
    title: intl.formatMessage({
      id: 'LobbyPageBuilder.Toolbox.Banner',
      defaultMessage: 'Banner'
    }),
    icon: 'BANNER',
    quantity: 1,
    element: {
      id: 'banner',
      type: moduleType.BANNER,
      category: lobbyElementCategory.MODULE,
      options: {
        label: intl.formatMessage({
          id: 'LobbyPageBuilder.Toolbox.Banner_label',
          defaultMessage: 'Banner'
        }),
        topSponsorOptions: {
          elements: [],
          size: 100
        }
      }
    },
    mandatory: false
  };
  var infoPages = {
    id: 'toolboxInformation',
    title: intl.formatMessage({
      id: 'LobbyPageBuilder.Toolbox.Information',
      defaultMessage: 'Information'
    }),
    icon: faInfoCircle,
    quantity: 1,
    element: {
      id: 'information',
      type: moduleType.INFO_PAGE,
      category: lobbyElementCategory.MODULE,
      options: {
        label: intl.formatMessage({
          id: 'LobbyPageBuilder.Toolbox.Information_label',
          defaultMessage: 'Information'
        }),
        text: intl.formatMessage({
          id: 'LobbyPageBuilder.Toolbox.Text_value',
          defaultMessage: 'Text'
        })
      }
    },
    mandatory: false
  };
  var agenda = {
    id: 'toolboxAgenda',
    title: intl.formatMessage({
      id: 'LobbyPageBuilder.Toolbox.Agenda',
      defaultMessage: 'Agenda'
    }),
    icon: faCalendarAlt,
    quantity: 1,
    element: {
      id: 'agenda',
      type: moduleType.AGENDA,
      category: lobbyElementCategory.MODULE,
      options: {
        label: intl.formatMessage({
          id: 'LobbyPageBuilder.Toolbox.Agenda_label',
          defaultMessage: 'Agenda'
        })
      }
    },
    mandatory: false
  };
  var speakers = {
    id: 'toolboxSpeakers',
    title: intl.formatMessage({
      id: 'LobbyPageBuilder.Toolbox.Speakers',
      defaultMessage: 'Speakers'
    }),
    icon: faMicrophoneLines,
    quantity: 1,
    element: {
      id: 'speakers',
      type: moduleType.SPEAKER,
      category: lobbyElementCategory.MODULE,
      options: {
        label: intl.formatMessage({
          id: 'LobbyPageBuilder.Toolbox.Speakers_label',
          defaultMessage: 'Speakers'
        })
      }
    },
    mandatory: false
  };
  var sponsors = {
    id: 'toolboxSponsors',
    title: intl.formatMessage({
      id: 'LobbyPageBuilder.Toolbox.Sponsors',
      defaultMessage: 'Sponsors'
    }),
    icon: faStar,
    quantity: 1,
    element: {
      id: 'sponsors',
      type: moduleType.SPONSOR,
      category: lobbyElementCategory.MODULE,
      options: {
        label: intl.formatMessage({
          id: 'LobbyPageBuilder.Toolbox.Sponsors_label',
          defaultMessage: 'Sponsors'
        })
      }
    },
    mandatory: false
  };
  var topSponsors = {
    id: 'toolboxTopSponsors',
    title: intl.formatMessage({
      id: 'LobbyPageBuilder.Toolbox.Top_sponsors',
      defaultMessage: 'Top sponsors'
    }),
    icon: faRankingStar,
    element: {
      id: shortid.generate(),
      type: moduleType.TOP_SPONSORS,
      category: lobbyElementCategory.MODULE,
      options: {
        label: intl.formatMessage({
          id: 'LobbyPageBuilder.Toolbox.Top_sponsors_label',
          defaultMessage: 'Top sponsors'
        }),
        size: 100,
        elements: []
      }
    },
    mandatory: false
  };
  return [banner, infoPages, agenda, speakers, sponsors, topSponsors];
}
export default getToolboxElements;