import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { createBrowserHistory } from 'history';
var history = createBrowserHistory();
function historyPush(navigateTo) {
  if (navigateTo != '') {
    history.push(navigateTo);
  }
}
function historyReplace(newUrl) {
  if (newUrl !== '') {
    history.replace(newUrl);
  }
}
function createListener(callback) {
  return history.listen(callback);
}
function getLastPage() {
  var route = history.location.pathname;
  if (!route) {
    return '';
  }
  return route.substring(route.lastIndexOf('/') + 1);
}
function getCurrentPath() {
  var route = history.location.pathname;
  if (!route) {
    return '';
  }
  return route;
}
export { history, historyPush, historyReplace, createListener, getLastPage, getCurrentPath };