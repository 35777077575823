import { appIntl } from '../../IntlGlobalProvider';
var groupType = {
  MANUAL: 0,
  AUTOMATIC: 1
};
var groupSubType = {
  FORM_ELEMENT: 0,
  FIELD: 1
};
function getTypeOptions() {
  var intl = appIntl();
  return [{
    label: intl.formatMessage({
      id: 'manual',
      defaultMessage: 'Manual'
    }),
    value: groupType.MANUAL
  }, {
    label: intl.formatMessage({
      id: 'automatic',
      defaultMessage: 'Automatic'
    }),
    value: groupType.AUTOMATIC
  }];
}
export default groupType;
export { groupType, groupSubType, getTypeOptions };