var emailLogConstants = {
  GET_EMAIL_LOG_REQUEST: 'GET_EMAIL_LOG_REQUEST',
  GET_EMAIL_LOG_SUCCESS: 'GET_EMAIL_LOG_SUCCESS',
  GET_EMAIL_LOG_FAILURE: 'GET_EMAIL_LOG_FAILURE',
  CANCEL_EMAIL_LOG_REQUEST: 'CANCEL_EMAIL_LOG_REQUEST',
  CANCEL_EMAIL_LOG_SUCCESS: 'CANCEL_EMAIL_LOG_SUCCESS',
  CANCEL_EMAIL_LOG_FAILURE: 'CANCEL_EMAIL_LOG_FAILURE',
  EXPORT_EMAIL_LOG_REQUEST: 'EXPORT_EMAIL_LOG_REQUEST',
  EXPORT_EMAIL_LOG_SUCCESS: 'EXPORT_EMAIL_LOG_SUCCESS',
  EXPORT_EMAIL_LOG_FAILURE: 'EXPORT_EMAIL_LOG_FAILURE'
};
export default emailLogConstants;