var _types;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var backgroundPositionType = {
  LEFT_TOP: 0,
  LEFT_CENTER: 1,
  LEFT_BOTTOM: 2,
  RIGHT_TOP: 3,
  RIGHT_CENTER: 4,
  RIGHT_BOTTOM: 5,
  CENTER_TOP: 6,
  CENTER_CENTER: 7,
  CENTER_BOTTOM: 8
};
var types = (_types = {
  0: 'center center'
}, _defineProperty(_types, backgroundPositionType.LEFT_TOP, 'left top'), _defineProperty(_types, backgroundPositionType.LEFT_CENTER, 'left center'), _defineProperty(_types, backgroundPositionType.LEFT_BOTTOM, 'left bottom'), _defineProperty(_types, backgroundPositionType.RIGHT_TOP, 'right top'), _defineProperty(_types, backgroundPositionType.RIGHT_CENTER, 'right center'), _defineProperty(_types, backgroundPositionType.RIGHT_BOTTOM, 'right bottom'), _defineProperty(_types, backgroundPositionType.CENTER_TOP, 'center top'), _defineProperty(_types, backgroundPositionType.CENTER_CENTER, 'center center'), _defineProperty(_types, backgroundPositionType.CENTER_BOTTOM, 'center bottom'), _types);
function positionToString(type) {
  return types[type];
}
export { backgroundPositionType, positionToString };