var timelineConstants = {
  GET_TIMELINE: 'TIMELINE_GET',
  GET_TIMELINE_SUCCESS: 'TIMELINE_GET_SUCCESS',
  GET_TIMELINE_FAILURE: 'TIMELINE_GET_FAILURE',
  DELETE_TIMELINE_ENTRY: 'TIMELINE_DELETE_ENTRY',
  DELETE_TIMELINE_ENTRY_SUCCESS: 'TIMELINE_DELETE_ENTRY_SUCCESS',
  DELETE_TIMELINE_ENTRY_FAILURE: 'TIMELINE_DELETE_ENTRY_FAILURE',
  EXPORT_TIMELINE: 'TIMELINE_EXPORT',
  EXPORT_TIMELINE_SUCCESS: 'TIMELINE_EXPORT_SUCCESS',
  EXPORT_TIMELINE_FAILURE: 'TIMELINE_EXPORT_FAILURE'
};
export default timelineConstants;