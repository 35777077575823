import agendaConstants from '../constants/actions/agenda-constants';
import agendaService from '../services/agenda-service';
var agendaActions = {
  getByEventId: getByEventId,
  addTrack: addTrack,
  addSession: addSession,
  updateTrack: updateTrack,
  updateSession: updateSession,
  deleteTrack: deleteTrack,
  deleteSession: deleteSession,
  clearErrors: clearErrors,
  getSessionsByEventId: getSessionsByEventId
};
function getByEventId(payload) {
  return function (dispatch) {
    dispatch(request());
    agendaService.getAgendaByEventId(payload).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: agendaConstants.GET_AGENDA_BY_EVENT_ID_REQUEST
    };
  }
  function success(result) {
    return {
      type: agendaConstants.GET_AGENDA_BY_EVENT_ID_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: agendaConstants.GET_AGENDA_BY_EVENT_ID_ERROR,
      error: error
    };
  }
}
function addTrack(eventIdOrSlug, agendaId, track, callback) {
  return function (dispatch) {
    dispatch(request());
    agendaService.addTrack(eventIdOrSlug, agendaId, track).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback(result.track.id);
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: agendaConstants.ADD_TRACK_REQUEST
    };
  }
  function success(result) {
    return {
      type: agendaConstants.ADD_TRACK_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: agendaConstants.ADD_TRACK_ERROR,
      error: error
    };
  }
}
function addSession(eventIdOrSlug, agendaId, session, callback) {
  return function (dispatch) {
    dispatch(request());
    agendaService.addSession(eventIdOrSlug, agendaId, session).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback(result.session);
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: agendaConstants.ADD_SESSION_REQUEST
    };
  }
  function success(result) {
    return {
      type: agendaConstants.ADD_SESSION_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: agendaConstants.ADD_SESSION_ERROR,
      error: error
    };
  }
}
function updateTrack(eventIdOrSlug, agendaId, track, callback) {
  return function (dispatch) {
    dispatch(request());
    agendaService.updateTrack(eventIdOrSlug, agendaId, track).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: agendaConstants.UPDATE_TRACK_REQUEST
    };
  }
  function success(result) {
    return {
      type: agendaConstants.UPDATE_TRACK_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: agendaConstants.UPDATE_TRACK_ERROR,
      error: error
    };
  }
}
function updateSession(eventIdOrSlug, agendaId, session, successCallback, failureCallback) {
  return function (dispatch) {
    dispatch(request());
    agendaService.updateSession(eventIdOrSlug, agendaId, session).then(function (result) {
      dispatch(success(result));
      if (successCallback) {
        successCallback(result.session);
      }
    }, function (error) {
      dispatch(failure(error));
      if (failureCallback) {
        failureCallback();
      }
    });
  };
  function request() {
    return {
      type: agendaConstants.UPDATE_SESSION_REQUEST
    };
  }
  function success(result) {
    return {
      type: agendaConstants.UPDATE_SESSION_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: agendaConstants.UPDATE_SESSION_ERROR,
      error: error
    };
  }
}
function deleteTrack(eventIdOrSlug, agendaId, trackId, callback) {
  return function (dispatch) {
    dispatch(request());
    agendaService.deleteTrack(eventIdOrSlug, agendaId, trackId).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: agendaConstants.DELETE_TRACK_REQUEST
    };
  }
  function success(result) {
    return {
      type: agendaConstants.DELETE_TRACK_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: agendaConstants.DELETE_TRACK_ERROR,
      error: error
    };
  }
}
function deleteSession(eventIdOrSlug, agendaId, sessionId, callback) {
  return function (dispatch) {
    dispatch(request());
    agendaService.deleteSession(eventIdOrSlug, agendaId, sessionId).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: agendaConstants.DELETE_SESSION_REQUEST
    };
  }
  function success(result) {
    return {
      type: agendaConstants.DELETE_SESSION_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: agendaConstants.DELETE_SESSION_ERROR,
      error: error
    };
  }
}
function clearErrors() {
  return function (dispatch) {
    dispatch({
      type: agendaConstants.CLEAR_ERRORS
    });
  };
}
function getSessionsByEventId(eventIdOrSlug) {
  return function (dispatch) {
    dispatch(request());
    agendaService.getSessionsByEventId(eventIdOrSlug).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: agendaConstants.GET_SESSIONS_BY_EVENT_ID_REQUEST
    };
  }
  function success(result) {
    return {
      type: agendaConstants.GET_SESSIONS_BY_EVENT_ID_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: agendaConstants.GET_SESSIONS_BY_EVENT_ID_ERROR,
      error: error
    };
  }
}
export default agendaActions;