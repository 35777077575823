import promotedMessagesService from '../services/promoted-messages-service';
import { promotedMessagesConstants } from '../constants/actions';
import messageActions from './message-actions';
import { appIntl } from '../IntlGlobalProvider';
var promotedMessagesActions = {
  getPromotedMessages: getPromotedMessages,
  createPromotedMessage: createPromotedMessage,
  updatePromotedMessage: updatePromotedMessage,
  updatePartialPromotedMessage: updatePartialPromotedMessage,
  deletePromotedMessage: deletePromotedMessage
};
function getPromotedMessages(eventId) {
  return function (dispatch) {
    dispatch(request());
    promotedMessagesService.getPromotedMessages(eventId).then(function (promotedMessages) {
      return dispatch(success(promotedMessages));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: promotedMessagesConstants.GET_PROMOTED_MESSAGES
    };
  }
  function success(promotedMessages) {
    return {
      type: promotedMessagesConstants.GET_PROMOTED_MESSAGES_SUCCESS,
      promotedMessages: promotedMessages
    };
  }
  function failure(error) {
    return {
      type: promotedMessagesConstants.GET_PROMOTED_MESSAGES_FAILURE,
      error: error
    };
  }
}
function createPromotedMessage(eventId, promotedMessage, onMessagesUpdated) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(createMessage());
    promotedMessagesService.createPromotedMessage(eventId, promotedMessage).then(function (message) {
      dispatch(success(message));
      if (onMessagesUpdated) {
        onMessagesUpdated();
      }
      dispatch(messageActions.success(intl.formatMessage({
        id: 'promoted_message_successful',
        defaultMessage: 'Promoted message was successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function createMessage() {
    return {
      type: promotedMessagesConstants.CREATE_PROMOTED_MESSAGE
    };
  }
  function success() {
    return {
      type: promotedMessagesConstants.CREATE_PROMOTED_MESSAGE_SUCCESS,
      promotedMessage: promotedMessage
    };
  }
  function failure(error) {
    return {
      type: promotedMessagesConstants.CREATE_PROMOTED_MESSAGE_FAILURE,
      error: error
    };
  }
}
function updatePromotedMessage(eventId, messageId, promotedMessage) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(updateMessage(promotedMessage));
    promotedMessagesService.updatePromotedMessage(eventId, messageId, promotedMessage).then(function (message) {
      dispatch(success(message));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'PromotedMessage.Changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function updateMessage() {
    return {
      type: promotedMessagesConstants.UPDATE_PROMOTED_MESSAGE,
      promotedMessage: promotedMessage
    };
  }
  function success(message) {
    return {
      type: promotedMessagesConstants.UPDATE_PROMOTED_MESSAGE_SUCCESS,
      promotedMessage: message
    };
  }
  function failure(error) {
    return {
      type: promotedMessagesConstants.UPDATE_PROMOTED_MESSAGE_FAILURE,
      error: error
    };
  }
}
function updatePartialPromotedMessage(eventId, messageId, promotedMessage) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(updateMessage({
      id: messageId,
      request: promotedMessage
    }));
    promotedMessagesService.updatePartialPromotedMessage(eventId, messageId, promotedMessage).then(function (message) {
      dispatch(success(message));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'PromotedMessage.Changes_successful',
        defaultMessage: 'Changes were successfully saved'
      })));
    }, function (error) {
      return dispatch(failure(messageId, error));
    });
  };
  function updateMessage(message) {
    return {
      type: promotedMessagesConstants.UPDATE_PROMOTED_PARTIAL_MESSAGE,
      message: message
    };
  }
  function success(message) {
    return {
      type: promotedMessagesConstants.UPDATE_PROMOTED_PARTIAL_MESSAGE_SUCCESS,
      message: message
    };
  }
  function failure(error) {
    return {
      type: promotedMessagesConstants.UPDATE_PROMOTED_PARTIAL_MESSAGE_FAILURE,
      message: {
        messageId: messageId,
        error: error
      }
    };
  }
}
function deletePromotedMessage(eventId, messageId) {
  var intl = appIntl();
  return function (dispatch) {
    dispatch(request(messageId));
    promotedMessagesService.deletePromotedMessage(eventId, messageId).then(function (isSuccess) {
      dispatch(success(isSuccess));
      dispatch(messageActions.success(intl.formatMessage({
        id: 'PromotedMessage.Delete_successful',
        defaultMessage: 'Message was successfully deleted'
      })));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: promotedMessagesConstants.DELETE_PROMOTED_MESSAGE,
      messageId: messageId
    };
  }
  function success(isSuccess) {
    var message = {
      isSuccess: isSuccess,
      messageId: messageId
    };
    return {
      type: promotedMessagesConstants.DELETE_PROMOTED_MESSAGE_SUCCESS,
      message: message
    };
  }
  function failure(error) {
    return {
      type: promotedMessagesConstants.DELETE_PROMOTED_MESSAGE_FAILURE,
      error: error
    };
  }
}
export default promotedMessagesActions;