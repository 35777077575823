import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponse } from '../helpers/request-helpers';
var actions = {
  getUpdatedEvents: getUpdatedEvents,
  getUpcomingEvents: getUpcomingEvents,
  getNewEvents: getNewEvents
};
function getUpdatedEvents(limit) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/updated/").concat(limit), requestOptions).then(handleResponse);
}
function getUpcomingEvents() {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/upcoming"), requestOptions).then(handleResponse);
}
function getNewEvents(limit) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/newest/").concat(limit), requestOptions).then(handleResponse);
}
export default actions;