import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { requestHeader, handleResponse, handleResponseWithFormFields } from '../helpers/request-helpers';
var workspaceService = {
  getAll: getAll,
  getWorkspace: getWorkspace,
  createWorkspace: createWorkspace,
  updateWorkspace: updateWorkspace,
  updateWorkspaceUsers: updateWorkspaceUsers,
  deleteWorkspaceUser: deleteWorkspaceUser,
  getWorkspaceLimitsSummary: getWorkspaceLimitsSummary,
  getWorkspaceStatistics: getWorkspaceStatistics,
  getEmailIdentities: getEmailIdentities,
  verifyEmailIdentity: verifyEmailIdentity,
  updateEmailIdentity: updateEmailIdentity,
  deleteEmailIdentity: deleteEmailIdentity,
  updateWorkspaceFeatures: updateWorkspaceFeatures,
  getWorkspaceFeatures: getWorkspaceFeatures
};
function getAll() {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/workspace"), requestOptions).then(handleResponse);
}
function getWorkspace(workspaceId, suppressNotFound) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  if (workspaceId === undefined) {
    return fetch("".concat(config.apiUrl, "/workspace/own"), requestOptions).then(function (response) {
      return handleResponse(response, suppressNotFound);
    });
  }
  return fetch("".concat(config.apiUrl, "/workspace/").concat(workspaceId), requestOptions).then(function (response) {
    return handleResponse(response);
  });
}
function createWorkspace(workspace) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify(workspace)
  };
  return fetch("".concat(config.apiUrl, "/workspace/create"), requestOptions).then(handleResponse);
}
function updateWorkspace(workspace, workspaceId) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify(workspace)
  };
  return fetch("".concat(config.apiUrl, "/workspace/").concat(workspaceId), requestOptions).then(handleResponseWithFormFields);
}
function updateWorkspaceUsers(users, workspaceId) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify({
      workspaceId: workspaceId,
      users: users
    })
  };
  return fetch("".concat(config.apiUrl, "/workspace/users"), requestOptions).then(handleResponse);
}
function deleteWorkspaceUser(workspaceId, email) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader(),
    body: JSON.stringify({
      workspaceId: workspaceId,
      email: email
    })
  };
  return fetch("".concat(config.apiUrl, "/workspace/user"), requestOptions).then(handleResponse);
}
function getWorkspaceLimitsSummary(workspaceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/workspace/").concat(workspaceId, "/limits"), requestOptions).then(handleResponse);
}
function getWorkspaceStatistics(workspaceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/workspace/").concat(workspaceId, "/statistics"), requestOptions).then(handleResponse);
}
function getEmailIdentities(workspaceIdOrSlug) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/communication/workspace/").concat(workspaceIdOrSlug, "/emailIdentities"), requestOptions).then(handleResponse);
}
function verifyEmailIdentity(workspaceIdOrSlug, emailIdentity) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify(emailIdentity)
  };
  return fetch("".concat(config.apiUrl, "/communication/workspace/").concat(workspaceIdOrSlug, "/emailIdentities"), requestOptions).then(handleResponse);
}
function updateEmailIdentity(workspaceIdOrSlug, emailIdentity) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify(emailIdentity)
  };
  return fetch("".concat(config.apiUrl, "/communication/workspace/").concat(workspaceIdOrSlug, "/emailIdentities/").concat(emailIdentity.id), requestOptions).then(handleResponse);
}
function deleteEmailIdentity(workspaceIdOrSlug, emailIdentityId) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/communication/workspace/").concat(workspaceIdOrSlug, "/emailIdentities/").concat(emailIdentityId), requestOptions).then(handleResponse);
}
function updateWorkspaceFeatures(features, workspaceId, type) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify(features)
  };
  return fetch("".concat(config.apiUrl, "/workspace/").concat(workspaceId, "/features/").concat(type), requestOptions).then(handleResponseWithFormFields);
}
function getWorkspaceFeatures(workspaceId) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/workspace/").concat(workspaceId, "/features"), requestOptions).then(function (response) {
    return handleResponse(response);
  });
}
export default workspaceService;