var workspaceConstants = {
  GETALL_REQUEST: 'WORKSPACES_GETALL_REQUEST',
  GETALL_SUCCESS: 'WORKSPACES_GETALL_SUCCESS',
  GETALL_FAILURE: 'WORKSPACES_GETALL_FAILURE',
  GETALL_SIMPLE_REQUEST: 'WORKSPACES_GETALL_SIMPLE_REQUEST',
  GETALL_SIMPLE_SUCCESS: 'WORKSPACES_GETALL_SIMPLE_SUCCESS',
  GETALL_SIMPLE_FAILURE: 'WORKSPACES_GETALL_SIMPLE_FAILURE',
  GET_REQUEST: 'WORKSPACE_GET_REQUEST',
  GET_SUCCESS: 'WORKSPACE_GET_SUCCESS',
  GET_FAILURE: 'WORKSPACE_GET_FAILURE',
  CREATE_REQUEST: 'WORKSPACE_CREATE_REQUEST',
  CREATE_REQUEST_SUCCESS: 'WORKSPACE_CREATE_REQUEST_SUCCESS',
  CREATE_REQUEST_FAILURE: 'WORKSPACE_CREATE_REQUEST_FAILURE',
  UPDATE_REQUEST: 'WORKSPACE_UPDATE_REQUEST',
  UPDATE_REQUEST_SUCCESS: 'WORKSPACE_UPDATE_REQUEST_SUCCESS',
  UPDATE_REQUEST_FAILURE: 'WORKSPACE_UPDATE_REQUEST_FAILURE',
  UPDATE_USERS_REQUEST: 'WORKSPACE_UPDATE_USERS_REQUEST',
  UPDATE_USERS_REQUEST_SUCCESS: 'WORKSPACE_UPDATE_USERS_REQUEST_SUCCESS',
  UPDATE_USERS_REQUEST_FAILURE: 'WORKSPACE_UPDATE_USERS_REQUEST_FAILURE',
  DELETE_USER_REQUEST: 'WORKSPACE_DELETE_USER_REQUEST',
  DELETE_USER_REQUEST_SUCCESS: 'WORKSPACE_DELETE_USER_REQUEST_SUCCESS',
  DELETE_USER_REQUEST_FAILURE: 'WORKSPACE_DELETE_USER_REQUEST_FAILURE',
  GET_LIMITS_SUMMARY_REQUEST: 'WORKSPACES_GET_LIMITS_SUMMARY_REQUEST',
  GET_LIMITS_SUMMARY_SUCCESS: 'WORKSPACES_GET_LIMITS_SUMMARY_SUCCESS',
  GET_LIMITS_SUMMARY_FAILURE: 'WORKSPACES_GET_LIMITS_SUMMARY_FAILURE',
  CLEAR: 'WORKSPACE_CLEAR',
  CLEAR_ERRORS: 'WORKSPACE_CLEAR_ERRORS',
  GET_WORKSPACE_STATISTICS: 'GET_WORKSPACE_STATISTICS',
  GET_WORKSPACE_STATISTICS_SUCCESS: 'GET_WORKSPACE_STATISTICS_SUCCESS',
  GET_WORKSPACE_STATISTICS_FAILURE: 'GET_WORKSPACE_STATISTICS_FAILURE',
  GET_WORKSPACE_EMAIL_IDENTITIES_REQUEST: 'GET_WORKSPACE_EMAIL_IDENTITIES_REQUEST',
  GET_WORKSPACE_EMAIL_IDENTITIES_SUCCESS: 'GET_WORKSPACE_EMAIL_IDENTITIES_SUCCESS',
  GET_WORKSPACE_EMAIL_IDENTITIES_FAILURE: 'GET_WORKSPACE_EMAIL_IDENTITIES_FAILURE',
  VERIFY_WORKSPACE_EMAIL_IDENTITY_REQUEST: 'VERIFY_WORKSPACE_EMAIL_IDENTITY_REQUEST',
  VERIFY_WORKSPACE_EMAIL_IDENTITY_SUCCESS: 'VERIFY_WORKSPACE_EMAIL_IDENTITY_SUCCESS',
  VERIFY_WORKSPACE_EMAIL_IDENTITY_FAILURE: 'VERIFY_WORKSPACE_EMAIL_IDENTITY_FAILURE',
  UPDATE_WORKSPACE_EMAIL_IDENTITY_REQUEST: 'UPDATE_WORKSPACE_EMAIL_IDENTITY_REQUEST',
  UPDATE_WORKSPACE_EMAIL_IDENTITY_SUCCESS: 'UPDATE_WORKSPACE_EMAIL_IDENTITY_SUCCESS',
  UPDATE_WORKSPACE_EMAIL_IDENTITY_FAILURE: 'UPDATE_WORKSPACE_EMAIL_IDENTITY_FAILURE',
  DELETE_WORKSPACE_EMAIL_IDENTITY_REQUEST: 'DELETE_WORKSPACE_EMAIL_IDENTITY_REQUEST',
  DELETE_WORKSPACE_EMAIL_IDENTITY_SUCCESS: 'DELETE_WORKSPACE_EMAIL_IDENTITY_SUCCESS',
  DELETE_WORKSPACE_EMAIL_IDENTITY_FAILURE: 'DELETE_WORKSPACE_EMAIL_IDENTITY_FAILURE',
  UPDATE_ALL_NAME_SLUG: 'UPDATE_ALL_NAME_SLUG',
  UPDATE_FEATURES_REQUEST: 'WORKSPACE_FEATURES_UPDATE_REQUEST',
  UPDATE_FEATURES_SUCCESS: 'WORKSPACE_FEATURES_UPDATE_SUCCESS',
  UPDATE_FEATURES_FAILURE: 'WORKSPACE_FEATURES_UPDATE_FAILURE'
};
export default workspaceConstants;